import React from 'react';
import { Title } from '../../../components/Title/Title';
import { Organization } from '../../../lib/orgContainer';
import { ProfilePageWrapper } from '../ProfilePageWrapper';
import { ManualPayment } from '../Subscriptions/ManualPayment';
import { InvoicesTable } from './InvoicesTable';
import { ProfileRouterChildProps } from '../Profile.router';

export const BillingPage: React.FunctionComponent<ProfileRouterChildProps> = ({ me }) => {
  const { org } = Organization.useContainer();

  if (org?.manualPayment) return <ManualPayment me={me} />;

  return (
    <ProfilePageWrapper me={me}>
      <Title is3>Invoices</Title>
      <InvoicesTable />
    </ProfilePageWrapper>
  );
};
