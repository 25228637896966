import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import './radio-tab.scss';
import { RadioTabProps } from './RadioTab.types';

export const RadioTab: React.FunctionComponent<RadioTabProps> = ({
  onChange,
  value,
  options,
  form,
  disabled,
  ...props
}) => {
  const [v, setValue] = useState<any>(value);
  useEffect(() => setValue(value), [value]);

  useEffect(() => {
    // Update formik
    if (form && props.name) {
      form.setFieldTouched(props.name);
      form.setFieldValue(props.name!, v);
    } else if (onChange) onChange(v);
  }, [v]);

  return (
    <div
      className={classnames({
        'radio-tab': true,
        'radio--disabled': disabled
      })}
    >
      {options.map(({ label, value: _v }, i) => (
        <div key={i} className={classnames({ active: v === _v })} onClick={() => setValue(_v)}>
          {' '}
          {label}{' '}
        </div>
      ))}
    </div>
  );
};
