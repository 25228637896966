import gql from 'graphql-tag';

export const teamQuery = gql`
  query team($id: Float!, $clubOverride: Boolean) {
    team(id: $id, clubOverride: $clubOverride) {
      id
      name
      gfxName
      gfxFullName
      abbreviation
      primaryBackgroundColor
      primaryTextColor
      secondaryBackgroundColor
      secondaryTextColor
      defaultVenueId
      countMatches
      countPlayers
      defaultVenueId
      archived
      age
      gender
      club {
        id
      }
      grade {
        id
      }
      competitions {
        id
        name
      }
      sport {
        id
        name
      }
      logo {
        id
        url
      }
      baseEntity {
        id
        name
        gfxName
        gfxFullName
        abbreviation
      }
    }
  }
`;
