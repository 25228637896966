import React from 'react';
import { Route, Switch } from 'react-router';
import { withTitle } from '../../../components/PageTitle/PageTitle';
import { routes } from '../../../router/Routes';
import { PlayersPage } from './PlayersPage/Players.page';
import { NewPlayerPage } from './NewPlayerPage';
import { UpdatePlayerPage } from './UpdatePlayerPage';
import { PageMenu } from '../../../components/PageMenu';
import { PlayersSideMenu } from './PlayersSideMenu';

export const PlayersRouter: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route
        exact
        path={routes.assetsPlayers(false)}
        component={withTitle({ component: PlayersPage, pageTitle: 'Players' })}
      />
      <Route path="*">
        <PageMenu title="Players" component={PlayersSideMenu} />
        <Switch>
          <Route
            exact
            path={routes.assetPlayerCreate(false)}
            component={withTitle({ component: NewPlayerPage, pageTitle: 'New Player' })}
          />
          <Route
            exact
            path={[routes.assetPlayerEdit(false), routes.assetTeamPlayersEdit(false)]}
            component={withTitle({ component: UpdatePlayerPage, pageTitle: 'Update Player' })}
          />
        </Switch>
      </Route>
    </Switch>
  );
};
