import { Player } from '../../../../api-types';
import { Icon } from '@ligr/shared';
import React from 'react';
import { useHistory } from 'react-router';
import { ConfirmWindow } from '../../../../components/ConfirmWindow';
import { ConfirmWindowPlayerContent } from '../../../../components/ConfirmWindow/Content/PlayerContent';
import { ContextMenu } from '../../../../components/ContextMenu';
import { Notification } from '../../../../lib/notificationContainer';
import { useArchivePlayer } from '../../../../lib/useArchivePlayer';
import { routes } from '../../../../router/Routes';

interface PlayerMenuProps {
  player: Player;
}

export const PlayerMenu: React.FunctionComponent<PlayerMenuProps> = ({ player }) => {
  const { archivePlayer } = useArchivePlayer();
  const { addNotification } = Notification.useContainer();
  const history = useHistory<{ referrer?: string } | undefined>();

  const archivePlayerFn = async (playerId: number) => {
    await archivePlayer(playerId);
    addNotification('Player archived successfully.', 'success');
    history.push(routes.assetsPlayers());
  };

  return (
    <ContextMenu
      place="bottom"
      clickable={true}
      className="pageHeader__menu playerMenu"
      renderMenu={() => {
        return (
          <ul>
            <li>
              <ConfirmWindow
                action={() => archivePlayerFn(player.id)}
                title={`Archive competiton`}
                content={<ConfirmWindowPlayerContent player={player} />}
              >
                <Icon icon="close" />
                <span>Archive player</span>
              </ConfirmWindow>
            </li>
          </ul>
        );
      }}
    >
      <Icon icon="dropdown" color="grey-600" />
    </ContextMenu>
  );
};
