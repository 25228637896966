import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { ReactStripeElements } from 'react-stripe-elements';
import { useErrorHandledMutation } from '../../../../lib/apolloHooks';
import { Me as MeContainer } from '../../../../lib/meContainer';
import { Notification } from '../../../../lib/notificationContainer';
import { CurrentSubscription, Selection, StripeSubscriptionVariables } from '../Subscription.type';
import { Organization } from '../../../../api-types';

interface ActionsProps {
  stripe: ReactStripeElements.StripeProps;
  currentSubscription?: CurrentSubscription;
  selection: Selection;
  updateSelection: (selection: Selection) => void;
  toggleForm: (b: boolean) => void;
  toggleCardForm: (b: boolean) => void;
}

export interface SubscribeMutation {
  createOrUpdateSubscription: Organization;
}

export const subscribeMutation = gql`
  mutation createOrUpdateSubscription($plan: String!, $quantity: Float!, $source: String, $ccLast4: String) {
    createOrUpdateSubscription(source: $source, plan: $plan, quantity: $quantity, ccLast4: $ccLast4) {
      id
      stripeCustomerId
      stripePlanId
      stripeCardLast4
    }
  }
`;

export interface UpdateCardMutation {
  updateCard: Organization;
}

const updateCardMutation = gql`
  mutation updateCard($source: String!, $ccLast4: String!) {
    updateCard(source: $source, ccLast4: $ccLast4) {
      id
      stripeCustomerId
      stripePlanId
      stripeCardLast4
    }
  }
`;

export const Actions: React.FunctionComponent<ActionsProps> = ({
  stripe,
  currentSubscription,
  selection,
  updateSelection,
  toggleForm,
  toggleCardForm
}) => {
  const [settingNewPlan, changingPlan] = useState<boolean>(false);
  const [subscribe] = useErrorHandledMutation<SubscribeMutation>(subscribeMutation);
  const [updateCard] = useErrorHandledMutation<UpdateCardMutation>(updateCardMutation);
  const { addNotification } = Notification.useContainer();
  const { refetch } = MeContainer.useContainer();

  useEffect(() => {
    if (selection.plan || selection.quantity) {
      if (currentSubscription) {
        if (selection.plan && currentSubscription.stripePlanId !== selection.plan.stripeId) {
          changingPlan(true);
        } else if (currentSubscription.planQuantity !== selection.quantity) {
          changingPlan(true);
        }
      } else {
        changingPlan(true);
      }
    }
  }, [currentSubscription, selection]);

  const createOrUpdateSubscription = async () => {
    const action = currentSubscription && currentSubscription.stripePlanId ? 'updated' : 'created';
    const variables: StripeSubscriptionVariables = {
      plan: selection.plan!.stripeId!,
      quantity: selection.quantity!
    };
    if (selection.validCard && selection.cardChanged) {
      const { token, error } = await stripe.createToken({ name: variables.plan });
      if (error || !token) {
        addNotification(`Subscription could not be ${action} (TOKEN ERROR). Please contact our support`, 'error');
        return;
      }
      variables.source = token.id;
      variables.ccLast4 = token.card!.last4;
    }
    const { data } = await subscribe({ variables });
    if (data && data.createOrUpdateSubscription) {
      addNotification(`Subscription ${action} successfully.`, 'success');
      refetch();
    } else {
      addNotification(`Subscription could not be ${action} (PAYMENT ERROR). Please contact our support`, 'error');
    }
  };

  const changeCard = async () => {
    const { token, error } = await stripe.createToken();
    if (!error && token) {
      const { data } = await updateCard({ variables: { source: token.id, ccLast4: token.card!.last4 } });
      if (data && data.updateCard) {
        addNotification('Card updated successfully.', 'success');
        refetch();
        toggleForm(false);
      } else {
        addNotification('Card could not be updated (PAYMENT ERROR). Please contact our support', 'error');
      }
    } else {
      addNotification('Card could not be updated (TOKEN ERROR). Please contact our support', 'error');
    }
  };

  return (
    <div className="subscription-actions">
      {(selection.plan || selection.quantity || (currentSubscription && currentSubscription.stripePlanId)) && (
        <button
          className="button is-borderless cancel"
          onClick={_e => {
            updateSelection({ cycle: selection.cycle, cardChanged: false, validCard: false });
            if (currentSubscription && currentSubscription.stripePlanId) {
              toggleForm(false);
              toggleCardForm(false);
            }
          }}
        >
          Cancel
        </button>
      )}
      {selection.plan && settingNewPlan ? (
        selection.plan.custom ? (
          <a className="button is-primary" href="//www.ligrsystems.com/contact-us" target="_blank">
            Contact us
          </a>
        ) : (
          <button
            className={`button ${!selection.validCard ? 'is-disabled' : null}`}
            onClick={createOrUpdateSubscription}
          >
            Upgrade
          </button>
        )
      ) : selection.cardChanged ? (
        <button className="button" onClick={changeCard}>
          Update card
        </button>
      ) : null}
    </div>
  );
};
