import { FormikContextType } from 'formik';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { object, string } from 'yup';
import { Button } from '../../../components/Button';
import { ButtonBottom as BottomButtonRow } from '../../../components/ButtonBottom/ButtonBottom';
import { Form } from '../../../components/Form/Form';
import { FormField } from '../../../components/FormField';
import { PageContent } from '../../../components/page/PageContent';
import { PageContentContent } from '../../../components/page/PageContentContent';
import { PageHeader } from '../../../components/PageHeader';
import { PageMenu } from '../../../components/PageMenu';
import { getGQLError } from '../../../lib/apolloClient';
import { useErrorHandledMutation, useErrorHandledQuery } from '../../../lib/apolloHooks';
import { Notification } from '../../../lib/notificationContainer';
import { routes } from '../../../router/Routes';
import { AdSetsSideMenu } from '../AdsSetsSideMenu';
import { AdSetImagesPage } from './AdSetImageForm';
import { AdSetMenu } from './AdSetMenu';
import { UpdateAdSetMutation } from '../../../api-types';
import './style.scss';

const adSetsQuery = gql`
  query adSets($options: IQueryOptions) {
    adSets(options: $options) {
      id
      name
      countAdAllocations
      adFiles {
        id
        adSetId
        file {
          id
          url
          entityId
          entityType
        }
        size
      }
    }
  }
`;

const updateAdSet = gql`
  mutation updateAdSet($updateAdSetInput: UpdateAdSetInput!) {
    updateAdSet(updateAdSetInput: $updateAdSetInput) {
      id
      name
    }
  }
`;

const validationSchema = object().shape({
  name: string().required().label('Name')
});

type AdSet = {
  name: string;
  id: number;
  countAdAllocations: number;
  adFiles: {
    size: string;
    id: number;
    file?: {
      url: string;
    };
  }[];
};

export const AdSetPage: React.FunctionComponent = () => {
  const { adSetId: id } = useParams<{ adSetId: string }>();
  const adSetId = parseInt(id);
  const [update] = useErrorHandledMutation<UpdateAdSetMutation>(updateAdSet);
  const [form, setForm] = useState<FormikContextType<any> | null>(null);
  const [error, setError] = useState('');
  const { addNotification } = Notification.useContainer();

  const { data: adSetsData, loading } = useErrorHandledQuery<{
    adSets: AdSet[];
  }>(adSetsQuery);

  const adSets = (adSetsData && adSetsData.adSets) || [];
  const adSet = adSets.find(({ id }) => id === adSetId);

  const submit = async (values: AdSet) => {
    try {
      const adSet = { ...values };
      await update({ variables: { updateAdSetInput: adSet } });
      addNotification('Ad set successfully updated.', 'success');
      form?.resetForm();
    } catch (e) {
      setError(getGQLError(e).message);
    }
  };

  return (
    <>
      <PageMenu title="All ad sets">
        <AdSetsSideMenu adSets={adSets} />
      </PageMenu>
      <PageContent loading={loading}>
        {adSet && (
          <>
            <PageHeader
              title={adSet.name}
              preTitle={''}
              close={routes.adSets()}
              editMenu={() => <AdSetMenu adSet={adSet} />}
            />
            <PageContentContent>
              <Form
                error={error}
                onSubmit={submit}
                className={'adSetFormContainer'}
                validationSchema={validationSchema}
                withSubmit
                initialValues={{
                  id: adSet?.id,
                  name: adSet?.name
                }}
                useConfirm={true}
                setForm={setForm}
              >
                <div className="adSet__label">
                  <div className="adSet__label__text">Name</div>
                </div>
                <FormField name="name" type="text" className="adSet__field" colSpan={2} />
              </Form>
              <AdSetImagesPage adSet={adSet} />
              {form && form.dirty && (
                <BottomButtonRow withResetFormButton resetOnClick={() => form.resetForm()}>
                  <Button
                    onClick={event => {
                      event.preventDefault();
                      form.submitForm();
                    }}
                    type="primary"
                    isDisabled={!form.isValid}
                    isLoading={loading}
                  >
                    Save
                  </Button>
                </BottomButtonRow>
              )}
            </PageContentContent>
          </>
        )}
      </PageContent>
    </>
  );
};
