import { calcClock, EFinishedStatus } from '@ligr/api-v2';
import { Match, MatchSummary } from '../../../../api-types';
import { getCurrentPeriod, useInterval } from '@ligr/shared';
import React, { useState } from 'react';
import { CancelledCell } from './CancelledCell';
import { FinishedCell } from './FinishedCell';
import { InProgressCell } from './InProgressCell';
import { PregameCell } from './PregameCell';
import './style.scss';

interface MatchDateCellProps {
  match: Match;
  summary: MatchSummary;
}

export const MatchDateCell: React.FunctionComponent<MatchDateCellProps> = ({ match, summary }) => {
  // Move higher up and use context if causing performance issues
  const [_bumpClock, setClockBump] = useState(0);
  useInterval(() => {
    setClockBump(state => state + 1);
  }, 20000);

  const finishedStatus = match.finishedStatus;
  const clockData = calcClock(
    match.date,
    match.sportName,
    summary.clock,
    summary.periods,
    summary.currentPeriod,
    match.config
  );
  const currentPeriod = getCurrentPeriod<typeof summary.periods[0]>(summary);

  const scoreString = `${summary.matchStats.score.home} - ${summary.matchStats.score.away}`;

  switch (finishedStatus) {
    case EFinishedStatus.pregame:
      return (
        <PregameCell
          archived={match.archived}
          date={match.date}
          expectedSecondsTillStart={clockData.expectedSecondsTillStart!}
          countdown={clockData.countdown}
        />
      );
    case EFinishedStatus.finished:
      return <FinishedCell score={scoreString} />;
    case EFinishedStatus.cancelled:
      return <CancelledCell />;
    case EFinishedStatus.inProgress:
      return (
        <InProgressCell
          currentPeriodStatus={currentPeriod.status}
          currentPeriodName={currentPeriod.name}
          clock={clockData.clock}
          score={scoreString}
        />
      );

    default:
      return null;
  }
};
