import gql from 'graphql-tag';

export const searchPlayersQuery = gql`
  query searchPlayers(
    $options: IQueryOptions
    $searchFromStart: Boolean
    $searchOnFields: [String!]!
    $searchStr: String!
  ) {
    searchPlayers(
      options: $options
      searchFromStart: $searchFromStart
      searchOnFields: $searchOnFields
      searchStr: $searchStr
    ) {
      id
      firstName
      lastName
      playsFor {
        team {
          id
          logo {
            id
            url
          }
        }
        photo {
          id
          url
        }
      }
    }
  }
`;
