import './style.scss';

import { GraphicsProps, SportsWithSpaceEnum } from '@ligr/api-v2';
import { detachedAd } from '@ligr/overlay/lib/components/DetatchedAd';
import { OverlayWithoutClockCorrection as OverlayPrev } from '@ligr/overlay/lib/pages/Overlay';
import { ITheme } from '@ligr/themes/lib/Theme.type';
import camelCase from 'camelcase';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Manager, Popper } from 'react-popper';
import { ApolloQueryResult } from 'apollo-client';

import { useErrorHandledQuery } from '../../lib/apolloHooks';
import { ExpiringLocalStorage } from '../../lib/localStorage';
import { factsQuery } from '../../lib/gql/queries/facts';
import { mainEventsPerSport } from '../Overlay';
import { ColumnManager } from './ColumnManager';
import { formatControlLayout } from './FormatControlLayout';
import {
  FactsQuery,
  OverlayWithFullMatchQuery,
  FileEntityType,
  SummaryFragment,
  MatchStats,
  Fact
} from '../../api-types';
import { OverlayControlRightColumn } from './OverlayControlRightColumn';
import { TPlayerFact } from './OverlayControlRightColumn/types';

export type ControlGridAdSet = {
  id: number;
  name: string;
  adFiles?: {
    id: number;
    adSetId: number;
    size: string;
    file?: { id: number; url: string; entityId: number; entityType: FileEntityType } | null;
  }[];
};

type ControlGridProps = {
  theme?: ITheme;
  manualGraphicState: GraphicsProps;
  adSets: ControlGridAdSet[];
  match: ApolloQueryResult<OverlayWithFullMatchQuery>['data']['overlay']['match'];
  overlay: ApolloQueryResult<OverlayWithFullMatchQuery>['data']['overlay'];
  summary: SummaryFragment;
  setOpenGraphicPreview: (graphicName: string) => void;
  openGraphicPreview?: string;
  sportName: string;
};

export const ControlGrid: React.FunctionComponent<ControlGridProps> = ({
  overlay,
  theme,
  summary,
  match,
  manualGraphicState,
  adSets,
  setOpenGraphicPreview,
  openGraphicPreview,
  sportName
}) => {
  const [previewState, setPreviewState] = useState(ExpiringLocalStorage.get(`local-overlay-state-${overlay.id}`) || {});

  const rootRef = useRef<(() => void) | null>(null);

  const { data } = useErrorHandledQuery<FactsQuery>(factsQuery, {
    variables: {
      matchId: overlay.match.id
    }
  });
  const facts = data?.facts;
  const playerFacts = facts?.filter((fact: Fact) => fact.playerId);

  const mainEvents = facts?.filter(({ name }: { name: string }) =>
    // @ts-ignore
    mainEventsPerSport[camelCase(sportName)].includes(name)
  );

  const updateGraphicPreviewState = (name: string) => (val: any) => {
    setPreviewState({
      ...previewState,
      [camelCase(name)]: val
    });
  };

  const manualState = useMemo(() => {
    if (!openGraphicPreview) {
      return {
        ...overlay.manualGraphicState,
        ...previewState
      };
    }
    return {
      ...overlay.manualGraphicState,
      ...previewState,
      [camelCase(openGraphicPreview)]: {
        ...overlay.manualGraphicState[camelCase(openGraphicPreview)],
        ...previewState[camelCase(openGraphicPreview)]
      }
    };
  }, [openGraphicPreview, overlay.manualGraphicState, previewState]);

  useEffect(() => {
    ExpiringLocalStorage.set(`local-overlay-state-${overlay.id}`, manualState);
  }, [manualState]);

  const columns = theme?.sportId
    ? formatControlLayout(theme?.sportId, [...(theme?.graphics || []), detachedAd])
    : undefined;

  return (
    <div className="controlGrid">
      <Manager>
        <div className="controlGrid__col1">
          <ColumnManager
            colId={'col-1'}
            columns={columns}
            openGraphicPreview={openGraphicPreview}
            adSets={adSets}
            mainEvents={mainEvents}
            overlay={overlay}
            updateGraphicPreviewState={updateGraphicPreviewState}
            manualState={manualState}
            manualGraphicState={manualGraphicState}
            rootRef={rootRef}
            setOpenGraphicPreview={setOpenGraphicPreview}
            match={match}
            summary={summary}
          />
        </div>
        <div className="controlGrid__col2">
          <ColumnManager
            colId={'col-2'}
            columns={columns}
            openGraphicPreview={openGraphicPreview}
            adSets={adSets}
            mainEvents={mainEvents}
            overlay={overlay}
            updateGraphicPreviewState={updateGraphicPreviewState}
            manualState={manualState}
            manualGraphicState={manualGraphicState}
            rootRef={rootRef}
            setOpenGraphicPreview={setOpenGraphicPreview}
            match={match}
            summary={summary}
          />
        </div>
        <div className="controlGrid__col3">
          <ColumnManager
            colId={'col-3'}
            columns={columns}
            openGraphicPreview={openGraphicPreview}
            adSets={adSets}
            mainEvents={mainEvents}
            overlay={overlay}
            updateGraphicPreviewState={updateGraphicPreviewState}
            manualState={manualState}
            manualGraphicState={manualGraphicState}
            rootRef={rootRef}
            setOpenGraphicPreview={setOpenGraphicPreview}
            match={match}
            summary={summary}
          />
        </div>
        <OverlayControlRightColumn
          playerFacts={playerFacts as TPlayerFact[]}
          matchStats={summary.matchStats as MatchStats}
          sportName={match.sportName as SportsWithSpaceEnum}
          homeTeamLogoUrl={match.homeTeam.logo?.url}
          awayTeamLogoUrl={match.awayTeam.logo?.url}
          competitionId={match.competitionId}
        />

        <Popper
          placement="left"
          modifiers={{
            offset: {
              offset: 55
            }
          }}
        >
          {// @ts-ignore
          ({ ref, style, placement, arrowProps, scheduleUpdate }) => {
            rootRef.current = scheduleUpdate;

            const customArrowStyles: React.CSSProperties = {};

            if (placement === 'left') {
              customArrowStyles.left = '478px';
              customArrowStyles.transform = 'rotate(180deg)';
            }

            return (
              <div
                className="overlayControl__previewBg"
                // @ts-ignore
                data-state={overlay.manualGraphicState[camelCase(openGraphicPreview)] ? 'showing' : 'previewing'}
                ref={ref}
                style={{
                  zIndex: 600,
                  display: !openGraphicPreview ? 'none' : undefined,
                  ...style,
                  left: placement === 'right' ? '20px' : '-20px'
                }}
                data-placement={placement}
              >
                <div
                  // @ts-ignore
                  data-state={overlay.manualGraphicState[camelCase(openGraphicPreview)] ? 'showing' : 'previewing'}
                  className="overlayControl__previewBgArrow"
                  ref={arrowProps.ref}
                  style={{ ...arrowProps.style, ...customArrowStyles }}
                >
                  <svg width="100%" height="100%" viewBox="0 0 400 600">
                    <polygon className="overlayControl__previewBgArrow" points="100,300 400,600 400,0" />
                  </svg>
                </div>
                <OverlayPrev
                  overlay={overlay}
                  resizable
                  noAnimation
                  manualState={manualState}
                  overlaySettings={{
                    ...overlay,
                    ...{
                      background: 'demo',
                      autoMode: false
                    }
                  }}
                  filteredGraphics={openGraphicPreview ? [openGraphicPreview] : []}
                />
              </div>
            );
          }}
        </Popper>
      </Manager>
    </div>
  );
};
