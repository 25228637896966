import React from 'react';
import { Route, Switch } from 'react-router';
import { Elements, injectStripe, StripeProvider } from 'react-stripe-elements';
import { Page } from '../../components/page/Page';
import { withTitle } from '../../components/PageTitle/PageTitle';
import { CONFIG } from '@ligr/shared';
import { routes } from '../../router/Routes';
import { BillingPage } from './Billing/Billing.page';
import './profile.page.scss';
import { ProfileOrganizationForm } from './ProfileOrganization.form';
import { ProfilePasswordForm } from './ProfilePassword.form';
import { ProfilePermissionForm } from './ProfilePermission.form';
import { ProfileSettingsForm } from './ProfileSettings.form';
import { ProfileSubscriptionForm } from './ProfileSubscription.form';
import { Me as MeContainer } from '../../lib/meContainer';
import { Loader } from '../../components/loader/Loader';
import { MeQuery } from '../../api-types';
import { ApolloQueryResult } from 'apollo-client';

export type ProfileRouterChildProps = {
  me: ApolloQueryResult<MeQuery>['data']['me'];
};

export const ProfileRouter: React.FunctionComponent = () => {
  const meContainer = MeContainer.useContainer();

  const ready = !meContainer.called || meContainer.loading;

  return (
    <Page type="profile">
      {ready ? (
        <Switch>
          <Route
            exact
            path={routes.profile()}
            component={withTitle({
              component: ProfileSettingsForm,
              pageTitle: 'Profile - Settings',
              me: meContainer.me!
            })}
          />
          <Route
            exact
            path={routes.profileOrganization()}
            component={withTitle({
              component: ProfileOrganizationForm,
              pageTitle: 'Profile - Organization',
              me: meContainer.me!
            })}
          />
          <Route
            exact
            path={routes.profilePassword()}
            component={withTitle({
              component: ProfilePasswordForm,
              pageTitle: 'Profile - Security',
              me: meContainer.me!
            })}
          />
          <Route
            path={routes.permissions()}
            component={withTitle({
              component: ProfilePermissionForm,
              pageTitle: 'Profile - Permissions',
              me: meContainer.me!
            })}
          />
          <Route
            path={routes.billing()}
            component={withTitle({ me: meContainer.me!, component: BillingPage, pageTitle: 'Profile - Billing' })}
          />
          {/* This must be the last item, otherwise the Switch breaks. TODO invert order of component so
         Route is the highest. */}
          <StripeProvider apiKey={CONFIG.STRIPE_PUBLISHABLE_KEY}>
            <Elements>
              <Route
                exact
                path={routes.subscriptions()}
                component={withTitle({
                  // @ts-ignore
                  component: injectStripe(ProfileSubscriptionForm),
                  pageTitle: 'Profile - Subscription',
                  me: meContainer.me!
                })}
              />
            </Elements>
          </StripeProvider>
        </Switch>
      ) : (
        <Loader />
      )}
    </Page>
  );
};
