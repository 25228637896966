import React from 'react';
import './style.scss';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface NotificationProps {
  text?: string;
  type?: NotificationType;
}

export const NotificationMessage: React.FunctionComponent<NotificationProps> = ({
  text,
  type = 'warning',
  children
}) => {
  return <div className={`notification notification--${type}`}>{text || children}</div>;
};
