// import { OverlayWithoutClockCorrection as OverlayPrev } from '@ligr/overlay/lib/pages/Overlay';
import { GraphicsProps } from '@ligr/api-v2';
import { OverlayWithFullMatchQuery, SummaryFragment } from '../../api-types';

import { IGraphic } from '@ligr/themes';
import camelCase from 'camelcase';
import React from 'react';
import { Reference } from 'react-popper';
import { GraphicControl } from '../../components/GraphicControl';
import { Title } from '../../components/Title/Title';
import { IColumns } from './FormatControlLayout';
import { ApolloQueryResult } from 'apollo-client';
import { ControlGridAdSet } from './ControlGrid';

interface ColumnManagerProps {
  colId: 'col-1' | 'col-2' | 'col-3';
  columns?: IColumns;
  openGraphicPreview?: string;
  adSets: ControlGridAdSet[];
  mainEvents: any;
  overlay: ApolloQueryResult<OverlayWithFullMatchQuery>['data']['overlay'];
  updateGraphicPreviewState: (name: string) => (val: any) => void;
  manualState: any;
  manualGraphicState: GraphicsProps;
  rootRef: React.MutableRefObject<any>;
  setOpenGraphicPreview: any;
  match: ApolloQueryResult<OverlayWithFullMatchQuery>['data']['overlay']['match'];
  summary: SummaryFragment;
}

export const ColumnManager: React.FunctionComponent<ColumnManagerProps> = ({
  colId,
  columns,
  openGraphicPreview,
  adSets,
  mainEvents,
  overlay,
  updateGraphicPreviewState,
  manualState,
  manualGraphicState,
  rootRef,
  setOpenGraphicPreview,
  match,
  summary
}) => {
  return (
    <Reference>
      {({ ref }) => (
        <div ref={ref}>
          {columns &&
            Object.keys(columns[colId]).map(key => {
              return (
                <React.Fragment key={key}>
                  <Title is4 className="controlGrid__title">
                    {key}
                  </Title>
                  {columns[colId][key].map((graphic: IGraphic) => {
                    const isPreviewing = openGraphicPreview === graphic.name;
                    return (
                      <GraphicControl
                        // @ts-ignore
                        forwardRef={openGraphicPreview === graphic.name ? ref : undefined}
                        adSets={adSets}
                        facts={mainEvents}
                        overlayId={overlay.id}
                        overlay={overlay}
                        isPreviewing={isPreviewing}
                        key={graphic.name}
                        onChange={updateGraphicPreviewState(graphic.name)}
                        localState={manualState[camelCase(graphic.name)]}
                        remoteState={manualGraphicState[camelCase(graphic.name)]}
                        graphic={graphic}
                        // @ts-ignore
                        triggerToGraphicData={graphic.triggerToGraphicData}
                        onSizeChange={() => rootRef.current && rootRef.current()}
                        onPreviewClick={() => setOpenGraphicPreview(isPreviewing ? '' : graphic.name)}
                        match={match}
                        summary={summary}
                      />
                    );
                  })}
                </React.Fragment>
              );
            })}
        </div>
      )}
    </Reference>
  );
};
