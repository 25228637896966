import './missing-entities-component.scss';

import React from 'react';
import { MissingEntitiesComponentProps } from './MissingEntitiesComponent.type';
import { Button } from '../Button';
import { Title } from '../Title/Title';

export const MissingEntitiesComponent: React.FunctionComponent<MissingEntitiesComponentProps> = ({
  text,
  buttonText,
  url,
  modal
}) => {
  return (
    <div className="missing-entities">
      <Title is3>{text}</Title>
      {url && buttonText && (
        <Button href={url} type="primary" onClick={modal}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};
