import { freezePlan } from '@ligr/api-v2';
import gql from 'graphql-tag';
import React, { useEffect, useRef, useState } from 'react';
import { useErrorHandledMutation } from '../../../../lib/apolloHooks';
import { Me as MeContainer } from '../../../../lib/meContainer';
import { Notification } from '../../../../lib/notificationContainer';
import { CurrentSubscription } from '../Subscription.type';
import { SubscribeMutation, subscribeMutation } from './Actions';
import { Organization } from '../../../../api-types';
import './CancelSubscriptionForm.scss';

interface CancelSubscriptionFormProps {
  currentSubscription?: CurrentSubscription;
}

interface CancelSubscriptionMutation {
  cancelSubscription: Organization;
}

interface Reason {
  value: string;
  label: string;
}

const cancelSubscriptionMutation = gql`
  mutation cancelSubscription($reason: String!) {
    cancelSubscription(reason: $reason) {
      id
      stripeCustomerId
      stripePlanId
      stripeCardLast4
    }
  }
`;

const reasons: Reason[] = [
  { value: 'freeze', label: 'I want to freeze my account. (Price may apply)' },
  { value: 'no_longer_working', label: 'I’m no longer working on this project' },
  { value: 'not_right_time', label: "This is not the right time for me to use LIGR's product." },
  { value: 'features', label: 'LIGR does not have the features I want.' },
  { value: 'technical', label: 'I had technical issues with the product and was not able to resolve them.' },
  { value: 'price', label: 'The price was too high for me.' },
  { value: 'other', label: 'Other' }
];

export const CancelSubscriptionForm: React.FunctionComponent<CancelSubscriptionFormProps> = ({
  currentSubscription
}) => {
  const { refetch } = MeContainer.useContainer();
  const [showReaons, toggleReasons] = useState(false);
  const [reason, setReason] = useState<Reason | undefined>(undefined);
  const otherReason = useRef<HTMLTextAreaElement>(null);
  const { addNotification } = Notification.useContainer();
  const [cancelSubscription] = useErrorHandledMutation<CancelSubscriptionMutation>(cancelSubscriptionMutation);
  const [subscribe] = useErrorHandledMutation<SubscribeMutation>(subscribeMutation);

  useEffect(() => {
    toggleReasons(!currentSubscription);
  }, [currentSubscription]);

  const freezeAccount = async () => {
    const { data } = await subscribe({ variables: { plan: freezePlan, quantity: 1 } });
    if (data && data.createOrUpdateSubscription) {
      addNotification('Subscription freezed successfully.', 'success');
    } else {
      addNotification('We were enable to freeze your account (PAYMENT). Please contact our support.', 'error');
    }
  };

  const cancelAccount = async (reason: Reason) => {
    const { data } = await cancelSubscription({
      variables: {
        reason: otherReason.current && reason.value === 'other' ? otherReason.current.value : reason.label
      }
    });
    if (data && data.cancelSubscription) {
      addNotification('Subscription cancelled successfully.', 'success');
    } else {
      addNotification('Subscription could not be cancelled (PAYMENT). Please contact our support', 'error');
    }
  };

  const submitCancelSubscription = async () => {
    if (reason) {
      if (reason.value === 'freeze') {
        await freezeAccount();
      } else {
        await cancelAccount(reason);
      }
      refetch();
    } else {
      addNotification('Subscription could not be cancelled (NO REASON). Please contact our support', 'error');
    }
  };

  if (!currentSubscription || (currentSubscription && !currentSubscription.stripePlanId)) return null;

  return (
    <div className="cancel-membership">
      <a className={`cancel-membership-link ${showReaons && 'is-disabled'}`} onClick={_e => toggleReasons(true)}>
        Freeze or Delete my account
      </a>
      {showReaons && (
        <>
          <div className="reasons">
            {reasons.map((reason, i) =>
              reason.value === 'freeze' && currentSubscription && !currentSubscription.stripeCardLast4 ? null : (
                <label key={i}>
                  <input type="radio" name="reason" value={reason.value} onChange={_e => setReason(reason)} />
                  {reason.label}
                </label>
              )
            )}
            {reason && reason.value === 'other' && <textarea ref={otherReason} name="reason-details"></textarea>}
          </div>
          <button
            className="button is-borderless cancel"
            onClick={_e => {
              setReason(undefined);
              toggleReasons(false);
            }}
          >
            Cancel
          </button>
          <button className={`button is-primary ${!reason ? 'is-disabled' : null}`} onClick={submitCancelSubscription}>
            Send
          </button>
        </>
      )}
    </div>
  );
};
