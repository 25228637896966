import { useSearchParams } from '@ligr/shared';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { TeamsWithCompQuery, Venue } from '../../../../api-types';
import { Button } from '../../../../components/Button';
import { CardVenue } from '../../../../components/CardVenue';
import { Filters } from '../../../../components/Filters';
import { Grid } from '../../../../components/Grid/Grid';
import { getMissingEntitiesComponentContent } from '../../../../components/MissingEntitiesComponent/getMissingEntitiesComponentContent';
import { MissingEntitiesComponent } from '../../../../components/MissingEntitiesComponent/MissingEntitiesComponent';
import { PageContent } from '../../../../components/page/PageContent';
import { PageContentContent } from '../../../../components/page/PageContentContent';
import { PageHeader } from '../../../../components/PageHeader';
import { PageSubHeader } from '../../../../components/PageSubHeader/PageSubHeader';
import { logDelay } from '../../../../lib/animation';
import { useErrorHandledLazyQuery } from '../../../../lib/apolloHooks';
import { teamsWithCompQuery } from '../../../../lib/gql/queries/teams';
import { venuesQuery } from '../../../../lib/gql/queries/venues';
import { Notification } from '../../../../lib/notificationContainer';
import { useArchiveVenue } from '../../../../lib/useArchiveVenue';
import { routes } from '../../../../router/Routes';
import { EntityEnum } from '../../../../types/assets';

interface VenuesPageProps {}

export const VenuesPage: React.FunctionComponent<VenuesPageProps> = () => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const searchParams = useSearchParams(['query', 'teams', 'archive']);
  const [fetchVenues, { data: venuesData, loading: venuesLoading, called }] = useErrorHandledLazyQuery<{
    venues: Venue[];
  }>(venuesQuery, { variables: { options: { archive: !!searchParams.archive } } });
  const { archiveVenue } = useArchiveVenue();
  const venues = (venuesData && venuesData.venues) || [];
  const [fetchTeams, { data: teamsData }] = useErrorHandledLazyQuery<TeamsWithCompQuery>(teamsWithCompQuery);
  const teams = teamsData ? teamsData.teams : [];
  const { addNotification } = Notification.useContainer();

  // Note can pass searchParams into the fetch as GQL variables
  // if we are server side searching
  useEffect(() => {
    fetchVenues();
    fetchTeams({});
  }, []);

  const archiveVenueFn = async (venueId: number) => {
    archiveVenue(venueId);
    addNotification('Venue archived successfully.', 'success');
  };

  const venuesSearch = useMemo(
    () =>
      venues.filter((venue: Venue) => {
        let query = true;
        if (searchParams.query) query = venue.name.toLowerCase().includes(searchParams.query.toLowerCase());
        if (searchParams.teams && teams.length > 0) {
          query = teams.find(t => t.id.toString() === searchParams.teams)?.defaultVenueId === venue.id;
        }
        return query;
      }),
    [searchParams.query, searchParams.teams, venues, teams]
  );

  const missingEntitiesDialogProps =
    called && !venuesLoading && getMissingEntitiesComponentContent(EntityEnum.venues, venues, venuesSearch);

  return (
    <>
      <PageContent loading={!called || venuesLoading}>
        <PageHeader title="Venues" />
        <PageContentContent>
          {venues.length > 0 && (
            <PageSubHeader>
              <Filters searchable="venues" filters={['teams', 'archive']} />
              <Button
                shrink={false}
                onClick={() => history.push({ pathname: routes.assetVenueCreate(), search: location.search })}
                icon="plus"
                iconPosition="left"
              >
                Create a venue
              </Button>
            </PageSubHeader>
          )}
          {missingEntitiesDialogProps ? (
            <MissingEntitiesComponent {...missingEntitiesDialogProps} />
          ) : (
            <Grid>
              {venuesSearch.map((v, i) => {
                const venueTeams = teams.filter(t => t.defaultVenueId === v.id);
                return (
                  <CardVenue
                    key={v.id}
                    venue={v}
                    teams={venueTeams}
                    {...logDelay(i)}
                    icon="close"
                    fn={() => archiveVenueFn(v.id)}
                  />
                );
              })}
            </Grid>
          )}
        </PageContentContent>
      </PageContent>
    </>
  );
};
