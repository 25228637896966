import gql from 'graphql-tag';
import { adSetFragment } from '../fragments/adSet';

export const createAdSetMutation = gql`
  mutation createAdSet($createAdSetInput: CreateAdSetInput!) {
    createAdSet(createAdSetInput: $createAdSetInput) {
      ...AdSet
    }
  }
  ${adSetFragment}
`;
