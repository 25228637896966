import { sportAdEvents, SportsEnum } from '@ligr/api-v2';
import { FormikConfig } from 'formik';
import gql from 'graphql-tag';
import React, { useMemo, useState } from 'react';
import { Form } from '../../../../../components/Form/Form';
import { adAllocationQuery } from '../../../../../lib/gql/queries/adAllocation';
import { useErrorHandledMutation } from '../../../../../lib/apolloHooks';
import { Notification } from '../../../../../lib/notificationContainer';
import { AdAllocationFormContent } from './AdSetsAllocationPageFormsContent';
import { AdAllocationForm, AdSetsAllocationsFormProps } from './AdSetsAllocationPageForms.types';
import { getSportValidationSchema } from './AdSetsAllocationPageForms.validation.';
import camelCase from 'camelcase';
import {
  AdAllocationEntityType,
  AdSet,
  AdsetAllocation,
  EntityAdAllocationUpdate,
  UpdateEntityAdAllocationMutation,
  CompetitionAdAllocationFragment,
  TeamAdAllocationFragment
} from '../../../../../api-types';

const getCurrentAllocation = (as: AdSet, allocationId: number) =>
  as.adSetAllocations
    ? as.adSetAllocations.find(({ entityAdAllocationId }: AdsetAllocation) => allocationId === entityAdAllocationId)
    : undefined;

const hasPercentageFilter = (as: AdSet, allocationId: number) => {
  const currentAllocation = getCurrentAllocation(as, allocationId);
  return currentAllocation ? currentAllocation.percentage : 0;
};

const updateAdAllocation = gql`
  mutation updateEntityAdAllocation($entityAdAllocationUpdate: EntityAdAllocationUpdate!) {
    updateEntityAdAllocation(entityAdAllocationUpdate: $entityAdAllocationUpdate)
  }
`;

const createUpdate = (
  values: AdAllocationForm,
  entityId: number,
  entityType: AdAllocationEntityType,
  initialValues: AdAllocationForm
) => {
  const update: EntityAdAllocationUpdate = {
    config: values.config,
    adSetAllocations: [],
    entityId,
    entityType
  };

  update.adSetAllocations = values.percentages
    .map(({ adSetId, percentage }) => {
      return {
        // @ts-ignore
        adSetId: parseInt(adSetId),
        percentage,
        events: Object.entries(values.events)
          .map(([event, eventAdSetId]) => {
            if (adSetId === eventAdSetId) {
              return event;
            }
          })
          .filter(val => val) as string[]
      };
    })
    .concat(
      initialValues.percentages
        .filter(({ adSetId }) => !values.percentages.find(({ adSetId: curAdSetId }) => curAdSetId === adSetId))
        .map(({ adSetId }) => {
          return {
            // @ts-ignore
            adSetId: parseInt(adSetId),
            percentage: 0,
            events: Object.entries(values.events)
              .map(([event, eventAdSetId]) => {
                if (adSetId === eventAdSetId) {
                  return event;
                }
              })
              .filter(val => val) as string[]
          };
        })
    );

  // prettier-ignore
  Object.entries(values.events)
    .filter(([_, adSetId]) => adSetId)
    .forEach(([event, eventAdSetId]) => {

      const found = update
        .adSetAllocations!
        // @ts-ignore
        .find(({ adSetId }) => adSetId === parseInt(eventAdSetId));

      if (!found) {
        update.adSetAllocations!.push({
          // @ts-ignore
          adSetId: parseInt(eventAdSetId),
          events: [event]
        });
      } else {
        if (!found.events) {
          found.events = [event];
        } else if (!found.events.includes(event)) {
          found.events.push(event);
        }
      }
    });

  return update;
};

const stripKeyFactory = (key: string) =>
  function stripKey(val: any) {
    if (typeof val === 'object' && !Array.isArray(val)) {
      delete val[key];
      Object.values(val).map((val: any) => stripKey(val));
    }
    return val;
  };

// Recursively strips __typename from objects
const strip__typenameKeys = stripKeyFactory('__typename');

export const AdSetsAllocationsForm: React.FunctionComponent<AdSetsAllocationsFormProps> = ({
  allocation,
  adSets: adSetsAll,
  entityType
}) => {
  const [error, setError] = useState();

  const sport = allocation.entity.sport;

  const { addNotification } = Notification.useContainer();

  const [updateEntityAdAllocationMutation, { loading }] = useErrorHandledMutation<UpdateEntityAdAllocationMutation>(
    updateAdAllocation,
    {
      refetchQueries: () => [
        {
          query: adAllocationQuery,
          variables: {
            adAllocationArgs: {
              id: allocation.id
            }
          }
        }
      ]
    }
  );

  const initialValues: any = useMemo(() => {
    const allEvents = sportAdEvents[camelCase(sport.name) as SportsEnum];
    return {
      new: '',
      percentages: (allocation as CompetitionAdAllocationFragment | TeamAdAllocationFragment).adSets
        // @ts-ignore
        .filter((as: AdSet) => hasPercentageFilter(as, allocation.id))
        .map((cur: AdSet) => {
          const currentAllocation = getCurrentAllocation(cur, allocation.id);

          return {
            adSetId: cur.id.toString(),
            percentage: currentAllocation ? currentAllocation.percentage : 0
          };
        }),
      events: {
        ...allEvents.reduce((all, event) => {
          const found = allocation.adSets
            // @ts-ignore
            .find((adSet: AdSet) => {
              //  @ts-ignore
              const currentAllocation = getCurrentAllocation(adSet, allocation.id);
              return currentAllocation ? currentAllocation.events!.includes(event as string) : false;
            });

          return {
            ...all,
            [event]: found ? found.id.toString() : ''
          };
        }, {})
      },
      config:
        entityType === AdAllocationEntityType.competition
          ? strip__typenameKeys((allocation as CompetitionAdAllocationFragment).config)
          : undefined
    };
  }, [allocation]);

  const onSubmit: FormikConfig<AdAllocationForm>['onSubmit'] = async (values: AdAllocationForm) => {
    const update = createUpdate(values, allocation.entityId, entityType, initialValues);
    try {
      await updateEntityAdAllocationMutation({
        variables: {
          entityAdAllocationUpdate: update
        }
      });
      addNotification('Ad Allocation successfully updated.', 'success');
    } catch (e) {
      setError(e);
    }
  };

  return (
    <>
      <Form
        error={error}
        withSubmit
        className="page-form"
        initialValues={initialValues}
        validationSchema={getSportValidationSchema(sport.name, entityType)}
        onSubmit={onSubmit}
        useConfirm={true}
      >
        <AdAllocationFormContent sport={sport} entityType={entityType} adSetsAll={adSetsAll} loading={loading} />
      </Form>
    </>
  );
};
