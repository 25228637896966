import { Sport } from '../../api-types';
import { updateQueryUrl } from '@ligr/shared';
import capitalize from 'capitalize';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { FilterOptions } from '.';
import { Select } from '../Select';
import { SelectOption } from '../Select/types';

interface SportFilterProps {
  sports: Sport[];
  setActiveFilter: (name: FilterOptions, value: string) => void;
}

export const SportFilter: React.FunctionComponent<SportFilterProps> = ({ sports, setActiveFilter }) => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const queryStrObject = queryString.parse(history.location.search);

  const options: SelectOption[] = useMemo(() => {
    const sportOptions = sports.map(sport => ({
      value: parseInt((sport.id as unknown) as string),
      label: capitalize(sport.name)
    })) as SelectOption[];

    return [{ value: '', label: 'All' } as SelectOption].concat(sportOptions);
  }, [sports]);

  const handleChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const val = (e.target as HTMLSelectElement).value === '' ? '' : parseInt((e.target as HTMLSelectElement).value);
    const optionValue = options.find(o => o.value.toString() === val.toString())!.label as string;
    setActiveFilter('sports', val === '' ? val : optionValue);
    updateQueryUrl(history, { sports: val, competitions: '', teams: '' });
  };

  return (
    <div className="filters__select__wrapper filters__select__wrapper--sport">
      <label className="filters__select__wrapper__label" htmlFor="sports">
        Sports
      </label>
      <Select
        name="sports"
        className="filters__select__wrapper__select"
        options={options}
        onChange={(e: React.FormEvent<HTMLSelectElement>) => handleChange(e)}
        value={queryStrObject?.sports || ''}
      />
    </div>
  );
};
