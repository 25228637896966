import { useSearchParams } from '@ligr/shared';
import clone from 'clone';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Button } from '../../../components/Button';
import { Filters } from '../../../components/Filters';
import { PageHeader } from '../../../components/PageHeader';
import { PageSubHeader } from '../../../components/PageSubHeader/PageSubHeader';
import { routes } from '../../../router/Routes';
import './style.scss';
import { Location } from 'history';

interface MatchHeaderProps {
  tab: string;
  createButton?: boolean;
  setStartDate: React.Dispatch<any>;
  setEndDate: React.Dispatch<any>;
}

export const MatchHeader: React.FunctionComponent<MatchHeaderProps> = ({
  tab,
  createButton = false,
  setStartDate,
  setEndDate
}) => {
  const params = ['competitions', 'teams', 'status', 'startAt', 'endAt'];
  const history = useHistory<{ referrer?: string | Location } | undefined>();
  const location = useLocation<{ referrer?: string | Location } | undefined>();
  const searchParams = useSearchParams(
    // @ts-ignore
    params
  );

  useEffect(() => {
    if (searchParams.startAt) {
      setStartDate(moment(searchParams.startAt, 'DD-MM-YYYY').format('MMM DD, YYYY'));
    } else {
      setStartDate('');
    }
  }, [searchParams.startAt]);

  useEffect(() => {
    if (searchParams.endAt) {
      setEndDate(moment(searchParams.endAt, 'DD-MM-YYYY').format('MMM DD, YYYY'));
    } else {
      setEndDate('');
    }
  }, [searchParams.endAt]);

  return (
    <>
      <PageHeader
        title="Matches"
        preTitle="manage"
        tabs={[
          { text: 'Live & Upcoming', to: routes.matchesLive() },
          { text: 'Not Started', to: routes.matchesNotStarted() },
          { text: 'Completed', to: routes.matchesCompleted() }
        ]}
      />
      <PageSubHeader className="filters">
        <Filters
          searchable="matches"
          filters={['sports', 'competitions', 'teams', 'status', 'archive', 'startAt', 'endAt']}
        />
        {createButton && (
          <Button
            className="pageSubHeader__button__create"
            onClick={() =>
              history.push({
                pathname: routes.createMatchFromLive(),
                search: location.search,
                state: {
                  referrer: clone(history.location)
                }
              })
            }
            type="primary"
          >
            Create match
          </Button>
        )}
      </PageSubHeader>
    </>
  );
};
