import { updateQueryUrl } from '@ligr/shared';
import gql from 'graphql-tag';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from '../../components/Button';
import { Loader } from '../../components/loader/Loader';
import { SearchField } from '../../components/SearchField/SearchField';
import { Table } from '../../components/Table/Table';
import { useErrorHandledLazyQuery, useErrorHandledMutation } from '../../lib/apolloHooks';
import { Notification } from '../../lib/notificationContainer';
import { routes } from '../../router/Routes';
import { AdminWrapper } from './Admin.wrapper';
import { competitionBasesQuery as baseCompetitionsQuery } from './gql';
import {
  CompetitionBase,
  OrganizationAdapter,
  SearchOrganizationAdaptersQuery,
  CompetitionBasesQuery,
  DeleteOrganizationAdapterMutation
} from '../../api-types';

const organizationAdapterQuery = gql`
  query searchOrganizationAdapters($query: String!) {
    searchOrganizationAdapters(query: $query) {
      id
      organization {
        id
        name
      }
      providerName
      providerCompetitionId
    }
  }
`;
const disconnectAdapterMutation = gql`
  mutation deleteOrganizationAdapter($id: Float!) {
    deleteOrganizationAdapter(id: $id)
  }
`;

export const AdminAdapters: React.FunctionComponent = () => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const searchParsed = queryString.parse(history.location.search);
  const [query, setQuery] = useState(searchParsed.query || '');

  const [fetch, { data, loading }] = useErrorHandledLazyQuery<SearchOrganizationAdaptersQuery>(
    organizationAdapterQuery
  );
  const [fetchBaseComps, { data: dataBaseComps, loading: loadingBaseComps }] = useErrorHandledLazyQuery<
    CompetitionBasesQuery
  >(baseCompetitionsQuery);
  const [deleteConnection] = useErrorHandledMutation<DeleteOrganizationAdapterMutation>(disconnectAdapterMutation);

  const { addNotification } = Notification.useContainer();

  const organizationAdapters = data?.searchOrganizationAdapters || [];
  const competitionBases = dataBaseComps?.competitionBases || [];

  useEffect(() => {
    submit();
  }, []);

  const submit = () => {
    fetch({ variables: { query } });
    fetchBaseComps();
  };

  useEffect(() => {
    if (query === '') submit();
  }, [query]);

  const deleteConnectionFn = async (id: number) => {
    const res = await deleteConnection({
      variables: { id },
      update: cache => {
        const queryCache = cache.readQuery<{ searchOrganizationAdapters: OrganizationAdapter[] }>({
          query: organizationAdapterQuery,
          variables: { query }
        });

        if (queryCache) {
          cache.writeQuery({
            query: organizationAdapterQuery,
            variables: { query },
            data: {
              searchOrganizationAdapters: queryCache.searchOrganizationAdapters.filter(oa => oa.id !== id)
            }
          });
        }
      }
    });
    if (res?.data?.deleteOrganizationAdapter) {
      addNotification('Connection removed sucessfully', 'success');
    }
  };

  if (loading || loadingBaseComps) return <Loader />;

  return (
    <AdminWrapper>
      <div className="admin-search">
        <SearchField
          placeholder="Search organization by name"
          onChange={(value: any) => {
            updateQueryUrl(history, { query: value });
            setQuery(value);
          }}
          value={query}
        />
        <Button onClick={submit}>Search</Button>
        <Button
          shrink={false}
          onClick={() =>
            history.push({
              pathname: routes.adminConnectAdapter(),
              search: location.search
            })
          }
        >
          Connect Organization
        </Button>
      </div>
      <div className="search-organizations">
        <Table
          className="adminAdapters__table"
          data={organizationAdapters}
          small
          columns={[
            {
              Header: 'Organization',
              accessor: 'organization',
              Cell: ({ row }) => <div>{row.original.organization.name}</div>
            },
            {
              Header: 'Provider Name',
              accessor: 'providerName',
              Cell: ({ cell }) => <div>{cell.value}</div>
            },
            {
              Header: 'External Competition ID',
              accessor: 'providerCompetitionId',
              Cell: ({ row, cell }) => {
                const baseComp = competitionBases.find(
                  (c: CompetitionBase) =>
                    c.providerId === row.original.providerCompetitionId && c.providerName === row.original.providerName
                );

                return (
                  <div>
                    {cell.value}
                    {baseComp ? ` - (${baseComp.name})` : ''}
                  </div>
                );
              }
            },
            {
              Header: 'Actions',
              Cell: ({ row }) => (
                <div className="actions">
                  <Button onClick={() => deleteConnectionFn(row.original.id)}>Delete</Button>
                </div>
              )
            }
          ]}
        />
      </div>
    </AdminWrapper>
  );
};
