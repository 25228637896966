import { Match } from '../../../../api-types';
import { Icon } from '@ligr/shared';
import React from 'react';
import { v4 } from 'uuid';
import { ContextMenu } from '../../../../components/ContextMenu';
import './style.scss';

interface HasCoverageIndicatorProps {
  match: Match;
}

export const HasCoverageIndicator: React.FunctionComponent<HasCoverageIndicatorProps> = ({ match }) => {
  if (!match.baseEntity) {
    return null;
  }
  const id = v4();
  return (
    <ContextMenu
      id={id}
      // @ts-ignore
      renderMenu={() => {
        return (
          <div className="hasCoverageIndicator">
            <div>This match is sourced from an external data provider.</div>
            <div>
              Provider<span className="coverageTooltip__providerName">{` ${match.baseEntity?.providerName}`}</span>
            </div>
            <div>
              Provider Match Id <span className="coverageTooltip__providerId">{`${match.baseEntity?.providerId}`}</span>
            </div>
          </div>
        );
      }}
    >
      <Icon icon="error" color="grey-800" />
    </ContextMenu>
  );
};
