import React from 'react';
import Select from 'react-select';
import { customStyles } from './MultiSelect.style';
import { MultiSelectProps } from './MultiSelect.types';

export const MultiSelect: React.FunctionComponent<MultiSelectProps> = ({
  onChange,
  options,
  value,
  noOptionsMessage,
  disabled,
  ...rest
}) => {
  return (
    <Select
      {...rest}
      isDisabled={disabled}
      onChange={onChange}
      noOptionsMessage={noOptionsMessage}
      value={value}
      options={options}
      closeMenuOnSelect={false}
      isMulti
      // @ts-ignore
      styles={customStyles}
    />
  );
};
