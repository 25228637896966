import React from 'react';
import { CycleOptions, Selection } from '../Subscription.type';
import './CycleSelectionForm.scss';

interface CycleSelectionFormProps {
  selection: Selection;
  updateSelection: (selection: Selection) => void;
}

export const CycleSelectionForm: React.FunctionComponent<CycleSelectionFormProps> = ({
  selection,
  updateSelection
}) => {
  const changeCycle = (cycle: CycleOptions) => (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    updateSelection({ cycle, cardChanged: false, validCard: false });
  };

  return (
    <div className="cycle-selection">
      <label className="label">Select your billing cycle</label>
      <div className="cycle-buttons">
        <button
          className={`button button-group ${selection.cycle === 'monthly' ? 'selected' : ''}`}
          onClick={changeCycle('monthly')}
        >
          Monthly
        </button>
        <button
          className={`button button-group ${selection.cycle === 'annual' ? 'selected' : ''}`}
          onClick={changeCycle('annual')}
        >
          Annually
        </button>
      </div>
    </div>
  );
};
