import { adFileFormatsArray, AdFileFormats, AdFileSize, adFileSizes, adFileSizeKb } from '@ligr/api-v2';

export const logoValidations: Validations = {
  validations: ['height', 'width', 'format', 'size'],
  width: 300,
  height: 300,
  formats: ['jpg', 'png', 'jpeg'],
  size: 500
};

export const watermarkValidations: Validations = {
  validations: ['format', 'size'],
  formats: ['jpg', 'png', 'jpeg'],
  size: 500
};

interface IImageProps {
  width?: number;
  height?: number;
  size?: number;
  sizeKb?: number;
  format?: string;
  base64String?: string;
  name?: string;
}

export const getImageProps = (file: File): Promise<IImageProps> => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = (e: ProgressEvent<FileReader>) => {
      const props: IImageProps = {};

      props.format = file.type;
      props.size = file.size;
      props.name = file.name;
      props.sizeKb = props.size / 1000;

      if (
        adFileFormatsArray.includes(
          file.name
            .split('.')
            .pop()!
            .toLowerCase() as AdFileFormats
        )
      ) {
        const img = new Image();

        img.onload = () => {
          props.width = img.width;
          props.height = img.height;
          resolve(props);
        };

        img.src = fr.result as string;
        props.base64String = e.target?.result as string;
      } else {
        resolve(props);
      }
    };
    fr.onerror = (e: ProgressEvent<FileReader>) => {
      reject(e);
    };
    fr.readAsDataURL(file);
  });
};

type ToValidate = ('width' | 'height' | 'format' | 'size')[];

export interface Validations {
  size?: number;
  width?: number;
  height?: number;
  formats?: string[];
  validations: ToValidate;
  adSize?: AdFileSize;
}

export const validateImage = (imageProps: IImageProps, validations: Validations) => {
  const errors: any = {};

  const { width: widthProp, height: heightProp, sizeKb: sizeProp, name } = imageProps;
  const { size, validations: val, adSize } = validations;

  if (adSize) {
    validations.width = adFileSizes[adSize].width;
    validations.height = adFileSizes[adSize].height;
    validations.size = adFileSizeKb;
    validations.formats = adFileFormatsArray;
  }

  const extension = name
    ?.split('.')
    .pop()!
    .toLowerCase();
  if (validations.formats && val.indexOf('format') !== -1 && !validations.formats.includes(extension)) {
    errors.format = `Invalid format file. Formats allowed are ${validations.formats.join(', ')}`;
  }
  if (val.indexOf('width') !== -1 && widthProp !== validations.width) {
    errors.width = `Width has to be ${validations.width} px`;
  }

  if (val.indexOf('height') !== -1 && heightProp !== validations.height) {
    errors.height = `Height has to be ${validations.height} px`;
  }

  if (size && val.indexOf('size') !== -1 && sizeProp! > size) {
    errors.size = `Size has to be smaller than ${size} KB`;
  }

  return errors;
};

export const logoValidation = async (items: any) => {
  const errors: any = {};
  for (const item of items) {
    const file = Object.values(item)[0];
    if (!file) continue;
    const name = Object.keys(item)[0];
    let validations = logoValidations;
    if (name === 'logo') validations = logoValidations;
    if (name === 'watermark' || name === 'secondaryWatermark') validations = watermarkValidations;
    const err = validateImage(await getImageProps(file as File), validations || logoValidations);
    if (Object.values(err).length > 0) {
      errors[name] = Object.values(err)[0];
    }
  }
  return errors;
};
