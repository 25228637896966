import React, { useMemo } from 'react';
import { SideMenuList } from '../../../../components/SideMenuList';
import { useErrorHandledQuery } from '../../../../lib/apolloHooks';
import { routes } from '../../../../router/Routes';
import { useLocation } from 'react-router';
import { playersQuery } from '../../../../lib/gql/queries/players';
import { FileEntityType, PlayersQuery } from '../../../../api-types';
import { ImagePlaceholderType } from '@ligr/shared';

export const PlayersSideMenu: React.FunctionComponent = () => {
  const { data, loading } = useErrorHandledQuery<PlayersQuery>(playersQuery);
  const players = data ? data.players : [];

  const { pathname } = useLocation<{ referrer?: string } | undefined>();

  const items = useMemo(() => {
    return players.map(p => ({
      imageUrl: p.playsFor?.[0]?.photo?.url,
      text: `${p.firstName} ${p.lastName}`,
      to: routes.assetPlayerEdit({ playerId: p.id }),
      imagePlaceholder: 'player' as ImagePlaceholderType
    }));
  }, [players]);

  return (
    <SideMenuList
      entityType={FileEntityType.player}
      items={items}
      link={(pathname.includes('/edit') && routes.assetPlayerCreate()) || ''}
      loading={loading}
    />
  );
};
