import { competitionAdAllocationFragment } from '@ligr/shared';
import gql from 'graphql-tag';

export const adAllocationsQuery = gql`
  query adAllocations {
    adAllocations {
      entity {
        ... on Team {
          countMatches
          countPlayers
          archived
          name
          id
          logo {
            id
            url
          }
          sport {
            id
            name
          }
        }
        ... on Competition {
          name
          id
          countTeams
          countMatches
          archived
          logo {
            id
            url
          }
          sport {
            id
            name
          }
        }
      }
      ...CompetitionAdAllocation
      id
      entityId
      entityType
    }
  }
  ${competitionAdAllocationFragment}
`;
