export interface ISizeHelperProps {
  isSmall?: boolean;
  isNormal?: boolean;
  isMedium?: boolean;
  isLarge?: boolean;
}

export interface ITextTransformHelperProps {
  isCapitalize?: boolean;
  isUppercase?: boolean;
  isLowercase?: boolean;
}

export interface IStateHelperProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  isChecked?: boolean;
}

export interface IColorTextHelperProps {
  hasTextGrey600?: boolean;
  hasTextGrey800?: boolean;
  hasTextWhite?: boolean;
  hasTextRed?: boolean;
}

export const sizeHelperPropsToClasses = (props: ISizeHelperProps) => {
  const { isSmall, isNormal, isMedium, isLarge } = props;
  return {
    'is-small': isSmall,
    'is-normal': isNormal,
    'is-medium': isMedium,
    'is-large': isLarge
  };
};

export const textTransformHelperPropsToClasses = (props: ITextTransformHelperProps) => {
  const { isCapitalize, isUppercase } = props;
  return {
    'is-capitalize': isCapitalize,
    'is-uppercase': isUppercase
  };
};

export const stateHelperPropsToClasses = (props: IStateHelperProps) => {
  const { isLoading, isDisabled, isError, isChecked } = props;
  return {
    'is-loading': isLoading,
    'is-disabled': isDisabled,
    'is-error': isError,
    'is-checked': isChecked
  };
};

export const colorTextHelperPropsToClasses = (props: IColorTextHelperProps) => {
  const { hasTextGrey600, hasTextGrey800, hasTextWhite, hasTextRed } = props;
  return {
    'has-text-grey-600': hasTextGrey600,
    'has-text-grey-800': hasTextGrey800,
    'has-text-white': hasTextWhite,
    'has-text-red': hasTextRed
  };
};
