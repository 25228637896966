import { useSubscription } from '@apollo/react-hooks';
import { MatchSubscriptionTopics } from '@ligr/api-v2/dist/types/subscriptions';
import { calendar, getCurrentPeriod, Image, LiveClockState, useClock } from '@ligr/shared';
import classnames from 'classnames';
import moment from 'moment';
import React, { useMemo } from 'react';
import {
  ClockInfo,
  EFinishedStatus,
  FileFragment,
  PeriodStatus,
  PeriodType,
  SmallOverlayFragment,
  SummaryFragment
} from '../../api-types';
import { matchSubscription } from '../../pages/Matches/matches.qql';
import { routes } from '../../router/Routes';
import { Card } from '../Card/Card';
import { Title } from '../Title/Title';
import './style.scss';

interface Team {
  logo?: Omit<FileFragment, 'entityType' | 'entityId'> | null;
  abbreviation?: string | null;
}

interface MatchCardProps {
  match: {
    date: Date;
    sportName: string;
    overlays: Omit<SmallOverlayFragment, 'manualGraphicState'>[];
    id: number;
    config: any;
    finishedStatus: string;
    summary: {
      clock: Pick<ClockInfo, 'clockRunning' | 'clockInMs' | 'clockChangeDate'>;
      matchStats: {
        score: { home: number; away: number };
      };
      periods: {
        matchFinished?: boolean | null;
        started?: Date;
        type: PeriodType;
        code: string;
        number: number;
        name: string;
        status: PeriodStatus;
      }[];
      currentPeriod: string;
    };
  };
  competition: {
    name: string;
  };
  homeTeam: Team;
  awayTeam: Team;
}

export const MatchCard: React.FunctionComponent<MatchCardProps> = ({
  match,
  homeTeam: { logo: homeTeamLogo, abbreviation: homeAbbr },
  awayTeam: { logo: awayTeamLogo, abbreviation: awayAbbr },
  competition
}) => {
  useSubscription(matchSubscription, {
    variables: {
      matchSubscriptionArgs: {
        id: match.id,
        topics: MatchSubscriptionTopics.updates
      }
    }
  });

  const {
    finishedStatus,
    summary: {
      matchStats: {
        score: { home: homeScore, away: awayScore }
      }
    }
  } = match;

  const { url: homeTeamLogoUrl } = homeTeamLogo || {};
  const { url: awayTeamLogoUrl } = awayTeamLogo || {};

  const liveClockState = useClock({ ...match, sport: { name: match.sportName } }, match.summary) as LiveClockState;
  const { matchFinished, started, status, name } = getCurrentPeriod(match.summary);
  const isInProgress = !!started && !matchFinished;

  return (
    <Card
      to={
        match.overlays[0]?.id
          ? routes.editOverlay({ matchId: match.id, overlayId: match.overlays[0].id, graphicName: 'select-graphic' })
          : undefined
      }
      className={classnames('matchCard', { 'matchCard--live': finishedStatus === 'inProgress' })}
    >
      <Title is4 className="matchCard__competition">
        {competition.name}
      </Title>
      <div className="matchCard__details">
        <Image className="matchCard__image matchCard__image--home" url={homeTeamLogoUrl} placeholder="team" />
        <Title is3 className="matchCard__team--home">
          {homeAbbr}
        </Title>
        <div className="matchCard__middleContent">
          <Title is5 hasTextRed={isInProgress} className="matchCard__statusDate">
            {matchFinished ? name : isInProgress ? (liveClockState?.running ? 'Live' : 'Paused') : calendar(match.date)}
          </Title>
          {(isInProgress || finishedStatus === EFinishedStatus.finished) && (
            <div className="matchCard__scores">
              <Title is2 className="matchCard__score--home">
                {homeScore}
              </Title>
              <Title is2 className="matchCard__score--away">
                {awayScore}
              </Title>
            </div>
          )}
          {finishedStatus === EFinishedStatus.pregame && (
            <Title is5 className="matchCard__vs">
              VS
            </Title>
          )}
          <Title is5 className="matchCard__time">
            {isInProgress ? (status === 'break' ? name : liveClockState?.clock) : moment(match.date).format('hh:mm A')}
          </Title>
        </div>
        <Title is3 className="matchCard__team--away">
          {awayAbbr}
        </Title>
        <Image className="matchCard__image matchCard__image--away" url={awayTeamLogoUrl} placeholder="team" />
      </div>
      {isInProgress && status !== 'break' && (
        <Title is5 hasTextGrey600 className="matchCard__period">
          {name}
        </Title>
      )}
    </Card>
  );
};
