import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { object, string } from 'yup';
import { Button } from '../../components/Button';
import { Form } from '../../components/Form/Form';
import { FormProps } from '../../components/Form/Form.types';
import { FormField } from '../../components/FormField';
import { FormStatus } from '../../components/FormStatus/FormStatus';
import { Title } from '../../components/Title/Title';
import { useErrorHandledMutation } from '../../lib/apolloHooks';
import { routes } from '../../router/Routes';
import { RegisterInput, RegisterMutation } from '../../api-types';

const registerMutation = gql`
  mutation register($registerInput: RegisterInput!) {
    register(registerInput: $registerInput) {
      user {
        id
        firstName
        lastName
        email
      }
      organization {
        name
      }
    }
  }
`;

export const validationSchema = object().shape({
  firstName: string().required().label('First name'),
  lastName: string().required().label('Last name'),
  email: string().email().required().label('Email'),
  password: string().required().label('Password'),
  orgName: string().required().label('Organization name')
});

const initialValues = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  orgName: ''
};

type RegisterForm = { orgName: string; email: string; firstName: string; lastName: string; password: string };

export const RegisterForm: React.FunctionComponent<FormProps<RegisterForm>> = props => {
  const [register, { data, loading }] = useErrorHandledMutation<RegisterMutation>(registerMutation);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);

  const onSubmit = async (values: RegisterForm) => {
    const { orgName, ...user } = values;

    setError(undefined);
    try {
      await register({
        variables: { registerInput: { user, organization: { name: orgName } } }
      });
    } catch (e) {
      setError(e.graphQLErrors[0].message);
    }
  };

  useEffect(() => {
    if (data && data.register) setSuccess(true);
  }, [data]);

  if (success) {
    return (
      <div className="success">
        <Title is2>Success!</Title>
        <p>
          Thanks for signing up to LIGR. We've sent you an email to <strong>{data!.register.user.email}</strong> with a
          link to get started. See you soon!
          <Button href={routes.login()}>Login</Button>
        </p>
      </div>
    );
  }

  return (
    <Form {...props} validationSchema={validationSchema} onSubmit={onSubmit} initialValues={initialValues}>
      <Title is2>Try LIGR absolutely free</Title>
      {error && <FormStatus error={true}>{error}</FormStatus>}
      <FormField name="firstName" type="text" label="First name" />
      <FormField name="lastName" type="text" label="Last name" />
      <FormField name="orgName" type="text" label="Organization name" />
      <FormField name="email" type="text" label="Email address" autoComplete="username" />
      <FormField name="password" type="password" label="Password" autoComplete="new-password" />
      <Button isLoading={loading}>Get started</Button>
    </Form>
  );
};
