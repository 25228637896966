import { OverlayWithoutClockCorrection as Overlay } from '@ligr/overlay/lib/pages/Overlay';
import camelCase from 'camelcase';
import React, { useEffect, useMemo, useState, memo } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router';
import { Button } from '../../components/Button';
import { Loader } from '../../components/loader/Loader';
import { MatchTitle } from '../../components/MatchTitle';
import { NavTab } from '../../components/NavTabs/NavTabs.types';
import { Page } from '../../components/page/Page';
import { PageContent } from '../../components/page/PageContent';
import { PageContentContent } from '../../components/page/PageContentContent';
import { PageHeader } from '../../components/PageHeader';
import { PageMenu } from '../../components/PageMenu';
import { ThemePreviewSettings } from '../../components/ThemePreview';
import { useErrorHandledQuery } from '../../lib/apolloHooks';
import { useThemeLoad } from '../../lib/useThemeLoad';
import { routes } from '../../router/Routes';
import { fullMatchQuery } from './gql';
import { OverlaySettings } from './OverlaySettings';
import { factsQuery } from '../../lib/gql/queries/facts';
import { matchSummaryQuery } from '../../lib/gql/queries/summary';
import { FootballSpecificFact, RugbyUnionSpecificFact, RugbyLeagueSpecificFact, SubstitutionFact } from '@ligr/api-v2';
import { MatchSummaryQuery, FactsQuery, FullMatchQuery } from '../../api-types';
import './style.scss';

const useReset = () => {
  const [canShow, setCanShow] = useState(true);

  const reset = () => {
    setCanShow(false);
  };

  useEffect(() => {
    !canShow && setCanShow(true);
  }, [canShow]);

  return { canShow, reset };
};

export const mainEventsPerSport = {
  rugbyLeague: [
    RugbyLeagueSpecificFact.TRY,
    RugbyLeagueSpecificFact.PENALTY_GOAL,
    RugbyLeagueSpecificFact.DROP_GOAL,
    RugbyLeagueSpecificFact.GOAL_KICK_SCORED
  ],
  rugbyUnion: [
    RugbyUnionSpecificFact.RED_CARD,
    RugbyUnionSpecificFact.YELLOW_CARD,
    RugbyUnionSpecificFact.TRY,
    RugbyUnionSpecificFact.TRY_ASSIST,
    RugbyUnionSpecificFact.PENALTY_GOAL,
    RugbyUnionSpecificFact.DROP_GOAL,
    RugbyUnionSpecificFact.CONVERSION,
    RugbyUnionSpecificFact.CONVERSION_MISSED,
    SubstitutionFact.SUBSTITUTION
  ],
  football: [FootballSpecificFact.RED_CARD, FootballSpecificFact.YELLOW_CARD, FootballSpecificFact.GOAL]
};
export const OverlayPage = () => {
  const [graphicProps, setGraphicProps] = useState<{ [key: string]: any }>({});

  const params = useParams<{ graphicName: string; matchId: string; overlayId: string }>();
  const parsedRouteObj = {
    matchId: parseInt(params.matchId),
    overlayId: parseInt(params.overlayId),
    graphicName: params.graphicName
  };
  const routeObj = parsedRouteObj;
  const { matchId, overlayId, graphicName } = parsedRouteObj;
  const id = matchId;

  const { data: matchData, loading } = useErrorHandledQuery<FullMatchQuery>(fullMatchQuery, {
    variables: { id },
    fetchPolicy: 'cache-first'
  });

  const { data: factData } = useErrorHandledQuery<FactsQuery>(factsQuery, { variables: { matchId } });

  const { data: matchSummaryData, loading: loadingMatchSummary } = useErrorHandledQuery<MatchSummaryQuery>(
    matchSummaryQuery,
    { variables: { matchId } }
  );

  const matchSummary = matchSummaryData?.matchSummary!;

  const match = matchData?.match!;

  const themeObj = useThemeLoad(match?.competition?.theme);

  const headerTabs: NavTab[] = useMemo(() => {
    return (
      themeObj?.graphics.map(({ name }) => ({
        to: `${routes.editOverlay({ ...routeObj, graphicName: name })}`,
        text: name
      })) || []
    );
  }, [routeObj, themeObj]);

  const overlayRoutes = [routes.editOverlay(false)];

  const updateGraphicProps = (graphicName: string, value: { [key: string]: any }) => {
    setGraphicProps({
      ...graphicProps,
      [graphicName]: {
        ...graphicProps[graphicName],
        ...value
      }
    });
  };

  const { reset, canShow } = useReset();

  const manualState = useMemo(() => {
    if (!canShow) {
      return {};
    }

    return {
      [camelCase(graphicName)]: {
        ...graphicProps[graphicName]
      }
    };
  }, [graphicName, graphicProps, canShow]);

  const mainEvents = useMemo(
    () =>
      match
        ? factData?.facts
            // @ts-ignore
            .filter(({ name }) => mainEventsPerSport[camelCase(match?.sportName)].includes(name))
        : [],
    [factData, match]
  );

  // load theme properties
  // render tabs based on themes, render ui based on graphics
  if (loading || loadingMatchSummary) return <Loader />;
  const overlay = match?.overlays?.find(({ id }) => id === overlayId);

  const currentGraphic = themeObj?.graphics?.find(({ name }) => name === graphicName);

  console.log('match - olay settings', match);

  return (
    <Page type="overlay">
      <Switch>
        <Route path={overlayRoutes}>
          <PageMenu
            title="Overlay config"
            component={() => match && <OverlaySettings overlays={match.overlays} match={match} />}
          />

          <PageContent loading={loading}>
            <PageHeader title={match && <MatchTitle match={match!} matchSummary={matchSummary} />} tabs={headerTabs} />
            <PageContentContent>
              {overlay && (
                <div className="overlay__tab-content-wrapper">
                  <div>
                    <div className="overlay__tab-content-wrapper__overlay">
                      <Overlay
                        manualState={manualState || {}}
                        overlaySettings={{
                          ...overlay,
                          ...{
                            autoMode: false,
                            background: overlay.background || 'demo',
                            backgroundColor: overlay.backgroundColor
                          }
                        }}
                        resizable
                        filteredGraphics={!canShow ? [] : graphicName ? [graphicName] : undefined}
                        overlay={overlay}
                      />
                    </div>
                    <div className="overlay__tab-content-wrapper__actions">
                      <Button onClick={reset} type={'secondary'}>
                        Re Animate
                      </Button>
                    </div>
                  </div>
                  <ThemePreviewSettings
                    adSets={[
                      ...(match.competition.adAllocation?.adSets || []),
                      ...(match.homeTeam.adAllocation?.adSets || []),
                      ...(match.awayTeam.adAllocation?.adSets || [])
                    ]}
                    value={graphicProps[graphicName] || {}}
                    facts={mainEvents || []}
                    sport={match.sportName}
                    match={match}
                    // @ts-ignore
                    triggerToGraphicData={currentGraphic?.triggerToGraphicData}
                    adFileSizes={currentGraphic?.imageAdSizes}
                    currentGraphicName={graphicName}
                    onChange={val => updateGraphicProps(graphicName, val)}
                  />
                </div>
              )}
            </PageContentContent>
          </PageContent>
        </Route>

        <Redirect
          to={routes.editOverlay({
            matchId: id,
            overlayId,
            graphicName: 'select-tab'
          })}
        />
      </Switch>
    </Page>
  );
};
