import gql from 'graphql-tag';
import { competitionFragment } from '../fragments/competition';

export const createCompQuery = gql`
  mutation createCompetition($createCompetitionInput: CreateCompetitionInput!) {
    createCompetition(createCompetitionInput: $createCompetitionInput) {
      ...Competition
    }
  }
  ${competitionFragment}
`;
