import { FormikConfig } from 'formik';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { object, string } from 'yup';
import { Button } from '../../components/Button';
import { Form } from '../../components/Form/Form';
import { FormField } from '../../components/FormField';
import { useErrorHandledMutation } from '../../lib/apolloHooks';
import { ProfilePageWrapper } from './ProfilePageWrapper';
import { ProfileRouterChildProps } from './Profile.router';

const updatePasswordQuery = gql`
  mutation updatePasswordWithCurrent($newPassword: String!, $currentPassword: String!) {
    updatePasswordWithCurrent(newPassword: $newPassword, currentPassword: $currentPassword)
  }
`;

export const validationSchema = object().shape({
  currentPassword: string().required().label('Current password'),
  newPassword: string().required().label('New password')
});

interface PasswordForm {
  currentPassword: string;
  newPassword: string;
}

export const ProfilePasswordForm: React.FunctionComponent<ProfileRouterChildProps> = ({ me }) => {
  const [error, setError] = useState();
  const [success, setSuccess] = useState<string | undefined>();
  const [updatePassword, { loading }] = useErrorHandledMutation(updatePasswordQuery);

  const onSubmit: FormikConfig<PasswordForm>['onSubmit'] = async (values: any, f) => {
    setError(undefined);
    setSuccess(undefined);

    try {
      await updatePassword({ variables: values });
      setSuccess('Password successfully updated');
      (document.activeElement as HTMLInputElement).blur();
      f.resetForm();
    } catch (e) {
      setError(e.graphQLErrors[0].message);
    }
  };

  return (
    <ProfilePageWrapper me={me} rightSide={true}>
      <Form
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        error={error}
        success={success}
        initialValues={{ currentPassword: '', newPassword: '' }}
        useConfirm={true}
      >
        <FormField name="currentPassword" type="password" label="Current password" autoComplete="current-password" />
        <FormField name="newPassword" type="password" label="New password" autoComplete="new-password" />
        <Button isLoading={loading}>Change password</Button>
      </Form>
    </ProfilePageWrapper>
  );
};
