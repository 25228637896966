import classnames from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../Button';
import { ButtonProps } from '../Button/type';
import './style.scss';

interface ButtonDropProps extends ButtonProps {
  options: { text: string; url: string }[];
}

export const ButtonDrop: React.FunctionComponent<ButtonDropProps> = ({ children, options, ...rest }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={classnames('button-drop', { 'button-drop--open': open })}>
      <Button {...rest} onClick={() => setOpen(!open)}>
        {children}
      </Button>
      {options.length > 0 && (
        <div className="button-drop__options">
          {options.map(({ text, url }) => {
            return (
              <Link key={`${text}-${url}`} to={url}>
                {text}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};
