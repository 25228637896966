import gql from 'graphql-tag';

export const clubsQuery = gql`
  query clubs {
    clubs {
      id
      name
    }
  }
`;

export const clubQuery = gql`
  query club($id: Float!) {
    club(id: $id) {
      id
      name
      archived
      gfxName
      gfxFullName
      abbreviation
      primaryBackgroundColor
      primaryTextColor
      secondaryBackgroundColor
      secondaryTextColor
      logo {
        id
        url
      }
      defaultVenue {
        id
        name
      }
      teams {
        id
        age
        gender
        archived
        grade {
          id
          name
        }
        countMatches
        countPlayers
        archived
        sport {
          id
          name
        }
        competitions {
          id
          name
          logo {
            id
            url
          }
          sport {
            id
            name
          }
        }
      }
    }
  }
`;
