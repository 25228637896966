import { Icon, IconType } from '@ligr/shared';
import classname from 'classnames';
import React, { HTMLProps, MutableRefObject, ReactElement } from 'react';
import { ToolTipIcon } from '../ToolTipIcon';
import './style.scss';

export type InputFieldProps = HTMLProps<HTMLInputElement> & CustomInputTypes;

type CustomInputTypes = {
  icon?: IconType;
  iconPosition?: 'left' | 'right';
  iconColor?: string;
  error?: string | Error;
  suffix?: string;
  forwardRef?: MutableRefObject<HTMLInputElement | null>;
  loading?: boolean;
  initialValue?: string;
  blurOnEnter?: boolean;
  iconTooltip?: string | JSX.Element;
  label?: ReactElement | string;
};

export const InputField: React.FunctionComponent<InputFieldProps> = ({
  icon,
  iconColor,
  iconPosition,
  iconTooltip,
  className,
  error,
  disabled,
  suffix,
  loading,
  blurOnEnter = true,
  value,
  forwardRef,
  ...props
}) => {
  // const [value] = useState(v); // This fixes lead 0 issue on timeField
  const [state, setState] = React.useState(0);
  const callback = React.useCallback((node: HTMLElement) => {
    if (node) return setState(node.getBoundingClientRect().width);
    setState(0);
  }, []);

  const err = error ? error.toString() : null;
  let icn: IconType | undefined = err ? 'error' : icon;
  let icnPosition = iconPosition;
  let icnColor = err ? 'red' : iconColor;

  if (disabled) {
    icn = 'lock';
    icnColor = 'grey-600';
  }
  if (err) icnPosition = 'right';

  return (
    <div
      className={classname('input', className, {
        'input--iconright': icnPosition === 'right',
        'input--loading': loading,
        'input--suffix': suffix,
        'input--disabled': disabled,
        'input--error': error
      })}
    >
      {loading ? (
        <Icon className="loader input__icon" icon="loader" color={icnColor} />
      ) : icn && iconTooltip ? (
        <ToolTipIcon className="formField__tooltipIcon" icon={icn} color="blue">
          {iconTooltip}
        </ToolTipIcon>
      ) : icn ? (
        <Icon className="input__icon" icon={icn} color={icnColor} />
      ) : null}
      <input
        className="input__input"
        onKeyDown={
          blurOnEnter
            ? (e: React.KeyboardEvent) => {
                if (e.key === 'Enter') {
                  (e.target as HTMLInputElement).blur();
                }
              }
            : undefined
        }
        {...props}
        value={value}
        disabled={disabled}
        style={suffix && !error ? { paddingRight: `calc(1.5rem + ${state}px)` } : {}}
      />
      {suffix && !err && <span ref={callback}>{suffix}</span>}
    </div>
  );
};
