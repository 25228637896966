import { Icon } from '@ligr/shared';
import { FormikContextType } from 'formik';
import React from 'react';
import { findErrors } from '../../lib/findErrors';
import { Title } from '../Title/Title';
import './style.scss';

export interface ErrorsGroupProps {
  form: FormikContextType<any>;
  errorGroupGridColum?: string;
}

export const ErrorsGroup: React.FunctionComponent<ErrorsGroupProps> = ({ form, errorGroupGridColum = 'span 2' }) => {
  const errors: string[] = findErrors(form.errors);
  return (
    <div style={{ gridColumn: errorGroupGridColum }} className="errorsGroup">
      <div className="errorsGroup__titleArea">
        <Icon icon="error" className="errorsGroup__iconTitle" />
        <Title is3 hasTextRed className="errorsGroup__title">
          Oops, there are errors on this page (You may need to scroll down)
        </Title>
      </div>
      <ul className="errorsGroup__list">
        {errors.map((e, idx) => (
          <li key={idx} className="errorsGroup__listItem">
            {e}
          </li>
        ))}
      </ul>
    </div>
  );
};
