import React from 'react';
import classnames from 'classnames';
import { Title } from '../../Title/Title';

export interface CountdownProps {
  divider?: string;
  countdown: {
    mins: number;
    secs: number;
    hours: number;
    days: number;
  };
}

interface PregameTimeProps {
  countdown: CountdownProps;
  expectedSecondsTillStart: number;
  className?: string;
}

const contentData: {
  key: keyof CountdownProps['countdown'];
  label: string;
}[] = [
  { key: 'days', label: 'days' },
  { key: 'hours', label: 'hrs' },
  { key: 'mins', label: 'mins' }
];

export const PregameTime: React.FunctionComponent<PregameTimeProps> = ({
  countdown,
  expectedSecondsTillStart,
  className
}) => {
  let isLate = false;
  if (expectedSecondsTillStart < 0) isLate = true;
  const content = contentData.map(({ key, label }, index) => {
    const isSingular = Math.abs(countdown.countdown?.[key]) === 1;
    if (countdown.countdown?.[key]) {
      return (
        <span key={index}>
          {`${Math.abs(countdown.countdown?.[key])}`}
          <span>{` ${isSingular ? label.slice(0, -1) : label}  `}</span>
        </span>
      );
    }
  });
  return (
    <Title className={classnames('pregameTime', className)} is3 hasTextRed={isLate}>
      {content}
      {isLate && ' ago'}
    </Title>
  );
};
