import { Icon } from '@ligr/shared';
import { saveAs } from 'file-saver';
import { FormikContextType } from 'formik';
import get from 'lodash.get';
import React, { useMemo } from 'react';
import { ContextMenu } from '../../../../components/ContextMenu';
import { FormField } from '../../../../components/FormField';
import { useErrorHandledMutation } from '../../../../lib/apolloHooks';
import { deleteAdFileMutation } from '../../../../lib/gql/mutations/deleteAdFile';
import { adSetsQuery } from '../../AdSets';
import { getImageURLFormat } from '../AdSetMenu';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { AdSetsQuery } from '../../../../api-types';

type AdSet = {
  name: string;
  id: number;
  countAdAllocations: number;
  adFiles: {
    size: string;
    id: number;
    file?: {
      url: string;
    };
  }[];
};

export interface AdSetImageFormContentProps {
  adSet: AdSet;
  size: string;
  sizeLabel: string;
  form: FormikContextType<any> | null;
}

export const AdSetImageFormContent: React.FunctionComponent<AdSetImageFormContentProps> = ({
  adSet,
  size,
  sizeLabel,
  form
}) => {
  const [deleteAdFile] = useErrorHandledMutation<boolean>(deleteAdFileMutation);

  const ad = adSet.adFiles.find(({ size: adsize }) => adsize === size);
  // use useCallback + useState to capture all ref changes and pass to the tooltip

  const image = useMemo(() => {
    if (get(form?.errors, `${adSet.id}.${size}`)) {
      return undefined;
    }
    return get(form?.values, `${adSet.id}.${size}`);
  }, [form?.values, form?.errors, adSet.id]);

  const handleDelete = async () => {
    if (ad) {
      await deleteAdFile({
        variables: { id: ad.id },
        update: (cache: InMemoryCache, { data }: any) => {
          const adSetsCache = cache.readQuery<AdSetsQuery>({
            query: adSetsQuery
          });
          if (adSetsCache && data) {
            const adSetIndex = adSetsCache.adSets
              // @ts-ignore
              .findIndex(({ id }: AdSet) => id === adSet.id);
            const as = adSetsCache.adSets[adSetIndex];
            const newAdFiles = as.adFiles.filter(({ id }) => id !== ad?.id);
            cache.writeQuery({
              query: adSetsQuery,
              data: {
                adSets: [
                  ...adSetsCache.adSets.slice(0, adSetIndex),
                  { ...as, adFiles: newAdFiles },
                  ...adSetsCache.adSets.slice(adSetIndex + 1)
                ]
              }
            });
          }
        }
      });
      form!.resetForm();
    }
  };

  return (
    <div className="adImage">
      <div className="adImage__labelImage">
        <div className="adImage__label">
          <div className="adImage__label__text">{size.charAt(0).toUpperCase() + size.slice(1)}</div>
          <div className="adImage__label__number">{sizeLabel}</div>
        </div>
        <FormField
          name={`${adSet.id}.${size}`}
          type="image"
          colSpan={2}
          src={ad?.file?.url}
          className={`adImage__file adImage__file--${size} ${!ad?.file?.url ? 'adImage__file--empty' : ''}`}
          size={size}
          value={image}
          isAd
        />
      </div>
      {ad?.file?.url && (
        <ContextMenu
          id={`adImageMenu__${size}`}
          className="adImage__menu"
          items={[
            {
              icon: 'upload',
              text: 'Replace',
              action: () => {
                const el = document.getElementById(`imageUpload-${size}`);
                el!.click();
              }
            },
            {
              icon: 'remove',
              text: 'Delete',
              action: () => handleDelete(),
              confirmWindow: {
                title: 'Confirm delete',
                content: 'Are you sure you want to delete this adset?'
              }
            },
            {
              icon: 'download',
              text: 'Download',
              action: () => {
                const format = getImageURLFormat(ad!.file!.url);
                saveAs(ad?.file!.url, `${size}.${format}`);
              }
            }
          ]}
        >
          <Icon icon="ellipsis" />
        </ContextMenu>
      )}
    </div>
  );
};
