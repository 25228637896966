import { Image } from '@ligr/shared';
import { ApolloQueryResult } from 'apollo-client';
import classnames from 'classnames';
import gql from 'graphql-tag';
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { MeFragment, MePermissionQuery, OrgFragment } from '../../api-types';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card/Card';
import { Loader } from '../../components/loader/Loader';
import { Select } from '../../components/Select';
import { Title } from '../../components/Title/Title';
import { useErrorHandledLazyQuery } from '../../lib/apolloHooks';
import { Me } from '../../lib/meContainer';
import { routes } from '../../router/Routes';
import './style.scss';

const orgFragment = gql`
  fragment Org on Organization {
    id
    name
    blocked
    logo {
      id
      url
    }
  }
`;

export const meFragment = gql`
  fragment Me on UserOrganization {
    id
    organizationId
    organization {
      ...Org
    }
    user {
      id
    }
    default
  }
  ${orgFragment}
`;

export const mePermissionsQuery = gql`
  query mePermission {
    mePermission {
      ...Me
    }
  }
  ${meFragment}
`;

interface DefaultTagProps {
  organizationId: number;
  permissions: MeFragment[];
}

const DefaultTag = ({ permissions, organizationId }: DefaultTagProps) => {
  const permission = permissions.find(p => p.organization.id === organizationId);

  if (!permission?.default) return null;
  return <span>Default</span>;
};

type OrganizationItem = {
  org: OrgFragment;
  permissions: ApolloQueryResult<MePermissionQuery>['data']['mePermission'];
};

const OrganizationItem: React.FunctionComponent<OrganizationItem> = ({ org, permissions }) => (
  <li className={classnames({ blocked: org.blocked })}>
    <Link key={org.id} to={routes.home({ orgId: org.id })}>
      <Image url={org.logo?.url} placeholder="organization" />
      <div className="orgs__name">
        {org.name}
        {org.blocked && <span>Blocked</span>}
        <DefaultTag permissions={permissions} organizationId={org.id} />
      </div>
    </Link>
  </li>
);

export const SelectOrganizationPage = () => {
  const { me } = Me.useContainer();
  const history = useHistory<{ referrer?: string } | undefined>();
  const [fetch, { data, loading }] = useErrorHandledLazyQuery<MePermissionQuery>(mePermissionsQuery, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    fetch({});
  }, [me]);

  if (loading) return <Loader />;

  const permissions = data?.mePermission;

  return (
    <div data-testid="select-org-page" className="select-organization page">
      <Card>
        <Title is3>SELECT ORGANIZATION</Title>
        <ul className="orgs">
          {permissions
            ?.map(p => p.organization)
            .slice(0, 5)
            .map((org, id) => (
              <OrganizationItem key={id} permissions={permissions} org={org} />
            ))}
        </ul>
        {(permissions?.slice(5, data?.mePermission.length)?.length || 0) > 0 && (
          <Select
            className="more-orgs"
            placeholder="More organizations"
            options={
              permissions
                ?.slice(5, permissions.length)
                ?.map(p => p.organization)
                .map((org: OrgFragment) => {
                  return { label: org.name, value: org.id };
                }) || []
            }
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              history.push(routes.home({ orgId: parseInt(e.target.value) }))
            }
          />
        )}
        <Button
          icon="plus"
          onClick={async () =>
            history.push({
              pathname: routes.organizationCreate(false)
            })
          }
        >
          Add organization
        </Button>
      </Card>
    </div>
  );
};
