import { useSearchParams } from '@ligr/shared';
import gql from 'graphql-tag';
import React, { useEffect, useMemo } from 'react';
import { Route, useHistory } from 'react-router';
import { Button } from '../../../components/Button';
import { CardAdSet } from '../../../components/CardAdSet';
import { Filters } from '../../../components/Filters';
import { Grid } from '../../../components/Grid/Grid';
import { getMissingEntitiesComponentContent } from '../../../components/MissingEntitiesComponent/getMissingEntitiesComponentContent';
import { MissingEntitiesComponent } from '../../../components/MissingEntitiesComponent/MissingEntitiesComponent';
import { PageContent } from '../../../components/page/PageContent';
import { PageContentContent } from '../../../components/page/PageContentContent';
import { PageHeader } from '../../../components/PageHeader';
import { PageSubHeader } from '../../../components/PageSubHeader/PageSubHeader';
import { logDelay } from '../../../lib/animation';
import { useErrorHandledLazyQuery } from '../../../lib/apolloHooks';
import { Notification } from '../../../lib/notificationContainer';
import { useArchiveAdSet } from '../../../lib/useArchiveAdSet';
import { CreateAdSetModal } from '../../../modals/CreateAdSetModal/CreateAdSet.modal';
import { routes } from '../../../router/Routes';
import { EntityEnum } from '../../../types/assets';
import { AdSetsWithFilesQuery } from '../../../api-types';
import { ApolloQueryResult } from 'apollo-client';

export const adSetsQuery = gql`
  query adSetsWithFiles($options: IQueryOptions) {
    adSets(options: $options) {
      id
      name
      countAdAllocations
      archived
      adFiles {
        id
        adSetId
        file {
          id
          url
          entityId
          entityType
        }
        size
      }
    }
  }
`;

export const adSetQuery = gql`
  query adSet($id: Float!) {
    adSet(id: $id) {
      id
      name
      archived
      countAdAllocations
      adFiles {
        id
        adSetId
        file {
          id
          url
          entityId
          entityType
        }
        size
      }
    }
  }
`;

type AdSet = ApolloQueryResult<AdSetsWithFilesQuery>['data']['adSets'][0];

export const AdSetsPage: React.FunctionComponent = () => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const searchParams = useSearchParams(['query', 'archive']);
  const { archiveAdSet } = useArchiveAdSet();
  const [fetch, { data, loading, called }] = useErrorHandledLazyQuery<AdSetsWithFilesQuery>(adSetsQuery, {
    variables: { options: { archive: !!searchParams.archive } }
  });
  const adSets = data?.adSets || [];
  const { addNotification } = Notification.useContainer();

  useEffect(() => {
    fetch();
  }, []);

  const adSetsSearch = useMemo(() => {
    return adSets?.filter((as: AdSet) => {
      let query = true;
      if (searchParams.query) {
        query = as.name.toLowerCase().includes(searchParams.query.toLowerCase());
      }
      return query;
    });
  }, [searchParams.query, adSets]);

  const archiveAdSetFn = async (adSetId: number) => {
    archiveAdSet(adSetId);
    addNotification('Ad set archived successfully.', 'success');
  };

  const missingEntitiesDialogProps =
    called && !loading && getMissingEntitiesComponentContent(EntityEnum.adSets, adSets, adSetsSearch);

  return (
    <>
      <PageContent loading={loading}>
        <PageHeader title="Ad sets" />
        <PageContentContent className="as">
          {adSets.length > 0 && (
            <PageSubHeader>
              <Filters searchable="ad sets" filters={['archive']} />
              <Button onClick={() => history.push({ pathname: routes.createAdSet(), search: location.search })}>
                Create Ad set
              </Button>
            </PageSubHeader>
          )}
          {missingEntitiesDialogProps ? (
            <MissingEntitiesComponent {...missingEntitiesDialogProps} />
          ) : (
            <Grid>
              {adSetsSearch.map((adSet, i) => (
                <CardAdSet
                  key={adSet.id}
                  adSet={adSet}
                  to={routes.adSetImageAds({ adSetId: adSet.id })}
                  {...logDelay(i)}
                  icon="close"
                  fn={() => archiveAdSetFn(adSet.id)}
                />
              ))}
            </Grid>
          )}
        </PageContentContent>
      </PageContent>
      <Route
        exact
        path={routes.createAdSet(false)}
        render={() => (
          <CreateAdSetModal
            onSuccess={() => {
              history.push({
                pathname: routes.adSetsAll(),
                search: location.search
              });
            }}
            onClose={() => {
              history.push({
                pathname: routes.adSetsAll(),
                search: location.search
              });
            }}
          />
        )}
      />
    </>
  );
};
