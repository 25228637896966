import React from 'react';
import ReactPaginate from 'react-paginate';
import './Pagination.scss';

export interface PaginationProps {
  pageCount: number;
  initialPage?: number;
  onPageChange: (SelectedItem: { selected: number }) => void;
  forcePage?: number;
}

export const Pagination: React.FunctionComponent<PaginationProps> = ({
  pageCount,
  onPageChange,
  initialPage = 1,
  forcePage
}) => (
  <ReactPaginate
    previousLabel={'previous'}
    nextLabel={'next'}
    breakLabel={'...'}
    breakClassName={'break'}
    pageClassName={'page'}
    pageCount={pageCount}
    forcePage={forcePage}
    initialPage={initialPage}
    marginPagesDisplayed={2}
    pageRangeDisplayed={5}
    onPageChange={onPageChange}
    containerClassName={'pagination'}
    activeClassName={'active'}
  />
);
