import { LogoutMutation, LoginMutation, ImpersonateMutation, UnimpersonateMutation } from '../api-types';
import gql from 'graphql-tag';
import { useEffect, useMemo, useState } from 'react';
import { createContainer } from 'unstated-next';
import { object, string } from 'yup';
import { cache } from '../lib/apolloClient';
import { useMutation } from '@apollo/react-hooks';
import { clearLocalToken, getLocalToken, setLocalToken } from './util/tokenLocalStorage';
import { client } from '../lib/apolloClient';

type AuthStatus =
  | 'loggedIn'
  | 'loggingIn'
  | 'loggedOut'
  | 'loggingOut'
  | 'verifying' // while verifying token
  | 'uninitialized' // On Load prior to verifying check started
  | 'impersonatingIn' // while switching from admin to impersonated account
  | 'impersonatingOut'; // while switching from impersonated account to admin

export const LoginValidationSchema = object().shape({
  email: string().email().required().label('Email'),
  password: string().required().label('Password')
});

const verifyToken = gql`
  query verifyToken($accessToken: String!) {
    verifyToken(accessToken: $accessToken) {
      valid
      userId
      adminId
    }
  }
`;

const logoutMutation = gql`
  mutation logout {
    logout {
      success
    }
  }
`;

const loginMutation = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
      defaultOrganization {
        id
      }
    }
  }
`;

const impersonateMutation = gql`
  mutation impersonate($id: Float!) {
    impersonate(id: $id) {
      accessToken
      adminId
      defaultOrganization {
        id
      }
    }
  }
`;

const unimpersonateMutation = gql`
  mutation unimpersonate {
    unimpersonate {
      accessToken
      defaultOrganization {
        id
      }
    }
  }
`;

export interface LoginInput {
  email: string;
  password: string;
}

const useAuth = () => {
  const [status, setStatus] = useState<AuthStatus>('uninitialized');
  const [hasBeenLoggedIn, setHasBeenLoggedIn] = useState(false);

  const [adminId, setAdminId] = useState<number | undefined>(undefined);
  // const [verifyQuery, VerifyData] = useLazyQuery<VerifyTokenQuery>(verifyToken);
  const [logoutMut, { error: logoutError, loading: loggingOut }] = useMutation<LogoutMutation>(logoutMutation);
  const [loginMut, { data: loginData, error: loginError, loading: loggingIn }] = useMutation<LoginMutation>(
    loginMutation
  );
  const [impersonateMut, { data: impersonateData, error: impersonateError, loading: impersonatingIn }] = useMutation<
    ImpersonateMutation
  >(impersonateMutation);
  const [unimpersonateMut, { data: unimpersonateData, error: unimpersonateError }] = useMutation<UnimpersonateMutation>(
    unimpersonateMutation
  );

  useEffect(() => {
    if (impersonateData?.impersonate) {
      setLocalToken(impersonateData?.impersonate.accessToken);
      setAdminId(impersonateData.impersonate.adminId);
      setHasBeenLoggedIn(true);
    }
  }, [impersonateData]);

  useEffect(() => {
    if (unimpersonateData?.unimpersonate) {
      setLocalToken(unimpersonateData?.unimpersonate.accessToken);
      setAdminId(undefined);
      setHasBeenLoggedIn(true);
    }
  }, [unimpersonateData]);

  const requestInFlight = useMemo(
    () => ['verifying', 'loggingIn', 'loggingOut', 'impersonatingIn', 'unimpersonatingIn'].includes(status),
    [status]
  );

  const logout = async (): Promise<void> => {
    if (requestInFlight) return console.error('Cannot logout while auth request in flight.');
    setStatus('loggingOut');
    await logoutMut();

    cache.reset();
    clearLocalToken();
    setStatus('loggedOut');
  };

  const login = async (values: LoginInput): Promise<void> => {
    if (requestInFlight) return console.error('Cannot login while auth request in flight.');
    setStatus('loggingIn');
    const response = await loginMut({ variables: { email: values.email, password: values.password } });

    if (response.data?.login) {
      setLocalToken(response.data?.login.accessToken);
      setHasBeenLoggedIn(true);
      return setStatus('loggedIn');
    }

    setStatus('loggedOut');
  };

  const verifyTokenFn = async (accessToken: string): Promise<boolean | number> => {
    if (accessToken) {
      const resp = await client.query({ query: verifyToken, variables: { accessToken } });
      const verifyData = resp.data;
      if (verifyData) {
        if (verifyData.verifyToken.valid) {
          if (verifyData.verifyToken.adminId) {
            return verifyData.verifyToken.adminId;
          }
          return true;
        }
      }
    }
    return false;
  };

  const handleTokenVerification = async (accessToken: string | null = getLocalToken()): Promise<void> => {
    if (requestInFlight) return console.error('Cannot verify while auth request in flight.');
    setStatus('verifying');
    if (!accessToken) return setStatus('loggedOut');
    const verified = await verifyTokenFn(accessToken);
    if (verified) {
      setStatus('loggedIn');
      setHasBeenLoggedIn(true);
      if (typeof verified === 'number') {
        setAdminId(verified);
      }
    } else {
      clearLocalToken();
      setStatus('loggedOut');
    }
  };

  const impersonate = async (id: number): Promise<void> => {
    if (requestInFlight) return console.error('Cannot impersonate while auth request in flight.');
    setStatus('impersonatingIn');
    const { data } = await impersonateMut({ variables: { id } });
    await handleTokenVerification(data?.impersonate?.accessToken);
  };

  const unimpersonate = async (): Promise<void> => {
    if (requestInFlight) return console.error('Cannot unimpersonate while auth request in flight.');
    setStatus('impersonatingOut');
    const { data } = await unimpersonateMut();
    await handleTokenVerification(data?.unimpersonate?.accessToken);
  };

  useEffect(() => {
    handleTokenVerification();
  }, []);

  console.log(status);

  return {
    hasBeenLoggedIn,
    status,
    verify: handleTokenVerification,
    logout,
    login,
    impersonate,
    unimpersonate,
    adminId,
    loginError,
    logoutError,
    impersonating: !!adminId,
    impersonateError,
    unimpersonateError,
    defaultOrganizationId: loginData?.login?.defaultOrganization?.id
  };
};

export const Auth = createContainer(useAuth);
