import capitalize from 'capitalize';
import pluralize from 'pluralize';
import React from 'react';

type AdSet = {
  adSet: { name: string; countAdAllocations: number };
};

export const ConfirmWindowAdSetContent: React.FunctionComponent<AdSet> = ({ adSet }) => {
  const withNotification = adSet.countAdAllocations > 0;
  const notifications = [];

  if (adSet.countAdAllocations > 0) {
    notifications.push(
      <li>
        Remove this ad from {adSet.countAdAllocations} {pluralize('allocations', adSet.countAdAllocations)}
      </li>
    );
  }

  return (
    <>
      Are you sure you want to archive {capitalize(adSet.name)}?
      {withNotification && (
        <div className={`notification notification--error confirm-window__notification`}>
          This action will also:
          <ul>{notifications.map(item => item)}</ul>
        </div>
      )}
    </>
  );
};
