import { CONFIG } from '@ligr/shared';
import copy from 'copy-to-clipboard';
import React, { SyntheticEvent, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { v4 } from 'uuid';
import { Button } from '../../../components/Button';
import { Form } from '../../../components/Form/Form';
import { FormField } from '../../../components/FormField';
import { Select } from '../../../components/Select';
import { getGQLError } from '../../../lib/apolloClient';
import { useErrorHandledMutation } from '../../../lib/apolloHooks';
import { Notification } from '../../../lib/notificationContainer';
import { routes } from '../../../router/Routes';
import { updateOverlayMutation } from '../../Matches/matches.qql';
import { OverlaySettingsForm } from './forms';
import './style.scss';
import { Form as IForm } from 'formik';
import { UpdateOverlayMutation, Match } from '../../../api-types';

interface OverlayFormValues {
  id: string | number;
  name?: string;
  encodingDelay?: string;
  autoMode?: string;
  resolution?: string;
  testMode?: string;
  showWatermarks?: string;
  enableAds?: string;
  background?: string;
  backgroundColor?: string;
  key?: string;
}

type OverlaySettingsProp = {
  match: Pick<Match, 'id'>;
  overlays?: {
    key: string;
    name: string;
    id: number;
  }[];
};

export const OverlaySettings: React.FunctionComponent<OverlaySettingsProp> = ({ match, overlays = [] }) => {
  const params = useParams<{ overlayId: string }>();
  const overlayId = params.overlayId;
  const history = useHistory<{ referrer?: string } | undefined>();

  // Find the overlay based on the match and the overlayId (changed by the select)
  const overlay = useMemo(() => overlays?.find(o => o.id === parseInt(overlayId))!, [overlayId, match]);

  // Generate overlay select options based on `match`
  const OverlayOptions = useMemo(() => overlays?.map(o => ({ label: o.name, value: o.id })), [match]);

  // Update the url based on the new `overlayId`
  const changeOverlay = (e: SyntheticEvent<HTMLSelectElement>) => {
    const newOverlay = parseInt(e.currentTarget.value);
    history.push(
      routes.editOverlay({
        matchId: match.id,
        overlayId: newOverlay,
        graphicName: 'select-graphic'
      })
    );
  };

  const [form, setForm] = useState<any | undefined>();
  const [updateOverlay, { loading }] = useErrorHandledMutation<UpdateOverlayMutation>(updateOverlayMutation);
  const [error, setError] = useState<string>('');

  const { addNotification } = Notification.useContainer();

  const submit = async (values: OverlayFormValues) => {
    try {
      const overlayValues = {
        id: values.id,
        name: values.name,
        encodingDelay: values.encodingDelay ? parseInt(values.encodingDelay) : undefined,
        autoMode: values.autoMode,
        resolution: values.resolution ? parseInt(values.resolution) : undefined,
        testMode: values.testMode,
        showWatermarks: values.showWatermarks,
        enableAds: values.enableAds,
        background: values.background,
        backgroundColor: values.backgroundColor,
        key: values.key
      };

      await updateOverlay({ variables: { updateOverlayInput: overlayValues } });
      addNotification('Overlay successfully updated.', 'success');
      form?.resetForm();
    } catch (e) {
      setError(getGQLError(e).message);
    }
  };

  return (
    <Form
      error={error}
      onSubmit={submit}
      className={`page-form overlaySettings`}
      withSubmit
      initialValues={overlay}
      useConfirm={true}
      setForm={setForm}
    >
      <section className="overlaySettings__overlay">
        <Select options={OverlayOptions} onChange={changeOverlay} value={overlayId} />
      </section>
      <section className="overlaySettings__url">
        {/* TODO: Get this link, and add a refresh button to the label (like login's label for password reset) */}
        {/* TODO: Make this label type below work. */}
        {/**
         *
        // @ts-ignore */}
        <FormField
          name="key"
          type="text"
          className="refresh-key-field"
          label={
            <div className="url-refresh-label">
              <div className="label">Live URL</div>
              <Button
                className="overlaySettings__copyBtn"
                btnType="button"
                type="secondary"
                onClick={() => {
                  copy(`${CONFIG.OVERLAY_URL}/${overlay.key}`);
                  addNotification('URL copied', 'info');
                }}
              >
                Copy
              </Button>
              <Button
                isSquare
                btnType="button"
                type="secondary"
                onClick={async () => {
                  await updateOverlay({
                    variables: {
                      updateOverlayInput: {
                        id: overlay.id,
                        key: v4()
                      }
                    }
                  });

                  addNotification('Overlay key updated', 'success');
                }}
                icon="refresh"
              />
            </div>
          }
          disabled={true}
        />
      </section>

      <OverlaySettingsForm overlayId={parseInt(overlayId)} loading={loading} />
    </Form>
  );
};
