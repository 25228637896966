import { Icon, IconType } from '@ligr/shared';
import classname from 'classnames';
import React from 'react';
import './style.scss';
import { SelectProps } from './types';

export const Select: React.FunctionComponent<SelectProps> = ({
  options,
  icon,
  iconColor,
  iconPosition,
  className,
  error,
  disabled,
  placeholder,
  initialValue,
  value,
  ...props
}) => {
  const err = error ? error.toString() : null;
  let icn: IconType | undefined = err ? 'error' : icon;
  let icnPosition = iconPosition;
  let icnColor = err ? 'red' : iconColor;

  if (disabled) {
    icn = 'lock';
    icnColor = 'grey-600';
  }
  if (err) icnPosition = 'right';

  return (
    <div
      className={classname('select', className, {
        'icon-right': icnPosition === 'right',
        disabled,
        error
      })}
    >
      <Icon icon="arrowDownSmall" className="arrow" color="grey-600" />
      {icn && <Icon icon={icn} color={icnColor} className={icn} />}
      <select
        {...props}
        onChange={e => {
          if (props.onChange) props.onChange(e);
        }}
        value={value}
        disabled={disabled}
        className={classname({ 'with-icon': icn }, { 'icon-right': icn && icnPosition === 'right' })}
      >
        {placeholder && (
          <option key={-1} value={''}>
            {placeholder}
          </option>
        )}
        {options?.map(({ label, value }, idx) => (
          <option key={idx} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};
