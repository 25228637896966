import { useLazyQueryWithErrorHandling, useQueryWithErrorHandling, useMutationWithErrorHandling } from '@ligr/shared';
import { DocumentNode } from 'apollo-link';
import { LazyQueryHookOptions, QueryHookOptions, MutationHookOptions } from '@apollo/react-hooks';
import { useError } from './errors/useError';

export const useErrorHandledLazyQuery = <TData = any, TVariables = any>(
  query: DocumentNode,
  options?: LazyQueryHookOptions<TData, TVariables>
) => {
  return useLazyQueryWithErrorHandling<TData, TVariables>(query, useError, options);
};

export const useErrorHandledQuery = <TData = any, TVariables = any>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
) => {
  return useQueryWithErrorHandling<TData, TVariables>(query, useError, options);
};

export const useErrorHandledMutation = <TData = any, TVariables = any>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>
) => {
  return useMutationWithErrorHandling<TData, TVariables>(mutation, useError, options);
};
