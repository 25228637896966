import { Icon, Image } from '@ligr/shared';
import classnames from 'classnames';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router';
import { NavTabs } from '../NavTabs/NavTabs';
import { NavTab } from '../NavTabs/NavTabs.types';
import { Title } from '../Title/Title';
import './style.scss';

export interface PageHeaderProps {
  title: string | ReactElement;
  preTitle?: string | false;
  tabs?: NavTab[];
  tabRowEndComponent?: React.FunctionComponent<any>;
  // TODO: remove edit
  edit?: boolean;
  editMenu?: React.FunctionComponent<any>;
  className?: string;
  url?: string;
  close?: string;
  // adFiles?: AdFile[];
  action?: React.FunctionComponent<any>;
}

export const PageHeader: React.FunctionComponent<PageHeaderProps> = ({
  title,
  preTitle = 'manage',
  tabs,
  tabRowEndComponent: TabRowEndComponent,
  editMenu: EditMenu,
  className,
  url,
  close,
  action
}) => {
  const history = useHistory<{ referrer?: string } | undefined>();

  return (
    <div
      className={classnames(
        'pageHeader',
        {
          'pageHeader--withTabs': tabs,
          'pageHeader--withImage': url,
          'pageHeader--withClose': close,
          'pageHeader--withPreTitle': preTitle,
          'pageHeader--withAction': action,
          'pageHeader--withEdit': EditMenu
        },
        className
      )}
    >
      {url && <Image url={url} className="pageHeader__image" placeholder="user" />}
      <div className="pageHeader__titles">
        {typeof title === 'string' ? (
          <>
            {preTitle && (
              <Title is4 hasTextGrey600 className="pageHeader__preTitle">
                {preTitle}
              </Title>
            )}
            <Title is2 className="pageHeader__title">
              {title}
            </Title>
            {EditMenu && <EditMenu />}
          </>
        ) : (
          title
        )}
      </div>
      {action && action({})}
      {close && (
        <div onClick={() => history.push(close)} className="pageHeader__close">
          <Icon icon="close" />
        </div>
      )}
      <div className="pageHeader__tabRow">
        {tabs && <NavTabs tabs={tabs} />}
        {TabRowEndComponent && <TabRowEndComponent />}
      </div>
    </div>
  );
};
