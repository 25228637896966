import React from 'react';
import { Route, Switch, useHistory } from 'react-router';
import { Page } from '../../components/page/Page';
import { withTitle } from '../../components/PageTitle/PageTitle';
import { ConnectOrganizationAdapter } from '../../modals/OrganizationAdapter';
import { routes } from '../../router/Routes';
import { AdminAdapters } from './Admin.Adapters';
import { AdminCompetitions } from './Admin.Competitions';
import { AdminOrganizations } from './Admin.Organizations';
import { AdminUsers } from './Admin.Users';

export const AdminRouter: React.FunctionComponent = () => {
  const history = useHistory<{ referrer?: string } | undefined>();

  return (
    <Page type="admin">
      <Switch>
        <Route
          exact
          path={routes.admin()}
          component={withTitle({ component: AdminUsers, pageTitle: 'Admin - users' })}
        />
        <Route
          exact
          path={routes.adminOrganizations()}
          component={withTitle({ component: AdminOrganizations, pageTitle: 'Admin - Organizations' })}
        />
        <Route
          exact
          path={routes.adminAdapters()}
          component={withTitle({ component: AdminAdapters, pageTitle: 'Admin - External Data' })}
        />
        <Route
          exact
          path={routes.adminCompetitions()}
          component={withTitle({ component: AdminCompetitions, pageTitle: 'Admin - Competitions' })}
        />
        <Route
          exact
          path={routes.adminConnectAdapter(false)}
          render={() => (
            <ConnectOrganizationAdapter
              onClose={() => {
                history.push({
                  pathname: routes.adminAdapters(),
                  search: location.search
                });
              }}
            />
          )}
        />
      </Switch>
    </Page>
  );
};
