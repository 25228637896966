import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { withTitle } from '../../components/PageTitle/PageTitle';
import { routes } from '../../router/Routes';
import { OverlayControl } from '.';

export const OverlaysRouter: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route
        exact
        path={[routes.controlOverlay(false)]}
        component={withTitle({ component: OverlayControl, pageTitle: 'Overlay Control' })}
      />

      <Route path={'*'} render={() => <Redirect to={routes.matchesLive(false)} />} />
    </Switch>
  );
};
