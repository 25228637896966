import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { InputFieldProps, InputField } from '..';
import { FormikContextType } from 'formik';
import './style.scss';

type Item = { value: any; label: string };

export interface InlineResultsInputProps extends InputFieldProps {
  items: Item[];
  onSelection?: (item: Item) => void;
  formik?: FormikContextType<any>;
  loading?: boolean;
  startAtLength?: number;
  menuTitle: string;
  renderItem?: any;
}

export const InlineResultsInput: React.FunctionComponent<InlineResultsInputProps> = ({
  items,
  onSelection,
  loading,
  menuTitle = '',
  startAtLength = 1,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if ((props.value as string).length < startAtLength) {
      setIsOpen(false);
    }
  }, [props.value]);

  return (
    <div className={classnames('inlineResults', { 'inlineResults--isOpen': isOpen })}>
      <InputField
        {...props}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          props.onChange && props.onChange(e);
          (e.target.value as string | number).toString().length >= startAtLength &&
            items.length !== 0 &&
            setIsOpen(true);
        }}
        onFocus={e => {
          // TODO: should only allow string or number inputs. Not string[] a multi select component.
          (e.target.value as string | number).toString().length >= startAtLength &&
            items.length !== 0 &&
            setIsOpen(true);
        }}
        onBlur={() => setTimeout(() => setIsOpen(false), 200)}
      />
      {isOpen && (items.length || loading) ? (
        <ul className="inlineResults__list">
          {menuTitle && <li className="inlineResults__menuTitle">{menuTitle}</li>}
          {items.map(({ label, value }, index) => {
            return (
              <li key={index} onClick={() => onSelection && onSelection(value)} className="inlineResults__item">
                {props.renderItem ? props.renderItem(value) : label}
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};
