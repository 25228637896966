import gql from 'graphql-tag';

export const playerQuery = gql`
  query player($id: Float!) {
    player(id: $id) {
      id
      firstName
      lastName
      gfxFirstName
      gfxLastName
      countTeams
      archived
      playsFor {
        team {
          id
          name
          abbreviation
        }
        position
        positionType
        number
        defaultStarter
        photo {
          id
          url
        }
      }
    }
  }
`;
