import React from 'react';

interface EditableImageFieldProps {
  disabled?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDelete: () => void;
}

export const EditableImageField: React.FunctionComponent<EditableImageFieldProps> = ({
  disabled,
  handleChange,
  onDelete
}) => {
  return (
    <>
      <div className="image-field__dnd">
        <input className="image-field__input" type="file" value="" onChange={disabled ? undefined : handleChange} />
      </div>
      <div className="image-field__edit">
        <div className="image-field__edit__action">
          Replace
          <input className="image-field__input" type="file" value="" onChange={disabled ? undefined : handleChange} />
        </div>
        {onDelete && (
          <div className="image-field__edit__action" onClick={onDelete}>
            Delete
          </div>
        )}
      </div>
    </>
  );
};
