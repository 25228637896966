import { Icon, IconType } from '@ligr/shared';
import classnames from 'classnames';
import React, { useContext, useEffect, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { ScrollContext } from '../VerticalScroll/VerticalScroll';
import './style.scss';
import { ConfirmWindow, ConfirmWindowProps } from '../ConfirmWindow';

export interface ContextMenuItem {
  id?: string;
  icon?: IconType;
  iconStyle?: React.CSSProperties;
  text?: string;
  action?: () => void;
  component?: JSX.Element;
  confirmWindow?: Omit<ConfirmWindowProps, 'action'>;
}

export interface ContextMenuProps {
  items?: ContextMenuItem[];
  id?: string | number;
  className?: string;
  renderMenu?: React.FunctionComponent;
  place?: ReactTooltip.Place;
  clickable?: boolean;
}

// This component implements a clickable tooltip with content.
// It works around some react-tooltip issues
// with scrolling associated with clickable tooltips
// https://github.com/wwayne/react-tooltip/issues/377#issuecomment-502973468 - multiple tooltips in the same page.

export const ContextMenu: React.FunctionComponent<ContextMenuProps> = ({
  items,
  id = 'default',
  className,
  children,
  renderMenu,
  place = 'top',
  clickable = false
}) => {
  const [isTooltipOpen, setTooltipOpen] = React.useState(false);
  const scrollContext = useContext(ScrollContext);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const hideTooltip = React.useCallback(() => {
    const tooltipWrapperEl = tooltipRef.current;
    if (tooltipWrapperEl) {
      ReactTooltip.hide(tooltipWrapperEl);
      setTooltipOpen(false);
      document.removeEventListener('click', hideTooltip);
    }
  }, []);

  const onTooltipTriggerClick = React.useCallback(() => {
    const tooltipWrapperEl = tooltipRef.current;
    if (tooltipWrapperEl) {
      if (isTooltipOpen) {
        hideTooltip();
      } else {
        ReactTooltip.show(tooltipWrapperEl);
        setTooltipOpen(true);
        document.addEventListener('click', hideTooltip);
      }
    }
  }, [isTooltipOpen]);

  useEffect(() => {
    if (scrollContext) {
      hideTooltip();
    }
  }, [scrollContext]);

  useEffect(() => {
    const onKeyDownListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        hideTooltip();
      }
    };
    window.document.addEventListener('keydown', onKeyDownListener);
    return () => {
      window.document.removeEventListener('keydown', onKeyDownListener);
    };
  }, []);

  return (
    <>
      <div
        ref={tooltipRef}
        data-event="click"
        data-event-off=""
        data-tip
        onClick={onTooltipTriggerClick}
        data-for={`context-menu-${id}`}
        className={className}
      >
        {children}
      </div>
      <ReactTooltip isCapture effect="solid" id={`context-menu-${id}`} place={place} clickable={clickable}>
        {renderMenu ? (
          renderMenu({})
        ) : (
          <ul className={classnames(`contextMenu`)}>
            {items?.map((i, index) => {
              if (i.component) {
                return React.cloneElement(i.component, { key: `context-menu-${i.id}-${index}` });
              }
              if (i.text) {
                const classModifier = i.text.replace(' ', '-').toLowerCase();
                const content = (
                  <li
                    key={`context-menu-${i.id}-${index}`}
                    className="contextMenu__item"
                    onMouseDown={i.confirmWindow ? undefined : i.action}
                  >
                    {i.icon && (
                      <Icon
                        style={i.iconStyle}
                        className={`contextMenu__icon contextMenu__icon--${classModifier}`}
                        icon={i.icon}
                      />
                    )}
                    <span className="contextMenu__text">{i.text}</span>
                  </li>
                );
                return i.confirmWindow ? (
                  <ConfirmWindow title={i.confirmWindow.title} content={i.confirmWindow.content} action={i.action!}>
                    {content}
                  </ConfirmWindow>
                ) : (
                  content
                );
              }
            })}
          </ul>
        )}
      </ReactTooltip>
    </>
  );
};
