import capitalize from 'capitalize';
import gql from 'graphql-tag';
import React from 'react';
import { useErrorHandledQuery } from '../../lib/apolloHooks';
import { Select } from '../Select';
import { SelectProps } from '../Select/types';

export type Entity = 'sports' | 'competitions' | 'teams';

export interface SelectEntityProps extends Omit<SelectProps, 'options'> {
  entity: Entity;
  labelProp?: string;
}

const queryMap = {
  competitions: gql`
    query competitionNameOnly {
      competitions {
        id
        name
      }
    }
  `,
  sports: gql`
    query sportsNameOnly {
      sports {
        id
        name
      }
    }
  `,
  teams: gql`
    query teamsNameOnly {
      teams {
        id
        name
      }
    }
  `
};

export const SelectEntity: React.FunctionComponent<SelectEntityProps> = ({
  entity,
  labelProp = 'name',
  onChange,
  ...props
}) => {
  const query = queryMap[entity as Entity];
  const { data } = useErrorHandledQuery(query);

  const defaultOption = { value: '', label: `All ${entity}` };
  const options = data
    ? data[entity].map((e: any) => ({
        value: parseInt((e.id as unknown) as string),
        label: capitalize(e[labelProp])
      }))
    : [];

  // Enforce value id is an integer not a string
  const change = (e: React.FormEvent<HTMLSelectElement>) => {
    if (onChange) {
      onChange({
        // @ts-ignore
        target: { value: parseInt(e.target.value), name: props.name }
      });
    }
  };

  return <Select options={[defaultOption, ...options]} {...props} onChange={change} />;
};
