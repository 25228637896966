import gql from 'graphql-tag';

export const venuesQuery = gql`
  query venues($options: IQueryOptions) {
    venues(options: $options) {
      id
      name
      lat
      lng
      city
      country
      state
      postcode
      address
      countDefaultVenue
      countMatches
      archived
    }
  }
`;
