import { allPlans, freezePlan } from '@ligr/api-v2';
import React, { useEffect, useMemo, useState } from 'react';
import './CurrentSubscriptionOverview.scss';
import { formatter } from './SelectionOverview';
import { CurrentSubscription } from './Subscription.type';

interface CurrentSubscriptionOverviewProps {
  currentSubscription: CurrentSubscription;
  displayForm: boolean;
  toggleForm: (b: boolean) => void;
  displayCardForm: boolean;
  toggleCardForm: (b: boolean) => void;
}

export const CurrentSubscriptionOverview: React.FunctionComponent<CurrentSubscriptionOverviewProps> = ({
  currentSubscription,
  toggleForm,
  displayForm,
  displayCardForm,
  toggleCardForm
}) => {
  const [frozen, freeze] = useState(false);

  const currentPlan = useMemo(() => {
    return allPlans.find(p => p.stripeId === currentSubscription.stripePlanId);
  }, [currentSubscription]);

  const price = useMemo(() => {
    if (currentPlan) {
      if (currentPlan.free) return <span className="free">FREE</span>;
      if (currentPlan.custom) return <span className="custom">N/A</span>;
      if (currentPlan.brackets && currentSubscription.planQuantity) {
        const bracket = currentPlan.brackets.find(({ upTo }) => currentSubscription.planQuantity! <= upTo);
        if (bracket) return <span>{formatter.format(bracket.price)}</span>;
      }
    }
    return <span>N/A</span>;
  }, [currentSubscription]);

  useEffect(() => {
    if (currentSubscription && currentSubscription.stripePlanId && currentSubscription.stripePlanId === freezePlan) {
      freeze(true);
    }
  }, [currentSubscription]);

  if (!currentPlan && !frozen) return null;

  if (frozen) {
    return (
      <div className={`frozen ${displayForm || displayCardForm ? 'hide' : ''}`}>
        Your account has been frozen. <a onClick={_e => toggleForm(true)}>Click here to upgrade</a>.
      </div>
    );
  }

  return (
    <div className="current-subscription">
      <div className="heading">Current plan</div>
      <div className="current-plan">
        {currentPlan!.name} (Billed {currentPlan!.annual ? 'annually' : 'monthly'})
        <br />
        {currentSubscription.stripeCardLast4 && (
          <>
            Card on file ends with {currentSubscription.stripeCardLast4}
            <br />
            <a
              className={`${displayCardForm || displayForm ? 'is-disabled' : ''}`}
              onClick={_e => toggleCardForm(true)}
            >
              Change card
            </a>
          </>
        )}
      </div>
      <div className="current-price">{price}</div>
      <div className="current-actions">
        <button
          className={`button ${displayForm || displayCardForm ? 'is-disabled' : ''}`}
          onClick={_e => toggleForm(true)}
        >
          Upgrade
        </button>
      </div>
    </div>
  );
};
