import { CONFIG } from '@ligr/shared';
import { FormikContextType } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Script from 'react-load-script';
import { v4 } from 'uuid';
import { InputField } from '../InputField';

type AddressFieldType = {
  form: FormikContextType<any>;
};

export const AddressField: React.FunctionComponent<AddressFieldType> = ({ form, ...props }) => {
  let autocomplete: any;

  const id = useMemo(() => `autocompletePlaces${v4()}`, []);
  const [loaded, setLoaded] = useState(false);

  const handlePlaceSelect = () => {
    const addressObject = autocomplete.getPlace();

    if (addressObject.formatted_address) form?.setFieldValue('address', addressObject.formatted_address);

    addressObject.address_components.forEach((component: any) => {
      if (component.types.includes('administrative_area_level_1')) form?.setFieldValue('state', component.long_name);
      if (component.types.includes('administrative_area_level_2')) form?.setFieldValue('city', component.long_name);
      if (component.types.includes('country')) form?.setFieldValue('country', component.long_name);
      if (component.types.includes('postal_code')) form?.setFieldValue('postcode', component.long_name);
    });

    form?.setFieldValue('lat', addressObject.geometry.location.lat());
    form?.setFieldValue('lng', addressObject.geometry.location.lng());
  };

  // Initialize Google Autocomplete
  const handleScriptLoad = useCallback(() => {
    // @ts-ignore
    autocomplete = new google.maps.places.Autocomplete(document.getElementById(id), {});
    autocomplete.setFields(['formatted_address', 'address_components', 'geometry']);
    autocomplete.addListener('place_changed', handlePlaceSelect);
  }, []);

  useEffect(() => {
    loaded && handleScriptLoad();
  }, [loaded]);

  return (
    <>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${CONFIG.GOOGLE_PLACES_KEY}&libraries=places`}
        onLoad={() => setLoaded(true)}
      />
      <InputField {...props} id={id} />
    </>
  );
};
