import { Icon } from '@ligr/shared';
import classname from 'classnames';
import React, { memo, useEffect, useMemo } from 'react';
import { Column, Row, useExpanded, useRowSelect, useSortBy, useTable } from 'react-table';
import { Checkbox } from '../Checkbox/Checkbox';
import { Loader } from '../loader/Loader';
import { Title } from '../Title/Title';
import { VerticalScroll } from '../VerticalScroll/VerticalScroll';
import './table.scss';
import { TableProps, TableRowProps } from './Table.types';

const columnSelectable: Column = {
  id: 'selection',
  // @ts-ignore
  Header: ({ getToggleAllRowsSelectedProps }) => <Checkbox {...getToggleAllRowsSelectedProps()} />,
  Cell: ({ row }) => (
    // @ts-ignore
    <Checkbox {...row.getToggleRowSelectedProps()} />
  )
};

export const isSubRow = (row: Row) => {
  return row.id.toString().split('.').length > 1;
};

interface TableBodyProps {
  loading?: boolean;
  rows: Row[];
  gridColumns: string;
  prepareRow: () => void;
  forwardRef?: (val: any) => void;
}

const TableBody: React.FunctionComponent<TableBodyProps> = ({ loading, rows, gridColumns, prepareRow, forwardRef }) => {
  return (
    <tbody ref={forwardRef} className="table__tbody">
      {!loading ? (
        rows.map((row: any, i: number) => {
          return <MemoizedTableRow key={i} row={row} gridColumns={gridColumns} prepareRow={prepareRow} />;
        })
      ) : (
        <Loader />
      )}
    </tbody>
  );
};

export const Table: React.FunctionComponent<TableProps> = ({
  data,
  columns,
  selectable,
  onSelect,
  loading,
  small,
  className,
  tdClassName,
  thClassName
}) => {
  const { getTableProps, headerGroups, rows, prepareRow, state } = useTable(
    {
      // @ts-ignore
      data,
      columns: useMemo(() => {
        const cols = columns || [];
        if (selectable) cols.unshift(columnSelectable);
        return cols;
      }, [columns])
    },
    useSortBy,
    useExpanded,
    useRowSelect
  );

  useEffect(() => {
    // @ts-ignore
    if (onSelect) onSelect(state.selectedRowPaths.map((i: string) => rows[parseInt(i)].original));
  }, [state]);

  return (
    <table {...getTableProps()} className={classname('table', className, { small })}>
      <thead className="table__thead">
        {headerGroups.map((hg: any, idx: number) => (
          <tr key={idx} className={classname('table__trHead', 'table__trow')} {...hg.getHeaderGroupProps()}>
            {hg.headers.map((column: any) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={classname('table__th', column.className, thClassName)}
              >
                <Title hasTextGrey600 className="table__thTitle" is5>
                  {column.render('Header')}
                </Title>
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <Icon iconSize="medium" icon="arrowDownSmall" />
                  ) : (
                    <Icon iconSize="medium" icon="arrowUpSmall" />
                  )
                ) : (
                  ''
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <VerticalScroll
        throttle={200}
        component={({ scrollRoot }) => {
          return (
            <TableBody forwardRef={scrollRoot} loading={loading} rows={rows} gridColumns={''} prepareRow={prepareRow} />
          );
        }}
      />
    </table>
  );
};

const MemoizedTableRow: React.FunctionComponent<TableRowProps> = memo(
  props => <TR className={classname('table__trBody', 'table__trow', props.row.original.classNames)} {...props} />,
  ({ row: rowA, gridColumns: gcA }, { row: rowB, gridColumns: gcB }) => {
    return (
      gcA === gcB &&
      !Object.entries(rowA.values).find(([key, value]) => {
        if (value instanceof Date) {
          if (rowB.values[key] instanceof Date) {
            return value.getTime() !== rowB.values[key].getTime();
          }
        }
        return rowB.values[key] !== value;
      })
    );
  }
);

export const TR: React.FunctionComponent<TableRowProps> = ({ row, prepareRow, className, tdClassName }) => {
  prepareRow(row);
  const isSubrow = isSubRow(row);
  return (
    <>
      <tr {...row.getRowProps()} className={className}>
        {row.cells.map((cell: any) => {
          const colSpan = isSubrow && cell.column.subRowColSpan ? cell.column.subRowColSpan : 1;
          const render = cell.render('Cell');

          // if (cell.column.skip && cell.column.skip(cell)) return null;

          return (
            <td
              {...cell.getCellProps()}
              className={classname('table__td', cell.column.className, { 'sub-row': isSubrow }, tdClassName)}
              colSpan={colSpan}
            >
              {render}
            </td>
          );
        })}
      </tr>
    </>
  );
};
