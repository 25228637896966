import { ErrorCode } from '@ligr/api-v2';
import { ApolloError } from 'apollo-client';
import { useEffect } from 'react';
import { routes } from '../../router/Routes';
import { useHistory } from 'react-router';
import { Auth } from '../../lib/authContainer';

export const useError = (error: ApolloError | undefined) => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const { verify } = Auth.useContainer();

  useEffect(() => {
    if (error?.graphQLErrors) {
      error.graphQLErrors.map((err: any) => {
        console.log(err.code);
        switch (err.code) {
          case ErrorCode.AuthInvalidToken:
            return verify();
          case ErrorCode.AuthNoAccess:
          case ErrorCode.AuthMissingOrgId:
          case ErrorCode.AuthInvalidAuthorizationHeader:
          case ErrorCode.MissingOrgHeader:
          case ErrorCode.AuthUnauthorized:
          case ErrorCode.AuthUnauthenticated:
          case ErrorCode.PermissionInvalid:
            return history.push({ pathname: routes.unauthorized(), state: history.location.state });
          case ErrorCode.ResourceNotFound:
            return history.push({ pathname: routes.notFound(), state: history.location.state });
          case ErrorCode.ErrorInvalidPlan:
            return history.push(routes.planUpgrade());
          default:
            break;
        }
      });
    }
  }, [error]);
};
