import { User, SearchUsersQuery } from '../../api-types';
import { updateQueryUrl } from '@ligr/shared';
import gql from 'graphql-tag';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from '../../components/Button';
import { Loader } from '../../components/loader/Loader';
import { SearchField } from '../../components/SearchField/SearchField';
import { Table } from '../../components/Table/Table';
import { Auth } from '../../lib/authContainer';
import { useErrorHandledLazyQuery, useErrorHandledMutation } from '../../lib/apolloHooks';
import { Me as MeContainer } from '../../lib/meContainer';
import { routes } from '../../router/Routes';
import { AdminWrapper } from './Admin.wrapper';

const usersSearchQuery = gql`
  query searchUsers($query: String!) {
    searchUsers(query: $query) {
      id
      firstName
      lastName
      email
      blocked
      admin
    }
  }
`;

interface ToggleBlockUserMutation {
  toggleBlockUser: User;
}

const toggleBlockUserMutation = gql`
  mutation toggleBlockUser($userId: Float!) {
    toggleBlockUser(userId: $userId) {
      id
      firstName
      lastName
      email
      blocked
      admin
    }
  }
`;

interface ToggleAdminUserMutation {
  toggleAdminUser: User;
}

const toggleAdminUserMutation = gql`
  mutation toggleAdminUser($userId: Float!) {
    toggleAdminUser(userId: $userId) {
      id
      firstName
      lastName
      email
      blocked
      admin
    }
  }
`;

export const AdminUsers: React.FunctionComponent = () => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const meContainer = MeContainer.useContainer();
  const { impersonate } = Auth.useContainer();
  const searchParsed = queryString.parse(history.location.search);
  const [query, setQuery] = useState(searchParsed.query || '');
  const [fetch, { data, loading }] = useErrorHandledLazyQuery<SearchUsersQuery>(usersSearchQuery);
  const [toggleBlockUser] = useErrorHandledMutation<ToggleBlockUserMutation>(toggleBlockUserMutation);
  const [toggleAdminUser] = useErrorHandledMutation<ToggleAdminUserMutation>(toggleAdminUserMutation);
  const users = data?.searchUsers || [];

  const submit = () => {
    fetch({ variables: { query } });
  };

  useEffect(() => {
    submit();
  }, []);

  useEffect(() => {
    if (query === '') submit();
  }, [query]);

  const doImpersonate = async (id: number) => {
    await impersonate(id);
    history.push(routes.orgSelection());
  };

  const toggleBlock = async (id: number) => {
    await toggleBlockUser({ variables: { userId: id } });
  };

  const toggleAdmin = async (id: number) => {
    await toggleAdminUser({ variables: { userId: id } });
  };

  const userData = useMemo(
    () =>
      users.map((user: User) => ({
        ...user,
        isMe: user.id === meContainer.me?.id
      })),
    [meContainer.me, users]
  );

  if (loading || meContainer.loading || !meContainer.called) return <Loader />;

  const me = meContainer.me!;

  return (
    <AdminWrapper>
      <div className="admin-search">
        <SearchField
          placeholder="Search user by first name, last name or email"
          onChange={(value: any) => {
            updateQueryUrl(history, { query: value });
            setQuery(value);
          }}
          value={query}
        />
        <Button onClick={submit}>Search</Button>
      </div>
      <div className="search-users">
        <Table
          data={userData}
          className="adminUsers__table"
          small
          columns={[
            {
              Header: 'Name',
              Cell: ({ row }) => (
                <div>
                  {row.original.firstName} {row.original.lastName}
                </div>
              )
            },
            {
              Header: 'Email',
              Cell: ({ row }) => <div>{row.original.email}</div>
            },
            {
              Header: 'Blocked',
              accessor: 'blocked',
              Cell: ({ cell }) => <div>{cell.value && <span className="blocked">Blocked</span>}</div>
            },
            {
              Header: 'Admin',
              accessor: 'admin',
              Cell: ({ cell }) => <div>{cell.value && <span className="admin">Admin</span>}</div>
            },
            {
              Header: 'Impersonate',
              accessor: 'isMe',
              Cell: ({ cell, row }) => (
                <div className="actions">
                  {!cell.value ? (
                    <>
                      <Button onClick={() => doImpersonate(row.original.id)}>Impersonate</Button>
                      <Button onClick={() => toggleBlock(row.original.id)}>
                        {row.original.blocked ? 'Unblock' : 'Block'}
                      </Button>
                      <Button onClick={() => toggleAdmin(row.original.id)}>
                        {row.original.admin ? 'Remove admin' : 'Make admin'}
                      </Button>
                    </>
                  ) : (
                    'This is you'
                  )}
                </div>
              )
            }
          ]}
        />
      </div>
    </AdminWrapper>
  );
};
