import { updateQueryUrl } from '@ligr/shared';
import capitalize from 'capitalize';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { FilterOptions } from '.';
import { Select } from '../Select';
import { SelectOption } from '../Select/types';

interface CompetitionFilterProps {
  competitions: {
    id: number;
    name: string;
    sport: {
      id: number;
    };
  }[];
  setActiveFilter: (name: FilterOptions, value: string) => void;
}

export const CompetitionFilter: React.FunctionComponent<CompetitionFilterProps> = ({
  competitions,
  setActiveFilter
}) => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const queryStrObject = queryString.parse(history.location.search);

  const options: SelectOption[] = useMemo(() => {
    const competitionOptions = competitions
      .filter(competition => {
        if (queryStrObject.sports) {
          return competition.sport.id.toString() === queryStrObject.sports;
        }
        return true;
      })
      .map(competition => ({
        value: parseInt((competition.id as unknown) as string),
        label: capitalize(competition.name)
      })) as SelectOption[];

    return [{ value: '', label: 'All' } as SelectOption].concat(competitionOptions);
  }, [competitions, queryStrObject.sports]);

  const handleChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const val = (e.target as HTMLSelectElement).value === '' ? '' : parseInt((e.target as HTMLSelectElement).value);
    const optionValue = options.find(o => o.value.toString() === val.toString())!.label as string;
    setActiveFilter('competitions', val === '' ? val : optionValue);
    updateQueryUrl(history, { competitions: val, teams: '' });
  };

  return (
    <div className="filters__select__wrapper filters__select__wrapper--competition">
      <label className="filters__select__wrapper__label" htmlFor="competitions">
        Competitions
      </label>
      <Select
        name="competitions"
        className="filters__select__wrapper__select"
        options={options}
        onChange={(e: React.FormEvent<HTMLSelectElement>) => handleChange(e)}
        value={queryStrObject?.competitions || ''}
      />
    </div>
  );
};
