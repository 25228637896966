import { competitionAdAllocationFragment } from '@ligr/shared';
import gql from 'graphql-tag';
import { competitionFragment } from '../fragments/competition';

export const competitionQuery = gql`
  query competition($id: Float!) {
    competition(id: $id) {
      ...Competition
    }
  }
  ${competitionFragment}
  ${competitionAdAllocationFragment}
`;
