import classnames from 'classnames';
import React, { useCallback } from 'react';
import './Radio.scss';
import { RadioProps } from './Radio.type';

export const Radio: React.FunctionComponent<RadioProps> = ({
  onChange,
  value,
  options,
  form,
  className,
  vertical,
  disabled,
  ...props
}) => {
  const onClick = useCallback(
    (value: string) => {
      if (form && props.name) form.setFieldValue(props.name, value);
      else if (onChange) onChange(event);
    },
    [onChange, form, props]
  );

  return (
    <div className={classnames('radio', className, { vertical })}>
      {options.map(({ label, value: _v }, i) => (
        <div key={i} className="radio-label-group">
          <div
            className={classnames('radio-btn', { active: value === _v }, { vertical }, { isDisabled: disabled })}
            onClick={!disabled ? () => onClick(_v) : undefined}
          ></div>
          <div className="label">{label}</div>
        </div>
      ))}
    </div>
  );
};
