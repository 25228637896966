import gql from 'graphql-tag';

export const gradesQuery = gql`
  query grades {
    grades {
      id
      name
    }
  }
`;
