import React from 'react';
import { Route, Switch } from 'react-router';
import { withTitle } from '../../../components/PageTitle/PageTitle';
import { routes } from '../../../router/Routes';
import { NewVenuePage } from './NewVenuePage';
import { VenuePage } from './VenuePage';
import { VenuesPage } from './VenuesPage';

export const VenuesRouter: React.FunctionComponent = () => (
  <Switch>
    <Route exact path={[routes.assetVenues(false)]} component={VenuesPage} />
    <Route
      exact
      path={routes.assetVenueCreate(false)}
      component={withTitle({ component: NewVenuePage, pageTitle: 'New Venue' })}
    />
    <Route path={routes.assetVenue(false)} component={withTitle({ component: VenuePage, pageTitle: 'Venue' })} />
  </Switch>
);
