import { Competition } from '../api-types';
import { useSearchParams } from '@ligr/shared';
import gql from 'graphql-tag';
import { useErrorHandledMutation } from './apolloHooks';
import { competitionsQuery } from './gql/queries/competitions';

export const useArchiveCompetition = () => {
  const searchParams = useSearchParams(['archive']);

  const deleteCompetitionMutation = gql`
    mutation deleteCompetition($id: Float!) {
      deleteCompetition(id: $id)
    }
  `;

  const restoreCompetitionMutation = gql`
    mutation restoreCompetition($id: Float!) {
      restoreCompetition(id: $id)
    }
  `;

  const [archiveCompetitionMutation] = useErrorHandledMutation<boolean>(deleteCompetitionMutation);
  const [unarchiveCompetitionMutation] = useErrorHandledMutation<boolean>(restoreCompetitionMutation);

  const archiveCompetition = async (competitionId: number) => {
    await archiveCompetitionMutation({
      variables: { id: competitionId },
      update: (cache, { data }) => {
        const compCache = cache.readQuery<{ competitions: Competition[] }>({
          query: competitionsQuery,
          variables: { options: { archive: !!searchParams.archive } }
        });
        if (compCache && data) {
          let comps;

          if (!!searchParams.archive) {
            comps = compCache.competitions.map((comp: Competition) => {
              if (comp.id === competitionId) {
                return { ...comp, archived: true };
              }
              return comp;
            });
          } else {
            comps = compCache.competitions.filter(({ id }: Competition) => id !== competitionId);
          }
          cache.writeQuery({
            query: competitionsQuery,
            variables: { options: { archive: !!searchParams.archive } },
            data: {
              competitions: comps
            }
          });
        }
      }
    });
  };

  const restoreCompetition = async (competitionId: number) => {
    await unarchiveCompetitionMutation({
      variables: { id: competitionId },
      update: (cache, { data }) => {
        const compCache = cache.readQuery<{ competitions: Competition[] }>({
          query: competitionsQuery,
          variables: { options: { archive: !!searchParams.archive } }
        });
        if (compCache && data) {
          const comps = compCache.competitions.map((comp: Competition) => {
            return {
              ...comp,
              archived: comp.id === competitionId ? false : comp.archived
            };
          });
          cache.writeQuery({
            query: competitionsQuery,
            variables: { options: { archive: !!searchParams.archive } },
            data: {
              competitions: comps
            }
          });
        }
      }
    });
  };

  return {
    archiveCompetition,
    restoreCompetition
  };
};
