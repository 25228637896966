import gql from 'graphql-tag';
import { summaryFragment } from '../../lib/gql/fragments/summary';

export const updateOverlayMutation = gql`
  mutation updateOverlay($updateOverlayInput: UpdateOverlayInput!) {
    updateOverlay(updateOverlayInput: $updateOverlayInput) {
      id
      name
      matchId
      encodingDelay
      autoMode
      testMode
      resolution
      showWatermarks
      background
      backgroundColor
      enableAds
      key
    }
  }
`;

export const createOverlayMutation = gql`
  mutation createOverlay($createOverlayInput: CreateOverlayInput!) {
    createOverlay(createOverlayInput: $createOverlayInput) {
      id
      matchId
      name
      encodingDelay
      autoMode
      testMode
      resolution
      showWatermarks
      background
      backgroundColor
      enableAds
      key
    }
  }
`;

export const deleteOverlayMutation = gql`
  mutation deleteOverlay($id: Float!) {
    deleteOverlay(id: $id)
  }
`;

export const overlayQuery = gql`
  query overlay($id: Float!) {
    overlay(id: $id) {
      id
      matchId
      name
      encodingDelay
      autoMode
      testMode
      resolution
      showWatermarks
      background
      backgroundColor
      enableAds
      key
    }
  }
`;

export const matchesSubscription = gql`
  subscription matchesSubscription($matchesSubscriptionArgs: MatchesSubscriptionArgs!) {
    matchesSubscription(matchesSubscriptionArgs: $matchesSubscriptionArgs) {
      topic
      match {
        id
        date
        lineup {
          id
          position
          number
          captain
          starting
          photo {
            id
            url
          }
          player {
            id
            firstName
            lastName
          }
          team {
            id
            abbreviation
          }
        }
        overlays {
          id
          matchId
          name
          encodingDelay
          autoMode
          testMode
          resolution
          showWatermarks
          background
          backgroundColor
          enableAds
          key
        }
        finishedStatus
        coverage
        hasFacts
        summary {
          matchId
          currentPeriod
          periods {
            duration
            name
            preOvertimeBreak
            code
            status
            type
            aboutToStart
            playersEntered
            started
            finished
            shootout
            matchFinished
          }
          matchStats {
            score {
              home
              away
            }
          }
          clock {
            clockRunning
            clockInMs
            clockChangeDate
          }
        }
        sportName
        homeTeam {
          id
          name
          gfxName
          abbreviation
          logo {
            id
            url
            entityType
            entityId
          }
        }
        awayTeam {
          id
          abbreviation
          name
          gfxName
          logo {
            id
            url
            entityType
            entityId
          }
        }
        competition {
          id
          name
          logo {
            id
            url
            entityType
            entityId
          }
        }
      }
    }
  }
`;

export const matchSubscription = gql`
  subscription matchSubscription($matchSubscriptionArgs: MatchSubscriptionArgs!) {
    matchSubscription(matchSubscriptionArgs: $matchSubscriptionArgs) {
      match {
        id
        name
        organizationId
        awayTeamId
        homeTeamId
        venueId
        sportName
        competitionId
        description
        finishedStatus
        lineup {
          id
          position
          number
          starting
          captain
          photo {
            id
            url
          }
          player {
            id
            firstName
            lastName
          }
          team {
            id
            abbreviation
          }
        }
        date
        awayTeam {
          id
          abbreviation
          name
          logo {
            id
            url
            entityType
            entityId
          }
        }
        homeTeam {
          id
          abbreviation
          name
          logo {
            id
            url
            entityType
            entityId
          }
        }
        venue {
          id
          name
        }
      }
    }
  }
  ${summaryFragment}
`;
