import { Logo } from '@ligr/shared';
import React from 'react';
import { Link } from 'react-router-dom';
// @ts-ignore
import avatar from '../../images/register/avatar.png';
import { routes } from '../../router/Routes';
import { RegisterForm } from './Register.form';
import './register.page.scss';

export const RegisterPage = () => {
  return (
    <div className="register">
      <div className="left-panel">
        <Link to={routes.login()}>
          {' '}
          <Logo type="light" />{' '}
        </Link>

        <blockquote>
          <p>
            “LIGR and the European Cricket Network have teamed up to help cricket grow rapidly across the continent with
            the help of automated live graphic technologies.”
          </p>

          <footer>
            <img src={avatar} alt="Daniel Weston" />
            <div className="person">
              <strong>Daniel Weston</strong>
              <span>European Cricket League Founder</span>
            </div>
          </footer>
        </blockquote>
      </div>
      <div className="right-panel">
        <RegisterForm />
        <div>
          Already have a LIGR account? <Link to={routes.login()}>Log in</Link>
        </div>
      </div>
    </div>
  );
};
