import { useSearchParams } from '@ligr/shared';
import React, { useEffect, useMemo } from 'react';
import { ClubOrTeam, ClubsAndTeamsQuery, Club, Team } from '../../../../api-types';
import { ButtonDrop } from '../../../../components/ButtonDrop';
import { CardClub } from '../../../../components/CardClub';
import { CardTeam } from '../../../../components/CardTeam';
import { Filters } from '../../../../components/Filters';
import { Grid } from '../../../../components/Grid/Grid';
import { getMissingEntitiesComponentContent } from '../../../../components/MissingEntitiesComponent/getMissingEntitiesComponentContent';
import { MissingEntitiesComponent } from '../../../../components/MissingEntitiesComponent/MissingEntitiesComponent';
import { PageContent } from '../../../../components/page/PageContent';
import { PageContentContent } from '../../../../components/page/PageContentContent';
import { PageHeader } from '../../../../components/PageHeader';
import { PageSubHeader } from '../../../../components/PageSubHeader/PageSubHeader';
import { logDelay } from '../../../../lib/animation';
import { useErrorHandledLazyQuery } from '../../../../lib/apolloHooks';
import { clubsAndTeamsQuery } from '../../../../lib/gql/queries/clubsAndTeams';
import { Notification } from '../../../../lib/notificationContainer';
import { useArchiveClub } from '../../../../lib/useArchiveClub';
import { useArchiveTeam } from '../../../../lib/useArchiveTeam';
import { routes } from '../../../../router/Routes';
import { EntityEnum } from '../../../../types/assets';

export const TeamsPage: React.FunctionComponent = () => {
  const searchParams = useSearchParams([
    'sports',
    'competitions',
    'archive',
    'query',
    'club',
    'grades',
    'age',
    'gender'
  ]);

  const { archiveTeam } = useArchiveTeam();
  const { archiveClub } = useArchiveClub();
  const [fetch, { data, loading, called }] = useErrorHandledLazyQuery<ClubsAndTeamsQuery>(clubsAndTeamsQuery, {
    variables: { options: { archive: !!searchParams.archive } }
  });
  const entities = data?.clubsAndTeams || [];
  const { addNotification } = Notification.useContainer();

  useEffect(() => {
    fetch({});
  }, []);

  const entitiesSearch = useMemo(() => {
    return entities
      ? entities.filter((e: ClubOrTeam) => {
          let query = true;
          if (searchParams.query) {
            query = e.name.toLowerCase().includes(searchParams.query.toLowerCase());
          }

          const competition =
            searchParams.competitions &&
            (e.__typename === 'Team'
              ? e.competitions.length && e.competitions[0].id.toString() === searchParams.competitions
              : (e as Club).teams
                  ?.map(t => t.competitions.length && t.competitions[0].id.toString() === searchParams.competitions)
                  .includes(true));
          const sport =
            searchParams.sports &&
            (e.__typename === 'Team'
              ? e.competitions.length && e.competitions[0].sport.id.toString() === searchParams.sports
              : (e as Club).teams
                  ?.map(t => t.competitions.length && t.competitions[0].sport.id.toString() === searchParams.sports)
                  .includes(true));
          const grade =
            searchParams.grades &&
            (e.__typename === 'Team'
              ? e.grade.id.toString() === searchParams.grades
              : (e as Club).teams?.map(t => t.grade.id.toString() === searchParams.grades).includes(true));
          const club = (e.__typename || '').toLowerCase() === searchParams.club;

          const age =
            searchParams.age &&
            (e.__typename === 'Team'
              ? e.age.toString() === searchParams.age
              : (e as Club).teams?.map(t => t.age.toString() === searchParams.age).includes(true));

          const gender =
            searchParams.gender &&
            (e.__typename === 'Team'
              ? e.gender.toString() === searchParams.gender
              : (e as Club).teams?.map(t => t.gender.toString() === searchParams.gender).includes(true));

          if (searchParams.age) query = query && !!age;
          if (searchParams.gender) query = query && !!gender;
          if (searchParams.club) query = query && club;
          if (searchParams.competitions) query = !!(query && competition);
          if (searchParams.sports) query = query && !!sport;
          if (searchParams.grades) query = query && !!grade;
          return query;
        })
      : [];
  }, [
    searchParams.query,
    searchParams.competitions,
    searchParams.sports,
    searchParams.club,
    searchParams.grades,
    searchParams.age,
    searchParams.gender,
    entities
  ]);

  const archiveTeamFn = async (teamId: number) => {
    archiveTeam(teamId);
    addNotification('Team archived successfully.', 'success');
  };

  const archiveClubFn = async (clubId: number) => {
    archiveClub(clubId);
    addNotification('Club archived successfully.', 'success');
  };

  const missingEntitiesDialogProps =
    called && !loading && getMissingEntitiesComponentContent(EntityEnum.teams, entities, entitiesSearch);

  return (
    <>
      <PageContent loading={!called || loading}>
        <PageHeader title="All Clubs & Teams" />
        <PageContentContent className="teams">
          {entities.length > 0 && (
            <PageSubHeader>
              <Filters
                searchable="clubs, teams"
                filters={['sports', 'competitions', 'club', 'grades', 'age', 'gender', 'archive']}
              />
              <ButtonDrop
                icon="plus"
                iconPosition="left"
                options={[
                  { text: 'Create a club', url: routes.assetClubCreate() },
                  { text: 'Create a team', url: routes.assetTeamCreate() }
                ]}
              >
                Create a club or team
              </ButtonDrop>
            </PageSubHeader>
          )}
          {missingEntitiesDialogProps ? (
            <MissingEntitiesComponent {...missingEntitiesDialogProps} />
          ) : (
            <Grid>
              {entitiesSearch.map((e: ClubOrTeam, i: number) => {
                return e.__typename === 'Club' ? (
                  <CardClub key={i} club={e} {...logDelay(i)} icon="close" fn={() => archiveClubFn(e.id)} />
                ) : (
                  <CardTeam
                    key={i}
                    team={e as Team}
                    {...logDelay(i)}
                    competitions={(e as Team).competitions}
                    icon="close"
                    fn={() => archiveTeamFn(e.id)}
                  />
                );
              })}
            </Grid>
          )}
        </PageContentContent>
      </PageContent>
    </>
  );
};
