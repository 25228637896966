import React from 'react';
import { Button } from '../../components/Button';
import { Title } from '../../components/Title/Title';
import { Me } from '../../lib/meContainer';
import { routes } from '../../router/Routes';
import './errors.scss';

export const NotFound = () => {
  const { me } = Me.useContainer();
  const link = me?.id ? routes.home() : routes.orgSelection();

  return (
    <div className="page error not-found">
      <div className="center">
        <Title is1>Nooo…</Title>
        <span>The referees looked at the video review but couldn’t find the page you’re looking for. Typical. </span>
        <br />
        <Button href={link}>Go back home</Button>
      </div>
    </div>
  );
};
