import classNames from 'classnames';
import moment from 'moment';
import React from 'react';

interface PregameCellProps {
  expectedSecondsTillStart: number;
  countdown: any;
  date: string;
  archived: boolean;
}

export const PregameCell: React.FunctionComponent<PregameCellProps> = ({
  expectedSecondsTillStart,
  countdown,
  date,
  archived
}) => {
  let isLate = false;

  /*if (expectedSecondsTillStart < - 60 * 30) {
    return <div className="dateCell__message-expired">
      <ToolTip reference={() => <div className="dateCell__message-expired__label">Running Late</div>}>
        {moment(date).format('MMM D Y, h:mma')}
      </ToolTip>
    </div>;
  }*/

  if (expectedSecondsTillStart < 0) isLate = true;

  return (
    <div className="dateCell__message-pregame">
      <div className="dateCell__message-pregame__date">{moment(date).format('MMM D Y, h:mma')}</div>
      {archived ? (
        <div className="dateCell__message-pregame--archived">Archived</div>
      ) : (
        <div
          className={classNames('dateCell__message-pregame__countdown', {
            'dateCell__message-pregame__countdown--isLate': isLate
          })}
        >
          {[
            { key: 'days', label: 'days' },
            { key: 'hours', label: 'hrs' },
            { key: 'mins', label: 'mins' }
          ].map(({ key, label }, index) => {
            const isSingular = Math.abs(countdown?.[key]) === 1;
            if (countdown?.[key]) {
              return (
                <span key={index} className="dateCell__message-pregame__countdown__item">
                  {`${Math.abs(countdown[key])}`}
                  <span className="dateCell__message-pregame__countdown__item__label">{` ${
                    isSingular ? label.slice(0, -1) : label
                  }  `}</span>
                </span>
              );
            }
          })}
          {isLate ? ' ago' : ''}
        </div>
      )}
    </div>
  );
};
