import React from 'react';
import { MatchConfig } from '@ligr/api-v2';
import { PeriodStatus } from '@ligr/api-v2/dist/types/period/Period';
import { Score } from '@ligr/api-v2/dist/gql/entities/MatchSummary';
import { MatchSummary } from '@ligr/shared';

import { Title } from '../../../../components/Title/Title';
import { ScoresMatchesCard } from './ScoresMatchesCard';

export type TMatch = {
  config: MatchConfig;
  date: Date;
  sportName: string;
  summary: MatchSummary;
  liveStatus: PeriodStatus;
  homeTeam: { abbreviation: string | null | undefined; logo: { url: string | undefined } };
  awayTeam: { abbreviation: string | null | undefined; logo: { url: string | undefined } };
  score: Score;
};

interface ScoresProps {
  matches?: TMatch[] | undefined;
  competitionName: string;
}

export const Scores: React.FunctionComponent<ScoresProps> = ({ matches, competitionName }) => {
  return (
    <div className="scores">
      <Title is4 className="scores__title">
        {`Live scores - ${competitionName}`}
      </Title>
      <div className="scoresGrid">
        {matches?.map((m, idx: number) => (
          <ScoresMatchesCard key={idx} match={m} />
        ))}
      </div>
    </div>
  );
};
