import { Icon } from '@ligr/shared';
import classnames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Loader } from '../loader/Loader';
import './styles.scss';
import { ButtonProps } from './type';

export const Button: React.FunctionComponent<ButtonProps> = props => {
  const {
    type,
    btnType,
    isDisabled,
    isLoading,
    isSquare,
    icon,
    iconPosition,
    className,
    onClick,
    children,
    href,
    iconColor,
    shrink = false,
    forwardRef,
    style,
    ...rest
  } = props;

  const classNames = classnames(
    'button',
    className,
    { [`is-${type}`]: type },
    { 'is-disabled': isDisabled },
    { 'is-loading': isLoading },
    { [`has-icon-${iconPosition}`]: iconPosition },
    { 'is-square': isSquare },
    { 'is-shrinkable': shrink }
  );

  let usedIconColor = iconColor;
  if (!usedIconColor) {
    usedIconColor = type === 'primary' || !type ? 'white' : 'grey-600';
  }

  const contents = (
    <>
      {icon && <Icon icon={icon} color={usedIconColor} />}
      {children && !isSquare && <span>{children}</span>}
      {isLoading && <Loader size="small" />}
    </>
  );

  if (href) {
    return (
      <NavLink className={classNames} to={href}>
        {' '}
        {contents}{' '}
      </NavLink>
    );
  }

  return (
    <button
      {...rest}
      ref={forwardRef}
      className={classNames}
      disabled={isDisabled}
      onClick={onClick}
      style={style}
      type={btnType}
    >
      {contents}
    </button>
  );
};
