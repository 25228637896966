import React from 'react';
import { Match } from '../../../../../api-types';
import { footballStatKeyToNameMap } from '@ligr/api-v2';
import { Select } from '../../../../Select';
import { TriggerProps } from '@ligr/themes/lib/football/themes/default/TeamStatComparison/types';
import './style.scss';

interface TeamStatComparisonCustomProps {
  value?: TriggerProps;
  onChange: (update: TriggerProps) => any;
  match: Match;
  disabled?: boolean;
}

// FULLY CONTROLLED ONLY
export const TeamStatComparison: React.FunctionComponent<TeamStatComparisonCustomProps> = ({
  disabled,
  onChange,
  value = {},
  match
}) => {
  return (
    <div className="teamstatcomparison">
      <div className="teamstatcomparison__period">
        <Select
          disabled={disabled}
          value={value.period}
          onChange={event => {
            // @ts-ignore
            onChange({ ...value, period: event.target.value });
          }}
          label="Period"
          options={[
            {
              value: 'total',
              label: 'Total'
              // TODO add started in
            },
            ...(match?.summary?.periods
              ?.filter(({ status, started }) => status === 'live' && started)
              .map(({ code, name }) => ({
                label: name,
                value: code
              })) || [])
          ]}
        />
      </div>
      <div className="teamstatcomparison__stat">
        <Select
          disabled={disabled}
          value={value.statName}
          onChange={event => {
            // @ts-ignore
            onChange({ ...value, statName: event.target.value });
          }}
          label="Stat"
          // @ts-ignore
          options={([{ label: 'Select Stat', value: null }] as { value: null | string; label: string }[]).concat(
            Object.entries(footballStatKeyToNameMap).map(([value, label]) => ({
              value,
              label
            }))
          )}
        />
      </div>
    </div>
  );
};
