import React from 'react';

import { routes } from '../../router/Routes';
import { CardPlayer } from '../CardPlayer';
import { StartingLineupItem } from '../StartingLineup/StartingLineupItem';
import { Title } from '../Title/Title';
import { getTeamPlayer } from '../../lib/util/getTeamPlayer';
import { CardPlayerList } from '../CardPlayerList';

import './style.scss';

type TeamPlayer = {
  defaultStarter: boolean;
  position?: string | null;
  photo?: {
    id: number;
    url: string;
  } | null;
  number?: string | null;
  team: {
    id: number;
  };
};

export type Player = {
  id: number;
  archived: boolean;
  countTeams: number;
  firstName: string;
  lastName: string;
  playsFor?:
    | {
        defaultStarter: boolean;
        team: Team;
        photo?: {
          url: string;
          id: number;
        } | null;
        position?: string | null;
        number?: string | null;
      }[]
    | null;
};

type Team = {
  id: number;
};

export interface PlayersByPositionProps {
  players: Player[] | { player: Player; index: number }[];
  team: Team;
  type: 'card' | 'lineup' | 'list';
  draggable?: boolean;
  forwardRef?: (val: any) => void;
}

const noPosition = 'No Position';

export const PlayersByPosition: React.FunctionComponent<PlayersByPositionProps> = ({
  players,
  team,
  type,
  ...props
}) => {
  let positions: string[];
  let playerList: { player: Player; index: number }[];

  if (props.draggable) {
    positions = [
      ...new Set(
        (players as [{ player: Player; index: number }])
          .map(({ player }) => player.playsFor!.find(t => t.team.id === team.id)?.position)
          .filter(val => val) as string[]
      )
    ];
    if (
      (players as [{ player: Player; index: number }]).filter(
        ({ player }) => !player.playsFor!.find(t => t.team.id === team.id)?.position
      ).length > 0
    ) {
      positions.push(noPosition);
    }
    playerList = players as [{ player: Player; index: number }];
  } else {
    positions = [
      ...new Set(
        (players as Player[])
          .map(({ playsFor }) => playsFor!.find(t => t.team.id === team.id)?.position)
          .filter(val => val) as string[]
      )
    ];
    if ((players as Player[]).filter(p => !p.playsFor?.find(t => t.team.id === team.id)?.position).length > 0) {
      positions.push(noPosition);
    }
    playerList = (players as Player[]).map((p, idx) => {
      return { player: p, index: idx };
    });
  }

  return (
    <div className="playersByPosition">
      {positions.map((pos, idx) => (
        <div className="playersByPosition__item" key={idx}>
          <Title is3 className="playersByPosition__title">
            {pos}
          </Title>
          <div className={`playersByPosition__grid playersByPosition__grid--${type ? type : 'card'}`}>
            {playerList
              .filter(({ player }) => {
                const playerTeam: TeamPlayer = player.playsFor?.find(({ team: { id } }) => id === team.id)!;
                if (!playerTeam.position && noPosition === pos) {
                  return true;
                }
                return playerTeam.position === pos;
              })
              .map((pl, i) => {
                if (type === 'lineup') {
                  return (
                    <StartingLineupItem
                      key={pl.player.id}
                      player={pl.player}
                      {...props}
                      index={props.draggable ? pl.index : undefined}
                      team={team}
                    />
                  );
                }
                if (type === 'list') {
                  const playerTeam = getTeamPlayer(pl.player, team);
                  return <CardPlayerList key={pl.player.id} player={pl.player} playerTeam={playerTeam} team={team} />;
                }
                return (
                  <CardPlayer
                    key={i}
                    player={pl.player}
                    to={{
                      pathname: routes.assetTeamPlayersEdit({ teamId: team.id, playerId: pl.player.id }),
                      search: location.search
                    }}
                    showSport={false}
                    showCompetitions={false}
                    showTeams={false}
                  />
                );
              })}
          </div>
        </div>
      ))}
    </div>
  );
};
