import { updateQueryUrl } from '@ligr/shared';
import queryString from 'query-string';
import React from 'react';
import { useHistory } from 'react-router';
import { FilterOptions } from '.';
import { Select } from '../Select';
import { SelectOption } from '../Select/types';

interface ClubFilterProps {
  setActiveFilter: (name: FilterOptions, value: string) => void;
}

export const ClubFilter: React.FunctionComponent<ClubFilterProps> = ({ setActiveFilter }) => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const queryStrObject = queryString.parse(history.location.search);
  const options: SelectOption[] = [
    { label: 'All', value: '' },
    { label: 'By Club', value: 'club' },
    { label: 'By Team', value: 'team' }
  ];

  const handleChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const val = (e.target as HTMLSelectElement).value;
    setActiveFilter('club', val === '' ? '' : `By ${val}`);
    updateQueryUrl(history, { club: val });
  };

  return (
    <div className="filters__select__wrapper filters__select__wrapper--club">
      <label className="filters__select__wrapper__label" htmlFor="club">
        Club/Team
      </label>
      <Select
        name="club"
        className="filters__select__wrapper__select"
        options={options}
        onChange={(e: React.FormEvent<HTMLSelectElement>) => handleChange(e)}
        value={queryStrObject?.club || ''}
      />
    </div>
  );
};
