import { Icon, updateQueryUrl } from '@ligr/shared';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { InputField } from '../InputField';
import './search-field.scss';
import { SearchFieldProps } from './SearchField.types';

export const SearchField: React.FunctionComponent<SearchFieldProps> = ({
  placeholder = 'Search…',
  onChange,
  urlSearch,
  style,
  ...props
}) => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const { query } = queryString.parse(history.location.search);

  const doChange = (value: string) => {
    urlSearch && updateQueryUrl(history, { query: value });
    onChange && onChange(value);
  };

  const val = useMemo(() => {
    return urlSearch ? ((query || '') as string) : props.value;
  }, [query, urlSearch]);

  return (
    <div className="search-field-wrap" style={style}>
      <InputField
        placeholder={placeholder}
        icon={'search'}
        iconPosition="right"
        className="search-field"
        value={val}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => doChange(e.target.value)}
        {...props}
      />
      {val && <Icon icon="close" className="close" onClick={() => doChange('')} />}
    </div>
  );
};
