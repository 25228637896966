import gql from 'graphql-tag';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { object, string } from 'yup';
import { CreateOrganizationInput, Organization } from '../../api-types';
import { Form } from '../../components/Form/Form';
import { Modal, ModalProps } from '../../components/modal/Modal';
import { getGQLError } from '../../lib/apolloClient';
import { useErrorHandledMutation } from '../../lib/apolloHooks';
import { Me } from '../../lib/meContainer';
import { routes } from '../../router/Routes';
import { OrganizationForm } from './forms';

const organizationFormSchema = object().shape({
  name: string().min(1).required().label('Organization name')
});

const createOrganizationMutation = gql`
  mutation createOrganization($createOrganizationInput: CreateOrganizationInput!) {
    createOrganization(createOrganizationInput: $createOrganizationInput) {
      id
      name
    }
  }
`;

export const CreateOrganizationModal: React.FunctionComponent<Partial<ModalProps>> = props => {
  const [error, setError] = useState('');
  const modal = useRef<Modal>(null);
  const [form, setForm] = useState<any>(null);
  const { refetch } = Me.useContainer();

  const initialValues = {
    name: ''
  };

  const history = useHistory<{ referrer?: string } | undefined>();
  const [createOrganization, { data, loading }] = useErrorHandledMutation<
    { createOrganization: Organization },
    {
      createOrganizationInput: CreateOrganizationInput;
    }
  >(createOrganizationMutation);

  const onClose = async () => {
    // await refetch();
    history.push({
      pathname: routes.home(),
      search: location.search
    });
  };

  const create = async (values: CreateOrganizationInput) => {
    try {
      const response = await createOrganization({ variables: { createOrganizationInput: values } });
      if (response.data?.createOrganization) {
        history.push({ pathname: routes.home({ orgId: response.data.createOrganization?.id }) });
      } else {
        onClose();
      }
    } catch (e) {
      setError(getGQLError(e).message);
    }
  };

  return (
    <Modal
      ref={modal}
      title="Create organization"
      {...props}
      footerButtons={[
        { text: 'Cancel', onClick: () => history.push('/o'), type: 'secondary' },
        { text: 'Create organization', onClick: () => form.submitForm(), isLoading: loading }
      ]}
    >
      <Form
        onSubmit={create}
        setForm={setForm}
        initialValues={initialValues}
        error={error}
        validationSchema={organizationFormSchema}
        className="create-organization organization-form"
        useConfirm={true}
      >
        <OrganizationForm />
      </Form>
    </Modal>
  );
};
