import React from 'react';
import { PageHeader, PageHeaderProps } from '../PageHeader';
import './style.scss';

export interface PageMenuProps extends Pick<PageHeaderProps, 'title' | 'preTitle'> {
  component?: React.FunctionComponent;
}

export const PageMenu: React.FunctionComponent<PageMenuProps> = ({
  title,
  preTitle,
  component: Component,
  children
}) => (
  <aside className="pageMenu">
    <PageHeader title={title} preTitle={preTitle} />
    <div className="pageMenu__wrapper">
      {Component && <Component />}
      {children}
    </div>
  </aside>
);
