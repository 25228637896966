import { useSubscription } from '@apollo/react-hooks';
import { EFinishedStatus, MatchSubscriptionTopics } from '@ligr/api-v2';

import { useSearchParams } from '@ligr/shared';
import moment from 'moment';
import queryString from 'query-string';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useErrorHandledLazyQuery } from './apolloHooks';
import { matchesQuery } from './gql/queries/matches';
import { useDebouncedEffect } from './util/useDebouncedEffect';
import { IQueryOptions, Match } from '../api-types';
import { DocumentNode } from 'apollo-link';

export const useMatchLoad = ({
  matchesSubscription,
  pageNumber = 1,
  pageSizeNumber = 25
}: {
  matchesSubscription: DocumentNode;
  pageNumber?: number;
  pageSizeNumber?: number;
}) => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const tab = history.location.pathname.split('/')[4];
  const { query, status, archive } = queryString.parse(history.location.search);
  const searchParams = useSearchParams(['sports', 'competitions', 'teams', 'status', 'startAt', 'endAt', 'archive']);

  const [startDate, setStartDate] = useState(searchParams.startAt ? moment(searchParams.startAt, 'DD-MM-YYYY') : '');
  const [endDate, setEndDate] = useState(searchParams.endAt ? moment(searchParams.endAt, 'DD-MM-YYYY') : '');

  const [fetchMatches, { data, loading, error }] = useErrorHandledLazyQuery<{
    matches: {
      matches: Match[];
      total: number;
    };
  }>(matchesQuery);

  const matches = useMemo(() => data?.matches?.matches || [], [data]);

  const count = useMemo(() => data?.matches?.total || 0, [data]);

  const { data: subscriptionUpdate } = useSubscription(matchesSubscription, {
    variables: {
      matchesSubscriptionArgs: {
        topics: [MatchSubscriptionTopics.updates, MatchSubscriptionTopics.creates, MatchSubscriptionTopics.deletes],
        dateRange: { start: moment(startDate).toString(), end: moment(endDate).toString() }
      }
    }
  });

  const variables = useMemo(() => {
    const searchOption: IQueryOptions = {
      limit: pageSizeNumber,
      offset: (pageNumber - 1) * pageSizeNumber,
      orderBy: 'date',
      order: tab === 'completed' ? 'DESC' : 'ASC',
      archive: !!searchParams.archive
    };
    if (startDate) {
      searchOption.startAt = moment(startDate).startOf('day').toString();
    }

    if (endDate) {
      searchOption.endAt = moment(endDate).endOf('day').toString();
    }

    if (startDate) {
      searchOption.order = 'ASC';
    } else if (!startDate && endDate) {
      searchOption.order = 'DESC';
    }

    let finishedStatus = status && [status as EFinishedStatus];

    if (!finishedStatus) {
      finishedStatus =
        tab === 'completed'
          ? [EFinishedStatus.finished, EFinishedStatus.cancelled]
          : tab === 'notstarted'
          ? [EFinishedStatus.expired]
          : [EFinishedStatus.pregame, EFinishedStatus.inProgress, EFinishedStatus.runningLate];
    }

    return {
      options: searchOption,
      query,
      finishedStatus
    };
  }, [pageSizeNumber, pageNumber, query, startDate, endDate, status, archive]);

  useDebouncedEffect(
    () => {
      fetchMatches({
        variables: {
          ...variables,
          competitionId: searchParams?.competitions ? parseInt(searchParams?.competitions) : undefined,
          sportId: searchParams?.sports ? parseInt(searchParams?.sports) : undefined,
          teamId: searchParams?.teams ? parseInt(searchParams?.teams) : undefined
        }
      });
    },
    [variables, searchParams?.sports, searchParams?.competitions, searchParams?.teams, searchParams.archive],
    300
  );

  const createdSinceFetch = useMemo(() => {
    if (subscriptionUpdate?.matchesSubscription.topic !== 'match/created') {
      return [];
    }

    return [subscriptionUpdate?.matchesSubscription.match];
  }, [subscriptionUpdate]);

  const useMatches = useMemo(() => [...matches, ...createdSinceFetch], [matches, createdSinceFetch]);

  return {
    fetchMatches,
    loading,
    error,
    matches: useMatches,
    count,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    variables
  };
};
