import React from 'react';
import { Button } from '../../components/Button';
import { Title } from '../../components/Title/Title';
import { Me } from '../../lib/meContainer';
import { routes } from '../../router/Routes';
import './errors.scss';

export const Unauthorized = () => {
  const { me } = Me.useContainer();
  const link = me?.id ? routes.home() : routes.orgSelection();

  return (
    <div className="page error unauthorized">
      <div className="center">
        <Title is1>Phwwwwwhht…</Title>
        <span>The referees checked the instant replay and confirmed you cannot access this page. Boo!</span>
        <br />
        <Button href={link}>Return home</Button>
      </div>
    </div>
  );
};
