import gql from 'graphql-tag';
import React from 'react';
import { useParams } from 'react-router-dom';
import { PageContent } from '../../../../components/page/PageContent';
import { PageHeader } from '../../../../components/PageHeader';
import { PageMenu } from '../../../../components/PageMenu';
import { adAllocationQuery } from '../../../../lib/gql/queries/adAllocation';
import { useErrorHandledQuery } from '../../../../lib/apolloHooks';
import { AdAllocactionSideMenu } from '../../AdAllocationSideMenu/AdAllocation.menu';
import { AdSetsAllocationsForm } from './AdSetsAllocationPageForms/index';
import {
  AdAllocationsQuery,
  AdAllocationEntityType,
  AdSetsWithAllocationsQuery,
  AdAllocationQuery,
  AdAllocationWithEntityQuery
} from '../../../../api-types';

const adSetsQuery = gql`
  query adSetsWithAllocations($options: IQueryOptions) {
    adSets(options: $options) {
      id
      name
      adSetAllocations {
        events
        percentage
        id
      }
    }
  }
`;

export const AdSetsAllocationPage: React.FunctionComponent = () => {
  const { entityId, entityType } = useParams();

  const { data: adSetsData, loading: adSetsLoading } = useErrorHandledQuery<AdSetsWithAllocationsQuery>(adSetsQuery);

  const adSets = (adSetsData && adSetsData.adSets) || [];
  const { data, loading } = useErrorHandledQuery<AdAllocationWithEntityQuery>(adAllocationQuery, {
    variables: {
      adAllocationArgs: {
        entityId: parseInt(entityId!),
        entityType
      }
    }
  });

  const allocation = data ? (data.adAllocation.length ? data.adAllocation[0] : undefined) : undefined;
  return (
    <>
      <PageMenu title="Allocation" component={AdAllocactionSideMenu} />
      <PageContent loading={loading}>
        {allocation && (
          <>
            <PageHeader title={allocation.entity.name} />
            <AdSetsAllocationsForm
              allocation={allocation}
              entityType={entityType as AdAllocationEntityType}
              adSets={adSets}
            />
          </>
        )}
      </PageContent>
    </>
  );
};
