import gql from 'graphql-tag';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { object, string } from 'yup';
import { Button } from '../../components/Button';
import { Form } from '../../components/Form/Form';
import { FormProps } from '../../components/Form/Form.types';
import { FormField } from '../../components/FormField';
import { FormStatus } from '../../components/FormStatus/FormStatus';
import { Title } from '../../components/Title/Title';
import { useErrorHandledMutation } from '../../lib/apolloHooks';
import { routes } from '../../router/Routes';
import { PasswordResetMutation } from '../../api-types';

export interface ForgotPasswordInput {
  email: string;
}

const passwordResetMutation = gql`
  mutation passwordReset($email: String!) {
    passwordReset(email: $email)
  }
`;

export const validationSchema = object().shape({
  email: string().email().required('Please enter your email to reset your password').label('Email')
});

export const LoginForm: React.FunctionComponent<FormProps<ForgotPasswordInput>> = props => {
  const [resetPassword, { loading }] = useErrorHandledMutation<PasswordResetMutation>(passwordResetMutation);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState<string | undefined>();

  const onSubmit = async (values: ForgotPasswordInput) => {
    setError(undefined);
    try {
      await resetPassword({
        variables: { email: values.email }
      });
      setEmail(values.email);
      setSuccess(true);
    } catch (e) {
      setError(e.graphQLErrors[0].message);
    }
  };

  if (success) {
    return (
      <div className="success">
        <Title is2>Success!</Title>
        <p>
          Your email is on it's way to {email} with your password reset link.
          <br />
          See you soon!
          <br />
          <Button href={routes.login()}>Return to Login</Button>
        </p>
      </div>
    );
  }

  return (
    <Form {...props} validationSchema={validationSchema} onSubmit={onSubmit} initialValues={{ email: '' }}>
      <Title is2>Reset your password</Title>
      <p>
        Oops! We all forget our passwords sometimes, don't worry. Enter your email below, and we'll send you a link to
        reset it in no time.
      </p>
      {error && <FormStatus error={true}>{error}</FormStatus>}
      <FormField name="email" type="text" label="Email address" />
      <Button isLoading={loading}>Email me a reset link</Button>
      <br />
      <Link to={routes.login()} className="back">
        or take me back to login
      </Link>
    </Form>
  );
};
