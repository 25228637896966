import { updateQueryUrl } from '@ligr/shared';
import capitalize from 'capitalize';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { FilterOptions } from '.';
import { Grade } from '../../api-types';
import { Select } from '../Select';
import { SelectOption } from '../Select/types';

interface GradeFilterProps {
  grades: Grade[];
  setActiveFilter: (name: FilterOptions, value: string) => void;
}

export const GradeFilter: React.FunctionComponent<GradeFilterProps> = ({ grades, setActiveFilter }) => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const queryStrObject = queryString.parse(history.location.search);

  const options: SelectOption[] = useMemo(() => {
    const gradeOptions = grades.map(grade => ({
      value: parseInt((grade.id as unknown) as string),
      label: capitalize(grade.name)
    })) as SelectOption[];

    return [{ value: '', label: 'All' } as SelectOption].concat(gradeOptions);
  }, [grades]);

  const handleChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const val = (e.target as HTMLSelectElement).value === '' ? '' : parseInt((e.target as HTMLSelectElement).value);
    const optionValue = options.find(o => o.value.toString() === val.toString())!.label as string;
    setActiveFilter('grades', val === '' ? val : optionValue);
    updateQueryUrl(history, { grades: val, competitions: '' });
  };

  return (
    <div className="filters__select__wrapper filters__select__wrapper--grade">
      <label className="filters__select__wrapper__label" htmlFor="grades">
        Division
      </label>
      <Select
        name="grades"
        className="filters__select__wrapper__select"
        options={options}
        onChange={(e: React.FormEvent<HTMLSelectElement>) => handleChange(e)}
        value={queryStrObject?.grades || ''}
      />
    </div>
  );
};
