import React, { useMemo } from 'react';
import Helmet from 'react-helmet';
import { PageTitleProps, WithTitleProps } from './PageTitle.type';

const BASE_TITLE = 'LIGR -';

export const PageTitle: React.FunctionComponent<PageTitleProps> = ({ title }) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export function withTitle<ComponentProps>({
  component: Comp,
  pageTitle,
  ...extraProps
}: WithTitleProps<ComponentProps>): React.FunctionComponent<ComponentProps> {
  const title = pageTitle ? `${BASE_TITLE} ${pageTitle}` : `${BASE_TITLE} Dashboard`;

  const Component = useMemo(
    () => (
      // @ts-ignore
      <Comp {...extraProps} />
    ),
    [...Object.values(extraProps)]
  );

  return useMemo(
    () => () => (
      <>
        <PageTitle title={title} />
        {Component}
      </>
    ),
    [Component]
  );
}
