import { IGraphic } from '@ligr/themes/lib/Theme.type';

export interface IColumns {
  'col-1': { [key: string]: IGraphic[] };
  'col-2': { [key: string]: IGraphic[] };
  'col-3': { [key: string]: IGraphic[] };
}

export const formatControlLayout = (sportId: number, graphics: IGraphic[]) => {
  if (sportId === 1) return formatSoccerControlLayout(graphics);
  if (sportId === 2) return formatRugbyLeagueControlLayout(graphics);
  if (sportId === 3) return formatRugbyUnionControlLayout(graphics);
};

const formatSoccerControlLayout = (graphics: IGraphic[]): IColumns => {
  const columns: IColumns = {
    // tslint:disable-next-line: object-literal-key-quotes
    'col-1': { break: [] as IGraphic[] },
    'col-2': { 'in game': [] as IGraphic[] },
    // tslint:disable-next-line: object-literal-key-quotes
    'col-3': { custom: [] as IGraphic[] }
  };

  graphics.forEach(graphic => {
    switch (graphic.name) {
      case 'Intro':
      case 'MatchId':
      case 'TeamStats':
      case 'Background':
      case 'TeamList':
        columns['col-1']['break'].push(graphic);
        break;
      case 'Scoreboard':
      case 'Event':
      case 'Status':
      case 'TeamStatComparison':
      case 'Penalties':
      case 'Player':
        columns['col-2']['in game'].push(graphic);
        break;
      case 'LowerThird':
      case 'DetachedAd':
        columns['col-3']['custom'].push(graphic);
        break;
      default:
        break;
    }
  });
  return columns;
};

const formatRugbyLeagueControlLayout = (graphics: IGraphic[]): IColumns => {
  const columns: IColumns = {
    // tslint:disable-next-line: object-literal-key-quotes
    'col-1': { break: [] as IGraphic[] },
    'col-2': { 'in game': [] as IGraphic[] },
    // tslint:disable-next-line: object-literal-key-quotes
    'col-3': { custom: [] as IGraphic[] }
  };

  graphics.forEach(graphic => {
    switch (graphic.name) {
      case 'Intro':
      case 'MatchId':
      case 'TeamStats':
      case 'Background':
        columns['col-1']['break'].push(graphic);
        break;
      case 'Scoreboard':
      case 'Event':
      case 'Status':
      case 'TeamStatComparison':
      case 'Penalties':
      case 'Player':
        columns['col-2']['in game'].push(graphic);
        break;
      case 'LowerThird':
      case 'DetachedAd':
        columns['col-3']['custom'].push(graphic);
        break;
      default:
        break;
    }
  });
  return columns;
};

const formatRugbyUnionControlLayout = (graphics: IGraphic[]): IColumns => {
  const columns: IColumns = {
    // tslint:disable-next-line: object-literal-key-quotes
    'col-1': { break: [] as IGraphic[] },
    'col-2': { 'in game': [] as IGraphic[] },
    // tslint:disable-next-line: object-literal-key-quotes
    'col-3': { custom: [] as IGraphic[] }
  };

  graphics.forEach(graphic => {
    switch (graphic.name) {
      case 'Intro':
      case 'MatchId':
      case 'TeamStats':
      case 'Background':
      case 'TeamList':
        columns['col-1']['break'].push(graphic);
        break;
      case 'Scoreboard':
      case 'Event':
      case 'Status':
      case 'Player':
        columns['col-2']['in game'].push(graphic);
        break;
      case 'LowerThird':
      case 'DetachedAd':
        columns['col-3']['custom'].push(graphic);
        break;
      default:
        break;
    }
  });
  return columns;
};
