import { useSearchParams } from '@ligr/shared';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Player, PlayersForTeamQuery, TeamWithPlayerPhoto } from '../../../../api-types';
import { Button } from '../../../../components/Button';
import { getMissingEntitiesComponentContent } from '../../../../components/MissingEntitiesComponent/getMissingEntitiesComponentContent';
import { MissingEntitiesComponent } from '../../../../components/MissingEntitiesComponent/MissingEntitiesComponent';
import { PageContentContent } from '../../../../components/page/PageContentContent';
import { PageSubHeader } from '../../../../components/PageSubHeader/PageSubHeader';
import { PlayersByPosition } from '../../../../components/PlayersByPosition';
import { SearchField } from '../../../../components/SearchField/SearchField';
import { StartingLineup } from '../../../../components/StartingLineup';
import { useErrorHandledLazyQuery } from '../../../../lib/apolloHooks';
import { playersForTeamQuery } from '../../../../lib/gql/queries/playersForTeam';
import { routes } from '../../../../router/Routes';
import { EntityEnum } from '../../../../types/assets';

interface PlayersTabProps {
  team: {
    id: number;
    name: string;
  };
}

export const PlayersTab: React.FunctionComponent<PlayersTabProps> = ({ team }) => {
  const history = useHistory<{ referrer?: string; teamId?: number } | undefined>();
  const location = history.location;

  const [fetch, { data, loading, called }] = useErrorHandledLazyQuery<PlayersForTeamQuery>(playersForTeamQuery, {
    variables: { teamId: team.id }
  });
  const players = (data && data.playersForTeam) || [];

  const searchParams = useSearchParams(['query']);

  // Note can pass searchParams into the fetch as GQL variables
  // if we are server side searching
  useEffect(() => {
    fetch({ variables: { teamId: team.id } });
  }, []);

  const playersSearch = useMemo(() => {
    return players
      ? players.filter((p: Player) => {
          const playerTeam: TeamWithPlayerPhoto = p.playsFor!.find(({ team: { id } }) => id === team.id)!;

          if (searchParams.query) {
            return (
              p.firstName.toLowerCase().includes(searchParams.query.toLowerCase()) ||
              p.lastName.toLowerCase().includes(searchParams.query.toLowerCase()) ||
              (playerTeam.position && playerTeam.position.toLowerCase().includes(searchParams.query.toLowerCase())) ||
              (playerTeam.number && playerTeam.number.toString().includes(searchParams.query))
            );
          }
          return true;
        })
      : [];
  }, [searchParams.query, players, team]);

  const missingEntitiesDialogProps =
    called && !loading && getMissingEntitiesComponentContent(EntityEnum.players, players, playersSearch);

  return (
    <>
      <PageContentContent loading={!called || loading} className="team-page-players">
        <PageSubHeader className="team-page-players__search">
          <Button
            onClick={() =>
              history.push({
                pathname: routes.assetPlayerCreate(),
                search: location.search,
                state: {
                  teamId: team.id
                }
              })
            }
          >
            Add player
          </Button>
          <SearchField placeholder="Search players" urlSearch />
        </PageSubHeader>
        <div className="team-page-players__players-list">
          {missingEntitiesDialogProps ? (
            <MissingEntitiesComponent
              {...missingEntitiesDialogProps}
              url={{
                pathname: routes.assetTeamPlayersCreate({
                  teamId: team.id
                }),
                search: location.search
              }}
            />
          ) : (
            <>
              <PlayersByPosition team={team} players={playersSearch} type="list" />
              <StartingLineup players={players} loading={loading} team={team} />
            </>
          )}
        </div>
      </PageContentContent>
    </>
  );
};
