import gql from 'graphql-tag';

export const updateVenueMutation = gql`
  mutation updateVenue($updateVenueInput: UpdateVenueInput!) {
    updateVenue(updateVenueInput: $updateVenueInput) {
      id
      name
      gfxName
      lat
      lng
      city
      state
      country
      postcode
      address
      baseEntity {
        id
        name
      }
    }
  }
`;
