import pluralize from 'pluralize';
import { routes } from '../../router/Routes';
import { EntityEnum, EntityList } from '../../types/assets';

export const getMissingEntitiesComponentContent = (
  type: EntityEnum,
  entity: EntityList,
  entitySearch?: EntityList,
  text?: string,
  button: boolean = true,
  buttonText?: string
) => {
  let url;
  switch (type) {
    case EntityEnum.competitions:
      url = routes.assetCompetitionCreate();
      break;
    case EntityEnum.teams:
      url = routes.assetTeamCreate();
      break;
    case EntityEnum.players:
      url = routes.assetPlayerCreate();
      break;
    case EntityEnum.venues:
      url = routes.assetVenueCreate();
      break;
    case EntityEnum.adSets:
      url = routes.createAdSet();
      break;
    default:
      url = '';
  }

  if (entity.length === 0) {
    return {
      text: text || `Create your first ${pluralize.singular(type)}.`,
      buttonText: button ? buttonText || `Add` : undefined,
      url
    };
  }
  if (entitySearch && entitySearch.length === 0) {
    return {
      text: 'No matching results for your search'
    };
  }
  return undefined;
};
