import gql from 'graphql-tag';

const teamWithCompFragment = gql`
  fragment TeamWithComp on Team {
    id
    name
    defaultVenueId
    countMatches
    countPlayers
    archived
    sport {
      id
      name
    }
    logo {
      id
      url
    }
    competitions {
      id
      name
      logo {
        id
        url
      }
      sport {
        id
        name
      }
    }
  }
`;

export const teamsWithCompQuery = gql`
  query teamsWithComp($competitionId: Float, $options: IQueryOptions) {
    teams(competitionId: $competitionId, options: $options) {
      ...TeamWithComp
    }
  }
  ${teamWithCompFragment}
`;

export const teamsQuery = gql`
  query teamswithSport($options: IQueryOptions) {
    teams(options: $options) {
      id
      name
      sportId
    }
  }
`;
