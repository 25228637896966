import { FootballMatchConfig } from '@ligr/api-v2';
import { FormikProps, FormikContextType } from 'formik';
import React from 'react';
import { FormField } from '../../../components/FormField';
import { Title } from '../../../components/Title/Title';
import { GroupErrorRow } from '../../../components/GroupErrorRow';
import { MatchFormValues } from '../NewMatchPage/Form/types';

export const FootballMatchFormConfigFields: React.FunctionComponent<FormikProps<MatchFormValues> & {
  disabled: boolean;
  form: FormikContextType<any>;
}> = props => {
  const { disabled } = props;
  const values = props.form.values;
  const config = values.config as FootballMatchConfig;

  return (
    <>
      <section className="footballConfigFields__matchSettings">
        <Title is3>Match settings</Title>
        <GroupErrorRow form={props.form} errorsName={['config.regularPeriodLength', 'config.regularHalftimeLength']}>
          <FormField
            type="text"
            name="config.regularPeriodLength"
            label="Half length"
            colSpan={1}
            suffix="mins"
            disabled={disabled}
            groupError
            value={values.config.regularPeriodLength}
          />
          <FormField
            type="text"
            name="config.regularHalftimeLength"
            label="Half time length"
            colSpan={1}
            suffix="mins"
            disabled={disabled}
            groupError
            value={values.config.regularHalftimeLength}
          />
        </GroupErrorRow>
        <FormField
          type="switch"
          name="config.penaltyShootout"
          label="Penalty Shootout"
          colSpan={1}
          disabled={disabled}
          value={values.config.penaltyShootout}
        />
        <FormField
          type="switch"
          name="config.extratime"
          label="Extra time"
          colSpan={1}
          disabled={disabled}
          value={values.config.extratime}
        />
        {config?.extratime && (
          <>
            <GroupErrorRow
              form={props.form}
              errorsName={['config.preOvertimeBreakLength', 'config.extratimePeriodLength']}
            >
              <FormField
                type="text"
                name="config.preOvertimeBreakLength"
                label="Pre Extra time break length"
                colSpan={1}
                suffix="mins"
                disabled={disabled}
                groupError
                value={values.config.preOvertimeBreakLength}
              />
              <FormField
                type="text"
                name="config.extratimePeriodLength"
                label="Extra time length"
                colSpan={1}
                suffix="mins"
                disabled={disabled}
                groupError
                value={values.config.extratimePeriodLength}
              />
            </GroupErrorRow>
            <FormField
              type="text"
              name="config.extratimeHalftimeLength"
              label="Extra time half length"
              colSpan={1}
              suffix="mins"
              disabled={disabled}
              value={values.config.extratimeHalftimeLength}
            />
          </>
        )}
      </section>

      <section className="footballConfigFields__dataCoverage">
        <Title is3>Data Coverage</Title>
        <FormField
          type="radio-tab"
          name="config.dataCoverage.stats"
          options={[
            { value: 'minimum', label: 'Minimum' },
            { value: 'standard', label: 'Standard' }
          ]}
          colSpan={2}
          disabled={disabled}
          value={values.config.dataCoverage.stats}
        />
        <FormField
          type="switch"
          name="config.dataCoverage.players"
          label="Players"
          colSpan={1}
          disabled={disabled}
          value={values.config.dataCoverage.players}
        />
        <FormField
          type="switch"
          name="config.dataCoverage.possession"
          label="Possession"
          colSpan={1}
          disabled={disabled}
          value={values.config.dataCoverage.possession}
        />
      </section>
    </>
  );
};
