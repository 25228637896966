import { SummaryFragment } from '../../../api-types';
import { getCurrentPeriod, LiveClockState, useClock } from '@ligr/shared';
import classnames from 'classnames';
import React from 'react';
import { Title } from '../../Title/Title';
import './style.scss';
import { MatchConfig } from '@ligr/api-v2';

interface InProgressTimeProps {
  match: {
    date: Date;
    config: MatchConfig;
    sportName: string;
  };
  matchSummary: SummaryFragment;
  className?: string;
}

export const InProgressTime: React.FunctionComponent<InProgressTimeProps> = ({ match, matchSummary, className }) => {
  const liveClockState = useClock({ ...match, sport: { name: match.sportName } }, matchSummary) as LiveClockState;
  const { status } = getCurrentPeriod(matchSummary);
  const periodName = matchSummary.periods.find(p => p.code === matchSummary.currentPeriod)!.name;

  return (
    <div className={classnames('inProgressTime', className)}>
      <Title is5 hasTextGrey600 className="inProgressTime__period">
        {periodName}
      </Title>
      <div className="inProgressTime__liveClock">
        {status === 'break' ? (
          <Title className="inProgressTime__live" is3 hasTextRed>
            BREAK
          </Title>
        ) : (
          <Title className="inProgressTime__live" is3 hasTextRed>
            LIVE
          </Title>
        )}

        {liveClockState?.clock && (
          <>
            <div className="inProgressTime__separator">•</div>
            <Title is3 className="inProgressTime__clock">
              {liveClockState ? liveClockState.clock : ''}
            </Title>
          </>
        )}
      </div>
      {!liveClockState?.running && status !== 'break' && (
        <Title is4 hasTextRed className="inProgressTime__status">
          Paused
        </Title>
      )}
    </div>
  );
};
