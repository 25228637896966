import { Player } from '../api-types';
import { useSearchParams } from '@ligr/shared';
import gql from 'graphql-tag';
import { useErrorHandledMutation } from './apolloHooks';
import { playersQuery } from './gql/queries/players';
import { deletePlayerMutation } from '../lib/gql/mutations/deletePlayer';

export const useArchivePlayer = () => {
  const searchParams = useSearchParams(['archive']);

  const restorePlayerMutation = gql`
    mutation restorePlayer($id: Float!) {
      restorePlayer(id: $id)
    }
  `;

  const [archivePlayerMutation] = useErrorHandledMutation<boolean>(deletePlayerMutation);
  const [unarchivePlayerMutation] = useErrorHandledMutation<boolean>(restorePlayerMutation);

  const archivePlayer = async (playerId: number) => {
    await archivePlayerMutation({
      variables: { id: playerId },
      update: (cache, { data }) => {
        const playerCache = cache.readQuery<{ players: Player[] }>({
          query: playersQuery,
          variables: { options: { archive: !!searchParams.archive } }
        });
        if (playerCache && data) {
          let players;

          if (!!searchParams.archive) {
            players = playerCache.players.map((p: Player) => {
              if (p.id === playerId) {
                return { ...p, archived: true };
              }
              return p;
            });
          } else {
            players = playerCache.players.filter(({ id }: Player) => id !== playerId);
          }
          cache.writeQuery({
            query: playersQuery,
            variables: { options: { archive: !!searchParams.archive } },
            data: {
              players
            }
          });
        }
      }
    });
  };

  const restorePlayer = async (playerId: number) => {
    await unarchivePlayerMutation({
      variables: { id: playerId },
      update: (cache, { data }) => {
        const playerCache = cache.readQuery<{ players: Player[] }>({
          query: playersQuery,
          variables: { options: { archive: !!searchParams.archive } }
        });
        if (playerCache && data) {
          const players = playerCache.players.map((p: Player) => {
            return {
              ...p,
              archived: p.id === playerId ? false : p.archived
            };
          });
          cache.writeQuery({
            query: playersQuery,
            variables: { options: { archive: !!searchParams.archive } },
            data: {
              players
            }
          });
        }
      }
    });
  };

  return {
    archivePlayer,
    restorePlayer
  };
};
