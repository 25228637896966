import { updateQueryUrl } from '@ligr/shared';
import queryString from 'query-string';
import React from 'react';
import { useHistory } from 'react-router';
import { FilterOptions } from '.';
import { Checkbox } from '../Checkbox/Checkbox';

interface ArchiveFilterProps {
  setActiveFilter: (name: FilterOptions, value: string) => void;
}

export const ArchiveFilter: React.FunctionComponent<ArchiveFilterProps> = ({ setActiveFilter }) => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const queryStrObject = queryString.parse(history.location.search);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActiveFilter('archive', e.target.checked ? 'Includes archived' : '');
    updateQueryUrl(history, { archive: e.target.checked ? 'true' : '' });
  };

  return (
    <div className="filters__checkbox__wrapper filters__checkbox__wrapper--archive">
      <Checkbox name="archive" id="archive" checked={queryStrObject.archive === 'true'} onChange={onChange} />
      <label className="filters__checkbox__wrapper__label" htmlFor="archive">
        Includes archived
      </label>
    </div>
  );
};
