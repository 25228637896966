import { Image } from '@ligr/shared';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { getTeamPlayer } from '../../../lib/util/getTeamPlayer';
import './style.scss';

type Team = {
  id: number;
};

export type Player = {
  id: number;
  firstName: string;
  lastName: string;
  playsFor?:
    | {
        defaultStarter: boolean;
        team: Team;
        photo?: {
          id: number;
          url: string;
        } | null;
        position?: string | null;
        number?: string | null;
      }[]
    | null;
};

interface StartingLineupItemProps {
  player: Player;
  onClick?: () => void;
  showModal?: any;
  index?: any;
  draggable?: boolean;
  team: Team;
}

export const StartingLineupItem: React.FunctionComponent<StartingLineupItemProps> = ({
  player,
  showModal,
  draggable,
  team,
  ...props
}) => {
  const { firstName, lastName } = player;
  const playerTeam = player ? getTeamPlayer(player, team) : undefined;

  const Content: React.FunctionComponent<{ style?: object; onClick?: () => void }> = props => (
    <div className="lineupItem" onClick={() => props} {...props}>
      <Image className="lineupItem__image" url={playerTeam?.photo?.url} placeholder="player" />
      <div className="lineupItem__name">{`${firstName} ${lastName}`}</div>
      <div className="lineupItem__position">{playerTeam!.position || 'No position set'}</div>
      {!draggable && playerTeam?.number && <div className="lineupItem__number">{playerTeam.number}</div>}
    </div>
  );

  if (draggable) {
    return (
      <Draggable draggableId={player.id.toString()} index={props.index}>
        {(provided, snapshot) => {
          const style = {
            borderColor: snapshot.isDragging ? '#5589F1' : '#E6E6E6'
          };
          return (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <Content style={style} {...props} />
            </div>
          );
        }}
      </Draggable>
    );
  }
  return <Content {...props} />;
};
