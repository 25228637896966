import capitalize from 'capitalize';
import { useFormikContext } from 'formik';
import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  AddTeamsToClubMutation,
  Age,
  ClubQuery,
  ClubTeamsInput,
  CompetitionsQuery,
  Gender,
  GradesQuery,
  SportsQuery,
  TeamswithSportQuery
} from '../../../../api-types';
import { Button } from '../../../../components/Button';
import { Form } from '../../../../components/Form/Form';
import { FormField } from '../../../../components/FormField';
import { Loader } from '../../../../components/loader/Loader';
import { PageHeader } from '../../../../components/PageHeader';
import { Title } from '../../../../components/Title/Title';
import { getGQLError } from '../../../../lib/apolloClient';
import { useErrorHandledMutation, useErrorHandledQuery } from '../../../../lib/apolloHooks';
import { addTeamsToClubMutation } from '../../../../lib/gql/mutations/addTeamsToClub';
import { clubQuery } from '../../../../lib/gql/queries/clubs';
import { competitionsQuery } from '../../../../lib/gql/queries/competitions';
import { gradesQuery } from '../../../../lib/gql/queries/grades';
import { sportsQuery } from '../../../../lib/gql/queries/sports';
import { teamsQuery } from '../../../../lib/gql/queries/teams';
import { Notification } from '../../../../lib/notificationContainer';
import { routes } from '../../../../router/Routes';
import './style.scss';
import { object, string, array } from 'yup';

const validationSchema = object().shape({
  clubTeams: array().of(
    object().shape({
      sportId: string().required().label('Sport')
    })
  )
});

type BasicEntity = {
  name: string;
  id: number;
};

type BasicEntityWithSport = {
  name: string;
  id: number;
  sportId: number;
};

type ClubTeam = {
  id: string;
  sportId: string;
  competitions: { label: string; value: string }[];
  age: string;
  gender: string;
  gradeId: string;
};

interface EditClubTeamsPageProps {
  teams: BasicEntityWithSport[];
  competitions: BasicEntityWithSport[];
  sports: BasicEntity[];
  grades: BasicEntity[];
  loading: boolean;
}

const EditClubTeamsFormContent: React.FunctionComponent<EditClubTeamsPageProps> = ({
  teams,
  competitions,
  sports,
  grades,
  loading
}) => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const form = useFormikContext<{ clubTeams: ClubTeam[] }>();

  const addAnotherTeam = () => {
    form.setValues({
      clubTeams: [
        ...form.values.clubTeams,
        {
          id: '',
          sportId: '',
          competitions: [],
          age: Age.Adults,
          gender: Gender.Male,
          gradeId: `${grades[0].id}`
        }
      ]
    });
  };

  const changeTeam = (
    e: React.FormEvent<HTMLSelectElement>,
    team: { id: string; sportId: string; competitions: { label: string; value: string }[] },
    index: number
  ) => {
    const newTeam = { ...team };
    newTeam.id = (e.target as HTMLSelectElement).value;

    if (newTeam.id) {
      const selectedTeam = teams.find(t => t.id === parseInt(newTeam.id));
      newTeam.sportId = `${selectedTeam?.sportId || team.sportId}`;
      newTeam.competitions = [];
    } else {
      newTeam.sportId = '';
      newTeam.competitions = [];
    }

    // @ts-ignore
    form.setFieldValue(`clubTeams[${index}]`, newTeam);
  };

  const changeSport = (e: React.FormEvent<HTMLSelectElement>, index: number) => {
    // @ts-ignore
    form.setFieldValue(`clubTeams[${index}][sportId]`, (e.target as HTMLSelectElement).value);
  };

  return (
    <>
      <div className="edit-page-club__form">
        <section>
          <Title is3>Club teams</Title>
          <div className="edit-page-club__form__team-list">
            <label>New or Existing Team</label>
            <label>Sport</label>
            <label>Competitions</label>
            <label>Division</label>
            <label>Age Group</label>
            <label>Gender</label>
            {form.values.clubTeams.map((team, index) => {
              return (
                <div className="edit-page-club__form__team-list-row" key={`club-team-${index}`}>
                  <FormField
                    name={`clubTeams[${index}][id]`}
                    placeholder="New Team"
                    options={teams.map(({ id, name }) => ({
                      label: capitalize(name),
                      value: id
                    }))}
                    type="select"
                    value={team.id}
                    colSpan={1}
                    onChange={(e: React.FormEvent<HTMLSelectElement>) => changeTeam(e, team, index)}
                  />
                  <FormField
                    name={`clubTeams[${index}][sportId]`}
                    placeholder="Select sport"
                    disabled={!!(team.id && team.id.toString() !== '')}
                    options={sports.map(({ id, name }) => ({
                      label: capitalize(name),
                      value: id
                    }))}
                    type="select"
                    value={team.sportId}
                    colSpan={1}
                    onChange={(e: React.FormEvent<HTMLSelectElement>) => changeSport(e, index)}
                  />
                  <FormField
                    name={`clubTeams[${index}][competitions]`}
                    disabled={!(team.sportId && team.sportId.toString() !== '')}
                    placeholder="Select competitions"
                    options={competitions
                      .filter(({ sportId }) => sportId === parseInt(`${team.sportId}`))
                      .map(({ id, name }) => ({ label: name, value: id }))}
                    noOptionsMessage={() => 'No remaining competitions'}
                    type="multiselect"
                    colSpan={1}
                  />
                  <FormField
                    name={`clubTeams[${index}][gradeId]`}
                    type="select"
                    options={grades.map(grade => ({ label: grade.name, value: grade.id }))}
                    colSpan={1}
                  />
                  <FormField
                    name={`clubTeams[${index}][age]`}
                    type="select"
                    options={Object.values(Age).map(age => ({ label: age, value: age }))}
                    colSpan={1}
                  />
                  <FormField
                    name={`clubTeams[${index}][gender]`}
                    type="select"
                    options={Object.values(Gender).map(gender => ({ label: gender, value: gender }))}
                    colSpan={1}
                  />
                </div>
              );
            })}
          </div>
        </section>
        <section>
          <Button
            icon="plus"
            iconPosition="left"
            onClick={event => {
              event.preventDefault();
              addAnotherTeam();
            }}
          >
            Add another team
          </Button>
        </section>
        <section className="pageFooter">
          <Button
            onClick={event => {
              event.preventDefault();
              history.push(routes.assetTeams());
            }}
            isLoading={loading}
            type="plain"
          >
            skip for now
          </Button>
          <Button
            onClick={event => {
              event.preventDefault();
              form.submitForm();
            }}
            isLoading={loading}
            type="primary"
          >
            add teams to club
          </Button>
        </section>
      </div>
    </>
  );
};

export const EditClubTeamsPage: React.FunctionComponent = () => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const { clubId } = useParams<{ clubId: string }>();
  const [error, setError] = useState('');
  const { addNotification } = Notification.useContainer();
  const { data: teamsData, loading: teamsLoading } = useErrorHandledQuery<TeamswithSportQuery>(teamsQuery);
  const { data: competitionsData, loading: competitionsLoading } = useErrorHandledQuery<CompetitionsQuery>(
    competitionsQuery
  );
  const { data: sportsData, loading: sportsLoading } = useErrorHandledQuery<SportsQuery>(sportsQuery);
  const { data: gradesData, loading: gradesLoading } = useErrorHandledQuery<GradesQuery>(gradesQuery);
  const { data: clubData, loading: clubLoading } = useErrorHandledQuery<ClubQuery>(clubQuery, {
    variables: { id: parseInt(clubId) }
  });

  const globalLoading = useMemo(
    () => competitionsLoading || sportsLoading || gradesLoading || teamsLoading || clubLoading,
    [competitionsLoading, sportsLoading, teamsLoading, gradesLoading, clubLoading]
  );

  const [addTeamsToClub, { loading }] = useErrorHandledMutation<AddTeamsToClubMutation>(addTeamsToClubMutation);

  const addTeams = async (values: { clubTeams: ClubTeam[] }) => {
    try {
      const teamsInput: ClubTeamsInput[] = [];
      values.clubTeams.forEach(clubTeam => {
        const team = clubData?.club?.teams?.find(t => t.id.toString() === clubTeam.id);
        const clubTeamComps = clubTeam.competitions || [];
        let deletedComps: { competitionId: number; delete: boolean }[] = [];
        if (team) {
          const existingCompIds = team.competitions.map(c => c.id);
          const newCompIds = clubTeamComps.map(c => parseInt(c.value));

          deletedComps = existingCompIds
            .filter(id => !newCompIds.includes(id))
            .map(id => ({ competitionId: id, delete: true }));
        }
        teamsInput.push({
          id: parseInt(clubTeam.id),
          age: clubTeam.age as Age,
          gender: clubTeam.gender as Gender,
          gradeId: parseInt(clubTeam.gradeId),
          sportId: parseInt(clubTeam.sportId),
          competitions: clubTeamComps.map(({ value }) => ({ competitionId: parseInt(value) })).concat(deletedComps)
        });
      });

      await addTeamsToClub({
        variables: { id: parseInt(clubId), teams: teamsInput }
      });
      addNotification('Saved successfully', 'success');
      history.push(routes.assetTeams());
    } catch (e) {
      setError(getGQLError(e).message);
    }
  };

  const teams = teamsData?.teams || [];
  const competitions = competitionsData?.competitions || [];
  const sports = sportsData?.sports || [];
  const grades = gradesData?.grades || [];

  const initialClubTeams: { clubTeams: ClubTeam[] } = {
    clubTeams:
      (clubData?.club?.teams?.length || 0) > 0
        ? clubData?.club?.teams!.map(t => ({
            id: `${t.id}`,
            sportId: `${t.sport.id}`,
            competitions: t.competitions.map(c => ({ label: c.name, value: `${c.id}` })),
            age: t.age,
            gender: t.gender,
            gradeId: `${t.grade.id}`
          }))
        : [
            {
              id: '',
              sportId: '',
              competitions: [],
              age: Age.Adults,
              gender: Gender.Male,
              gradeId: `${grades[0]?.id}`
            }
          ]
  };

  return (
    <div className="edit-page edit-page-club">
      <PageHeader
        preTitle="manage"
        title="add teams to club"
        close={routes.assetClub({ clubId: parseInt(clubId || '') })}
      />
      {globalLoading ? (
        <Loader />
      ) : (
        <Form
          validationSchema={validationSchema}
          error={error}
          className="page-form"
          initialValues={initialClubTeams}
          onSubmit={addTeams}
          withSubmit
        >
          <EditClubTeamsFormContent
            teams={teams}
            competitions={competitions}
            grades={grades}
            sports={sports}
            loading={loading}
          />
        </Form>
      )}
    </div>
  );
};
