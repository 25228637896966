import React from 'react';

import { PageContentProps } from './PageContent.types';
import classnames from 'classnames';
import { VerticalScroll } from '../VerticalScroll/VerticalScroll';

const Component: React.FunctionComponent<{
  className: string;
  scrollRoot: (ref: HTMLElement | null) => void;
}> = ({ scrollRoot, className, children }) => {
  // TODO: ClassName is not getting here
  return (
    <div ref={scrollRoot} className={classnames(`page-content-content`, className)}>
      {children}
    </div>
  );
};

export const PageContentContent: React.FunctionComponent<PageContentProps> = ({ children, className }) => (
  <VerticalScroll className={className} throttle={200} component={Component}>
    {children}
  </VerticalScroll>
);
