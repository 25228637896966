import React from 'react';
import { PageMenuProps } from '../components/PageMenu';
import { SideNav } from '../components/SideTabs';
import { routes } from './Routes';

export const MenuAssets: PageMenuProps = {
  title: 'assets',
  component: () => (
    <SideNav
      tabs={{
        Competitions: routes.assetsCompetitions(),
        'Clubs & Teams': routes.assetTeams(),
        Players: routes.assetsPlayers(),
        Venues: routes.assetVenues()
      }}
    />
  )
};

export const MenuAdSets: PageMenuProps = {
  title: 'ad sets',
  component: () => (
    <SideNav
      tabs={{
        'Ad Sets': routes.adSetsAll(),
        Allocation: routes.adSetsAllocation()
      }}
    />
  )
};
