import { updateQueryUrl } from '@ligr/shared';
import queryString from 'query-string';
import React from 'react';
import { useHistory } from 'react-router';
import { FilterOptions } from '.';
import { Select } from '../Select';
import { SelectOption } from '../Select/types';

interface AllocationFilterProps {
  setActiveFilter: (name: FilterOptions, value: string) => void;
}

export const AllocationFilter: React.FunctionComponent<AllocationFilterProps> = ({ setActiveFilter }) => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const queryStrObject = queryString.parse(history.location.search);
  const options: SelectOption[] = [
    { label: 'All', value: '' },
    { label: 'By Competition', value: 'competition' },
    { label: 'By Team', value: 'team' }
  ];

  const handleChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const val = (e.target as HTMLSelectElement).value;
    setActiveFilter('allocation', val === '' ? '' : `By ${val}`);
    updateQueryUrl(history, { allocation: val });
  };

  return (
    <div className="filters__select__wrapper filters__select__wrapper--allocation">
      <label className="filters__select__wrapper__label" htmlFor="allocation">
        Allocation
      </label>
      <Select
        name="allocation"
        className="filters__select__wrapper__select"
        options={options}
        onChange={(e: React.FormEvent<HTMLSelectElement>) => handleChange(e)}
        value={queryStrObject?.allocation || ''}
      />
    </div>
  );
};
