const DEFAULT_TTL = 86_400_000;
const EXPIRY_KEY: string = 'local-storage-key-expiries';

const clearExpiredItems = () => {
  const found = localStorage.getItem(EXPIRY_KEY);
  if (!found) {
    return;
  }
  const obj = JSON.parse(found);
  if (!obj) {
    return;
  }
  const now = new Date();

  const expiryEntries = Object.entries(obj);

  const newExpiryObject: { [key: string]: any } = {};

  expiryEntries.forEach(([key, value]: [string, number]) => {
    if (now.getTime() > value) {
      return localStorage.removeItem(key);
    }
    newExpiryObject[key] = value;
  });

  localStorage.setItem(EXPIRY_KEY, JSON.stringify(newExpiryObject));
};

const get = (key: string) => {
  const jsonItem = localStorage.getItem(key);
  if (!jsonItem) return undefined;

  const item = JSON.parse(jsonItem);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return undefined;
  }

  return item.value;
};

const updateExpiry = (key: string, expiry: number) => {
  const found = localStorage.getItem(EXPIRY_KEY);
  if (!found) {
    return;
  }
  const obj = JSON.parse(found) || {};
  obj[key] = expiry;
  localStorage.setItem(EXPIRY_KEY, JSON.stringify(obj));
};

const set = (key: string, value: string, ttl: number = DEFAULT_TTL) => {
  clearExpiredItems();
  const now = new Date();
  if (value === '') {
    localStorage.removeItem(key);
    return;
  }
  const expiry = now.getTime() + ttl;
  const item = {
    value,
    expiry
  };
  updateExpiry(key, expiry);
  localStorage.setItem(key, JSON.stringify(item));
};

export const ExpiringLocalStorage = {
  clearExpiredItems,
  updateExpiry,
  get,
  set
};
