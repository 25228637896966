import React from 'react';
import { ProfilePageWrapper } from '../ProfilePageWrapper';
import { ProfileRouterChildProps } from '../Profile.router';

export const ManualPayment: React.FunctionComponent<ProfileRouterChildProps> = ({ me }) => {
  return (
    <ProfilePageWrapper me={me}>
      <div className="manual-payment">
        You are currently on our Premium subscription. Invoices are emailed to your directly. If you have any questions,
        please contact your{' '}
        <a href="//www.ligrsystems.com/contact-us" target="_blank">
          dedicated account manager
        </a>
        .
      </div>
    </ProfilePageWrapper>
  );
};
