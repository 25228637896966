import gql from 'graphql-tag';

export const addTeamsToClubMutation = gql`
  mutation addTeamsToClub($id: Float!, $teams: [ClubTeamsInput!]!) {
    addTeamsToClub(id: $id, teams: $teams) {
      id
      name
      gfxName
      gfxFullName
      abbreviation
      primaryBackgroundColor
      primaryTextColor
      secondaryBackgroundColor
      secondaryTextColor
      organization {
        id
      }
      defaultVenue {
        id
      }
      teams {
        id
        name
        sport {
          id
        }
        competitions {
          id
          name
        }
        grade {
          id
          name
        }
        age
        gender
      }
      logo {
        id
        url
        entityId
        entityType
      }
    }
  }
`;
