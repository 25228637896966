import React, { useState } from 'react';
import { FormikContextType } from 'formik';
import { object, string, mixed } from 'yup';
import { Form } from '../../../../components/Form/Form';
import { PageContent } from '../../../../components/page/PageContent';
import { PageHeader } from '../../../../components/PageHeader';
import { PageMenu } from '../../../../components/PageMenu';
import { getGQLError } from '../../../../lib/apolloClient';
import { useErrorHandledMutation } from '../../../../lib/apolloHooks';
import { createCompQuery } from '../../../../lib/gql/mutations/createCompetition';
import { competitionsQuery } from '../../../../lib/gql/queries/competitions';
import { Notification } from '../../../../lib/notificationContainer';
import { logoValidation } from '../../../../lib/util/imageValidation';
import { CompetitionSideMenu } from '../CompetitionSideMenu/CompetitionSideMenu';
import { NewCompetitionPageFormContent } from './form';
import { useHistory } from 'react-router';
import { routes } from '../../../../router/Routes';
import './style.scss';
import { Competition, CreateCompetitionInput, CreateCompetitionMutation } from '../../../../api-types';

export interface CreateCompetitionValues {
  sportId: string;
  name: string;
  gfxName: string;
  logo?: File;
  watermark?: File;
  secondaryWatermark?: File;
}

const validationSchema = object().shape({
  name: string().required().label('Name'),
  gfxName: string().max(40).nullable().label('Gfx name'),
  sportId: string().required().label('Sport').nullable(),
  logo: mixed().test('logo', 'Invalid logo', async function (value) {
    if (value) {
      const { logo } = await logoValidation([{ logo: value }]);
      if (logo) {
        return this.createError({
          path: this.path,
          message: `Logo: ${logo}`
        });
      }
    }
    return true;
  }),
  watermark: mixed().test('watermark', 'Invalid watermark', async function (value) {
    if (value) {
      const { watermark } = await logoValidation([{ watermark: value }]);
      if (watermark) {
        return this.createError({
          path: this.path,
          message: `Watermark: ${watermark}`
        });
      }
    }
    return true;
  }),
  secondaryWatermark: mixed().test('secondaryWatermark', 'Invalid secondaryWatermark', async function (value) {
    if (value) {
      const { secondaryWatermark } = await logoValidation([{ secondaryWatermark: value }]);
      if (secondaryWatermark) {
        return this.createError({
          path: this.path,
          message: `Secondary watermark: ${secondaryWatermark}`
        });
      }
    }
    return true;
  })
});

export const NewCompetitionPage: React.FunctionComponent = () => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const { addNotification } = Notification.useContainer();
  const [form, setForm] = useState<FormikContextType<any>>();

  const [createCompetition, { loading }] = useErrorHandledMutation<{
    createCompetition: Competition;
    createCompetitionInput: CreateCompetitionInput;
  }>(createCompQuery);

  const newCompetition: CreateCompetitionValues = {
    name: '',
    gfxName: '',
    sportId: '',
    logo: undefined,
    watermark: undefined,
    secondaryWatermark: undefined
  };

  const create = async (createCompetitionInput: CreateCompetitionValues) => {
    try {
      const res = await createCompetition({ variables: { createCompetitionInput } });

      history.push(routes.assetsCompetition({ competitionId: res.data!.createCompetition.id }));
      addNotification('Competition successfully created', 'success');
    } catch (e) {
      // @ts-ignore
      form.setFieldError(getGQLError(e).details.field, getGQLError(e).message);
    }
  };

  return (
    <>
      <PageMenu title="Competitions" component={CompetitionSideMenu} />

      <PageContent className="newCompetitionPage">
        <PageHeader preTitle="create" title="new competition" />
        <Form
          initialValues={newCompetition}
          validationSchema={validationSchema}
          className="page-form createCompetition"
          onSubmit={create}
          withSubmit
          setForm={setForm}
        >
          <NewCompetitionPageFormContent loading={loading} />
        </Form>
      </PageContent>
    </>
  );
};
