type Player<T> = {
  id: number;
  playsFor?: T[] | null;
};

type Team = {
  id: number;
};

type PlaysFor = {
  team: Team;
};

export function getTeamPlayer<T extends PlaysFor>(player: Player<T>, team: Team): T | undefined {
  return player.playsFor ? player.playsFor.find(({ team: { id } }) => id === team.id) : undefined;
}
