import { Icon } from '@ligr/shared';
import classnames from 'classnames';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Title } from '../Title/Title';
import './style.scss';

export interface ConfirmWindowProps {
  action: () => void;
  title: string;
  content: JSX.Element | string;
  className?: string;
}

export const ConfirmWindow: React.FunctionComponent<ConfirmWindowProps> = ({
  action,
  title,
  content,
  className,
  children
}) => {
  const submit = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-window">
            <div className="confirm-window__header">
              <Title is2>{title}</Title>
              <div className="close" onClick={onClose}>
                <Icon icon="close" color="grey-600" />
              </div>
            </div>
            <div className="confirm-window__content">{content}</div>
            <div className="confirm-window__footer">
              <button className="button is-secondary" onClick={onClose}>
                Cancel
              </button>
              <button
                className="button"
                onClick={() => {
                  action();
                  onClose();
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        );
      }
    });
  };

  return (
    <div className={classnames('confirm-window__wrapper', className)} onClick={submit}>
      {children}
    </div>
  );
};
