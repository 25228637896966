import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { mixed, object, string } from 'yup';
import { CreateClubInput, CreateClubMutation, VenuesQuery } from '../../../../api-types';
import { Button } from '../../../../components/Button';
import { Form } from '../../../../components/Form/Form';
import { FormField } from '../../../../components/FormField';
import { GroupErrorRow } from '../../../../components/GroupErrorRow';
import { Loader } from '../../../../components/loader/Loader';
import { PageHeader } from '../../../../components/PageHeader';
import { Title } from '../../../../components/Title/Title';
import { getGQLError } from '../../../../lib/apolloClient';
import { useErrorHandledMutation, useErrorHandledQuery } from '../../../../lib/apolloHooks';
import { createClubMutation } from '../../../../lib/gql/mutations/createClub';
import { venuesQuery } from '../../../../lib/gql/queries/venues';
import { Notification } from '../../../../lib/notificationContainer';
import { logoValidation } from '../../../../lib/util/imageValidation';
import { routes } from '../../../../router/Routes';
import './style.scss';

const validationSchema = object().shape({
  name: string().required().min(4).max(60).label('Name'),
  gfxFullName: string().required().min(4).max(19).label('Full name'),
  gfxName: string().required().min(4).max(19).label('Medium name'),
  abbreviation: string().required().min(2).max(4).label('Short name'),
  primaryBackgroundColor: string().required().label('Primary color'),
  primaryTextColor: string().required().label('Primary text color'),
  logo: mixed().test('logo', 'Invalid logo', async function (value) {
    if (value) {
      const { logo } = await logoValidation([{ logo: value }]);
      if (logo) {
        return this.createError({
          path: this.path,
          message: logo
        });
      }
    }
    return true;
  })
});

export interface CreateClubValues {
  name: string;
  gfxName: string;
  gfxFullName?: string;
  abbreviation: string;
  primaryBackgroundColor: string;
  primaryTextColor: string;
  secondaryBackgroundColor?: string | null;
  secondaryTextColor?: string | null;
  logo?: File;
  defaultVenueId: string;
}

type BasicEntity = {
  name: string;
  id: number;
};

interface NewClubPageFormProps {
  venues?: BasicEntity[];
  loading: boolean;
}

const NewClubPageFormContent: React.FunctionComponent<NewClubPageFormProps> = ({ venues = [], loading }) => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const form = useFormikContext<CreateClubValues>();
  return (
    <>
      <div className="create-page-club__form">
        <section>
          <Title is3>Club name & venue</Title>
          <FormField name="name" type="text" label="Club name" />
          <FormField
            name="defaultVenueId"
            type="select"
            placeholder={'Select Venue'}
            options={venues.map(v => ({ label: v.name, value: v.id }))}
            label="Default venue"
          />
          <Title is3>GFX Names</Title>
          <FormField name="gfxFullName" type="text" label="Full name" />
          <FormField name="gfxName" type="text" label="Medium name" />
          <FormField name="abbreviation" type="text" label="Short name" />
        </section>
        <section>
          <Title is3>Club colors</Title>
          <GroupErrorRow form={form} errorsName={['primaryBackgroundColor', 'primaryTextColor']}>
            <FormField name="primaryBackgroundColor" type="color" label="Primary color" colSpan={1} groupError />
            <FormField name="primaryTextColor" type="color" label="Primary text color" colSpan={1} groupError />
          </GroupErrorRow>

          <GroupErrorRow form={form} errorsName={['secondaryBackgroundColor', 'secondaryTextColor']}>
            <FormField name="secondaryBackgroundColor" type="color" label="Secondary color" colSpan={1} groupError />
            <FormField name="secondaryTextColor" type="color" label="Secondary text color" colSpan={1} groupError />
          </GroupErrorRow>
        </section>
        <section>
          <Title is3>Club Logo</Title>
          <FormField name="logo" type="image" editable />
          <p className="pro-tip">
            Pro Tip: Upload a logo with a transparent background, and no padding around the logo.
          </p>
        </section>
      </div>
      <section className="pageFooter">
        <Button
          onClick={event => {
            event.preventDefault();
            history.push(routes.assetTeams());
          }}
          isLoading={loading}
          type="plain"
        >
          cancel
        </Button>
        <Button
          onClick={event => {
            event.preventDefault();
            form.submitForm();
          }}
          isLoading={loading}
          type="primary"
          icon="arrowRight"
          iconPosition="right"
        >
          create club
        </Button>
      </section>
    </>
  );
};

export const NewClubPage: React.FunctionComponent = () => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const [error, setError] = useState('');
  const { addNotification } = Notification.useContainer();

  const { data: venuesData, loading: venueLoading } = useErrorHandledQuery<VenuesQuery>(venuesQuery);
  const venues = venuesData?.venues;

  const [createClub, { loading }] = useErrorHandledMutation<CreateClubMutation>(createClubMutation);

  const newClub: CreateClubValues = {
    name: '',
    gfxName: '',
    gfxFullName: '',
    abbreviation: '',
    logo: undefined,
    primaryBackgroundColor: '',
    primaryTextColor: '',
    secondaryBackgroundColor: null,
    secondaryTextColor: null,
    defaultVenueId: ''
  };

  const create = async (createClubInput: CreateClubValues) => {
    try {
      const input = {
        ...createClubInput,
        defaultVenueId: createClubInput.defaultVenueId ? parseInt(createClubInput.defaultVenueId) : null,
        primaryBackgroundColor:
          createClubInput.primaryBackgroundColor === '' ? null : createClubInput.primaryBackgroundColor,
        primaryTextColor: createClubInput.primaryTextColor === '' ? null : createClubInput.primaryTextColor,
        secondaryBackgroundColor:
          createClubInput.secondaryBackgroundColor === '' ? null : createClubInput.secondaryBackgroundColor,
        secondaryTextColor: createClubInput.secondaryTextColor === '' ? null : createClubInput.secondaryTextColor
      } as CreateClubInput;

      const res = await createClub({
        variables: { createClubInput: input },
        update: (cache, { data }) => {
          // TODO - update with the club/team query
          // const teamsCache = cache.readQuery<TeamsWithCompQuery>({ query: teamsWithCompQuery });
          // if (teamsCache && data) {
          //   cache.writeQuery({
          //     query: teamsWithCompQuery,
          //     data: { teams: [...teamsCache.teams, data.createTeam] }
          //   });
          // }
        }
      });

      history.push(routes.assetClubEditTeams({ clubId: res.data!.createClub.id }));
      addNotification('Club successfully created', 'success');
    } catch (e) {
      setError(getGQLError(e).message);
    }
  };

  return (
    <div className="create-page create-page-club">
      <PageHeader preTitle="create" title="new club" close={routes.assetTeams()} />
      {venueLoading ? (
        <Loader />
      ) : (
        <Form
          initialValues={newClub}
          validationSchema={validationSchema}
          className="page-form"
          onSubmit={create}
          withSubmit
        >
          <NewClubPageFormContent venues={venues} loading={loading} />
        </Form>
      )}
    </div>
  );
};
