import React from 'react';
import { CardElement } from 'react-stripe-elements';
import { Selection } from '../Subscription.type';
import './CardForm.scss';

interface CardFormProps {
  selection: Selection;
  updateSelection: (selection: Selection) => void;
}

export const CardForm: React.FunctionComponent<CardFormProps> = ({ selection, updateSelection }) => {
  const change = (event: stripe.elements.ElementChangeResponse) => {
    const sel = { ...selection, cardChanged: true };
    if (event.error) {
      sel.validCard = false;
    } else if (event.complete) {
      sel.validCard = true;
    }
    updateSelection(sel);
  };

  return (
    <div className="card-form">
      <label className="label">Enter your card</label>
      <CardElement hidePostalCode onChange={change} />
    </div>
  );
};
