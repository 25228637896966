import { sportAdEvents, EventAllocationType, AdAllocationEvent, AdLoopType } from '@ligr/api-v2';
import { AdAllocationEntityType, AdSet, AdSetFragment } from '../../../../../api-types';
import decamelize from 'decamelize';
import { getIn, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { Button } from '../../../../../components/Button';
import { ButtonBottom as BottomButtonRow } from '../../../../../components/ButtonBottom/ButtonBottom';
import { FormField } from '../../../../../components/FormField';
import { FormStatus } from '../../../../../components/FormStatus/FormStatus';
import { SelectOption } from '../../../../../components/Select/types';
import { Title } from '../../../../../components/Title/Title';
import { ToolTipIcon } from '../../../../../components/ToolTipIcon';
import { humanize } from '../../../../../lib/util/humanize';
import { AdAllocationForm, AdAllocationFormProps, PercentageAllocation } from './AdSetsAllocationPageForms.types';
import { GroupErrorRow } from '../../../../../components/GroupErrorRow';
import camelCase from 'camelcase';
import './style.scss';

type ASFragment = Omit<AdSetFragment, 'adFiles' | 'archived'>;

const getAdsetName = (adSetsAll: ASFragment[], adSetId: number) => {
  const found = adSetsAll.find(({ id }: ASFragment) => `${id}` === `${adSetId}`);
  return found ? found.name : '';
};

const EventsConfigTooltipContent = () => {
  return (
    <div className="events-tooltip">
      <h3>
        Configure how the event triggered Ad space is allocated between the teams and competition for a give match
      </h3>
      <ul>
        <li>
          <b>Competition - </b> competition selected event adsets will be used, falling back to percentage based if
          unassigned.
        </li>
        <li>
          <b>Team - </b> team selected event adsets will be used for based on the team involved in the trigger e.g. the
          team which has scored.
        </li>
        <li>
          <b>Home Only - </b> Similar to team but only triggers involving the home team and only home team adsets will
          be used, falling back to percentage based for away team events.
        </li>
        <li>
          <b>Use Percentage - </b> Will use percentage allocation for this event.
        </li>
      </ul>
    </div>
  );
};

export const AdAllocationFormContent: React.FunctionComponent<AdAllocationFormProps> = ({
  adSetsAll,
  sport,
  entityType,
  loading
}) => {
  const form = useFormikContext<AdAllocationForm>();
  const values = form.values;

  const allOptions: SelectOption[] = useMemo(() => {
    return adSetsAll.map((a: AdSet) => ({ label: a.name, value: a.id }));
  }, [adSetsAll]);

  const unselectedOptions: SelectOption[] = useMemo(() => {
    return adSetsAll
      .filter((a: AdSet) => {
        return !values.percentages!.find(({ adSetId }) => `${adSetId}` === `${a.id}`);
      })
      .map((a: AdSet) => ({ label: a.name, value: a.id }));
  }, [values.percentages, adSetsAll]);

  return (
    <>
      {form.errors.form && <FormStatus error={true}>{form.errors.form}</FormStatus>}
      <div className={`asAllocationContainer`}>
        {adSetsAll.length > 0 && (
          <div className="adSetsAllocation">
            <div className="adSetsAllocationTitle">
              <Title className="adSetsAllocationTitle__title" is3>
                Ad Sets Allocation
              </Title>
              <ToolTipIcon className="adSetsAllocationTitle__icon">
                Assign the percentage that each adset should show during the stream
              </ToolTipIcon>
            </div>
            <div className="adSetsAllocation__subtitle--adset">Ad Set</div>
            <div className="adSetsAllocation__subtitle--percentage">
              {values.percentages.length ? 'Percentage' : ''}
            </div>

            {values.percentages.map(({ adSetId }: PercentageAllocation, idx: number) => (
              <GroupErrorRow
                key={idx}
                className="adSetsAllocationRow"
                errorsName={[`percentages.${idx}.adSetId`, `percentages.${idx}.percentage`]}
                form={form}
                gridTemplateColumns="auto 8rem 4rem"
                errorGridColumn="span 3"
                gridColumn="span 3"
              >
                <FormField
                  className="adSetsAllocationRow__adSetField"
                  name={`percentages.${idx}.adSetId`}
                  type="select"
                  placeholder={'Select Adset'}
                  options={[
                    ...unselectedOptions,
                    {
                      label: getAdsetName(adSetsAll, adSetId),
                      value: adSetId,
                      disabled: true
                    }
                  ]}
                  colSpan={1}
                  groupError
                />
                <FormField
                  className={'percentage-field adSetsAllocationRow__percentageField'}
                  name={`percentages.${idx}.percentage`}
                  type="number"
                  suffix="%"
                  colSpan={1}
                  groupError
                />
                <Button
                  className="adSetsAllocation__removeBtn"
                  icon={'close'}
                  isSquare
                  onClick={() => {
                    form.setFieldValue('percentages', [
                      ...values.percentages.slice(0, idx),
                      ...values.percentages.slice(idx + 1, values.percentages.length)
                    ]);
                  }}
                />
              </GroupErrorRow>
            ))}

            {values.percentages.length < 10 && (
              <FormField
                className="adSetsAllocation__addAdSet"
                name="new"
                type="select"
                options={
                  unselectedOptions.length
                    ? [
                        {
                          label: 'Add An Adset',
                          value: undefined,
                          disabled: true
                        },
                        ...unselectedOptions
                      ]
                    : [
                        {
                          label: 'All Adsets Allocated',
                          value: undefined,
                          disabled: true
                        }
                      ]
                }
                colSpan={1}
                onChange={(event: any) => {
                  form.setFieldValue('percentages', [
                    ...values.percentages,
                    {
                      adSetId: event.target.value,
                      percentage: 0
                    }
                  ]);
                  form.setFieldValue('new', '');
                }}
              />
            )}
          </div>
        )}

        {entityType === AdAllocationEntityType.competition ? (
          <div className="eventsAllocation">
            <div className="eventsAllocationTitle">
              <Title is3 className="eventsAllocationTitle__title">
                Events Allocation Config
              </Title>
              <ToolTipIcon className="eventsAllocationTitle__icon">
                <EventsConfigTooltipContent />
              </ToolTipIcon>
            </div>

            <div className="eventsAllocation__subtitle--adset">Ad Set</div>
            <div className="eventsAllocation__subtitle--type">Type</div>

            {sportAdEvents[camelCase(sport.name as 'cricket' | 'football')].map((event: AdAllocationEvent) => {
              return (
                <div key={event} className="eventsAllocationRow">
                  <div className="eventsAllocationRow__event">{humanize(decamelize(event as string, ' '))}</div>
                  <FormField
                    className="eventsAllocationRow__typeField"
                    name={`config.events.${event}`}
                    type="select"
                    options={Object.keys(EventAllocationType).map((eventCode: string) => ({
                      value: eventCode,
                      label: humanize(decamelize(eventCode))
                    }))}
                    colSpan={1}
                  />
                </div>
              );
            })}
          </div>
        ) : null}

        {entityType === AdAllocationEntityType.competition ? (
          <div className="teamAllocation">
            <div className="teamAllocationTitle">
              <Title is3 className="teamAllocationTitle__title">
                Team Allocation
              </Title>
              <ToolTipIcon className="teamAllocationTitle__icon">
                Assign the percentage of the overall adspace to the home and away teams
              </ToolTipIcon>
            </div>

            <div className="teamAllocation__subtitle--adset">Ad Set</div>
            <div className="teamAllocation__subtitle--percentage">Percentage</div>

            {['home', 'away'].map(teamType => {
              const disabled = !getIn(form.values, `config.teams.${teamType}.enabled`);
              return (
                <div
                  key={teamType}
                  className={`teamAllocationRow ${
                    getIn(form.errors, `config.teams.${teamType}.percentage`) &&
                    getIn(form.touched, `config.teams.${teamType}.percentage`)
                      ? 'with-error'
                      : ''
                  }`}
                >
                  <div className="toggleGroup">
                    <div className="toggleGroup__event">{humanize(decamelize(teamType as string, ' '))}</div>
                    <FormField
                      type="switch"
                      className="toggleGroup__switch"
                      name={`config.teams.${teamType}.enabled`}
                    />
                  </div>
                  {
                    <FormField
                      className="teamAllocationRow__percentage"
                      disabled={disabled}
                      name={`config.teams.${teamType}.percentage`}
                      type="number"
                      suffix="%"
                      colSpan={1}
                    />
                  }
                </div>
              );
            })}
          </div>
        ) : null}

        <div className="evASAllocation">
          <div className="evASAllocationTitle">
            <Title is3 className="evASAllocationTitle__title">
              Events Ad Sets Allocation
            </Title>
            <ToolTipIcon className="evASAllocationTitle__icon">
              Assign adsets to specific events or triggers during the broadcast
            </ToolTipIcon>
          </div>

          <div className="evASAllocation__subtitle--event">Event</div>
          <div className="evASAllocation__subtitle--adset">Ad Set</div>

          {sportAdEvents[camelCase(sport.name as 'cricket' | 'football')].map((event: AdAllocationEvent) => {
            const disabled =
              entityType === AdAllocationEntityType.competition &&
              getIn(form.values, `config.events.${event}`) !== EventAllocationType.competition;
            return (
              <div key={event} className="evASAllocationRow">
                <div className="evASAllocationRow__event">{humanize(decamelize(event as string, ' '))}</div>

                <FormField
                  className="evASAllocationRow__adset"
                  disabled={disabled}
                  name={`events.${event}`}
                  type="select"
                  options={!disabled ? [{ value: '', label: 'None' }, ...allOptions] : []}
                  colSpan={1}
                />
              </div>
            );
          })}
        </div>

        {entityType === AdAllocationEntityType.competition ? (
          <div className="liveSpLoop">
            <div className="liveSpLoopTitle">
              <Title is3 className="liveSpLoopTitle__title">
                Live Sponsor loop
              </Title>
              <ToolTipIcon className="liveSpLoopTitle__icon">Configure live sponsor loop</ToolTipIcon>
            </div>

            <div className="activateLoop">
              <div className="activateLoop__text">Activate loop</div>
              <FormField className="activateLoop__switch" type="switch" name={`config.loop.enabled`} colSpan={1} />
            </div>

            <div className="liveSpLoop__loop">
              <div className="xPerPeriod">
                <FormField
                  className="xPerPeriod__radio"
                  disabled={!getIn(values, `config.loop.enabled`)}
                  options={[{ value: AdLoopType.xPerPeriod, label: '' }]}
                  type="radio"
                  name={`config.loop.type`}
                  colSpan={1}
                />
                <FormField
                  disabled={
                    !getIn(values, `config.loop.enabled`) || getIn(values, `config.loop.type`) !== AdLoopType.xPerPeriod
                  }
                  suffix={'times per period'}
                  className="xPerPeriod__input"
                  type="number"
                  name={`config.loop.perPeriod`}
                  colSpan={1}
                />
              </div>
              <div className="everyXSecs">
                <FormField
                  className="everyXSecs__radio"
                  disabled={!getIn(values, `config.loop.enabled`)}
                  options={[{ value: AdLoopType.everyXSecs, label: '' }]}
                  type="radio"
                  name={`config.loop.type`}
                  colSpan={1}
                />
                <div className="everyXSecs__name">Every</div>
                <FormField
                  disabled={
                    !getIn(values, `config.loop.enabled`) || getIn(values, `config.loop.type`) !== AdLoopType.everyXSecs
                  }
                  suffix={'secs'}
                  type="number"
                  className="everyXSecs__input"
                  name={`config.loop.secsBetweenShows`}
                  colSpan={1}
                />
              </div>
              <div className="secsPerShow">
                <div className="secsPerShow__name">Show for</div>
                <FormField
                  disabled={!getIn(values, `config.loop.enabled`)}
                  suffix={'secs'}
                  type="number"
                  className="secsPerShow__input"
                  name={`config.loop.secsPerShow`}
                  colSpan={1}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {form?.dirty && (
        <BottomButtonRow withResetFormButton resetOnClick={() => form.resetForm()}>
          <Button isLoading={loading} type="primary">
            {' '}
            Save{' '}
          </Button>
        </BottomButtonRow>
      )}
    </>
  );
};
