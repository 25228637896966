import classnames from 'classnames';
import { getIn, useFormikContext } from 'formik';
import React, { useEffect, useState, useMemo } from 'react';
import { positionTypesBySport } from '@ligr/shared';
import { v4 } from 'uuid';
import { deleteFileMutation } from '../../../../lib/gql/mutations/deleteFile';
import { FormProps } from '../../../../components/Form/Form.types';
import { Notification } from '../../../../lib/notificationContainer';
import { useErrorHandledMutation } from '../../../../lib/apolloHooks';
import { playerQuery } from '../../../../lib/gql/queries/player';
import { FormField } from '../../../../components/FormField';
import { Tabs } from '../../../../components/Tabs/Tabs';
import { Button } from '../../../../components/Button';
import { Title } from '../../../../components/Title/Title';
import { ButtonBottom as BottomButtonRow } from '../../../../components/ButtonBottom/ButtonBottom';
import { TeamsWithCompQuery, PlayerQuery } from '../../../../api-types';
import { ApolloQueryResult } from 'apollo-client';
import './style.scss';

const photoText = (
  <div>
    <p>Upload a photo with a transparent background, and no padding around the photo.</p>
  </div>
);

export interface PlayerTeamValues {
  number: string;
  position?: string;
  photo?: File | string;
  photoId?: number;
  teamId: number;
  abbreviation?: string;
  defaultStarter?: boolean;
  delete?: boolean;
  name: string;
  preexisting?: boolean;
}
export interface PlayerFormValues {
  id?: number;
  firstName?: string;
  lastName?: string;
  teams?: PlayerTeamValues[];
  gfxFirstName?: string;
  gfxLastName?: string;
}

interface UpdatePlayerPageFormContentProps extends FormProps<PlayerFormValues> {
  teams: ApolloQueryResult<TeamsWithCompQuery>['data']['teams'];
}

export const UpdatePlayerPageFormContent: React.FunctionComponent<UpdatePlayerPageFormContentProps> = ({ teams }) => {
  const form = useFormikContext<PlayerFormValues>();
  const values = form.values;
  const [currentTab, setCurrentTab] = useState<number>(0);
  const { addNotification } = Notification.useContainer();

  const currentTeam = values.teams && values.teams[currentTab];
  const currentTeamDeleted = getIn(values, `teams.${currentTab}.delete`);
  const [deleteFile] = useErrorHandledMutation<boolean>(deleteFileMutation);

  const firstNameLength = form.values.firstName?.length || 0;
  const lastNameLength = form.values.lastName?.length || 0;
  const showGfxNameFields = firstNameLength > 26 || lastNameLength > 26 || firstNameLength + lastNameLength > 28;

  useEffect(() => {
    if (!showGfxNameFields) {
      form.setFieldValue('gfxFirstName', '');
      form.setFieldValue('gfxLastName', '');
    }
  }, [showGfxNameFields]);

  const teamOptions = useMemo(() => {
    if (teams) {
      return teams.map(({ name, id }) => ({
        label: name,
        value: id
      }));
    }
    return [];
  }, [teams]);

  const sport = useMemo(() => {
    if (!currentTeam || !teams?.length) {
      return undefined;
    }
    const team = teams.find(t => t.id === currentTeam?.teamId)!;
    return team.sport.name;
  }, [currentTeam, teams]);

  const positionOptions = useMemo(() => {
    if (!sport) {
      return [];
    }
    return positionTypesBySport[sport as keyof typeof positionTypesBySport].map(p => {
      return { value: p, label: p };
    });
  }, [sport]);

  const isdelete = (teamId: number) => {
    const team = values.teams!.find(({ teamId: curTeamId }) => teamId === curTeamId);
    return team?.delete;
  };

  const getRemoveMessage = () => {
    if (currentTeam && currentTeam.preexisting) {
      return `${!currentTeam.delete ? 'Remove Player From Team' : 'Undo Remove Team'}`;
    }

    return 'Remove Player From Team';
  };

  const onDelete = async (id: number) => {
    if (id) {
      await deleteFile({
        variables: { id },
        update: (cache: any, { data }: any) => {
          const playerCache = cache.readQuery({
            query: playerQuery,
            variables: { id: form.values.id }
          }) as ApolloQueryResult<PlayerQuery>['data'];
          if (playerCache && data.deleteFile) {
            const newCache = {
              ...playerCache.player,
              playsFor: (playerCache.player.playsFor || [])?.map(pf => {
                if (pf.team.id !== currentTeam?.teamId) return pf;
                return {
                  ...pf,
                  photo: null
                };
              })
            };
            cache.writeQuery({
              query: playerQuery,
              variables: { id: form.values.id },
              data: { player: newCache }
            });
            addNotification('Player photo removed successfully', 'success');
          }
        }
      });
      form.resetForm();
    }
  };

  const showWarningTooltipFirst =
    (firstNameLength > 26 || firstNameLength + lastNameLength > 28) && (form.values.firstName?.length || 0) > 0;
  const showWarningTooltipLast =
    (lastNameLength > 26 || firstNameLength + lastNameLength > 28) && (form.values.lastName?.length || 0) > 0;

  const exceedsCharactersMsg = (
    <>
      <div>
        This name exceeds the character limit. Form the live stream graphics, please edit the name below if you wish to
        change it
      </div>
      <div>
        <b>Limits:</b>
      </div>
      <div>
        <b>First name:</b> 26 characters
      </div>
      <div>
        <b>Last name:</b> 26 characters
      </div>
      <div>
        <b>Total:</b> 28 characters
      </div>
    </>
  );

  return (
    <div className="player-form-content">
      <div className="player">
        <div className="name-fields">
          <FormField
            className="firstNameField"
            name="firstName"
            type="text"
            label="First Name"
            colSpan={1}
            icon={showWarningTooltipFirst ? 'warning' : undefined}
            iconColor={showWarningTooltipFirst ? 'blue' : undefined}
            iconPosition={showWarningTooltipFirst ? 'right' : undefined}
            iconTooltip={showWarningTooltipFirst ? exceedsCharactersMsg : undefined}
            tooltipPosition={showWarningTooltipLast ? 'top' : undefined}
          />
          <FormField
            className="lastNameField"
            name="lastName"
            type="text"
            label="Last Name"
            colSpan={1}
            icon={showWarningTooltipLast ? 'warning' : undefined}
            iconColor={showWarningTooltipLast ? 'blue' : undefined}
            iconPosition={showWarningTooltipLast ? 'right' : undefined}
            iconTooltip={showWarningTooltipLast ? exceedsCharactersMsg : undefined}
            tooltipPosition={showWarningTooltipLast ? 'top' : undefined}
          />

          {showGfxNameFields && (
            <>
              <FormField
                className="gfxFirstNameField"
                name="gfxFirstName"
                type="text"
                label="Gfx First Name"
                colSpan={1}
                placeholder="Please add a shorter first name for graphics (max 26 characters)"
                maxLength={28 - (form.values.gfxLastName?.length || 0)}
              />
              <FormField
                className="gfxLastNameField"
                name="gfxLastName"
                type="text"
                label="Gfx Last Name"
                colSpan={1}
                placeholder="Please add a shorter last name for graphics (max 26 characters)"
                maxLength={28 - (form.values.gfxFirstName?.length || 0)}
              />
              <div style={{ marginTop: '-30px', marginBottom: '30px' }}>{`${
                28 - ((form.values.gfxLastName?.length || 0) + (form.values.gfxFirstName?.length || 0))
              } characters left`}</div>
            </>
          )}
        </div>
      </div>
      <div className="player-team">
        <div className={classnames('tab-row', { 'no-teams': !values.teams || !values.teams.length })}>
          {values.teams && values.teams.length ? (
            <Tabs
              active={currentTab}
              onChange={({ index }) => setCurrentTab(index)}
              tabs={
                values && values.teams
                  ? values.teams.map(({ teamId, name }, index) => ({
                      value: teamId,
                      text: name,
                      className: classnames({
                        delete: isdelete(teamId),
                        error: currentTab !== index && getIn(form.errors, `teams.${index}`)
                      })
                    }))
                  : []
              }
            />
          ) : (
            ''
          )}

          <Button
            iconColor="black"
            icon="plus"
            type="secondary"
            onClick={e => {
              e.preventDefault();
              form.setFieldValue('teams', [
                ...values.teams!,
                {
                  name: 'New Team',
                  number: '',
                  position: '',
                  photo: undefined,
                  defaultStarter: false,
                  teamId: `new_team_${v4()}`
                }
              ]);
              setCurrentTab(values.teams!.length);
            }}
          >
            ADD TEAM
          </Button>
        </div>

        {values.teams && values.teams.length ? (
          <div className="player-team-content">
            <div>
              {/*
           // @ts-ignore */}
              {currentTeam && !currentTeam.preexisting && (
                <FormField
                  disabled={currentTeamDeleted}
                  name={`teams.${currentTab}.teamId`}
                  type="select"
                  placeholder={currentTeam.teamId ? 'Select Team' : ''}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const team = teamOptions.find(({ value }) => value === parseInt(e.target.value))!;
                    form.setFieldValue('teams', [
                      ...values.teams!.slice(0, currentTab),
                      {
                        ...currentTeam,
                        name: team.label,
                        teamId: team.value
                      },
                      ...values.teams!.slice(currentTab + 1, values.teams!.length)
                    ]);
                  }}
                  options={teamOptions.filter(
                    ({ value }) => value === currentTeam.teamId || !values.teams!.find(({ teamId }) => teamId === value)
                  )}
                  label="Team"
                />
              )}
              <FormField
                disabled={!sport}
                name={`teams.${currentTab}.positionType`}
                type="select"
                label="Position type"
                options={positionOptions}
                placeholder="Select a position"
              />
              <FormField type="text" name={`teams.${currentTab}.position`} label="Position" disabled={!sport} />
              <FormField disabled={currentTeamDeleted} name={`teams.${currentTab}.number`} type="text" label="Number" />
              <FormField
                isDisabled={currentTeamDeleted}
                name={`teams.${currentTab}.defaultStarter`}
                label="Starter"
                type="switch"
              />
              <Button
                onClick={event => {
                  event.preventDefault();
                  if (currentTeam && currentTeam.preexisting) {
                    form.setFieldValue(`teams`, [
                      ...values.teams!.slice(0, currentTab),
                      {
                        ...currentTeam,
                        delete: !currentTeam.delete
                      },
                      ...values.teams!.slice(currentTab + 1, values.teams!.length)
                    ]);
                    return;
                  }

                  setCurrentTab(0);

                  form.setFieldValue(`teams`, [
                    ...values.teams!.slice(0, currentTab),
                    ...values.teams!.slice(currentTab + 1, values.teams!.length)
                  ]);
                }}
                type={'borderless'}
                className="remove-player-btn"
              >
                {getRemoveMessage()}
              </Button>
            </div>
            <div>
              <Title is3>Photo</Title>
              <FormField
                name={`teams.${currentTab}.photo`}
                disabled={currentTeamDeleted}
                type="image"
                colSpan={1}
                // @ts-ignore
                url={form.values.teams?.[currentTab]?.photo}
                tooltip={photoText}
                editable
                // @ts-ignore
                onDelete={() => onDelete(form.values.teams?.[currentTab]?.photoId)}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {form?.dirty && (
        <BottomButtonRow withResetFormButton resetOnClick={() => form.resetForm()}>
          <Button
            onClick={event => {
              event.preventDefault();
              form.submitForm();
            }}
            type="primary"
          >
            Save
          </Button>
        </BottomButtonRow>
      )}
    </div>
  );
};
