import { useFormikContext } from 'formik';
import React from 'react';
import { CreateVenueValues } from '.';
import { Button } from '../../../../components/Button';
import { ButtonBottom as BottomButtonRow } from '../../../../components/ButtonBottom/ButtonBottom';
import { FormField } from '../../../../components/FormField';
import { AddressField } from '../../../../components/AddressField';

interface NewVenuePageFormProps {
  loading: boolean;
}

export const NewVenuePageFormContent: React.FunctionComponent<NewVenuePageFormProps> = ({ loading }) => {
  const form = useFormikContext<CreateVenueValues>();

  return (
    <>
      <FormField name="name" type="text" label="Name" autoFocus />
      <FormField name="gfxName" type="text" label="Gfx name" />
      <AddressField form={form} />
      {form?.dirty && (
        <BottomButtonRow withResetFormButton resetOnClick={() => form.resetForm()}>
          <Button
            onClick={event => {
              event.preventDefault();
              form.submitForm();
            }}
            isLoading={loading}
            type="primary"
          >
            Save
          </Button>
        </BottomButtonRow>
      )}
    </>
  );
};
