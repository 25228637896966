import { summaryFragment } from '../fragments/summary';

import gql from 'graphql-tag';

export const matchesQuery = gql`
  query matches(
    $finishedStatus: [EFinishedStatus!]
    $options: IQueryOptions
    $query: String
    $teamId: Float
    $sportId: Float
    $competitionId: Float
  ) {
    matches(
      finishedStatus: $finishedStatus
      options: $options
      query: $query
      teamId: $teamId
      sportId: $sportId
      competitionId: $competitionId
    ) {
      matches {
        id
        name
        archived
        sportName
        score {
          home
          away
        }
        liveStatus
        awayTeam {
          id
          name
          abbreviation
          logo {
            id
            url
          }
        }
        homeTeam {
          id
          name
          abbreviation
          logo {
            id
            url
          }
        }
        competitionId
        date
        competition {
          id
          name
          sport {
            id
            name
          }
          logo {
            id
            url
          }
        }
        baseEntity {
          id
          providerId
          providerName
        }
        organizationId
        finishedStatus
        coverage
        hasFacts
        overlays {
          id
          matchId
          name
          encodingDelay
          autoMode
          testMode
          resolution
          showWatermarks
          background
          enableAds
          key
        }
        config
        summary {
          ...Summary
        }
      }
      total
    }
  }
  ${summaryFragment}
`;
