import { Overlay } from '../../../../../api-types';
import { TriggerProps } from '@ligr/themes/lib/football/themes/default/LowerThird/types';
import React from 'react';
import { InputField } from '../../../../InputField';
import './style.scss';

interface LowerThirdCustomProps {
  value?: TriggerProps;
  onChange: (update: TriggerProps) => any;
  overlay: Overlay;
  disabled?: boolean;
}

// FULLY CONTROLLED ONLY
export const LowerThird: React.FunctionComponent<LowerThirdCustomProps> = ({
  disabled,
  onChange,
  overlay,
  value = {}
}) => {
  return (
    <div className="lowerthird">
      <div className="lowerthird__customText">
        <InputField
          placeholder="Enter Custom Heading Text"
          disabled={disabled}
          value={value.headingRow}
          onChange={event => {
            // @ts-ignore
            onChange({ ...value, headingRow: event.target.value });
            // @ts-ignore
          }}
        />
      </div>
      {Array(4)
        .fill(undefined)
        .map((_, index) => {
          return (
            <div className="lowerthird__customText">
              <InputField
                placeholder={`Enter Custom Row ${index} Text`}
                disabled={disabled}
                value={value.bottomRows ? value.bottomRows[index] : undefined}
                onChange={event => {
                  // @ts-ignore
                  onChange({
                    ...value,
                    bottomRows: value.bottomRows
                      ? [
                          ...value.bottomRows.slice(0, index),
                          // @ts-ignore
                          event.target.value,
                          ...value.bottomRows.slice(index + 1)
                        ]
                      : [
                          ...Array(index).fill(undefined),
                          // @ts-ignore
                          event.target.value,
                          ...Array(3 - index).fill(undefined)
                        ]
                  });
                  // @ts-ignore
                }}
              />
            </div>
          );
        })}
    </div>
  );
};
