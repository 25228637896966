import classnames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';
import './card.scss';
import { CardProps } from './Card.types';

export const Card: React.FunctionComponent<CardProps> = ({
  to,
  hover,
  children,
  className,
  style,
  title,
  ...props
}) => {
  const klass = classnames('card', className, { hover: hover || Boolean(to) });
  if (to)
    return (
      <NavLink to={to} className={klass} title={title} style={style}>
        {children}
      </NavLink>
    );
  return (
    <div className={klass} {...props} title={title} style={style}>
      {children}
    </div>
  );
};
