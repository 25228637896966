import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import classnames from 'classnames';
import { FormField } from '../../../../components/FormField';
import { GroupErrorRow } from '../../../../components/GroupErrorRow';
import { Title } from '../../../../components/Title/Title';
import { MatchFormConfigFields } from '../../MatchFormConfigFields';
import { Button } from '../../../../components/Button';
import { ButtonBottom as BottomButtonRow } from '../../../../components/ButtonBottom/ButtonBottom';
import { ApolloQueryResult } from 'apollo-client';
import { MatchQuery } from '../../../../api-types';

export interface UpdateMatchFormContentProps {
  match: ApolloQueryResult<MatchQuery>['data']['match'];
  venues: { name: string; id: number }[];
  loading: boolean;
}

const setDefaultOption = (opt: any, entity: string) => {
  opt.unshift({ value: '', label: `Select ${entity}` });
  return opt;
};

export const UpdateMatchFormContent: React.FunctionComponent<UpdateMatchFormContentProps> = ({
  match,
  venues,
  loading
}) => {
  const form = useFormikContext<any>();

  const externalDataEnabled = !!match?.baseEntity;
  const values = form.values;

  const homeTeamOptions = useMemo(() => {
    const opt = match.competition.teams
      .filter(t => t.id.toString() !== values.awayTeamId) // Removes away team option selected option
      .map(t => ({ value: `${t.id}`, label: `${t.name}` }));
    return setDefaultOption(opt, 'home team');
  }, [values.awayTeamId]);

  const awayTeamOptions = useMemo(() => {
    const opt = match.competition.teams
      .filter(t => t.id.toString() !== values.homeTeamId) // Removes home team option selected option
      .map(t => ({ value: `${t.id}`, label: `${t.name}` }));
    return setDefaultOption(opt, 'away team');
  }, [values.homeTeamId]);

  const venueOptions = useMemo(() => {
    const opt = venues.length ? venues.map(v => ({ value: `${v.id}`, label: `${v.name}` })) : [];
    return setDefaultOption(opt, 'venue');
  }, [venues]);

  const externalData = !!match?.baseEntity;

  return (
    <>
      <section className="createMatchFormContent__gameInfo">
        <Title is3 className="">
          Game Info
        </Title>
        <FormField
          className="updateMatch__competitionField"
          name="competition"
          type="text"
          label="Competition"
          value={match.competition?.name}
          disabled={true}
          colSpan={2}
        />
        <FormField
          name="homeTeamId"
          type="select"
          label="Home team"
          options={homeTeamOptions}
          disabled={externalDataEnabled}
          colSpan={1}
        />
        <FormField
          name="awayTeamId"
          type="select"
          label="Away team"
          options={awayTeamOptions}
          disabled={externalDataEnabled}
          colSpan={1}
        />
        <FormField name="name" type="text" label="Match name" colSpan={2} />
      </section>
      {values.competitionId !== '' && (
        <section className="createMatchFormContent__whenWhere">
          <Title is3 className="">
            When & Where
          </Title>
          <GroupErrorRow className="updateMatch__dateTime col-span-1" form={form} errorsName={['date', 'time']}>
            <FormField
              name="date"
              label="Date"
              type="date"
              colSpan={1}
              icon="calendar"
              iconPosition="right"
              iconColor="grey-200"
              disabled={externalDataEnabled}
              groupError
            />
            <FormField
              name="time"
              label="Time"
              type="time"
              colSpan={1}
              icon="clock"
              iconPosition="right"
              iconColor="grey-200"
              disabled={externalDataEnabled}
              groupError
            />
          </GroupErrorRow>

          <FormField
            name="venueId"
            type="select"
            label="Venue"
            options={venueOptions}
            disabled={externalDataEnabled}
            colSpan={1}
          />
        </section>
      )}

      {form.values.config && (
        <section
          className={classnames('updateMatchFormContent__matchConfig', {
            'updateMatchFormContent__matchConfig--externalData': externalData
          })}
        >
          {MatchFormConfigFields[match.sportName.toLowerCase()]({ disabled: externalData, form })}
        </section>
      )}

      {form?.dirty && (
        <BottomButtonRow withResetFormButton resetOnClick={() => form.resetForm()}>
          <Button
            onClick={event => {
              event.preventDefault();
              form.submitForm();
            }}
            isLoading={loading}
            type="primary"
          >
            Save
          </Button>
        </BottomButtonRow>
      )}
    </>
  );
};
