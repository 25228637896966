import gql from 'graphql-tag';

export const createClubMutation = gql`
  mutation createClub($createClubInput: CreateClubInput!) {
    createClub(createClubInput: $createClubInput) {
      id
      name
      gfxName
      gfxFullName
      abbreviation
      primaryBackgroundColor
      primaryTextColor
      secondaryBackgroundColor
      secondaryTextColor
      organization {
        id
      }
      defaultVenue {
        id
      }
      logo {
        id
        url
        entityId
        entityType
      }
    }
  }
`;
