import * as Yup from 'yup';
import { AdAllocationEntityType } from '../../../../../api-types';

export const getSportValidationSchema = (sport: string, entityType: AdAllocationEntityType) => {
  return Yup.object()
    .shape({
      percentages: Yup.array().of(
        Yup.object().shape({
          adSetId: Yup.string().required('Ad set is a required field'),
          percentage: Yup.number()
            .min(1, 'Must be a number greater than 0.')
            .max(100, 'Must be a number below 100.')
          // adSetId: Yup.string().matches(new RegExp(/ ^[1-9][0-9]*$/))
        })
      ),
      // events: Yup.object().shape(eventToIdSchema),
      ...(entityType === AdAllocationEntityType.competition
        ? {
            config: Yup.object().shape({
              teams: Yup.object().shape({
                // event: Yup.object().shape(eventToSchema),
                home: Yup.object().shape({
                  // enabled: Yup.bool(),
                  percentage: Yup.number()
                    .min(0, 'Must be a valid percentage')
                    .max(100, 'Must be a number below 100.')
                }),
                away: Yup.object().shape({
                  // enabled: Yup.bool(),
                  percentage: Yup.number()
                    .min(0, 'Must be a valid percentage')
                    .max(100, 'Must be a number below 100.')
                })
              })
            })
          }
        : {})
    })
    .test('Too Many Adsets', 'Maximum of 10 adsets may be assigned percentages', function(value) {
      if (!value) return true;
      // @ts-ignore
      const { config, percentages } = value;
      // @ts-ignore
      if (percentages.length > 10) {
        // @ts-ignore
        return this.createError({
          path: 'form',
          message: 'Maximum of 10 adsets may be assigned percentages'
        });
      }
      return true;
    })
    .test('Invalid Percentage', 'Total allocated percentages must not be greater than 100%', function(value) {
      if (!value) return true;
      // @ts-ignore
      const { config, percentages } = value;
      // @ts-ignore
      const percentageSum = percentages.reduce((sum: number, { percentage }) => sum + percentage, 0);
      const configSum = config
        ? (config.teams.home.enabled ? config.teams.home.percentage : 0) +
          (config.teams.away.enabled ? config.teams.away.percentage : 0)
        : 0;

      if (percentageSum + configSum > 100) {
        // @ts-ignore
        return this.createError({
          path: 'form',
          message:
            'Total allocated percentage must not be greater than 100%. Current Total: ' + (percentageSum + configSum)
        });
      }
      return true;
    });
};
