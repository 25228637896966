import { FormikHelpers } from 'formik';
import gql from 'graphql-tag';
import React, { useState, FunctionComponent } from 'react';
import { object, string } from 'yup';
import { deleteFileMutation } from '../../lib/gql/mutations/deleteFile';
import { Button } from '../../components/Button';
import { Form } from '../../components/Form/Form';
import { FormField } from '../../components/FormField';
import { useErrorHandledMutation } from '../../lib/apolloHooks';
import { meQuery } from '../../lib/meContainer';
import { ProfilePageWrapper } from './ProfilePageWrapper';
import { UpdateUserInput, UpdateUserMutation, MeQuery } from '../../api-types';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ProfileRouterChildProps } from './Profile.router';

const updateMeMutation = gql`
  mutation updateUser($userInput: UpdateUserInput!) {
    updateUser(userInput: $userInput) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const validationSchema = object().shape({
  firstName: string().required().label('First name'),
  lastName: string().required().label('Last name'),
  email: string().email().required().label('Email')
});

type FormValues = {
  firstName: string;
  lastName: string;
  photo?: File;
  email: string;
};

export const ProfileSettingsForm: React.FunctionComponent<ProfileRouterChildProps> = ({ me }) => {
  const [updateMe, { loading }] = useErrorHandledMutation<UpdateUserMutation>(updateMeMutation);
  const [deleteFile] = useErrorHandledMutation<boolean>(deleteFileMutation);
  const [error, setError] = useState();

  const onSubmit = async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
    setError(undefined);

    const userInput: UpdateUserInput = {
      firstName: values.firstName,
      lastName: values.lastName
    };

    if (values.photo) userInput.photo = values.photo;

    try {
      if (me.firstName !== userInput.firstName || me.lastName !== userInput.lastName || userInput.photo) {
        await updateMe({ variables: { userInput } });
        formikHelpers.resetForm();
      }
    } catch (e) {
      setError(e.graphQLErrors[0].message);
    }
  };

  const handleDelete = async (name: string) => {
    // @ts-ignore
    const id = me[name]?.id;

    if (id) {
      await deleteFile({
        variables: { id },
        update: (cache: InMemoryCache, { data }: any) => {
          const meCache = cache.readQuery<MeQuery>({ query: meQuery });
          if (meCache && data.deleteFile) {
            cache.writeQuery({
              query: meQuery,
              data: {
                me: {
                  ...meCache.me,
                  [name]: null
                }
              }
            });
          }
        }
      });
    }
  };

  return (
    <ProfilePageWrapper me={me} rightSide={true}>
      <Form
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={{
          firstName: me.firstName,
          lastName: me.lastName,
          photo: undefined,
          email: me.email
        }}
        error={error}
        useConfirm={true}
        className="profileForm"
      >
        <FormField name="firstName" type="text" label="First name" className="profileForm__firstName" />
        <FormField name="lastName" type="text" label="Last name" className="profileForm__lastName" />
        <FormField
          className="profileForm__photo"
          name="photo"
          label="Photo"
          type="image"
          colSpan={2}
          src={me?.photo?.url}
          editable
          onDelete={() => handleDelete('photo')}
        />
        <FormField name="email" type="text" label="Email" disabled={true} className="profileForm__email" />
        <Button isLoading={loading} className="profileForm__submit">
          Update profile
        </Button>
      </Form>
    </ProfilePageWrapper>
  );
};
