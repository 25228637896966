import gql from 'graphql-tag';

export const adSetFragment = gql`
  fragment AdSet on AdSet {
    id
    name
    archived
    adFiles {
      id
      adSetId
      file {
        id
        url
        entityId
        entityType
      }
      size
    }
  }
`;
