import { AdFileSize } from '@ligr/api-v2';
import { Image } from '@ligr/shared';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { getImageProps } from '../../lib/util/imageValidation';
import { UploadImageField } from './states/UploadImageField';
import { EditableImageField } from './states/EditableImageField';
import './style.scss';

export interface ImageFieldProps {
  name?: string;
  src?: string;
  onChange?: (event: { type: string; target: { value: File | null; name: string } }) => any;
  value?: File | null;
  disabled?: boolean;
  className?: string;
  size?: AdFileSize;
  editable?: boolean;
  useContrastingBackground?: boolean;
  onDelete: () => void;
}

export const ImageField: React.FunctionComponent<ImageFieldProps> = ({
  children,
  onChange,
  className,
  disabled,
  src,
  size,
  editable,
  useContrastingBackground,
  onDelete,
  ...props
}) => {
  const [image, setImage] = useState<string | null>(null);

  const setImageAsBase64String = async (file: File | string) => {
    if (typeof file === 'string') {
      setImage(file);
    } else {
      const { base64String } = await getImageProps(file);
      setImage(base64String as string);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    if (!file) {
      return;
    }

    if (onChange) {
      onChange({
        type: 'change',
        target: { value: file || null, name: props.name! }
      });
    }
  };

  useEffect(() => {
    // handles formik and other controlled cases
    if (props.value) {
      setImageAsBase64String(props.value);
    } else {
      setImage(null);
    }
  }, [props.value]);

  return (
    <div
      className={classnames('image-field', className, size, {
        empty: !src,
        disabled: 'image-field--disabled',
        editable: 'image-field--editable'
      })}
    >
      {src || image ? (
        editable ? (
          <>
            <EditableImageField disabled={disabled} handleChange={handleChange} onDelete={onDelete} />
            <Image id={`image-${size}`} className={'image-field__image'} url={image || src} />
          </>
        ) : (
          <>
            <Image id={`image-${size}`} className={'image-field__image'} url={image || src} />
            <input
              className="image-field__input"
              id={`imageUpload-${size}`}
              type="file"
              value=""
              onChange={disabled ? undefined : handleChange}
            />
          </>
        )
      ) : (
        <UploadImageField disabled={disabled} handleChange={handleChange} />
      )}
      {children && <div className="contents">{children}</div>}
    </div>
  );
};
