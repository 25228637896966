import React from 'react';
import { Notification } from '../../lib/notificationContainer';
import './stackbar.scss';

export const StackBar: React.FunctionComponent = () => {
  const { notifications } = Notification.useContainer();

  if (notifications.length === 0) {
    return null;
  }

  return (
    <div className="stackbar-notifications">
      {notifications.map((notification, index) => (
        <div key={index} className={`stackbar-notification ${notification.type}`} role={notification.type}>
          <p>{notification.message}</p>
        </div>
      ))}
    </div>
  );
};
