import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { withTitle } from '../../components/PageTitle/PageTitle';
import { routes } from '../../router/Routes';
import { OverlayPage } from '../Overlay';
import { CompletedMatchesPage } from './CompletedMatchesPage';
import { NotStartedMatchesPage } from './NotStartedMatchesPage';
import { LiveMatchesPage } from './LiveMatchesPage';
import { NewMatchPage } from './NewMatchPage';
import { UpdateMatchPage } from './UpdateMatchPage';

export const MatchesRouter: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route
        exact
        path={routes.matchesLive(false)}
        component={withTitle({ component: LiveMatchesPage, pageTitle: 'Matches - Live and upcoming' })}
      />
      <Route
        exact
        path={routes.matchesNotStarted(false)}
        component={withTitle({ component: NotStartedMatchesPage, pageTitle: 'Match - Not started' })}
      />
      <Route
        exact
        path={routes.createMatchFromLive(false)}
        component={withTitle({ component: NewMatchPage, pageTitle: 'New Match' })}
      />
      <Route
        exact
        path={routes.editMatchFromLive(false)}
        component={withTitle({ component: UpdateMatchPage, pageTitle: 'New Match' })}
      />
      <Route
        exact
        path={routes.matchesCompleted(false)}
        component={withTitle({ component: CompletedMatchesPage, pageTitle: 'Matches - Completed' })}
      />
      <Route
        path={routes.editOverlay(false)}
        component={withTitle({ component: OverlayPage, pageTitle: 'Matches - Overlay' })}
      />

      <Route path={'*'} render={() => <Redirect to={routes.matchesLive()} />} />
    </Switch>
  );
};
