import { Icon, Logo } from '@ligr/shared';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { object, ref, string } from 'yup';
import { AppPreviewAndSlogan } from '../../components/AppPreview';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card/Card';
import { Form } from '../../components/Form/Form';
import { FormField } from '../../components/FormField';
import { FormStatus } from '../../components/FormStatus/FormStatus';
import { Title } from '../../components/Title/Title';
import { useErrorHandledMutation } from '../../lib/apolloHooks';
import { routes } from '../../router/Routes';
import { UpdatePasswordMutation } from '../../api-types';
import { updatePasswordMutation } from '../UpdatePassword/UpdatePassword.page';

export interface ResetPasswordValues {
  password: string;
  passwordConfirmation: string;
}

export const validationSchema = object().shape({
  password: string().required(),
  passwordConfirmation: string()
    .required()
    .oneOf([ref('password')], 'Password confirmation must be the same as password')
});

const SuccessPanel = () => {
  return (
    <Card>
      <Icon icon="check" color="green" iconSize="large" />
      <FormStatus error={false}>Success! Redirecting you to login…</FormStatus>
    </Card>
  );
};

export const InvitedUserPasswordSet = () => {
  const [error, setError] = useState();
  const history = useHistory<{ referrer?: string } | undefined>();
  const { token } = useParams();
  const [updatePassword, { loading, data }] = useErrorHandledMutation<UpdatePasswordMutation>(updatePasswordMutation);
  const [success, setSuccess] = useState(false);

  const onSubmit = async (values: ResetPasswordValues) => {
    setError(undefined);
    try {
      await updatePassword({
        variables: { updatePassword: { token, password: values.password } }
      });
    } catch (e) {
      setError(e.graphQLErrors[0].message);
    }
  };

  useEffect(() => {
    if (data && data.updatePassword) {
      setSuccess(true);
      setTimeout(() => history.replace(routes.login()), 3000);
    }
  }, [data]);

  return (
    <div className="forgot-password">
      <div className="left-panel">
        {success ? (
          <SuccessPanel />
        ) : (
          <>
            <Link to={routes.login()}>
              <Logo type="color" />
            </Link>
            <Form
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              initialValues={{ password: '', passwordConfirmation: '' }}
            >
              <Title is2>Welcome to ligr</Title>
              <p>Please set your password below before you can login.</p>
              {error && <FormStatus error={true}>{error}</FormStatus>}
              <FormField name="password" type="password" label="Password" autoComplete="new-password" />
              <FormField
                name="passwordConfirmation"
                type="password"
                label="Password Confirmation"
                autoComplete="new-password"
              />
              <Button isLoading={loading}>Save password</Button>
            </Form>
          </>
        )}
      </div>
      <AppPreviewAndSlogan />
    </div>
  );
};
