import { Icon } from '@ligr/shared';
import React, { useState, memo } from 'react';
import { Route, useHistory } from 'react-router';
import { routes } from '../../router/Routes';
import { Button } from '../Button';
import { Title } from '../Title/Title';
import { EditStartingLineup } from './EditStartingLineup';
import { StartingLineupItem } from './StartingLineupItem';
import './style.scss';
import { getTeamPlayer } from '../../lib/util/getTeamPlayer';

type Team = {
  id: number;
};

type PlaysFor = {
  defaultStarter: boolean;
  team: Team;
  photo?: {
    id: number;
    url: string;
  } | null;
  position?: string | null;
  number?: string | null;
};

export type Player = {
  id: number;
  firstName: string;
  lastName: string;
  playsFor?: PlaysFor[] | null;
};

interface StartingLineupProps {
  players: Player[];
  team: {
    id: number;
  };
  loading?: boolean;
}

export const StartingLineup: React.FunctionComponent<StartingLineupProps> = ({ players, team, loading }) => {
  const [showLineupIcon, setShowLineupIcon] = useState(false);

  const history = useHistory<{ referrer?: string } | undefined>();

  const openModalPath = () => history.push(routes.assetTeamPlayersEditLineup({ teamId: team.id }));

  return (
    <div className="lineup" onMouseEnter={() => setShowLineupIcon(true)} onMouseLeave={() => setShowLineupIcon(false)}>
      <div className="lineup-header">
        <Title is3>Default starting lineup</Title>
        <Icon icon="pencil" className={showLineupIcon ? 'show' : ''} onClick={openModalPath} />
      </div>
      {!loading &&
        players
          .filter(p => getTeamPlayer<PlaysFor>(p, team)!.defaultStarter)
          .map(p => <StartingLineupItem key={p.id} player={p} onClick={openModalPath} team={team} />)}
      <div className="lineupItem lineupItem--add-starter" onClick={openModalPath}>
        <Button type="primary" isSquare icon="plus" />
        <span>Add starter</span>
      </div>
      <Route
        exact
        path={routes.assetTeamPlayersEditLineup(false)}
        render={() => (
          <EditStartingLineup
            players={players}
            team={team}
            onClose={() => history.push(routes.assetTeamPlayers({ teamId: team.id }))}
          />
        )}
      />
    </div>
  );
};
