import React, { MutableRefObject, useState, createContext, useMemo, useEffect, useRef, useCallback } from 'react';
import classnames from 'classnames';
import throttle from 'lodash.throttle';

type VerticalScrollProps = {
  controlledOffset?: number;
  initialOffsetTop?: number;
  throttle?: number;
  className?: string;
  component?: React.FunctionComponent<{
    scrollRoot: (val: any) => void;
    className: string;
  }>;
};

export interface ScrollContextType {
  offsetTop?: number;
  updateOffset: (val: number) => void;
}

export const ScrollContext = createContext<ScrollContextType | null>(null);

const isNil: (val: any) => boolean = val => val === undefined && val === null;

// Can be used to both control and get information regarding the scroll offset.
export const VerticalScroll: React.FunctionComponent<VerticalScrollProps> = ({
  component,
  children,
  initialOffsetTop = 0,
  controlledOffset,
  throttle: throttleMs,
  className
}) => {
  const controlled = isNil(controlledOffset);

  const [refId, setRefId] = useState(0);
  // Ref to set at the base of the ref
  const scrollRootRef = useRef<HTMLElement | null>(null);

  // Classnames
  const classNames = classnames('scrollable', className);

  const refSetter = useCallback(ref => {
    scrollRootRef.current = ref;
    setRefId(state => state + 1);
  }, []);
  // When controlled this is used to control the offest
  // When uncontrolled this will reflect the reported offset
  const [scrollState, setScrollState] = useState<Partial<ScrollContextType>>({
    offsetTop: initialOffsetTop
  });

  const updateOffset = useCallback((val: number) => {
    setScrollState({
      offsetTop: val
    });
  }, []);

  useEffect(() => {
    if (!controlled && scrollRootRef.current) {
      let scrollHandler = (e: Event) => {
        setScrollState({
          // @ts-ignore
          offsetTop: e.target.scrollTop
        });
      };
      if (throttle) {
        scrollHandler = throttle(scrollHandler, throttleMs);
      }
      scrollRootRef.current.addEventListener('scroll', scrollHandler);

      return () => scrollRootRef.current!.addEventListener('scroll', scrollHandler);
    }
  }, [refId]);

  useEffect(() => {
    if (!isNil(controlledOffset)) {
      setScrollState({ offsetTop: controlledOffset });
    }
  }, [controlledOffset]);

  useEffect(() => {
    if (scrollRootRef.current) {
      scrollRootRef.current.scrollTo(0, scrollState.offsetTop!);
    }
  }, [scrollState.offsetTop]);

  return (
    <ScrollContext.Provider
      value={{
        offsetTop: scrollState.offsetTop || initialOffsetTop,
        updateOffset
      }}
    >
      {component ? component({ scrollRoot: refSetter, className: classNames, children }) : null}
      {!component && children}
    </ScrollContext.Provider>
  );
};
