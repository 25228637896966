import { CONFIG } from '@ligr/shared';
import React from 'react';
import Script from 'react-load-script';
import './style.scss';

type MapType = {
  lat: number;
  lng: number;
};

export const Map: React.FunctionComponent<MapType> = ({ lat, lng }) => {
  let map: any;

  const handleScriptLoad = () => {
    // @ts-ignore
    const placeCoords = new google.maps.LatLng(lat, lng);
    // @ts-ignore
    map = new google.maps.Map(document.getElementById('map'), {
      zoom: 15,
      center: placeCoords
    });
    // @ts-ignore
    new google.maps.Marker({ position: placeCoords, map });
  };

  return (
    <>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${CONFIG.GOOGLE_PLACES_KEY}&libraries=maps`}
        onLoad={handleScriptLoad}
      />
      <div id="map"></div>
    </>
  );
};
