import './style.scss';

import React, { useState } from 'react';

import { Tabs } from '../../../components/Tabs/Tabs';
import { PlayAndScoreTab } from './PlayAndScoreTab';
import { TeamStatsTab } from './TeamStatsTab';
import { OverlayControlRightColumnProps } from './types';

export const OverlayControlRightColumn: React.FunctionComponent<OverlayControlRightColumnProps> = ({
  playerFacts,
  matchStats,
  sportName,
  homeTeamLogoUrl,
  awayTeamLogoUrl,
  competitionId
}) => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabOnChange = ({ index }: { index: number }) => setCurrentTab(index);

  return (
    <div className="overlayControlRightColumn">
      <div className="overlayControlRightColumn__tabsWrapper">
        <Tabs
          active={currentTab}
          tabs={[
            { value: 0, text: 'Plays & Scores' },
            { value: 1, text: 'Team stats' }
          ]}
          onChange={handleTabOnChange}
        />
      </div>
      {currentTab === 0 && (
        <PlayAndScoreTab playerFacts={playerFacts} competitionId={competitionId} sportName={sportName} />
      )}
      {currentTab === 1 && (
        <TeamStatsTab
          matchStats={matchStats}
          sportName={sportName}
          homeTeamLogoUrl={homeTeamLogoUrl}
          awayTeamLogoUrl={awayTeamLogoUrl}
        />
      )}
    </div>
  );
};
