import './button-bottom.scss';

import React from 'react';

import { Button } from '../Button';
import { ButtonBottomProps } from './ButtonBottom.types';

export const ButtonBottom: React.FunctionComponent<ButtonBottomProps> = ({
  children,
  withResetFormButton,
  resetOnClick
}) => {
  return (
    <div className="button-bottom">
      {withResetFormButton && (
        <button className="reset-default" onClick={resetOnClick}>
          Reset
        </button>
      )}
      {children}
    </div>
  );
};
