import { Venue } from '../../../api-types';
import capitalize from 'capitalize';
import pluralize from 'pluralize';
import React from 'react';

export const ConfirmWindowVenueContent: React.FunctionComponent<{
  venue: Pick<Venue, 'countMatches' | 'countDefaultVenue' | 'name'>;
}> = ({ venue }) => {
  const withNotification = venue.countMatches > 0 || venue.countDefaultVenue > 0;
  const notifications = [];

  if (venue.countMatches > 0) {
    notifications.push(
      <li>
        Archive {venue.countMatches} {pluralize('matches', venue.countMatches)}
      </li>
    );
  }

  if (venue.countDefaultVenue > 0) {
    notifications.push(
      <li>
        Remove the default venue on {venue.countDefaultVenue} {pluralize('teams', venue.countDefaultVenue)}
      </li>
    );
  }

  return (
    <>
      Are you sure you want to archive {capitalize(venue.name)}?
      {withNotification && (
        <div className={`notification notification--error confirm-window__notification`}>
          This action will also:
          <ul>{notifications.map(item => item)}</ul>
        </div>
      )}
    </>
  );
};
