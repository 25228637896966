import { Icon, IconType, Image } from '@ligr/shared';
import classnames from 'classnames';
import React, { HTMLProps } from 'react';
import { Notification } from '../../lib/notificationContainer';
import { useArchiveClub } from '../../lib/useArchiveClub';
import { routes } from '../../router/Routes';
import { Button } from '../Button';
import { Card } from '../Card/Card';
import { ConfirmWindow } from '../ConfirmWindow';
import { ConfirmWindowClubContent } from '../ConfirmWindow/Content/ClubContent';
import { Title } from '../Title/Title';
import { ToolTip } from '../ToolTipIcon';
import './style.scss';

interface CardClubProps extends Partial<HTMLProps<HTMLDivElement>> {
  club: {
    name: string;
    id: number;
    archived: boolean;
    logo?: {
      url: string;
    } | null;
    teams?:
      | {
          countMatches: number;
          countPlayers: number;
          archived: boolean;
          competitions?: {
            name: string;
            id: number;
            logo?: {
              url: string;
            } | null;
          }[];
        }[]
      | null;
  };
  to?: string;
  fn?: () => void;
  icon?: IconType;
}

export const CardClub: React.FunctionComponent<CardClubProps> = ({ club, to, fn, icon, ...rest }) => {
  const { restoreClub } = useArchiveClub();
  const { addNotification } = Notification.useContainer();
  const competitions = club.teams?.map(t => t.competitions).flat();

  return (
    <Card
      to={club.archived ? '' : to || routes.assetClub({ clubId: club.id })}
      className={classnames('cardClub', {
        'card--archived': club.archived
      })}
      title={club.name}
      {...rest}
    >
      {club.archived && (
        <div className="card--archived__overlay">
          <Button
            onClick={() => {
              restoreClub(club.id);
              addNotification('Club restored successfully.', 'success');
            }}
          >
            Restore
          </Button>
        </div>
      )}

      {!club.archived && fn && icon && (
        <ConfirmWindow action={fn} title={`Archive Club`} content={<ConfirmWindowClubContent club={club} />}>
          <Icon icon={icon} />
        </ConfirmWindow>
      )}

      <Image url={club.logo?.url} className="cardClub__image" />

      <Title is3 className="cardClub__title">
        {club.name}
      </Title>
      <Title is4 className="cardClub__subtitle">
        {club.teams?.length || 'no'} teams
      </Title>

      {competitions && (
        <div className="cardClub__competitions">
          {competitions.length > 0 && (
            <Image
              url={competitions[0].logo?.url}
              className="cardClub__competitions__image"
              useContrastingBackground
              placeholder="competition"
            />
          )}
          {competitions.length === 2 && (
            <Image
              url={competitions[1].logo?.url}
              className="cardClub__competitions__image"
              useContrastingBackground
              placeholder="competition"
            />
          )}
          {competitions.length > 2 && (
            <ToolTip reference={() => <div className="cardClub__competitions__number">+{competitions.length - 1}</div>}>
              <ul className="cardClub__competitions__competitionList">
                {competitions.slice(1).map(c => (
                  <li key={`${club.id}.${c.id}`}>
                    <Image
                      className="cardClub__competitions__competitionList__image"
                      url={c.logo?.url}
                      placeholder="competition"
                    />
                    {c.name}
                  </li>
                ))}
              </ul>
            </ToolTip>
          )}
        </div>
      )}
    </Card>
  );
};
