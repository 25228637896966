import { Workbox } from 'workbox-window';
import { CONFIG } from '@ligr/shared';

if ('serviceWorker' in navigator && CONFIG.ENV !== 'development') {
  const wb = new Workbox('/service-worker.js');

  wb.addEventListener('waiting', event => {
    console.log('waiting');
    console.log(event);
  });

  wb.addEventListener('installed', event => {
    console.log('installed');
    console.log(event);
  });

  wb.addEventListener('controlling', event => {
    console.log('controlling');
    console.log(event);
  });

  wb.addEventListener('activated', event => {
    console.log('activated');
    console.log(event);
  });

  wb.addEventListener('externalinstalled', event => {
    console.log('externalinstalled');
    console.log(event);
  });

  wb.addEventListener('externalwaiting', event => {
    console.log('externalwaiting');
    console.log(event);
  });

  wb.addEventListener('externalactivated', event => {
    console.log('externalactivated');
    window.location.reload();
  });

  wb.register();
  navigator.serviceWorker.getRegistration();
}
