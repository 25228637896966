import { useCallback, useEffect, useState } from 'react';
import { ITheme } from '@ligr/themes';
import camelCase from 'camelcase';

export const useThemeLoad = (theme?: { name: string; sportName: string }): ITheme | undefined => {
  const [themeObj, setThemeObj] = useState<ITheme | undefined>(undefined);
  const loadThemObj = useCallback(async () => {
    theme &&
      setThemeObj(
        (await import(`@ligr/themes/lib/${camelCase(theme.sportName)}/themes/${theme.name}/index.js`)).default
      );
  }, [theme]);

  useEffect(() => {
    theme && loadThemObj();
  }, [loadThemObj, theme]);

  return themeObj;
};
