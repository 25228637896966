import React from 'react';
import { NavLink } from 'react-router-dom';
import { logDelay } from '../../lib/animation';
import './nav-tabs.scss';
import { NavTabsProps } from './NavTabs.types';
import classnames from 'classnames';

export const NavTabs: React.FunctionComponent<NavTabsProps> = ({ tabs, className }) => (
  <nav className={classnames('link-tabs', className)}>
    {tabs.map(({ to, text, exact = true }, i) => (
      <NavLink to={to} key={to} exact={exact}>
        <span {...logDelay(i)}>{text}</span>
      </NavLink>
    ))}
  </nav>
);
