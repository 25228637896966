import { CONFIG, Icon } from '@ligr/shared';

import copy from 'copy-to-clipboard';
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
import { ContextMenu } from '../../components/ContextMenu';

import { Switch } from '../../components/Switch';
import { useErrorHandledMutation } from '../../lib/apolloHooks';
import { Notification } from '../../lib/notificationContainer';
import { routes } from '../../router/Routes';
import { updateOverlayMutation } from '../Matches/matches.qql';

import './style.scss';
import { UpdateOverlayMutation, Overlay, Match } from '../../api-types';

type HeaderProps = {
  overlay: Pick<Overlay, 'id' | 'key' | 'autoMode'>;
  match: Pick<Match, 'id' | 'organizationId'>;
};

export const Header: React.FunctionComponent<HeaderProps> = ({ overlay, match }) => {
  const [updateOverlay] = useErrorHandledMutation<UpdateOverlayMutation>(updateOverlayMutation);
  const { addNotification } = Notification.useContainer();
  const history = useHistory<{ referrer?: string } | undefined>();

  return (
    <div className="overlayControl__header">
      <NavLink className="overlayControl__header__backLink" to={routes.matchesLive({})}>
        <Icon icon="back" /> <span className="overlayControl__header__backLink__label">MATCHES</span>
      </NavLink>

      <Switch
        dark
        value={overlay?.autoMode}
        onChange={value =>
          updateOverlay({
            variables: {
              updateOverlayInput: {
                autoMode: value,
                id: overlay?.id
              }
            }
          })
        }
        label="Manual mode"
        label2="Auto mode"
      />
      <div className="overlayControl__header__menu">
        <ContextMenu
          id="overlayControl__header__contextualMenu"
          place="left"
          // @ts-ignore
          items={[
            {
              icon: 'link',
              text: 'Copy Live link',
              action: () => {
                copy(`${CONFIG.OVERLAY_URL}/${overlay.key}`);
                addNotification('URL copied', 'info');
              }
            },
            {
              icon: 'liveScore',
              text: 'Open LiveScore',
              action: () =>
                window.open(`${CONFIG.LIVE_SCORE_URL}/o/${match.organizationId}/match/${match.id}`, '_blank')
            },
            {
              icon: 'controlCenter',
              text: 'Overlay Settings',
              action: () => {
                history.push(
                  routes.editOverlay({ matchId: match.id, overlayId: overlay.id, graphicName: 'select-graphic' })
                );
              }
            }
          ]}
        >
          <Button type="plain" isSquare icon="ellipsis" iconColor="grey-600" />
        </ContextMenu>
      </div>
    </div>
  );
};
