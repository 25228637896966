import { roles } from '@ligr/api-v2';
import { object, string } from 'yup';

export const userFormSchema = object().shape({
  firstName: string()
    .min(1)
    .required()
    .label('First name'),
  lastName: string()
    .min(1)
    .required()
    .label('Last name'),
  email: string()
    .email()
    .required()
    .label('Email'),
  role: string()
    .oneOf(Object.keys(roles))
    .required()
    .label('Role')
});
