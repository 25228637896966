import React, { useMemo } from 'react';
import { AdSetItem } from '../../../components/AdSetItem';
import { SideMenuList, SideMenuListItem } from '../../../components/SideMenuList';
import { routes } from '../../../router/Routes';

type AdSet = {
  name: string;
  id: number;
  adFiles: {
    size: string;
    id: number;
    file?: {
      url: string;
    };
  }[];
};

export interface AdSetsSideMenuProps {
  adSets: AdSet[];
}

export const AdSetsSideMenu: React.FunctionComponent<AdSetsSideMenuProps> = ({ adSets }) => {
  const items = useMemo(() => {
    return adSets.map<SideMenuListItem>(adSet => {
      const smallAdSet = adSet.adFiles.find(as => as.size === 'small');

      return {
        imageUrl: smallAdSet?.file?.url,
        text: adSet.name,
        to: routes.adSetImageAds({ adSetId: adSet.id }),
        count: adSet.adFiles.length
      };
    });
  }, [adSets]);

  return (
    <SideMenuList
      items={items}
      renderItem={(i: SideMenuListItem) => <AdSetItem url={i.imageUrl} count={i.count} name={i.text} />}
      link={routes.createAdSet()}
    />
  );
};
