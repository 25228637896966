import { MatchConfig } from '@ligr/api-v2';
import { FormikContextType } from 'formik';
import React, { useMemo, useState } from 'react';
import { mixed, object, string } from 'yup';
import { Age, DefaultOverlayConfig, Gender, UpdateCompetitionMutation } from '../../../../api-types';
import { Form } from '../../../../components/Form/Form';
import { getGQLError } from '../../../../lib/apolloClient';
import { useErrorHandledMutation } from '../../../../lib/apolloHooks';
import { updateCompetition } from '../../../../lib/gql/mutations/updateCompetition';
import { Notification } from '../../../../lib/notificationContainer';
import { logoValidation } from '../../../../lib/util/imageValidation';
import { Competition } from './Competition.page';
import { CompetitionSettingsForm } from './CompetitionSettingsForm';

export type CompFormValues = {
  name: string;
  gfxName?: string | null;
  age: Age;
  gender: Gender;
  gradeId: number;
  logo?: File | string;
  watermark?: File | string;
  secondaryWatermark?: File | string;
  config?: MatchConfig;
  overlayConfig?: DefaultOverlayConfig;
  id: number;
};

interface CompetitionSettingsPageProps {
  competition: Competition;
}

const validationSchema = object().shape({
  name: string()
    .required()
    .label('Name'),
  gfxName: string()
    .max(40)
    .nullable()
    .label('Gfx name'),
  age: string()
    .required()
    .oneOf(Object.values(Age))
    .label('Age'),
  gender: string()
    .required()
    .oneOf(Object.values(Gender))
    .label('Gender'),
  logo: mixed().test('logo', 'Invalid logo', async function(value) {
    if (value) {
      const { logo } = await logoValidation([{ logo: value }]);
      if (logo) {
        return this.createError({
          path: this.path,
          message: `Logo: ${logo}`
        });
      }
    }
    return true;
  }),
  watermark: mixed().test('watermark', 'Invalid watermark', async function(value) {
    if (value) {
      const { watermark } = await logoValidation([{ watermark: value }]);
      if (watermark) {
        return this.createError({
          path: this.path,
          message: `Watermark: ${watermark}`
        });
      }
    }
    return true;
  }),
  secondaryWatermark: mixed().test('secondaryWatermark', 'Invalid secondaryWatermark', async function(value) {
    if (value) {
      const { secondaryWatermark } = await logoValidation([{ secondaryWatermark: value }]);
      if (secondaryWatermark) {
        return this.createError({
          path: this.path,
          message: `Secondary watermark: ${secondaryWatermark}`
        });
      }
    }
    return true;
  })
});

export const CompetitionSettingsPage: React.FunctionComponent<CompetitionSettingsPageProps> = ({
  competition,
  ...rest
}) => {
  const [form, setForm] = useState<FormikContextType<CompFormValues> | null>(null);

  const [update, { loading }] = useErrorHandledMutation<UpdateCompetitionMutation>(updateCompetition);
  const { addNotification } = Notification.useContainer();

  const submit = async (values: CompFormValues) => {
    const competition = { ...values, matchConfig: values.config, gradeId: parseInt(values.gradeId.toString()) };
    delete competition.config;
    try {
      await update({ variables: { updateCompetitionInput: competition } });
      addNotification('Competition successfully updated.', 'success');
      form?.resetForm();
    } catch (e) {
      // @ts-ignore
      form.setFieldError(getGQLError(e).details.field, getGQLError(e).message);
    }
  };

  const initialValues = useMemo(() => {
    const comp: CompFormValues = {
      name: competition.name,
      age: competition.age,
      gender: competition.gender,
      gradeId: competition.grade.id,
      gfxName: competition.gfxName,
      id: competition.id,
      config: competition.matchConfig,
      overlayConfig: competition.overlayConfig
    };
    return comp;
  }, [competition]);

  return (
    <Form
      {...rest}
      onSubmit={submit}
      className={`page-form competition__settings`}
      validationSchema={validationSchema}
      withSubmit
      initialValues={initialValues}
      useConfirm={true}
      setForm={setForm}
    >
      <CompetitionSettingsForm competition={competition} loading={loading} />
    </Form>
  );
};
