import React from 'react';
import { footballFactNameMap } from '@ligr/api-v2';
import { Match, Team, Fact } from '../../../../../api-types';
import { Select } from '../../../../Select';
import { TriggerProps } from '@ligr/themes/lib/football/themes/default/Event/types';
import './style.scss';

interface EventCustomProps {
  value?: TriggerProps;
  onChange: (update: TriggerProps) => any;
  match: Match;
  disabled?: boolean;
  facts: Fact[];
}

// FULLY CONTROLLED ONLY
export const Event: React.FunctionComponent<EventCustomProps> = ({
  disabled,
  onChange,
  value = {},
  facts = [],
  match
}) => {
  const homeTeam = match.homeTeam;
  const awayTeam = match.awayTeam;

  const getFactTeam = (teamId: number) => {
    if (!teamId) {
      return '';
    }
    return teamId === homeTeam.id ? homeTeam.gfxName : awayTeam.gfxName;
  };

  return (
    <div className="event">
      <Select
        disabled={disabled}
        value={value.factId}
        onChange={event => {
          // @ts-ignore
          onChange({ factId: parseInt(event.target.value) });
        }}
        label="Event"
        options={([
          {
            value: null,
            label: 'Select Event'
          }
        ] as { value: number | null; label: string }[]).concat(
          facts
            ?.sort((a, b) => b.minute! - a.minute!)
            .map(fact => ({
              // @ts-ignore
              label: `${footballFactNameMap[fact.name]} ${getFactTeam(fact.teamId)} ${fact.minute}\``,
              value: fact.id
            }))
        )}
      />
    </div>
  );
};
