import * as Yup from 'yup';
import { logoValidation } from '../../../lib/util/imageValidation';

export const playerFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(1)
    .required()
    .label('First Name'),
  lastName: Yup.string()
    .min(1)
    .required()
    .label('Last Name'),
  gfxNFirstame: Yup.string()
    .max(26)
    .nullable()
    .label('Gfx First Name'),
  gfxLastName: Yup.string()
    .max(26)
    .nullable()
    .label('Gfx Last Name'),
  teams: Yup.array().of(
    Yup.object().shape({
      teamId: Yup.string().matches(/^(?!new_team_).*/, 'Missing Team Selection'),
      number: Yup.string().nullable(),
      position: Yup.string().nullable(),
      positionType: Yup.string()
        .required()
        .label('Position type'),
      photo: Yup.mixed()
        .test('photo', '${path} is an invalid image', async function(value) {
          if (typeof value === 'string') return true;
          if (value) {
            const { logo } = await logoValidation([{ logo: value }]);
            if (logo) {
              return this.createError({
                path: this.path,
                message: logo
              });
            }
          }
          return true;
        })
        .label('Player photo')
    })
  )
});
