import React from 'react';
import { Button } from '../../components/Button';
import { Title } from '../../components/Title/Title';
import { routes } from '../../router/Routes';
import './errors.scss';

export const NetworkError = () => {
  return (
    <div className="page error network-error">
      <div className="center">
        <Title is1>Oooooops...</Title>
        <span>The referees couldn't look at the video review. The connection might be at fault. Unusual.</span>
        <br />
        <Button href={routes.orgSelection()}>Refresh</Button>
      </div>
    </div>
  );
};
