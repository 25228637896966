import React from 'react';
import * as Yup from 'yup';
import { Form } from '../../../components/Form/Form';
import { FormProps } from '../../../components/Form/Form.types';
import { FormField } from '../../../components/FormField';
import { AdSetFormValues } from './AdSetForm.type';

const adSetFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(1)
    .required()
    .label('Ad Set name')
});

export const AdSetForm: React.FunctionComponent<FormProps<AdSetFormValues>> = props => {
  return (
    <Form {...props} validationSchema={adSetFormSchema} className="create-as as-form">
      <FormField name="name" type="text" label="Name" />
    </Form>
  );
};
