import { Icon } from '@ligr/shared';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { Auth } from '../../lib/authContainer';
import { Me } from '../../lib/meContainer';
import { routes } from '../../router/Routes';
import './user-menu.scss';

type UserMenuProps = {
  disabled?: boolean;
};

export const UserMenu: React.FunctionComponent<UserMenuProps> = ({ disabled }) => {
  const [open, setOpen] = useState(false);
  const { logout } = Auth.useContainer();
  const { me } = Me.useContainer();

  const close = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) window.addEventListener('click', close);
    return () => window.removeEventListener('click', close);
  }, [open]);

  const location = useLocation<{ referrer?: string } | undefined>();

  const active = useMemo(
    () =>
      matchPath(location.pathname, {
        path: routes.profile(false)
      }),
    [location]
  );

  const disabledClass = disabled ? 'disabled' : '';

  return (
    <div className="user-menu" onClick={() => setOpen(!open)}>
      <Icon icon="profile" iconSize="large" color={active ? 'red' : 'white'} />
      {open && (
        <div className="menu tooltip right">
          {me?.admin && me.email.match('@ligrsystems.com') && <Link to={routes.admin()}>Admin</Link>}
          <Link className={`${disabledClass}`} to={routes.profile()}>
            Profile
          </Link>
          <Link className={`${disabledClass}`} to={routes.profileOrganization()}>
            Organization
          </Link>
          <Link className={`${disabledClass}`} to={routes.profilePassword()}>
            Security
          </Link>
          <Link className={`${disabledClass}`} to={routes.permissions()}>
            Permissions
          </Link>
          <Link className={`${disabledClass}`} to={routes.subscriptions()}>
            Subscription
          </Link>
          <Link to={routes.orgSelection()}>Switch organization</Link>
          <a onClick={logout}>Logout</a>
        </div>
      )}
    </div>
  );
};
