import gql from 'graphql-tag';

export const createMatchQuery = gql`
  mutation createMatch($createMatchInput: CreateMatchInput!) {
    createMatch(createMatchInput: $createMatchInput) {
      id
      name
      awayTeam {
        id
        name
      }
      homeTeam {
        id
        name
      }
      competitionId
      date
      competition {
        id
        name
      }
      overlays {
        id
        matchId
        encodingDelay
        autoMode
        resolution
        showWatermarks
        background
        enableAds
        key
      }
      config
    }
  }
`;
