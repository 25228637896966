import { updateQueryUrl } from '@ligr/shared';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import isNil from 'lodash.isnil';

type UsePaginationProps = {
  pageNumber: number;
  pageSize: number;
  count: number;
};

export const usePagination = ({ pageNumber, pageSize, count }: UsePaginationProps) => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const [totalCount, setTotalCount] = useState(0);

  const pageCount = useMemo(() => Math.ceil(totalCount / pageSize), [totalCount, pageSize]);

  useEffect(() => {
    if (!isNil(count)) {
      setTotalCount(count);
    }
  }, [count]);

  useEffect(() => {
    if (pageCount && pageNumber > pageCount) {
      updateQueryUrl(history, { page: '1' });
    }
  }, [pageNumber, pageCount]);

  return {
    pageNumber,
    pageSize,
    pageCount,
    totalCount
  };
};
