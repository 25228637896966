import { Icon, updateQueryUrl } from '@ligr/shared';
import React from 'react';
import { useHistory } from 'react-router';

interface ResetFiltersProps {
  active: boolean;
  toggleFilter: React.Dispatch<React.SetStateAction<boolean>>;
  opened: boolean;
  setActiveFilters: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
}

export const ResetFilters: React.FunctionComponent<ResetFiltersProps> = ({
  active,
  opened,
  toggleFilter,
  setActiveFilters
}) => {
  const history = useHistory<{ referrer?: string } | undefined>();

  const resetFilters = () => {
    updateQueryUrl(history, {
      sports: '',
      competitions: '',
      teams: '',
      archive: '',
      allocation: '',
      club: '',
      age: '',
      gender: '',
      grades: '',
      status: '',
      startAt: '',
      endAt: ''
    });
    setActiveFilters({});
    toggleFilter(false);
  };

  if (!active || !opened) return null;

  return (
    <div className="filters__reset-filters__wrapper">
      <div className="filters__reset-filters" onClick={resetFilters}>
        Reset Filters
        <Icon icon="close" />
      </div>
    </div>
  );
};
