import { useSearchParams } from '@ligr/shared';
import React, { useEffect, useMemo } from 'react';
import { CardAllocation } from '../../../../components/CardAllocation';
import { Filters } from '../../../../components/Filters';
import { Grid } from '../../../../components/Grid/Grid';
import { getMissingEntitiesComponentContent } from '../../../../components/MissingEntitiesComponent/getMissingEntitiesComponentContent';
import { MissingEntitiesComponent } from '../../../../components/MissingEntitiesComponent/MissingEntitiesComponent';
import { PageContent } from '../../../../components/page/PageContent';
import { PageContentContent } from '../../../../components/page/PageContentContent';
import { PageHeader } from '../../../../components/PageHeader';
import { PageSubHeader } from '../../../../components/PageSubHeader/PageSubHeader';
import { logDelay } from '../../../../lib/animation';
import { useErrorHandledLazyQuery } from '../../../../lib/apolloHooks';
import { adAllocationsQuery } from '../../../../lib/gql/queries/adAllocations';
import { EntityEnum } from '../../../../types/assets';
import './style.scss';
import { AdAllocationsQuery } from '../../../../api-types';

export const AdSetsAllocationsPage: React.FunctionComponent = () => {
  const searchParams = useSearchParams(['query', 'allocation']);
  const [fetch, { data, loading, called }] = useErrorHandledLazyQuery<AdAllocationsQuery>(adAllocationsQuery);
  const adAllocations = (data && data.adAllocations) || [];

  useEffect(() => {
    fetch({});
  }, []);

  const searchAdAllocations = useMemo(
    () =>
      adAllocations.filter(a => {
        let query = true;
        if (searchParams.query) query = a.entity.name.toLowerCase().includes(searchParams.query.toLowerCase());
        if (searchParams.allocation) query = a.entityType === searchParams.allocation;
        return query;
      }),
    [searchParams.allocation, adAllocations, searchParams.query]
  );

  const missingEntitiesDialogProps =
    called &&
    !loading &&
    getMissingEntitiesComponentContent(
      EntityEnum.allocations,
      adAllocations,
      searchAdAllocations,
      'Allocations are automatically created with competitions and teams',
      false
    );

  return (
    <PageContent loading={loading}>
      <PageHeader title="Allocation" />
      <PageContentContent className="as-allocation">
        {adAllocations.length > 0 && (
          <PageSubHeader>
            <Filters searchable="Allocation" filters={['allocation']} />
          </PageSubHeader>
        )}
        {missingEntitiesDialogProps ? (
          <MissingEntitiesComponent {...missingEntitiesDialogProps} />
        ) : (
          <Grid>
            {searchAdAllocations.map((c, i) => (
              <CardAllocation key={i} entity={c.entity} type={c.entityType} {...logDelay(i)} />
            ))}
          </Grid>
        )}
      </PageContentContent>
    </PageContent>
  );
};
