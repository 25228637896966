import { Icon, Image } from '@ligr/shared';
import React from 'react';
import { SideMenuItemProps } from '../SideMenuItem';
import './style.scss';

export interface ClubItemProps extends SideMenuItemProps {}

export const ClubItem: React.FunctionComponent<ClubItemProps> = ({ item: { text, imageUrl, imagePlaceholder } }) => {
  return (
    <>
      <Icon icon="dropdown" />
      <Image url={imageUrl} placeholder={imagePlaceholder} className="sideMenuClubItem__image" />
      <div className="sideMenuClubItem__text">{text}</div>
    </>
  );
};
