import React from 'react';
import { MatchPlayer, Match, Fact } from '../../../../../api-types';
import { Select } from '../../../../Select';
import { Checkbox } from '../../../../Checkbox/Checkbox';
import { DataProps, TriggerProps } from '@ligr/themes/lib/sports/themes/rugbyunion1/Templates/Scoreboard/types';

import './style.scss';
import { SubstitutionFact } from '@ligr/api-v2/dist/types/facts/Facts';

interface ScoreboardCustomProps {
  value?: Partial<TriggerProps>;
  onChange: (update: Partial<TriggerProps>) => any;
  match: Match;
  disabled?: boolean;
  facts: Fact[];
}

const getPlayerName = (lineup: MatchPlayer[] = [], playerId: number) => {
  const player = lineup.find(({ player: { id } }) => playerId === id);
  if (!player) return '';
  return `(${player.number}) ${player.player.firstName} ${player.player.lastName}`;
};

// FULLY CONTROLLED ONLY
export const Scoreboard: React.FunctionComponent<ScoreboardCustomProps> = ({
  disabled,
  onChange,
  value = {},
  match,
  facts = []
}) => {
  const homeTeam = match.homeTeam;
  const awayTeam = match.awayTeam;

  const getFactTeam = (teamId: number) => {
    if (!teamId) {
      return '';
    }
    return teamId === homeTeam.id ? homeTeam.gfxName : awayTeam.gfxName;
  };

  return (
    <div className="scoreboard">
      <div className="scoreboard__preset">
        <div className="scoreboard__substitution">
          <Checkbox
            className="scoreboard__substitution__checkbox"
            disabled={!value.factId}
            checked={value.preset === 'substitution'}
            onChange={() => {
              return onChange({
                ...value,
                preset: value.preset !== 'substitution' ? 'substitution' : 'default'
              });
            }}
            label="Show Substitution Event"
          />
          <div>Include Substitution</div>
        </div>
        <Select
          disabled={disabled}
          value={value.factId}
          onChange={event => {
            // @ts-ignore
            onChange({ factId: parseInt(event.target.value) });
          }}
          label="Event"
          options={([
            {
              value: null,
              label: 'Select Substitution Event'
              // @ts-ignore
            }
          ] as { value: null | number; label: string }[]).concat(
            facts
              ?.filter(fact => (fact.name as string) === SubstitutionFact.SUBSTITUTION)
              .sort((a, b) => b.minute! - a.minute!)
              .map(fact => ({
                label: `${fact.minute}\` - ${getFactTeam(fact.teamId!)} - on: ${getPlayerName(
                  match.lineup,
                  fact.data.on
                )}, off: ${getPlayerName(match.lineup, fact.data.off)}`,
                value: fact.id
              })) || []
          )}
        />
        {/*<Select
        disabled={disabled}
        value={value.secondaryTeamStat}
        onChange={event => {
          // @ts-ignore
          return onChange({ ...value, secondaryTeamStat: event.target.value });
        }}
        label="Second Stat"
        // @ts-ignore
        options={[{
          value: '',
          label: 'None'
          // @ts-ignore
        }].concat(Object.entries(statKeyToNameMap).map(([value, label]) => ({
          value,
          label
        })))}
      />*/}
      </div>
    </div>
  );
};
