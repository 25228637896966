import gql from 'graphql-tag';

export const teamPlayerFragment = gql`
  fragment TeamPlayer on Player {
    id
    firstName
    lastName
    playsFor {
      ...PlaysFor
    }
  }
`;
