import { FormikHelpers } from 'formik';
import React, { useMemo, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { mixed, object, string } from 'yup';
import { ClubQuery, UpdateClubInput, UpdateClubMutation } from '../../../../api-types';
import { Button } from '../../../../components/Button';
import { Form } from '../../../../components/Form/Form';
import { Loader } from '../../../../components/loader/Loader';
import { PageContent } from '../../../../components/page/PageContent';
import { PageHeader } from '../../../../components/PageHeader';
import { PageMenu } from '../../../../components/PageMenu';
import { getGQLError } from '../../../../lib/apolloClient';
import { useErrorHandledMutation, useErrorHandledQuery } from '../../../../lib/apolloHooks';
import { updateClubMutation } from '../../../../lib/gql/mutations/updateClub';
import { clubQuery } from '../../../../lib/gql/queries/clubs';
import { Notification } from '../../../../lib/notificationContainer';
import { logoValidation } from '../../../../lib/util/imageValidation';
import { routes } from '../../../../router/Routes';
import { TeamsSideMenu } from '../../Team/TeamsSideMenu';
import { ClubForm } from './ClubForm';
import { ClubMenu } from './ClubMenu';
import './style.scss';

const validationSchema = object().shape({
  name: string().required().min(2).label('Name'),
  gfxFullName: string().required().min(4).max(35).label('Full name'),
  gfxName: string().required().min(4).max(19).label('Medium name'),
  abbreviation: string().required().min(2).max(4).label('Short name'),
  primaryBackgroundColor: string().required().label('Primary color'),
  primaryTextColor: string().required().label('Primary text color'),
  secondaryBackgroundColor: string(),
  secondaryTextColor: string(),
  logo: mixed().test('logo', 'Invalid logo', async function (value) {
    if (value) {
      const { logo } = await logoValidation([{ logo: value }]);
      if (logo) {
        return this.createError({
          path: this.path,
          message: logo
        });
      }
    }
    return true;
  })
});

export const ClubPage: React.FunctionComponent = () => {
  const [error, setError] = useState('');
  const { clubId: id } = useParams<{ clubId: string }>();
  const clubId = parseInt(id);

  const { data, loading } = useErrorHandledQuery<ClubQuery>(clubQuery, {
    variables: { id: clubId }
  });

  const [updateClub, { loading: updateLoading }] = useErrorHandledMutation<UpdateClubMutation>(updateClubMutation);
  const { addNotification } = Notification.useContainer();

  if (data && !data.club && !loading) return <Redirect to={routes.notFound()} />;

  const club = data?.club;

  const initialValues = useMemo(
    () =>
      club
        ? {
            id: club.id,
            name: club!.name || '',
            gfxName: club!.gfxName || '',
            gfxFullName: club!.gfxFullName || '',
            abbreviation: club!.abbreviation || '',
            primaryBackgroundColor: club!.primaryBackgroundColor || '',
            primaryTextColor: club!.primaryTextColor || '',
            secondaryBackgroundColor: club!.secondaryBackgroundColor || '',
            secondaryTextColor: club!.secondaryTextColor || '',
            logo: undefined,
            defaultVenueId: club!.defaultVenue?.id
          }
        : undefined,
    [club]
  );

  const update = async (updateClubInput: UpdateClubInput, formikHelpers: FormikHelpers<UpdateClubInput>) => {
    try {
      const inp = {
        ...updateClubInput,
        defaultVenueId: updateClubInput.defaultVenueId ? parseInt(`${updateClubInput.defaultVenueId}`) : null,
        secondaryBackgroundColor: updateClubInput.secondaryBackgroundColor || null,
        secondaryTextColor: updateClubInput.secondaryTextColor || null,
        id: club!.id
      } as UpdateClubInput;
      await updateClub({ variables: { updateClubInput: inp } });
      addNotification('Saved successfully', 'success');
      formikHelpers.resetForm();
    } catch (e) {
      setError(getGQLError(e).message);
    }
  };

  if (loading) return <Loader />;

  return (
    <>
      <PageMenu title="Clubs & Teams" component={TeamsSideMenu} />

      <PageContent className="clubPage" loading={loading}>
        <PageHeader
          title={club?.name || ''}
          preTitle={''}
          close={routes.assetTeams()}
          action={() => (
            <Button
              className="pageHeader__action"
              icon="plus"
              href={club ? routes.assetClubEditTeams({ clubId: club.id }) : ''}
              type="primary"
            >
              Add team to club
            </Button>
          )}
          editMenu={() => (club ? <ClubMenu club={club} /> : null)}
        />

        {club && (
          <Form
            onSubmit={update}
            error={error}
            className={`page-form club-page`}
            validationSchema={validationSchema}
            withSubmit
            initialValues={initialValues}
            useConfirm={true}
          >
            <ClubForm club={club} loading={loading} />
          </Form>
        )}
      </PageContent>
    </>
  );
};
