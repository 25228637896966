import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Form } from '../../components/Form/Form';
import { FormField } from '../../components/FormField';
import { FormStatus } from '../../components/FormStatus/FormStatus';
import { Title } from '../../components/Title/Title';
import { Auth, LoginValidationSchema } from '../../lib/authContainer';
import { routes } from '../../router/Routes';

export const LoginForm: React.FunctionComponent = ({ ...rest }) => {
  const { login, status, loginError } = Auth.useContainer();
  const [errorMessage, setError] = useState('');

  useEffect(() => {
    if (loginError) setError(loginError.graphQLErrors[0].message);
  }, [loginError]);

  return (
    <Form
      {...rest}
      validationSchema={LoginValidationSchema}
      onSubmit={login}
      initialValues={{ email: '', password: '' }}
    >
      <Title is2>Welcome back to Ligr</Title>
      {loginError && errorMessage && <FormStatus error={true}>{errorMessage}</FormStatus>}
      <FormField
        data-testid="email"
        name="email"
        type="text"
        label="Email address"
        tabIndex={1}
        autoFocus
        autoComplete="username"
      />
      <FormField
        name="password"
        type="password"
        label="Password"
        data-testid="password"
        tabIndex={2}
        autoComplete="current-password"
        labelComponent={props => (
          <span className="password">
            <label {...props} className="label">
              {props.children}
            </label>
            <Link to={routes.forgotPassword()}>Forgot your password?</Link>
          </span>
        )}
      />
      <Button data-testid="submit" isLoading={status === 'loggingIn'}>
        Sign in{' '}
      </Button>
    </Form>
  );
};
