import { Icon } from '@ligr/shared';
import JSZip from 'jszip';
import React from 'react';
import { useHistory } from 'react-router';
import { ConfirmWindow } from '../../../components/ConfirmWindow';
import { ConfirmWindowAdSetContent } from '../../../components/ConfirmWindow/Content/AdSetContent';
import { ContextMenu } from '../../../components/ContextMenu';
import { Notification } from '../../../lib/notificationContainer';
import { useArchiveAdSet } from '../../../lib/useArchiveAdSet';
import { routes } from '../../../router/Routes';

type AdSet = {
  name: string;
  id: number;
  countAdAllocations: number;
  adFiles: {
    size: string;
    id: number;
    file?: {
      url: string;
    };
  }[];
};

interface AdSetMenuProps {
  adSet: AdSet;
}

export const getImageURLFormat = (url: string) => {
  // @ts-ignore
  return url.split(/\#|\?/)[0].split('.').pop().trim();
};

export const AdSetMenu: React.FunctionComponent<AdSetMenuProps> = ({ adSet }) => {
  const { archiveAdSet } = useArchiveAdSet();
  const { addNotification } = Notification.useContainer();
  const history = useHistory<{ referrer?: string } | undefined>();
  const { adFiles } = adSet;

  const archiveAdSetnFn = async (adSetId: number) => {
    await archiveAdSet(adSetId);
    addNotification('Ad set archived successfully.', 'success');
    history.push(routes.adSets());
  };

  const handleDownload = () => {
    const zip = new JSZip();

    Promise.all(
      Array.from(adFiles).map(async adFile => {
        const res = await fetch(adFile.file!.url);
        const format = getImageURLFormat(adFile.file!.url);
        zip.file(`${adFile.size}.${format}`, res.blob());
      })
    ).then(() => {
      zip.generateAsync({ type: 'blob' }).then(blob => {
        saveAs(blob, `${adSet.name}.zip`);
      });
    });
  };

  return (
    <ContextMenu
      place="bottom"
      clickable={true}
      className="pageHeader__menu asMenu"
      renderMenu={() => {
        return (
          <ul>
            <li>
              <ConfirmWindow
                action={() => archiveAdSetnFn(adSet.id)}
                title={`Archive ad set`}
                content={<ConfirmWindowAdSetContent adSet={adSet} />}
              >
                <Icon icon="close" />
                <span>Archive ad set</span>
              </ConfirmWindow>
            </li>
            <li onClick={handleDownload}>
              <Icon icon="download" />
              <span>Download all</span>
            </li>
          </ul>
        );
      }}
    >
      <Icon icon="dropdown" color="grey-600" />
    </ContextMenu>
  );
};
