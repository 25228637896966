import capitalize from 'capitalize';
import pluralize from 'pluralize';
import React from 'react';

type Player = {
  firstName: string;
  lastName: string;
  countTeams: number;
};

export const ConfirmWindowPlayerContent: React.FunctionComponent<{ player: Player }> = ({ player }) => {
  const withNotification = player.countTeams > 0;
  const notifications = [];

  if (player.countTeams > 0) {
    notifications.push(
      <li>
        Remove this player from {player.countTeams} {pluralize('teams', player.countTeams)}
      </li>
    );
  }

  return (
    <>
      Are you sure you want to archive {capitalize(player.firstName)} {capitalize(player.lastName)}?
      {withNotification && (
        <div className={`notification notification--error confirm-window__notification`}>
          This action will also:
          <ul>{notifications.map(item => item)}</ul>
        </div>
      )}
    </>
  );
};
