import { CompetitionFragment } from '../../api-types';
import { Icon, IconType, Image } from '@ligr/shared';
import classnames from 'classnames';
import React, { HTMLProps } from 'react';
import { Notification } from '../../lib/notificationContainer';
import { useArchiveTeam } from '../../lib/useArchiveTeam';
import { routes } from '../../router/Routes';
import { Button } from '../Button';
import { Card } from '../Card/Card';
import { ConfirmWindow } from '../ConfirmWindow';
import { ConfirmWindowTeamContent } from '../ConfirmWindow/Content/TeamContent';
import { Title } from '../Title/Title';
import { ToolTip } from '../ToolTipIcon';
import './style.scss';

interface CardTeamProps extends Partial<HTMLProps<HTMLDivElement>> {
  team: {
    countMatches: number;
    countPlayers: number;
    archived: boolean;
    name: string;
    id: number;
    logo?: {
      url: string;
    } | null;
  };
  to?: string;
  competitions?: { name: string; id: number; logo?: { url: string } | null }[];
  fn?: () => void;
  icon?: IconType;
}

export const CardTeam: React.FunctionComponent<CardTeamProps> = ({ team, to, competitions, fn, icon, ...rest }) => {
  const { restoreTeam } = useArchiveTeam();
  const { addNotification } = Notification.useContainer();

  return (
    <Card
      to={team.archived ? '' : to || routes.assetTeam({ teamId: team.id })}
      className={classnames('cardTeam', {
        'card--archived': team.archived
      })}
      title={team.name}
      {...rest}
    >
      {team.archived && (
        <div className="card--archived__overlay">
          <Button
            onClick={() => {
              restoreTeam(team.id);
              addNotification('Team restored successfully.', 'success');
            }}
          >
            Restore
          </Button>
        </div>
      )}

      {!team.archived && fn && icon && (
        <ConfirmWindow action={fn} title={`Archive team`} content={<ConfirmWindowTeamContent team={team} />}>
          <Icon icon={icon} />
        </ConfirmWindow>
      )}

      <Image url={team.logo?.url} className="cardTeam__image" placeholder="team" />

      <Title is3 className="cardTeam__title">
        {team.name}
      </Title>

      {competitions && (
        <div className="cardTeam__competitions">
          {competitions.length > 0 && (
            <Image
              url={competitions[0].logo?.url}
              className="cardTeam__competitions__image"
              useContrastingBackground
              placeholder="competition"
            />
          )}
          {competitions.length === 2 && (
            <Image
              url={competitions[1].logo?.url}
              className="cardTeam__competitions__image"
              useContrastingBackground
              placeholder="competition"
            />
          )}
          {competitions.length > 2 && (
            <ToolTip reference={() => <div className="cardTeam__competitions__number">+{competitions.length - 1}</div>}>
              <ul className="cardTeam__competitions__competitionList">
                {competitions.slice(1).map(c => (
                  <li key={`${team.id}.${c.id}`}>
                    <Image
                      className="cardTeam__competitions__competitionList__image"
                      url={c.logo?.url}
                      placeholder="competition"
                    />
                    {c.name}
                  </li>
                ))}
              </ul>
            </ToolTip>
          )}
        </div>
      )}
    </Card>
  );
};
