import { matchSummaryQuery } from '../../lib/gql/queries/summary';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Loader } from '../../components/loader/Loader';
import { MatchTitle } from '../../components/MatchTitle';
import { Page } from '../../components/page/Page';
import { PageContent } from '../../components/page/PageContent';
import { useErrorHandledLazyQuery, useErrorHandledQuery } from '../../lib/apolloHooks';

import { useThemeLoad } from '../../lib/useThemeLoad';

import { overlayQuery } from './gql';
import './style.scss';
import { MatchSummaryQuery, OverlayWithFullMatchQuery } from '../../api-types';

import { Header } from './Header';
import { ControlGrid } from './ControlGrid';

export const OverlayControl: React.FunctionComponent<any> = () => {
  const { overlayId } = useParams();
  const [openGraphicPreview, setOpenGraphicPreview] = useState('');

  const overlayQueryResult = useErrorHandledQuery<OverlayWithFullMatchQuery>(overlayQuery, {
    variables: { id: parseInt(overlayId!) },
    fetchPolicy: 'cache-first'
  });

  const [fetch, matchSummaryQueryResult] = useErrorHandledLazyQuery<MatchSummaryQuery>(matchSummaryQuery);

  const themeObj = useThemeLoad(overlayQueryResult.data?.overlay?.match?.competition?.theme);

  useEffect(() => {
    overlayQueryResult.data && fetch({ variables: { matchId: overlayQueryResult.data.overlay.matchId } });
  }, [overlayQueryResult.data?.overlay.matchId]);

  if (overlayQueryResult.loading || matchSummaryQueryResult.loading || !matchSummaryQueryResult.called) {
    return <Loader />;
  }

  const overlay = overlayQueryResult.data?.overlay!;
  const match = overlayQueryResult.data?.overlay.match!;
  const matchSummary = matchSummaryQueryResult.data?.matchSummary!;

  return (
    <Page type="Overlay-Control">
      <PageContent className="overlayControl">
        <Header overlay={overlay} match={match} />
        <div className="overlayControl__matchSection">
          <MatchTitle className="matchTitle__overlayControl" match={match} matchSummary={matchSummary} />
          <ControlGrid
            summary={matchSummary}
            overlay={overlay}
            match={match}
            setOpenGraphicPreview={setOpenGraphicPreview}
            openGraphicPreview={openGraphicPreview}
            sportName={match.sportName}
            adSets={[
              ...(match.competition.adAllocation?.adSets || []),
              ...(match.homeTeam.adAllocation?.adSets || []),
              ...(match.awayTeam.adAllocation?.adSets || [])
            ]}
            manualGraphicState={overlay.manualGraphicState}
            theme={themeObj}
          />
        </div>
      </PageContent>
    </Page>
  );
};
