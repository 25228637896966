import { Icon } from '@ligr/shared';
import classnames from 'classnames';
import React, { useMemo, useState } from 'react';
import { SearchField } from '../SearchField/SearchField';
import { ActiveFilter } from './ActiveFilter';
import { AllFilters } from './AllFilters';
import { ResetFilters } from './ResetFilters';
import './style.scss';

export type FilterOptions =
  | 'sports'
  | 'competitions'
  | 'teams'
  | 'archive'
  | 'allocation'
  | 'club'
  | 'age'
  | 'gender'
  | 'grades'
  | 'status'
  | 'startAt'
  | 'endAt';

interface FiltersProps {
  searchable?: string;
  filters: FilterOptions[];
}

export const Filters: React.FunctionComponent<FiltersProps> = ({ searchable, filters }) => {
  const [opened, toggleFilter] = useState(false);
  const [activeFilters, setActiveFilters] = useState<{ [key: string]: string }>({});

  const setActiveFilter = (name: FilterOptions, value: string | undefined) => {
    const obj = { ...activeFilters };
    if (value === '' || value === undefined) {
      delete obj[name];
    } else {
      obj[name] = value;
    }
    setActiveFilters(obj);
  };

  const hasActiveFilters = useMemo(() => {
    return Object.values(activeFilters).filter(v => v !== undefined || v !== '').length > 0;
  }, [activeFilters]);

  return (
    <div className={classnames('filters__wrapper', { 'filters__wrapper--open': opened })}>
      {opened && <div className="filters__backdrop" onClick={() => toggleFilter(false)}></div>}
      {searchable && <SearchField placeholder={`Search ${searchable}`} urlSearch />}
      <div className={classnames('filters__wrapper__filters')}>
        <div className="filters__wrapper__icon">
          <Icon icon="filter" onClick={() => toggleFilter(!opened)} />
          {!opened && hasActiveFilters && (
            <div className="filters__wrapper__active-filter__wrapper">
              {Object.entries(activeFilters).map(([property, value], i) => (
                <ActiveFilter
                  setActiveFilter={setActiveFilter}
                  property={property as FilterOptions}
                  value={value}
                  key={`active-filter-${i}`}
                />
              ))}
            </div>
          )}
        </div>
        <div
          className={classnames('filters__filterGrid', {
            'filters__filterGrid--open': opened,
            'filters__filterGrid--large': filters.length > 2
          })}
        >
          <AllFilters opened={opened} filters={filters} setActiveFilter={setActiveFilter} />
          <ResetFilters
            toggleFilter={toggleFilter}
            opened={opened}
            active={hasActiveFilters}
            setActiveFilters={setActiveFilters}
          />
        </div>
      </div>
    </div>
  );
};
