import { Overlay } from '../../../../../api-types';
import { TriggerProps } from '@ligr/themes/lib/rugbyLeague/themes/default/Templates/Intro/types';
import React from 'react';
import { Checkbox } from '../../../../Checkbox/Checkbox';
import { InputField } from '../../../../InputField';
import './style.scss';

interface IntroCustomProps {
  value?: Partial<TriggerProps>;
  onChange: (update: Partial<TriggerProps>) => any;
  overlay: Overlay;
  disabled?: boolean;
}

// FULLY CONTROLLED ONLY
export const Intro: React.FunctionComponent<IntroCustomProps> = ({ disabled, onChange, value = {} }) => {
  return (
    <div className="intro">
      <div className="intro__customText">
        <InputField
          placeholder="Enter Custom Heading Text"
          disabled={disabled}
          value={value.customHeading}
          onChange={event => {
            // @ts-ignore
            onChange({ ...value, customHeading: event.target.value });
            // @ts-ignore
          }}
          label="Custom Message"
        />
        <Checkbox
          checked={value.useCustomHeading}
          onChange={event => {
            // @ts-ignore
            onChange({ ...value, useCustomHeading: event.target.checked });
          }}
        />
      </div>
      <div className="intro__customText">
        <InputField
          placeholder="Enter Custom Bottom Left Text"
          disabled={disabled}
          value={value.customMessage}
          onChange={event => {
            // @ts-ignore
            onChange({ ...value, customMessage: event.target.value });
            // @ts-ignore
          }}
          label="Custom Message"
        />
        <Checkbox
          checked={value.useCustomMessage}
          onChange={event => {
            // @ts-ignore
            onChange({ ...value, useCustomMessage: event.target.checked });
          }}
        />
      </div>
    </div>
  );
};
