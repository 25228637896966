import { AdFileSize, adFileSizes, Size, sizeDisplayOrder } from '@ligr/api-v2';
import { FormikContextType } from 'formik';
import React, { useState } from 'react';
import { Form } from '../../../../components/Form/Form';
import { useErrorHandledMutation } from '../../../../lib/apolloHooks';
import { createAdFileMutation } from '../../../../lib/gql/mutations/createFile';
import { updateAdFileMutation } from '../../../../lib/gql/mutations/updateFile';
import { Notification } from '../../../../lib/notificationContainer';
import { getImageProps, validateImage, Validations } from '../../../../lib/util/imageValidation';
import { adSetsQuery } from '../../AdSets';
import { AdSetImageFormContent } from './AdSetImageFormContent';
import './style.scss';
import { UpdateAdFileMutation, CreateAdFileMutation } from '../../../../api-types';

type AdSet = {
  name: string;
  id: number;
  countAdAllocations: number;
  adFiles: {
    size: string;
    id: number;
    file?: {
      url: string;
    };
  }[];
};

export interface AdSetImagesPageProps {
  adSet: AdSet;
}

const adData = Object.entries(adFileSizes)
  .map(([size, { width, height }]: [string, Size]) => ({
    size,
    sizeLabel: `${width}x${height}`
  }))
  .sort(
    ({ size: sizeA }, { size: sizeB }) =>
      sizeDisplayOrder.indexOf(sizeA as AdFileSize) - sizeDisplayOrder.indexOf(sizeB as AdFileSize)
  );

export const AdSetImagesPage: React.FunctionComponent<AdSetImagesPageProps> = ({ adSet }) => {
  const [form, setForm] = useState<FormikContextType<any> | null>(null);

  const { addNotification } = Notification.useContainer();

  const [updateAdFile] = useErrorHandledMutation<UpdateAdFileMutation>(updateAdFileMutation);
  const [createAdFile] = useErrorHandledMutation<CreateAdFileMutation>(createAdFileMutation);

  React.useMemo(() => {
    form?.resetForm();
  }, [adSet.id]);

  const handleOnChange = async (_: any, { values }: FormikContextType<any>) => {
    const size = Object.keys(values[adSet.id])[0];
    const file = Object.values(values[adSet.id])[0] as File;

    const validations: Validations = {
      adSize: size as AdFileSize,
      validations: ['height', 'width', 'format', 'size']
    };

    const errors = validateImage(await getImageProps(file), validations);

    if (Object.values(errors).length > 0) {
      addNotification('There has been a problem with your upload', 'error');
      return form?.setFieldError(`${adSet.id}.${size}`, Object.values((errors as unknown) as string)[0]);
    }

    const ad = adSet.adFiles.find(({ size: adsize }) => adsize === size);

    if (ad) {
      try {
        await updateAdFile({
          variables: {
            updateAdFileInput: {
              id: ad.id,
              image: file
            }
          }
        });
        form?.resetForm();
        addNotification('The ad has been updated correctly', 'success');
      } catch (e) {
        addNotification('There has been a problem with your upload', 'error');
        console.log(e);
      }
    } else {
      try {
        const res = await createAdFile({
          variables: {
            createAdFileInput: {
              adSetId: adSet.id,
              size,
              image: file
            }
          },
          update: (cache: any, { data }: any) => {
            const adSetsCache = cache.readQuery({
              query: adSetsQuery
            });
            if (adSetsCache && data) {
              const adSetIndex = adSetsCache.adSets.findIndex(({ id }: AdSet) => id === data.createAdFile.adSetId);
              const adSet = adSetsCache.adSets[adSetIndex];
              const newAdFiles = [...adSet.adFiles, data.createAdFile];
              const length = adSetsCache.adSets.length;
              cache.writeQuery({
                query: adSetsQuery,
                data: {
                  adSets: [
                    ...adSetsCache.adSets.slice(0, adSetIndex),
                    { ...adSet, adFiles: newAdFiles },
                    ...adSetsCache.adSets.slice(adSetIndex + 1, length)
                  ]
                }
              });
            }
          }
        });
        form?.resetForm();
        addNotification('The ad has been uploaded correctly', 'success');
      } catch (e) {
        addNotification('There has been a problem with your upload', 'error');
        console.log(e);
      }
    }
  };

  return (
    <Form onChange={handleOnChange} setForm={setForm} className="adSetImagesContainer">
      {adData.map((props, idx) => (
        <AdSetImageFormContent key={idx} adSet={adSet} form={form} {...props} />
      ))}
    </Form>
  );
};
