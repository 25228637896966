import { updateQueryUrl } from '@ligr/shared';
import moment from 'moment';
import queryString from 'query-string';
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useHistory } from 'react-router';
import { FilterOptions } from '.';
import { useMatchLoad } from '../../lib/useMatchLoad';
import { matchesSubscription } from '../../pages/Matches/matches.qql';

interface DateFilterProps {
  setActiveFilter: (name: FilterOptions, value: string) => void;
}

export const dateFormat = 'MMM DD, YYYY';

export const DateFilter: React.FunctionComponent<DateFilterProps> = ({ setActiveFilter }) => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const { setStartDate, setEndDate } = useMatchLoad({ matchesSubscription });
  const queryStrObject = queryString.parse(history.location.search);

  const formatDate = (date: Date, format: string | string[], locale: string) => {
    return moment(date).format(format as string);
  };

  return (
    <div className="filters__select__wrapper filters__select__wrapper--date">
      <label className="filters__select__wrapper__label" htmlFor="date">
        Date
      </label>
      <div className="filters__select__wrapper-date--from">
        <DayPickerInput
          placeholder="Start Date"
          value={queryStrObject.startAt ? moment(queryStrObject.startAt, 'DD-MM-YYYY').toDate() : ''}
          format={dateFormat}
          formatDate={formatDate}
          onDayChange={(date: Date) => {
            setActiveFilter('startAt', date !== undefined ? `From ${moment(date).format(dateFormat)}` : '');
            updateQueryUrl(history, { startAt: moment(date).format('DD-MM-YYYY') });
            setStartDate(moment(date).format('MMM DD, YYYY'));
          }}
        />
      </div>
      <div className="filters__select__wrapper-date--to">
        <DayPickerInput
          placeholder="End Date"
          clickUnselectsDay
          value={queryStrObject.endAt ? moment(queryStrObject.endAt, 'DD-MM-YYYY').toDate() : ''}
          format={dateFormat}
          formatDate={formatDate}
          onDayChange={(date: Date) => {
            setActiveFilter('endAt', date !== undefined ? `To ${moment(date).format(dateFormat)}` : '');
            updateQueryUrl(history, { endAt: moment(date).format('DD-MM-YYYY') });
            setEndDate(moment(date).format('MMM DD, YYYY'));
          }}
        />
      </div>
    </div>
  );
};
