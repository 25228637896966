import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useEffect } from 'react';
import { createContainer } from 'unstated-next';
import { Auth } from './authContainer';
import { MeQuery } from '../api-types';
import { useErrorHandledLazyQuery } from './apolloHooks';

export const meQuery = gql`
  query me {
    me {
      id
      admin
      firstName
      lastName
      email
      organizations {
        id
        name
      }
      photo {
        id
        url
      }
    }
  }
`;

const useMe = () => {
  const { status } = Auth.useContainer();
  const [getMe, { data, error, loading, called, refetch }] = useErrorHandledLazyQuery<MeQuery>(meQuery, {});

  useEffect(() => {
    if (['loggedIn'].includes(status)) getMe();
    if (['impersonating', 'unimpersonating'].includes(status)) refetch();
  }, [status]);

  return {
    me: data ? data.me : undefined,
    loading,
    called,
    refetch,
    error
  };
};

export const Me = createContainer(useMe);
