import React from 'react';

interface FinishedCellProps {
  score: string;
}

export const FinishedCell: React.FunctionComponent<FinishedCellProps> = ({ score }) => {
  return (
    <div className="dateCell__message-finished">
      <div className="dateCell__message-finished__label">Finished</div>
      <div className="dateCell__message-in-progress__divider">•</div>
      <div className="dateCell__message-finished__score">{score}</div>
    </div>
  );
};
