import React from 'react';
import { FormProps } from '../../../components/Form/Form.types';
import { FormField } from '../../../components/FormField';

export type OrganiationFormValues = {
  name: string;
};

export const OrganizationForm: React.FunctionComponent<FormProps<OrganiationFormValues>> = () => {
  return (
    <>
      <FormField name="name" type="text" label="Organization name" tabIndex={1} autoFocus />
    </>
  );
};
