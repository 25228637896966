import capitalize from 'capitalize';
import { useFormikContext } from 'formik';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { mixed, object, string } from 'yup';
import {
  Age,
  Competition,
  CreateTeamInput,
  CreateTeamMutation,
  Gender,
  Grade,
  GradesQuery,
  SportsQuery,
  TeamsWithCompQuery,
  VenuesQuery
} from '../../../../api-types';
import { Button } from '../../../../components/Button';
import { Form } from '../../../../components/Form/Form';
import { FormField } from '../../../../components/FormField';
import { GroupErrorRow } from '../../../../components/GroupErrorRow';
import { Loader } from '../../../../components/loader/Loader';
import { PageHeader } from '../../../../components/PageHeader';
import { Title } from '../../../../components/Title/Title';
import { getGQLError } from '../../../../lib/apolloClient';
import { useErrorHandledMutation, useErrorHandledQuery } from '../../../../lib/apolloHooks';
import { createTeamMutation } from '../../../../lib/gql/mutations/createTeam';
import { competitionsQuery } from '../../../../lib/gql/queries/competitions';
import { gradesQuery } from '../../../../lib/gql/queries/grades';
import { sportsQuery } from '../../../../lib/gql/queries/sports';
import { teamsWithCompQuery } from '../../../../lib/gql/queries/teams';
import { venuesQuery } from '../../../../lib/gql/queries/venues';
import { Notification } from '../../../../lib/notificationContainer';
import { logoValidation } from '../../../../lib/util/imageValidation';
import { routes } from '../../../../router/Routes';
import './style.scss';

export interface CreateTeamValues {
  competitions: { value: number; label?: string; deleted?: boolean }[];
  sportId: string;
  name: string;
  gfxName: string;
  gfxFullName?: string;
  abbreviation: string;
  primaryBackgroundColor: string;
  primaryTextColor: string;
  secondaryBackgroundColor?: string;
  secondaryTextColor?: string;
  logo?: File;
  defaultVenueId: string;
}

type BasicEntity = {
  name: string;
  id: number;
};

interface BasicComp extends BasicEntity {
  sportId: number;
}

interface NewTeamPageFormProps {
  competitions: BasicComp[];
  sports: BasicEntity[];
  venues: BasicEntity[];
  defaultCompetition?: BasicEntity;
  grades?: Grade[];
  loading: boolean;
}

const validationSchema = object().shape({
  sportId: string().required().label('Sport').nullable(),
  name: string().required().min(4).max(60).label('Name'),
  gfxFullName: string().required().min(4).max(19).label('Full name'),
  gfxName: string().required().min(4).max(19).label('Medium name'),
  abbreviation: string().required().min(2).max(4).label('Short name'),
  primaryBackgroundColor: string().required().label('Primary color'),
  primaryTextColor: string().required().label('Primary text color'),
  logo: mixed().test('logo', 'Invalid logo', async function (value) {
    if (value) {
      const { logo } = await logoValidation([{ logo: value }]);
      if (logo) {
        return this.createError({
          path: this.path,
          message: logo
        });
      }
    }
    return true;
  })
});

const NewTeamPageFormContent: React.FunctionComponent<NewTeamPageFormProps> = ({
  competitions,
  sports,
  venues,
  grades,
  defaultCompetition,
  loading
}) => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const form = useFormikContext<CreateTeamValues>();

  useEffect(() => {
    form.setFieldValue('competitions', []);
  }, [form.values.sportId]);

  return (
    <>
      <div className="create-page-team__form">
        <section>
          <Title is3>Team settings</Title>
          <FormField
            disabled={!!defaultCompetition}
            name="sportId"
            placeholder="Select sport"
            options={sports.map(({ id, name }) => ({
              label: capitalize(name),
              value: id
            }))}
            type="select"
            label="Sport"
            value={form.values.sportId}
          />
          <FormField
            name="competitions"
            disabled={!form.values.sportId}
            placeholder="Select competitions"
            options={
              form.values.sportId && competitions
                ? competitions
                    ?.filter(({ sportId }) => !form.values.sportId || sportId === parseInt(`${form.values.sportId}`))
                    .map(({ id, name }) => ({ label: name, value: id }))
                : []
            }
            noOptionsMessage={() => 'No remaining competitions'}
            type="multiselect"
            label="Competitions"
          />
          <FormField
            name="gradeId"
            type="select"
            label="Division"
            options={grades?.map(grade => ({ label: grade.name, value: grade.id })) || []}
          />
          <FormField
            name="age"
            type="select"
            label="Age Group"
            options={Object.values(Age).map(age => ({ label: age, value: age }))}
          />
          <FormField
            name="gender"
            type="select"
            label="Gender"
            options={Object.values(Gender).map(gender => ({ label: gender, value: gender }))}
          />
        </section>
        <section>
          <Title is3>Team name & Venue</Title>
          <FormField name="name" type="text" label="Team name" />
          <FormField
            name="defaultVenueId"
            type="select"
            placeholder={'Select Venue'}
            options={venues.map(v => ({ label: v.name, value: v.id }))}
            label="Default venue"
          />
          <Title is3>GFX Names</Title>
          <FormField name="gfxFullName" type="text" label="Full name" />
          <FormField name="gfxName" type="text" label="Medium name" />
          <FormField name="abbreviation" type="text" label="Short name" />
        </section>
        <section>
          <Title is3>Team Logo</Title>
          <FormField name="logo" type="image" editable />
          <p className="pro-tip">
            Pro Tip: Upload a logo with a transparent background, and no padding around the logo.
          </p>
          <Title is3>Team colors</Title>
          <GroupErrorRow form={form} errorsName={['primaryBackgroundColor', 'primaryTextColor']}>
            <FormField name="primaryBackgroundColor" type="color" label="Primary color" colSpan={1} groupError />
            <FormField name="primaryTextColor" type="color" label="Primary text color" colSpan={1} groupError />
          </GroupErrorRow>

          <GroupErrorRow form={form} errorsName={['secondaryBackgroundColor', 'secondaryTextColor']}>
            <FormField name="secondaryBackgroundColor" type="color" label="Secondary color" colSpan={1} groupError />
            <FormField name="secondaryTextColor" type="color" label="Secondary text color" colSpan={1} groupError />
          </GroupErrorRow>
        </section>
      </div>
      <section className="pageFooter">
        <Button
          onClick={event => {
            event.preventDefault();
            history.push(routes.assetTeams());
          }}
          isLoading={loading}
          type="plain"
        >
          cancel
        </Button>
        <Button
          onClick={event => {
            event.preventDefault();
            form.submitForm();
          }}
          isLoading={loading}
          type="primary"
        >
          create team
        </Button>
      </section>
    </>
  );
};

export const NewTeamPage: React.FunctionComponent = () => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const { competitionId } = queryString.parse(history.location.search, { parseNumbers: true });
  const [error, setError] = useState('');
  const { addNotification } = Notification.useContainer();

  const { data: competitionsData, loading: competitionLoading } = useErrorHandledQuery<{ competitions: Competition[] }>(
    competitionsQuery
  );
  const { data: sportsData, loading: sportLoading } = useErrorHandledQuery<SportsQuery>(sportsQuery);
  const { data: venuesData, loading: venueLoading } = useErrorHandledQuery<VenuesQuery>(venuesQuery);
  const { data: gradesDate, loading: gradesLoading } = useErrorHandledQuery<GradesQuery>(gradesQuery);
  const [createTeam, { loading }] = useErrorHandledMutation<CreateTeamMutation>(createTeamMutation);

  const globalLoading = useMemo(() => competitionLoading || sportLoading || venueLoading || gradesLoading, [
    competitionLoading,
    sportLoading,
    venueLoading,
    gradesLoading
  ]);

  const grades = gradesDate?.grades;
  const competitions = competitionsData?.competitions;
  const sports = sportsData?.sports;
  const venues = venuesData?.venues;

  const defaultCompetition = useMemo(() => {
    if (competitionId && competitions) {
      return competitions.find(({ id }) => id === competitionId!)!;
    }
    return undefined;
  }, [competitionId, competitions]);

  const newTeam: CreateTeamValues = {
    competitions: competitionId
      ? [{ value: competitionId as number, label: defaultCompetition && defaultCompetition.name }]
      : [],
    name: '',
    gfxName: '',
    gfxFullName: '',
    abbreviation: '',
    logo: undefined,
    primaryBackgroundColor: '',
    primaryTextColor: '',
    secondaryBackgroundColor: '',
    secondaryTextColor: '',
    sportId: defaultCompetition ? defaultCompetition.sport.id.toString() : '',
    defaultVenueId: ''
  };

  const create = async (createTeamInput: CreateTeamValues) => {
    try {
      const input = {
        ...createTeamInput,
        defaultVenueId: createTeamInput.defaultVenueId ? parseInt(createTeamInput.defaultVenueId) : null,
        competitions: createTeamInput.competitions.map(({ value }) => ({ competitionId: value })),
        sportId: parseInt(`${createTeamInput.sportId}`)
      } as CreateTeamInput;

      const res = await createTeam({
        variables: { createTeamInput: input },
        update: (cache, { data }) => {
          // TODO - update with the club/team query
          const teamsCache = cache.readQuery<TeamsWithCompQuery>({ query: teamsWithCompQuery });
          if (teamsCache && data) {
            cache.writeQuery({
              query: teamsWithCompQuery,
              data: { teams: [...teamsCache.teams, data.createTeam] }
            });
          }
        }
      });

      history.push(routes.assetTeam({ teamId: res.data!.createTeam.id }));
      addNotification('Team successfully created', 'success');
    } catch (e) {
      setError(getGQLError(e).message);
    }
  };

  return (
    <div className="create-page create-page-team">
      <PageHeader preTitle="create" title="new team" close={routes.assetTeams()} />
      {globalLoading ? (
        <Loader />
      ) : (
        <Form
          initialValues={newTeam}
          validationSchema={validationSchema}
          className="page-form"
          onSubmit={create}
          withSubmit
        >
          <NewTeamPageFormContent
            competitions={competitions || []}
            sports={sports || []}
            venues={venues || []}
            defaultCompetition={defaultCompetition}
            grades={grades}
            loading={loading}
          />
        </Form>
      )}
    </div>
  );
};
