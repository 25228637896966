import { playsForFragment } from '@ligr/shared';
import gql from 'graphql-tag';

export const updatePlayerMutation = gql`
  mutation updatePlayer($updatePlayerInput: UpdatePlayerInput!) {
    updatePlayer(updatePlayerInput: $updatePlayerInput) {
      id
      firstName
      lastName
      gfxFirstName
      gfxLastName
      playsFor {
        ...PlaysFor
      }
    }
  }
  ${playsForFragment}
`;
