import { Icon } from '@ligr/shared';
import React, { useEffect, useMemo, useState } from 'react';
import { DragDropContext, DraggableLocation, Droppable, DropResult } from 'react-beautiful-dnd';
import { useErrorHandledMutation } from '../../../lib/apolloHooks';
import { getTeamPlayer } from '../../../lib/util/getTeamPlayer';
import { MissingEntitiesComponent } from '../../MissingEntitiesComponent/MissingEntitiesComponent';
import { Modal } from '../../modal/Modal';
import { PlayersByPosition } from '../../PlayersByPosition';
import { SearchField } from '../../SearchField/SearchField';
import { Title } from '../../Title/Title';
import { StartingLineupItem } from '../StartingLineupItem';
import './style.scss';
import { updatePlayerMutation } from '../../../lib/gql/mutations/updatePlayer';
import { UpdatePlayerMutation } from '../../../api-types';

export type Player = {
  id: number;
  firstName: string;
  lastName: string;
  playsFor?:
    | {
        defaultStarter: boolean;
        team: Team;
        photo?: {
          id: number;
          url: string;
        } | null;
        position?: string | null;
        number?: string | null;
      }[]
    | null;
};

type Team = {
  id: number;
};

export interface EditStartingLineupProps {
  players: Player[];
  team: Team;
  onClose?: () => void;
}

/**
 * Moves an item from one list to another list.
 */
const move = (
  source: string,
  destination: string,
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation
) => {
  const sourceClone = Array.from(source);
  let destClone = Array.from(destination);

  const result: any = {};
  let removed;
  if (droppableSource.droppableId === droppableDestination.droppableId) {
    [removed] = sourceClone.splice(droppableSource.index, 1);
    sourceClone.splice(droppableDestination.index, 0, removed);
    destClone = sourceClone;
  } else {
    [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
  }

  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;
  result.player = removed;

  return result;
};

export const EditStartingLineup: React.FunctionComponent<EditStartingLineupProps> = ({ players, team, ...props }) => {
  const [searchValue, setSearchValue] = useState('');
  const [state, setState] = useState<{ substitutes: Player[]; lineup: Player[] }>({
    substitutes: [],
    lineup: []
  });
  const [dragStartClass, setDragStartClass] = useState('');

  const [updatePlayer] = useErrorHandledMutation<UpdatePlayerMutation>(updatePlayerMutation);

  useEffect(() => {
    const el = document.querySelector('div.modal');
    el && el.setAttribute('id', 'edit-lineup');
  }, []);

  const playersSearch = useMemo(() => {
    if (!searchValue) {
      return players;
    }
    return players
      ? players.filter((p: Player) => {
          return (
            p.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
            p.lastName.toLowerCase().includes(searchValue.toLowerCase()) ||
            (getTeamPlayer(p, team)!.position
              ? getTeamPlayer(p, team)!
                  .position!.toLowerCase()
                  .includes(searchValue.toLowerCase())
              : false) ||
            (getTeamPlayer(p, team)!.number
              ? getTeamPlayer(p, team)!
                  .number!.toString()
                  .includes(searchValue)
              : false)
          );
        })
      : [];
  }, [searchValue, players]);

  useEffect(() => {
    setState({
      substitutes: playersSearch.filter(p => !getTeamPlayer(p, team)!.defaultStarter),
      lineup: players.filter(p => getTeamPlayer(p, team)!.defaultStarter)
    });
  }, [players, playersSearch]);

  const substitutes = state.substitutes.map((p, idx) => {
    return { player: p, index: idx };
  });

  const onDragStart = () => {
    setDragStartClass('dragging');
  };

  const onDragEnd = async (result: DropResult) => {
    setDragStartClass('');

    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const res: { substitutes: Player[]; lineup: Player[]; player: Player } = move(
      // @ts-ignore
      state[source.droppableId],
      // @ts-ignore
      state[destination.droppableId],
      source,
      destination
    );

    const defaultStarter = destination.droppableId === 'lineup';
    const oldSubstitutes = state.substitutes;
    const oldLineup = state.lineup;

    if (destination.droppableId === source.droppableId) {
      if (res.lineup) {
        setState({
          substitutes: oldSubstitutes,
          lineup: res.lineup
        });
      } else {
        setState({
          substitutes: res.substitutes,
          lineup: oldLineup
        });
      }
      return;
    }
    setState({
      substitutes: res.substitutes,
      lineup: res.lineup
    });
    try {
      await updatePlayer({
        variables: {
          updatePlayerInput: {
            id: res.player.id,
            teams: [
              {
                teamId: team.id,
                defaultStarter
              }
            ]
          }
        }
      });
    } catch (e) {
      setState({
        substitutes: oldSubstitutes,
        lineup: oldLineup
      });
    }
  };

  return (
    <Modal {...props} title="Edit Starting Lineup" footerButtons={[{ text: 'Done', onClick: props.onClose }]}>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <div className="editLineup">
          <div className="editLineup__allPlayers">
            <Title is3>Set the team's starting lineup</Title>
            <SearchField
              placeholder="Search players by name, position or number"
              onChange={(value: any) => {
                setSearchValue(value);
              }}
              value={searchValue}
            />
            <Droppable droppableId="substitutes">
              {provided => {
                const startLineUpSide = document.getElementsByClassName('editLineup__defaultLineup')[0];
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      position: 'relative',
                      height:
                        substitutes.length === 0
                          ? startLineUpSide && startLineUpSide.getBoundingClientRect().height - 220
                          : undefined
                    }}
                  >
                    {substitutes.length === 0 ? (
                      <MissingEntitiesComponent text="No remaining players" />
                    ) : (
                      // @ts-ignore
                      <PlayersByPosition team={team} players={substitutes} type="lineup" draggable />
                    )}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </div>

          <Droppable droppableId="lineup">
            {(provided, snapshot) => {
              const style = {
                backgroundColor: snapshot.isDraggingOver ? 'rgb(100, 195, 165, 0.1)' : ''
              };
              const dragginOverClass = snapshot.isDraggingOver ? 'dragging-over' : '';
              return (
                <div
                  className={`editLineup__defaultLineup ${dragStartClass}`}
                  style={style}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <Title is3>Default starting lineup</Title>
                  {state.lineup.map((p: Player, idx: number) => (
                    <StartingLineupItem key={p.id} player={p} draggable index={idx} team={team} />
                  ))}
                  <div className={`editLineup__dragAndDropTip ${dragStartClass} ${dragginOverClass}`}>
                    <Icon icon="profile" />
                    <span>Drag-and-drop player anywhere in this column</span>
                  </div>
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </div>
      </DragDropContext>
    </Modal>
  );
};
