import { updateQueryUrl } from '@ligr/shared';
import gql from 'graphql-tag';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from '../../components/Button';
import { Loader } from '../../components/loader/Loader';
import { SearchField } from '../../components/SearchField/SearchField';
import { Select } from '../../components/Select';
import { Table } from '../../components/Table/Table';
import { useErrorHandledLazyQuery, useErrorHandledMutation } from '../../lib/apolloHooks';
import { Notification } from '../../lib/notificationContainer';
import { AdminWrapper } from './Admin.wrapper';
import {
  Competition,
  CompetitionBase,
  SearchCompetitionsQuery,
  CompetitionBasesQuery,
  UpdateCompetitionMutation
} from '../../api-types';
import { competitionBasesQuery } from './gql';

const competitionsSearchQuery = gql`
  query searchCompetitions($query: String!) {
    searchCompetitions(query: $query) {
      id
      name
      baseId
      organization {
        id
        name
      }
      baseEntity {
        id
        name
        providerId
        providerName
      }
    }
  }
`;

const updateCompetitionMutation = gql`
  mutation updateCompetitionAdmin($updateCompetitionInput: UpdateCompetitionAdminInput!) {
    updateCompetitionAdmin(updateCompetitionInput: $updateCompetitionInput) {
      id
      name
      baseId
      baseEntity {
        id
        name
        providerId
        providerName
      }
    }
  }
`;

export const AdminCompetitions: React.FunctionComponent = () => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const searchParsed = queryString.parse(history.location.search);
  const [query, setQuery] = useState(searchParsed.query || '');
  const [fetch, { data, loading }] = useErrorHandledLazyQuery<SearchCompetitionsQuery>(competitionsSearchQuery);
  const [fetchBase, { data: dataBase, loading: loadingBase }] = useErrorHandledLazyQuery<CompetitionBasesQuery>(
    competitionBasesQuery
  );
  const [updateCompetition, { error: updateError, data: updateData }] = useErrorHandledMutation<
    UpdateCompetitionMutation
  >(updateCompetitionMutation);

  const { addNotification } = Notification.useContainer();

  const competitions = data?.searchCompetitions || [];
  const competitionBases = dataBase?.competitionBases || [];

  useEffect(() => {
    submit();
  }, []);

  const submit = () => {
    fetch({ variables: { query } });
    fetchBase();
  };

  useEffect(() => {
    if (updateError) addNotification('Oops something went wrong.', 'error');
  }, [updateError]);

  useEffect(() => {
    if (updateData) addNotification('Competition successfully updated.', 'success');
  }, [updateData]);

  useEffect(() => {
    if (query === '') submit();
  }, [query]);

  const updateCompetitionFn = (competitionId: number, base: string) => {
    let baseId: number | undefined;
    try {
      baseId = parseInt(base);
    } catch {
      baseId = undefined;
    }
    updateCompetition({ variables: { updateCompetitionInput: { id: competitionId, baseId } } });
  };

  if (loading || loadingBase) return <Loader />;

  return (
    <AdminWrapper>
      <div className="admin-search">
        <SearchField
          placeholder="Search competition by name or organization name"
          onChange={(value: any) => {
            updateQueryUrl(history, { query: value });
            setQuery(value);
          }}
          value={query}
        />
        <Button onClick={submit}>Search</Button>
      </div>
      <div className="search-competitions">
        <Table
          className="adminComps__table"
          data={competitions}
          small
          columns={[
            {
              Header: 'Competition',
              accessor: 'name',
              Cell: ({ row }) => <div>{row.original.name}</div>
            },
            {
              Header: 'Organization',
              accessor: 'organization',
              Cell: ({ row }) => <div>{row.original.organization.name}</div>
            },
            {
              Header: 'External Competition',
              accessor: 'baseId',
              Cell: ({ row }) => (
                <div>
                  <Select
                    value={row.original.baseId || ''}
                    onChange={e => {
                      // @ts-ignore
                      updateCompetitionFn(row.original.id, e.target.value);
                    }}
                    options={[
                      {
                        value: '',
                        label: 'No base competition'
                      },
                      ...competitionBases.map(cb => ({ value: cb.id, label: cb.name }))
                    ]}
                  />
                </div>
              )
            },
            {
              Header: 'External ID',
              accessor: 'baseEntity.providerId',
              Cell: ({ cell }) => <div>{cell.value}</div>
            },
            {
              Header: 'Provider Name',
              accessor: 'baseEntity.providerName',
              Cell: ({ cell }) => <div>{cell.value}</div>
            }
          ]}
        />
      </div>
    </AdminWrapper>
  );
};
