import { useSearchParams } from '@ligr/shared';
import gql from 'graphql-tag';
import { ClubOrTeam, ClubsAndTeamsQuery } from '../api-types';
import { useErrorHandledMutation } from './apolloHooks';
import { clubsAndTeamsQuery } from './gql/queries/clubsAndTeams';

export const useArchiveClub = () => {
  const searchParams = useSearchParams(['archive']);

  const deleteClubMutation = gql`
    mutation deleteClub($id: Float!) {
      deleteClub(id: $id)
    }
  `;

  const restoreClubMutation = gql`
    mutation restoreClub($id: Float!) {
      restoreClub(id: $id)
    }
  `;

  const [archiveClubMutation] = useErrorHandledMutation<boolean>(deleteClubMutation);
  const [unarchiveClubMutation] = useErrorHandledMutation<boolean>(restoreClubMutation);

  const archiveClub = async (clubId: number) => {
    await archiveClubMutation({
      variables: { id: clubId },
      update: (cache, { data }) => {
        const clubsAndTeamsCache = cache.readQuery<ClubsAndTeamsQuery>({
          query: clubsAndTeamsQuery,
          variables: { options: { archive: !!searchParams.archive } }
        });
        if (clubsAndTeamsCache && data) {
          let clubsAndTeams;

          if (!!searchParams.archive) {
            clubsAndTeams = clubsAndTeamsCache.clubsAndTeams.map((t: ClubOrTeam) => {
              if (t.id === clubId && t.__typename === 'Club') {
                return { ...t, archived: true };
              }
              return t;
            });
          } else {
            clubsAndTeams = clubsAndTeamsCache.clubsAndTeams.filter((t: ClubOrTeam) => {
              if (t.__typename !== 'Club') {
                return true;
              }
              return t.id !== clubId;
            });
          }
          cache.writeQuery({
            query: clubsAndTeamsQuery,
            variables: { options: { archive: !!searchParams.archive } },
            data: { clubsAndTeams }
          });
        }
      }
    });
  };

  const restoreClub = async (clubId: number) => {
    await unarchiveClubMutation({
      variables: { id: clubId },
      update: (cache, { data }) => {
        const clubsAndTeamsCache = cache.readQuery<ClubsAndTeamsQuery>({
          query: clubsAndTeamsQuery,
          variables: { options: { archive: !!searchParams.archive } }
        });
        if (clubsAndTeamsCache && data) {
          const clubsAndTeams = clubsAndTeamsCache.clubsAndTeams.map((t: ClubOrTeam) => {
            return {
              ...t,
              archived: t.id === clubId && t.__typename === 'Club' ? false : t.archived
            };
          });
          cache.writeQuery({
            query: clubsAndTeamsQuery,
            variables: { options: { archive: !!searchParams.archive } },
            data: { clubsAndTeams }
          });
        }
      }
    });
  };

  return {
    archiveClub,
    restoreClub
  };
};
