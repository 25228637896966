import { ApolloProvider as AHooksProvider } from '@apollo/react-hooks';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { StackBar } from './components/stackbar/StackBar';
import { client } from './lib/apolloClient';
import { Auth } from './lib/authContainer';
import { BugSnagErrorBoundary } from './lib/bugsnag';
import { Competition, Competitions } from './lib/competitions.container';
import { history } from './lib/history';
import { Me } from './lib/meContainer';
import { Notification } from './lib/notificationContainer';
import { Router as AppRouter } from './router/Router';
import './run-sw';
import './styles/app.scss';

ReactDOM.render(
  <BugSnagErrorBoundary>
    <AHooksProvider client={client}>
      <Notification.Provider>
        <StackBar />
        <Auth.Provider>
          <Router history={history}>
            <Me.Provider>
              <Competitions.Provider>
                <Competition.Provider>
                  <AppRouter />
                </Competition.Provider>
              </Competitions.Provider>
            </Me.Provider>
          </Router>
        </Auth.Provider>
      </Notification.Provider>
    </AHooksProvider>
  </BugSnagErrorBoundary>,
  document.getElementById('root')
);
