import gql from 'graphql-tag';
import React, { useState } from 'react';
import { object, string } from 'yup';
import { Button } from '../../components/Button';
import { Form } from '../../components/Form/Form';
import { FormField } from '../../components/FormField';
import { Loader } from '../../components/loader/Loader';
import { deleteFileMutation } from '../../lib/gql/mutations/deleteFile';
import { useErrorHandledMutation } from '../../lib/apolloHooks';
import { currentOrgQuery, Organization as OrganizationContainer } from '../../lib/orgContainer';
import { ProfilePageWrapper } from './ProfilePageWrapper';
import {
  Organization,
  UpdateOrganizationInput,
  UpdateOrganizationMutation,
  MakeDefaultOrganizationMutation
} from '../../api-types';
import { ProfileRouterChildProps } from './Profile.router';

const updateOrgNameMutation = gql`
  mutation updateOrganization($org: UpdateOrganizationInput!) {
    updateOrganization(updateOrganizationInput: $org) {
      id
      name
      default
      logo {
        id
        url
      }
    }
  }
`;

const makeDefaultOrganizationMutation = gql`
  mutation makeDefaultOrganization {
    makeDefaultOrganization {
      id
      name
      default
      logo {
        id
        url
      }
    }
  }
`;

export const validationSchema = object().shape({
  name: string().required().label('Organization name')
});

export const ProfileOrganizationForm: React.FunctionComponent<ProfileRouterChildProps> = ({ me }) => {
  const { org, loading, refetch } = OrganizationContainer.useContainer();

  const [updateOrgName, { loading: loadingUpdate }] = useErrorHandledMutation<UpdateOrganizationMutation>(
    updateOrgNameMutation
  );

  const [markAsDefault, { loading: loadingMake }] = useErrorHandledMutation<MakeDefaultOrganizationMutation>(
    makeDefaultOrganizationMutation
  );
  const [deleteFile] = useErrorHandledMutation<boolean>(deleteFileMutation);

  const [error, setError] = useState();

  if (loading || !org?.name) return <Loader />;

  const onSubmit = async (values: { name: string; logo?: File }) => {
    setError(undefined);

    try {
      const orgInput: UpdateOrganizationInput = {
        id: org!.id,
        name: values.name
      };

      if (values.logo) orgInput.logo = values.logo;

      if (values.name !== org!.name || values.logo) {
        await updateOrgName({
          variables: {
            org: orgInput
          }
        });
        await refetch();
      }
    } catch (e) {
      setError(e.graphQLErrors[0].message);
    }
  };

  const makeDefault = async () => {
    try {
      await markAsDefault();
    } catch (e) {
      setError(e.graphQLErrors[0].message);
    }
  };

  const handleDelete = async (name: string) => {
    const id = org[name]?.id;

    if (id) {
      await deleteFile({
        variables: { id },
        update: (cache: any, { data }: any) => {
          const orgCache = cache.readQuery({ query: currentOrgQuery });
          if (orgCache && data.deleteFile) {
            cache.writeQuery({
              query: currentOrgQuery,
              data: {
                currentOrganization: {
                  ...orgCache.currentOrganization,
                  [name]: null
                }
              }
            });
          }
        }
      });
      await refetch();
    }
  };

  return (
    <ProfilePageWrapper me={me} rightSide={true}>
      <Form
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={{
          name: org?.name || '',
          logo: undefined
        }}
        error={error}
        useConfirm={true}
        className="organizationForm"
      >
        <FormField name="name" type="text" label="Name" className="organizationForm__name" />
        <FormField
          name="logo"
          label="Logo"
          type="image"
          colSpan={2}
          src={org?.logo?.url}
          editable
          onDelete={() => handleDelete('logo')}
          className="organizationForm__logo"
        />
        <div className="organizationForm__submit">
          <Button isLoading={loadingUpdate}>Update organization</Button>
          <Button
            isLoading={loadingMake}
            onClick={makeDefault}
            className={`is-secondary ${org.default ? 'is-disabled' : ''}`}
          >
            Make default
          </Button>
        </div>
      </Form>
    </ProfilePageWrapper>
  );
};
