import gql from 'graphql-tag';
import { footballStatFragment, rugbyLeagueStatFragment, rugbyUnionStatFragment } from '@ligr/shared';

export const minSummaryFragment = gql`
  fragment MinSummary on MatchSummary {
    matchId
    clock {
      clockRunning
      clockInMs
      clockChangeDate
    }
    currentPeriod
    periods {
      duration
      name
      code
      preOvertimeBreak
      status
      type
      pregame
      aboutToStart
      playersEntered
      shootout
      finished
      number
      started
      matchFinished
    }
    matchStats {
      shootoutResults {
        home
        away
      }
      score {
        home
        away
      }
    }
  }
`;

export const summaryFragment = gql`
  fragment Summary on MatchSummary {
    matchId
    clock {
      clockRunning
      clockInMs
      clockChangeDate
    }
    currentPeriod
    periods {
      duration
      name
      code
      preOvertimeBreak
      shortName
      status
      type
      pregame
      aboutToStart
      playersEntered
      started
      number
      finished
      shootout
      matchFinished
    }
    matchStats {
      home {
        ... on FootballStats {
          ...FootballStats
        }
        ... on RugbyLeagueStats {
          ...LeagueStats
        }
        ... on RugbyUnionStats {
          ...UnionStats
        }
      }
      away {
        ... on FootballStats {
          ...FootballStats
        }
        ... on RugbyLeagueStats {
          ...LeagueStats
        }
        ... on RugbyUnionStats {
          ...UnionStats
        }
      }
      players {
        playerId
        stats {
          ... on FootballStats {
            ...FootballStats
          }
          ... on RugbyLeagueStats {
            ...LeagueStats
          }
          ... on RugbyUnionStats {
            ...UnionStats
          }
        }
      }
      periods {
        period
        home {
          ... on FootballStats {
            ...FootballStats
          }
          ... on RugbyLeagueStats {
            ...LeagueStats
          }
          ... on RugbyUnionStats {
            ...UnionStats
          }
        }
        away {
          ... on FootballStats {
            ...FootballStats
          }
          ... on RugbyLeagueStats {
            ...LeagueStats
          }
          ... on RugbyUnionStats {
            ...UnionStats
          }
        }
        players {
          playerId
          stats {
            ... on FootballStats {
              ...FootballStats
            }
            ... on RugbyLeagueStats {
              ...LeagueStats
            }
            ... on RugbyUnionStats {
              ...UnionStats
            }
          }
        }
      }
      score {
        home
        away
      }
      shootoutResults {
        home
        away
      }
    }
  }
  ${footballStatFragment}
  ${rugbyUnionStatFragment}
  ${rugbyLeagueStatFragment}
`;
