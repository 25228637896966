import { updateQueryUrl } from '@ligr/shared';
import capitalize from 'capitalize';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { FilterOptions } from '.';
import { Gender } from '../../api-types';
import { Select } from '../Select';
import { SelectOption } from '../Select/types';

interface GenderFilterProps {
  setActiveFilter: (name: FilterOptions, value: string) => void;
}

export const GenderFilter: React.FunctionComponent<GenderFilterProps> = ({ setActiveFilter }) => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const queryStrObject = queryString.parse(history.location.search);

  const options: SelectOption[] = useMemo(() => {
    const genderOptions = Object.values(Gender).map(gender => ({
      value: gender,
      label: capitalize(gender)
    })) as SelectOption[];

    return [{ value: '', label: 'All' } as SelectOption].concat(genderOptions);
  }, []);

  const handleChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const val = (e.target as HTMLSelectElement).value;
    setActiveFilter('gender', val);
    updateQueryUrl(history, { gender: val, competitions: '' });
  };

  return (
    <div className="filters__select__wrapper filters__select__wrapper--gender">
      <label className="filters__select__wrapper__label" htmlFor="gender">
        Gender
      </label>
      <Select
        name="gender"
        className="filters__select__wrapper__select"
        options={options}
        onChange={(e: React.FormEvent<HTMLSelectElement>) => handleChange(e)}
        value={queryStrObject?.gender || ''}
      />
    </div>
  );
};
