import { RoleName, roles } from '@ligr/api-v2';
import { Icon, updateQueryUrl, useSearchParams } from '@ligr/shared';
import capitalize from 'capitalize';
import gql from 'graphql-tag';
import React, { useEffect, useMemo, useState } from 'react';
import { Route, useHistory } from 'react-router';
import { Button } from '../../components/Button';
import { ContextMenu, ContextMenuItem } from '../../components/ContextMenu';
import { PageSubHeader } from '../../components/PageSubHeader/PageSubHeader';
import { Select } from '../../components/Select';
import { Table } from '../../components/Table/Table';
import { useErrorHandledLazyQuery, useErrorHandledMutation } from '../../lib/apolloHooks';
import { CreateUserModal } from '../../modals/Users/CreateUser.modal';
import { EditUserModal } from '../../modals/Users/EditUser.modal';
import { routes } from '../../router/Routes';
import './Permissions.scss';
import { ProfilePageWrapper } from './ProfilePageWrapper';
import { PermissionsQuery, TransferOwnershipMutation, UserOrganization } from '../../api-types';
import { ProfileRouterChildProps } from './Profile.router';

export const permissionFragment = gql`
  fragment Permission on UserOrganization {
    organizationId
    user {
      id
      lastName
      firstName
      email
    }
    role
  }
`;

export const permissionsQuery = gql`
  query permissions {
    permissions {
      ...Permission
    }
  }
  ${permissionFragment}
`;

const deletePermissionMutation = gql`
  mutation revoke($userId: Float!) {
    revoke(userId: $userId)
  }
`;

const transferOwnershipMutation = gql`
  mutation transferOwnership($userId: Float!) {
    transferOwnership(userId: $userId) {
      organizationId
      user {
        id
        lastName
        firstName
        email
      }
      role
    }
  }
`;

const all_roles = 'All roles';

export const ProfilePermissionForm: React.FunctionComponent<ProfileRouterChildProps> = ({ me }) => {
  const [fetch, { data }] = useErrorHandledLazyQuery<PermissionsQuery>(permissionsQuery);
  const [revoke] = useErrorHandledMutation<boolean>(deletePermissionMutation);
  const [transfer] = useErrorHandledMutation<TransferOwnershipMutation>(transferOwnershipMutation);
  const permissions = (data && data.permissions) || [];
  const history = useHistory<{ referrer?: string } | undefined>();
  const [selectedFilter, setFilter] = useState<string>(all_roles);

  const searchParams = useSearchParams(['role']);

  const filteredPermissions = useMemo(() => {
    if (selectedFilter === all_roles) {
      return permissions;
    }
    return permissions.filter(permission => permission.role === selectedFilter);
  }, [permissions, searchParams]);

  useEffect(() => {
    fetch({});
  }, []);

  useEffect(() => {
    setFilter(searchParams.role || all_roles);
  }, [searchParams]);

  const options = [{ label: 'All Roles', value: '' }].concat(
    Object.keys(roles)
      .filter(r => r !== 'ligrAdmin' && r !== 'overlay')
      .map(r => ({ label: capitalize(r), value: r }))
  );

  const handleChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const val = (e.target as HTMLSelectElement).value;
    updateQueryUrl(history, { role: val });
    setFilter(val === '' ? all_roles : val);
  };

  const revokeAccess = async (value: string) => {
    await revoke({
      variables: { userId: value },
      update: (cache, { data }) => {
        const permissionsCache = cache.readQuery<{ permissions: UserOrganization[] }>({
          query: permissionsQuery
        });
        if (permissionsCache) {
          cache.writeQuery({
            query: permissionsQuery,
            data: {
              permissions: permissionsCache.permissions.filter(p => p.user.id !== parseInt(value))
            }
          });
        }
      }
    });
  };

  const transferOwnership = async (value: string) => {
    await transfer({
      variables: { userId: value },
      update: (cache, { data }) => {
        const permissionsCache = cache.readQuery<{ permissions: UserOrganization[] }>({ query: permissionsQuery });
        if (permissionsCache && data) {
          cache.writeQuery({
            query: permissionsQuery,
            data: {
              permissions: permissionsCache.permissions.map(p => {
                if (p.user.id === data.transferOwnership.user.id) return data.transferOwnership;
                if (p.user.id === me.id) return { ...p, role: RoleName.admin };
                return p;
              })
            }
          });
        }
      }
    });
  };

  return (
    <ProfilePageWrapper me={me}>
      <div className="permissions-list">
        <PageSubHeader>
          <div className="filters">
            <div className="selectEntity__wrapper">
              <div className="selectEntity__label">{selectedFilter}</div>
              <Icon icon="arrowDownSmall" className="selectEntity__icon" color="grey-600" />
              <Select
                className="selectEntity__select"
                options={options}
                onChange={handleChange}
                value={selectedFilter}
              />
            </div>
          </div>

          <Button
            shrink={false}
            onClick={() =>
              history.push({
                pathname: routes.createUser(),
                search: location.search
              })
            }
          >
            Invite user
          </Button>
        </PageSubHeader>
        <Table
          selectable={false}
          data={filteredPermissions}
          columns={[
            {
              Header: 'Name',
              accessor: row => `${row.user.firstName} ${row.user.lastName}`
            },
            {
              Header: 'Email',
              accessor: 'user.email'
            },
            {
              Header: 'Role',
              accessor: 'role',
              Cell: ({ cell }) => capitalize(cell.value)
            },
            {
              Header: () => <Icon icon="settings" iconSize="medium" />,
              accessor: 'user.id',
              // @ts-ignore
              className: 'center',
              Cell: ({
                cell: { value },
                row: {
                  original: { role }
                }
              }) => {
                if (role === RoleName.owner) return null;

                const items: ContextMenuItem[] = [
                  {
                    text: 'Edit',
                    action: () => history.push(routes.editPermission({ userId: value }))
                  },
                  {
                    text: 'Revoke access',
                    action: () => revokeAccess(value)
                  },
                  {
                    text: 'Transfer ownership',
                    action: () => transferOwnership(value)
                  }
                ];

                return (
                  <ContextMenu id={`user-${value}`} items={items}>
                    <Icon icon="ellipsis" color="grey-600" />
                  </ContextMenu>
                );
              }
            }
          ]}
        />

        <Route
          exact
          path={routes.createUser(false)}
          render={() => (
            <CreateUserModal
              onClose={() => {
                history.push({
                  pathname: routes.permissions(),
                  search: location.search
                });
              }}
            />
          )}
        />
        <Route
          exact
          path={routes.editPermission(false)}
          render={() => (
            <EditUserModal
              onClose={() => {
                history.push({
                  pathname: routes.permissions(),
                  search: location.search
                });
              }}
            />
          )}
        />
      </div>
    </ProfilePageWrapper>
  );
};
