import { competitionAdAllocationFragment } from '@ligr/shared';
import gql from 'graphql-tag';

const competitionFragment = gql`
  fragment FullCompetition on Competition {
    id
    name
    gfxName
    countMatches
    countTeams
    themeId
    archived
    theme {
      id
      name
      sportId
      sportName
    }
    teams {
      id
      name
      defaultVenueId
    }
    watermark {
      id
      url
    }
    secondaryWatermark {
      id
      url
    }
    logo {
      id
      url
    }
    sportId
    sport {
      id
      name
    }
    matchConfig
    overlayConfig
    adAllocation {
      ...CompetitionAdAllocation
    }
  }
`;

export const competitionsQuery = gql`
  query competitions($options: IQueryOptions) {
    competitions(options: $options) {
      ...FullCompetition
    }
  }
  ${competitionFragment}
  ${competitionAdAllocationFragment}
`;
