import { updateQueryUrl } from '@ligr/shared';
import capitalize from 'capitalize';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { FilterOptions } from '.';
import { Select } from '../Select';
import { SelectOption } from '../Select/types';

interface TeamFilterProps {
  teams: {
    name: string;
    id: number;
    sport: {
      id: number;
    };
    competitions: {
      id: number;
    }[];
  }[];
  setActiveFilter: (name: FilterOptions, value: string) => void;
}

export const TeamFilter: React.FunctionComponent<TeamFilterProps> = ({ teams, setActiveFilter }) => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const queryStrObject = queryString.parse(history.location.search);

  const options: SelectOption[] = useMemo(() => {
    const teamOptions = teams
      .filter(team => {
        if (queryStrObject.sports && queryStrObject.competitions) {
          return (
            team.sport.id.toString() === queryStrObject.sports &&
            team.competitions.map(c => c.id.toString()).includes(queryStrObject.competitions as string)
          );
        }
        if (queryStrObject.sports) {
          return team.sport.id.toString() === queryStrObject.sports;
        }
        if (queryStrObject.competitions) {
          return team.competitions.map(c => c.id.toString()).includes(queryStrObject.competitions as string);
        }
        return true;
      })
      .map(team => ({
        value: parseInt((team.id as unknown) as string),
        label: capitalize(team.name)
      })) as SelectOption[];

    return [{ value: '', label: 'All' } as SelectOption].concat(teamOptions);
  }, [teams, queryStrObject.sports, queryStrObject.competitions]);

  const handleChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const val = (e.target as HTMLSelectElement).value === '' ? '' : parseInt((e.target as HTMLSelectElement).value);
    const optionValue = options.find(o => o.value.toString() === val.toString())!.label as string;
    setActiveFilter('teams', val === '' ? val : optionValue);
    updateQueryUrl(history, { teams: val });
  };

  return (
    <div className="filters__select__wrapper filters__select__wrapper--teams">
      <label className="filters__select__wrapper__label" htmlFor="teams">
        Teams
      </label>
      <Select
        name="teams"
        className="filters__select__wrapper__select"
        options={options}
        onChange={(e: React.FormEvent<HTMLSelectElement>) => handleChange(e)}
        value={queryStrObject?.teams || ''}
      />
    </div>
  );
};
