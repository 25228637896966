import React, { useMemo } from 'react';
import { SideMenuList } from '../../../../components/SideMenuList';
import { useErrorHandledQuery } from '../../../../lib/apolloHooks';
import { venuesQuery } from '../../../../lib/gql/queries/venues';
import { routes } from '../../../../router/Routes';
import { VenuesQuery } from '../../../../api-types';
import { ImagePlaceholderType } from '@ligr/shared';

export const VenueSideMenu: React.FunctionComponent = () => {
  const { data } = useErrorHandledQuery<VenuesQuery>(venuesQuery, {
    variables: { options: { orderBy: 'name', order: 'ASC' } }
  });
  const venues = data ? data.venues : [];

  const items = useMemo(() => {
    return venues.map(c => ({
      imageUrl: '',
      text: c.name,
      to: routes.assetVenue({ venueId: c.id }),
      imagePlaceholder: 'venue' as ImagePlaceholderType
    }));
  }, [venues]);

  return <SideMenuList items={items} link={routes.assetVenueCreate()} />;
};
