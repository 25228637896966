import React from 'react';
import { Card } from '../Card/Card';
import './loaders.scss';
import { LoaderText } from './LoaderText';

export const LoaderAssetCard: React.FunctionComponent = () => (
  <Card className="loader-asset">
    <LoaderText />
  </Card>
);
