import { playsForFragment } from '@ligr/shared';
import gql from 'graphql-tag';

export const playersForTeamQuery = gql`
  query playersForTeam($teamId: Float!) {
    playersForTeam(teamId: $teamId) {
      id
      archived
      countTeams
      firstName
      lastName
      playsFor {
        ...PlaysFor
      }
    }
  }
  ${playsForFragment}
`;
