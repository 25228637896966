import gql from 'graphql-tag';

export const updateClubMutation = gql`
  mutation updateClub($updateClubInput: UpdateClubInput!) {
    updateClub(updateClubInput: $updateClubInput) {
      id
      name
      archived
      gfxName
      gfxFullName
      abbreviation
      primaryBackgroundColor
      primaryTextColor
      secondaryBackgroundColor
      secondaryTextColor
      logo {
        id
        url
      }
      defaultVenue {
        id
        name
      }
      teams {
        id
        sport {
          id
        }
        competitions {
          id
          name
        }
        grade {
          id
          name
        }
        age
        gender
      }
    }
  }
`;
