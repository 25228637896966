import { Icon, IconType, Image } from '@ligr/shared';
import classnames from 'classnames';
import React, { HTMLProps } from 'react';
import { Notification } from '../../lib/notificationContainer';
import { useArchiveCompetition } from '../../lib/useArchiveCompetition';
import { routes } from '../../router/Routes';
import { Button } from '../Button';
import { Card } from '../Card/Card';
import { ConfirmWindow } from '../ConfirmWindow';
import { ConfirmWindowCompetitionContent } from '../ConfirmWindow/Content/CompetitionContent';
import { Title } from '../Title/Title';
import './style.scss';

type Competition = {
  archived: boolean;
  name: string;
  id: number;
  countMatches: number;
  countTeams: number;
  logo?: {
    url: string;
  } | null;
};

interface CardCompetitionProps extends Partial<HTMLProps<HTMLDivElement>> {
  competition: Competition;
  to?: string;
  fn?: () => void;
  icon?: IconType;
}

export const CardCompetition: React.FunctionComponent<CardCompetitionProps> = ({
  competition,
  to,
  fn,
  icon,
  ...rest
}) => {
  const { restoreCompetition } = useArchiveCompetition();
  const { addNotification } = Notification.useContainer();

  return (
    <Card
      to={competition.archived ? '' : to || routes.assetsCompetition({ competitionId: competition.id })}
      className={classnames('cardCompetition', {
        'card--archived': competition.archived
      })}
      title={competition.name}
      {...rest}
    >
      {competition.archived && (
        <div className="card--archived__overlay">
          <Button
            onClick={() => {
              restoreCompetition(competition.id);
              addNotification('Competition restored successfully.', 'success');
            }}
          >
            Restore
          </Button>
        </div>
      )}

      {!competition.archived && fn && icon && (
        <ConfirmWindow
          action={fn}
          title={`Archive competiton`}
          content={<ConfirmWindowCompetitionContent competition={competition} />}
        >
          <Icon icon={icon} />
        </ConfirmWindow>
      )}
      <Image className="cardCompetition__image" url={competition.logo?.url} placeholder="competition" />

      <Title is3 className="cardCompetition__title">
        {competition.name}
      </Title>
    </Card>
  );
};
