import { Match, MatchesQuery } from '../api-types';
import { useSearchParams } from '@ligr/shared';
import gql from 'graphql-tag';
import { matchesSubscription } from '../pages/Matches/matches.qql';
import { useErrorHandledMutation } from './apolloHooks';
import { matchesQuery } from './gql/queries/matches';
import { useMatchLoad } from './useMatchLoad';

export const useArchiveMatch = () => {
  const searchParams = useSearchParams(['competitions', 'teams', 'archive']);

  const deleteMatchMutation = gql`
    mutation deleteMatch($id: Float!) {
      deleteMatch(id: $id)
    }
  `;

  const restoreMatchMutation = gql`
    mutation restoreMatch($id: Float!) {
      restoreMatch(id: $id)
    }
  `;

  const [archiveMatchMutation] = useErrorHandledMutation<boolean>(deleteMatchMutation);
  const [unarchiveMatchMutation] = useErrorHandledMutation<boolean>(restoreMatchMutation);

  const { variables: matchVariables } = useMatchLoad({ matchesSubscription });
  matchVariables.options.archive = !!searchParams.archive;

  const variables = {
    ...matchVariables,
    competitionId: searchParams?.competitions ? parseInt(searchParams?.competitions) : undefined,
    teamId: searchParams?.teams ? parseInt(searchParams?.teams) : undefined
  };

  const archiveMatch = async (matchId: number) => {
    await archiveMatchMutation({
      variables: { id: matchId },
      update: (cache, { data }) => {
        const matchCache = cache.readQuery<MatchesQuery>({
          query: matchesQuery,
          variables
        });
        if (matchCache && data) {
          let matches;

          if (!!searchParams.archive) {
            matches = matchCache.matches.matches.map((m: Match) => {
              if (m.id === matchId) {
                return { ...m, archived: true, __typename: 'Match' };
              }
              return m;
            });
          } else {
            matches = matchCache.matches.matches.filter(({ id }: Match) => id !== matchId);
          }
          cache.writeQuery({
            query: matchesQuery,
            variables,
            data: {
              matches: {
                __typename: matchCache.matches.__typename,
                matches,
                total: matchCache.matches.total - 1
              }
            }
          });
        }
      }
    });
  };

  const restoreMatch = async (matchId: number) => {
    await unarchiveMatchMutation({
      variables: { id: matchId },
      update: (cache, { data }) => {
        const matchCache = cache.readQuery<{
          matches: {
            matches: Match[];
            total: number;
          };
        }>({
          query: matchesQuery,
          variables
        });
        if (matchCache && data) {
          const matches = matchCache.matches.matches.map((m: Match) => {
            return {
              ...m,
              archived: m.id === matchId ? false : m.archived
            };
          });
          cache.writeQuery({
            query: matchesQuery,
            variables,
            data: {
              matches: {
                matches,
                total: matchCache.matches.total - 1
              }
            }
          });
        }
      }
    });
  };

  return {
    archiveMatch,
    restoreMatch
  };
};
