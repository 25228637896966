import './tabs.scss';

import React from 'react';

import { TabsProps } from './Tabs.types';
import { logDelay } from '../../lib/animation';
import classnames from 'classnames';

export const Tabs: React.FunctionComponent<TabsProps> = ({ tabs, onChange, active, renderTab, className }) => (
  <nav className={classnames('tabs', className)}>
    {tabs.map((t, i) =>
      renderTab ? (
        renderTab({
          ...t,
          index: i,
          active: i === active,
          onClick: () => onChange({ ...t, index: i })
        })
      ) : (
        <div
          className={classnames(`tab ${i === active ? 'active' : ''}`, t.className)}
          onClick={() => onChange({ value: t.value, index: i })}
          key={t.value}
        >
          <span {...logDelay(i)}>{t.text}</span>
        </div>
      )
    )}
  </nav>
);
