import { Icon } from '@ligr/shared';
import React from 'react';
import { useHistory } from 'react-router';
import { ConfirmWindow } from '../../../../components/ConfirmWindow';
import { ConfirmWindowTeamContent } from '../../../../components/ConfirmWindow/Content/TeamContent';
import { ContextMenu } from '../../../../components/ContextMenu';
import { Notification } from '../../../../lib/notificationContainer';
import { useArchiveTeam } from '../../../../lib/useArchiveTeam';
import { routes } from '../../../../router/Routes';

type Team = {
  countMatches: number;
  countPlayers: number;
  archived: boolean;
  name: string;
  id: number;
  logo?: {
    id: number;
    url: string;
  } | null;
};

interface TeamMenuProps {
  team: Team;
}

export const TeamMenu: React.FunctionComponent<TeamMenuProps> = ({ team }) => {
  const { archiveTeam } = useArchiveTeam();
  const { addNotification } = Notification.useContainer();
  const history = useHistory<{ referrer?: string } | undefined>();

  const archiveTeamFn = async (teamId: number) => {
    await archiveTeam(teamId);
    addNotification('Team archived successfully.', 'success');
    history.push(routes.assetTeams());
  };

  return (
    <ContextMenu
      place="bottom"
      clickable={true}
      className="pageHeader__menu teamMenu"
      renderMenu={() => {
        return (
          <ul>
            <li>
              <ConfirmWindow
                action={() => archiveTeamFn(team.id)}
                title={`Archive team`}
                content={<ConfirmWindowTeamContent team={team} />}
              >
                <Icon icon="close" />
                <span>Archive team</span>
              </ConfirmWindow>
            </li>
          </ul>
        );
      }}
    >
      <Icon icon="dropdown" color="grey-600" />
    </ContextMenu>
  );
};
