import { Image, Logo } from '@ligr/shared';
import React from 'react';
import { NavTabs } from '../../components/NavTabs/NavTabs';
import { PageContent } from '../../components/page/PageContent';
import { Title } from '../../components/Title/Title';
import { Organization } from '../../lib/orgContainer';
import { routes } from '../../router/Routes';
import './profile.page.scss';
import { ApolloQueryResult } from 'apollo-client';
import { MeQuery } from '../../api-types';

export interface ProfilePageWrapperProps {
  rightSide?: boolean;
  me: ApolloQueryResult<MeQuery>['data']['me'];
}

export const ProfilePageWrapper: React.FunctionComponent<ProfilePageWrapperProps> = ({
  children,
  rightSide = false,
  me
}) => {
  const { org } = Organization.useContainer();

  return (
    <PageContent>
      <div className="left">
        <div className="page-header">
          <div className="profile">
            <div className="images">
              <Image url={me?.photo?.url} placeholder="user" className="profile__userImage" />
              <Image url={org?.logo?.url} placeholder="organization" className="profile__organizationImage" />
            </div>
            <div>
              <Title is1>
                {me.firstName} {me.lastName}
              </Title>
              <Title is4>{org?.name}</Title>
            </div>
          </div>

          <NavTabs
            tabs={[
              { text: 'Profile', to: routes.profile() },
              { text: 'Organization', to: routes.profileOrganization() },
              { text: 'Password', to: routes.profilePassword() },
              { text: 'Permissions', to: routes.permissions() },
              // Usage
              { text: 'Subscription', to: routes.subscriptions() },
              { text: 'Billing', to: routes.billing() }
            ]}
          />
        </div>

        <div className="form">{children}</div>
      </div>

      {rightSide && (
        <div className="right">
          <Logo type="light" />
        </div>
      )}
    </PageContent>
  );
};
