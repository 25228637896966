import capitalize from 'capitalize';
import pluralize from 'pluralize';
import React from 'react';

type Club = {
  name: string;
  id: number;
  archived: boolean;
  logo?: {
    url: string;
  } | null;
  teams?:
    | {
        countMatches: number;
        countPlayers: number;
        archived: boolean;
        competitions?:
          | {
              name: string;
              id: number;
              logo?: {
                url: string;
              } | null;
            }[]
          | null;
      }[]
    | null;
};

export const ConfirmWindowClubContent: React.FunctionComponent<{
  club: Club;
}> = ({ club }) => {
  const teamLength = club.teams?.length || 0;
  const totalCounMatches = club.teams?.map(t => t.countMatches).reduce((x, y) => x + y, 0) || 0;
  const totalCountPlayers = club.teams?.map(t => t.countPlayers).reduce((x, y) => x + y, 0) || 0;
  const withNotification = totalCounMatches > 0 || totalCountPlayers > 0 || (teamLength || 0) > 0;
  const notifications = [];

  if (teamLength > 0) {
    notifications.push(
      <li>
        Archive {club.teams!.length} {pluralize('teams', club.teams!.length)}
      </li>
    );
  }

  if (totalCounMatches > 0) {
    notifications.push(
      <li>
        Archive {totalCounMatches} {pluralize('matches', totalCounMatches)}
      </li>
    );
  }

  if (totalCountPlayers > 0) {
    notifications.push(
      <li>
        Remove {totalCountPlayers} {pluralize('player associations', totalCountPlayers)}
      </li>
    );
  }

  return (
    <>
      Are you sure you want to archive {capitalize(club.name)}?
      {withNotification && (
        <div className={`notification notification--error confirm-window__notification`}>
          This action will also:
          <ul>{notifications.map(item => item)}</ul>
        </div>
      )}
    </>
  );
};
