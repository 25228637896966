import { EFinishedStatusNames } from '@ligr/api-v2/dist/types/Match';
import { useSearchParams } from '@ligr/shared';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Age, CompetitionsQuery, Gender, GradesQuery, SportsQuery, TeamsWithCompQuery } from '../../api-types';
import { useErrorHandledLazyQuery } from '../../lib/apolloHooks';
import { competitionsQuery } from '../../lib/gql/queries/competitions';
import { gradesQuery } from '../../lib/gql/queries/grades';
import { sportsQuery } from '../../lib/gql/queries/sports';
import { teamsWithCompQuery } from '../../lib/gql/queries/teams';
import { AgeFilter } from './AgeFilter';
import { AllocationFilter } from './AllocationFilter';
import { ArchiveFilter } from './ArchiveFilter';
import { ClubFilter } from './ClubFilter';
import { CompetitionFilter } from './CompetitionFilter';
import { DateFilter, dateFormat } from './DateFilter';
import { GenderFilter } from './GenderFilter';
import { GradeFilter } from './GradeFilter';
import { FilterOptions } from './index';
import { SportFilter } from './SportFilter';
import { StatusFilter } from './StatusFilter';
import { TeamFilter } from './TeamFilter';

interface AllFiltersProps {
  filters: FilterOptions[];
  opened: boolean;
  setActiveFilter: (name: FilterOptions, value: string) => void;
}

export const AllFilters: React.FunctionComponent<AllFiltersProps> = ({ filters, opened, setActiveFilter }) => {
  const queryParams = useSearchParams(filters);

  const entries = {
    sports: [] as { id: number; name: string }[],
    competitions: [] as { id: number; name: string; sport: { id: number } }[],
    teams: [] as {
      id: number;
      name: string;
      sport: { id: number };
      competitions: {
        id: number;
      }[];
    }[],
    archive: '',
    allocation: '',
    club: '',
    age: '',
    gender: '',
    grades: undefined as { id: number; name: string }[] | undefined,
    status: '',
    startAt: '',
    endAt: ''
  };

  const [fetchGrades, { data: gradesData }] = useErrorHandledLazyQuery<GradesQuery>(gradesQuery);
  entries.grades = gradesData?.grades || [];

  const [fetchSports, { data: sportsData }] = useErrorHandledLazyQuery<SportsQuery>(sportsQuery);
  entries.sports = sportsData?.sports || [];

  const [fetchCompetitions, { data: competitionsData }] = useErrorHandledLazyQuery<CompetitionsQuery>(
    competitionsQuery
  );
  entries.competitions = competitionsData?.competitions || [];

  const [fetchTeams, { data: teamsData }] = useErrorHandledLazyQuery<TeamsWithCompQuery>(teamsWithCompQuery);
  entries.teams = teamsData?.teams || [];

  useEffect(() => {
    if (filters.includes('sports')) fetchSports({});
    if (filters.includes('competitions')) fetchCompetitions({});
    if (filters.includes('teams')) fetchTeams({});
    if (filters.includes('grades')) fetchGrades({});
    if (queryParams.allocation) entries.allocation = queryParams.allocation ? `By ${queryParams.allocation}` : '';
    if (queryParams.club) entries.club = queryParams.club ? `By ${queryParams.club}` : '';
    if (queryParams.archive) entries.archive = 'Includes archived';
    if (queryParams.status) entries.status = queryParams.status ? `${queryParams.status}` : '';
    if (queryParams.startAt) {
      entries.startAt = queryParams.startAt
        ? `From ${moment(queryParams.startAt, 'DD-MM-YYYY').format(dateFormat)}`
        : '';
    }
    if (queryParams.endAt) {
      entries.endAt = queryParams.endAt ? `To ${moment(queryParams.endAt, 'DD-MM-YYYY').format(dateFormat)}` : '';
    }
  }, []);

  useEffect(() => {
    if (filters.includes('sports') && queryParams.sports) {
      setActiveFilter('sports', entries.sports.find(sport => sport.id === parseInt(queryParams.sports!))!.name);
    }
  }, [entries.sports]);

  useEffect(() => {
    if (filters.includes('grades') && queryParams.grades) {
      setActiveFilter(
        'grades',
        entries['grades']?.find(grade => grade.id === parseInt(queryParams.grades || ''))!.name
      );
    }
  }, [entries.grades]);

  useEffect(() => {
    if (filters.includes('competitions') && queryParams.competitions) {
      const found = entries['competitions'].find(comp => comp.id === parseInt(queryParams.competitions!));
      found && setActiveFilter('competitions', found.name);
    }
  }, [entries.competitions]);

  useEffect(() => {
    if (filters.includes('teams') && queryParams.teams) {
      const found = entries['teams'].find(team => team.id === parseInt(queryParams.teams!));
      found && setActiveFilter('teams', found.name);
    }
  }, [entries.teams]);

  useEffect(() => {
    if (filters.includes('age') && queryParams.age) {
      setActiveFilter('age', Object.values(Age).find(age => age === queryParams.age)!);
    }
  }, [entries.age]);

  useEffect(() => {
    if (filters.includes('gender') && queryParams.gender) {
      setActiveFilter('gender', Object.values(Gender).find(gender => gender === queryParams.gender)!);
    }
  }, [entries.gender]);

  useEffect(() => {
    if (filters.includes('allocation') && queryParams.allocation) {
      setActiveFilter('allocation', queryParams.allocation ? `By ${queryParams.allocation}` : '');
    }
  }, [entries.allocation]);

  useEffect(() => {
    if (filters.includes('club') && queryParams.club) {
      setActiveFilter('club', queryParams.club ? `By ${queryParams.club}` : '');
    }
  }, [entries.club]);

  useEffect(() => {
    if (filters.includes('status') && queryParams.status) {
      setActiveFilter(
        'status',
        queryParams.status
          ? /*
      // @ts-ignore */
            EFinishedStatusNames[queryParams.status]
          : ''
      );
    }
  }, [entries.status]);

  useEffect(() => {
    if (filters.includes('startAt') && queryParams.startAt) {
      setActiveFilter('startAt', entries.startAt);
    }
  }, [entries.startAt]);

  useEffect(() => {
    if (filters.includes('endAt') && queryParams.endAt) {
      setActiveFilter('endAt', entries.endAt);
    }
  }, [entries.endAt]);

  useEffect(() => {
    if (filters.includes('archive') && queryParams.archive) {
      setActiveFilter('archive', entries.archive);
    }
  }, [entries.archive]);

  if (!opened) return null;

  return (
    <>
      {filters.includes('allocation') && <AllocationFilter setActiveFilter={setActiveFilter} />}
      {filters.includes('sports') && <SportFilter sports={entries['sports']} setActiveFilter={setActiveFilter} />}
      {filters.includes('competitions') && (
        <CompetitionFilter competitions={entries['competitions']} setActiveFilter={setActiveFilter} />
      )}
      {filters.includes('club') && <ClubFilter setActiveFilter={setActiveFilter} />}
      {filters.includes('teams') && <TeamFilter teams={entries['teams']} setActiveFilter={setActiveFilter} />}
      {filters.includes('grades') && <GradeFilter grades={entries['grades']} setActiveFilter={setActiveFilter} />}
      {filters.includes('age') && <AgeFilter setActiveFilter={setActiveFilter} />}
      {filters.includes('gender') && <GenderFilter setActiveFilter={setActiveFilter} />}
      {filters.includes('status') && <StatusFilter setActiveFilter={setActiveFilter} />}
      {filters.includes('startAt') && filters.includes('endAt') && <DateFilter setActiveFilter={setActiveFilter} />}
      {filters.includes('archive') && <ArchiveFilter setActiveFilter={setActiveFilter} />}
    </>
  );
};
