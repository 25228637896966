import { Player } from '../../../../api-types';
import { Image } from '@ligr/shared';
import React from 'react';
import './style.scss';

interface ExistingPlayerMenuItemProps {
  player: Player;
}

export const ExistingPlayerMenuItem: React.FunctionComponent<ExistingPlayerMenuItemProps> = ({ player }) => {
  return (
    <div className="existingPlayerMenuItem">
      <div className="existingPlayerMenuItem__text">{`${player.firstName} ${player.lastName}`}</div>
      {player.playsFor?.map(t => (
        <Image className="existingPlayerMenuItem__image" url={t.photo?.url} placeholder="player" />
      ))}
    </div>
  );
};
