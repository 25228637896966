import React from 'react';
import { ToolTip } from '../../../../components/ToolTipIcon';

interface InProgressCellProps {
  currentPeriodStatus: string;
  currentPeriodName?: string;
  clock?: string;
  score: string;
}

export const InProgressCell: React.FunctionComponent<InProgressCellProps> = ({
  currentPeriodStatus,
  currentPeriodName,
  clock,
  score
}) => {
  return (
    <div className="dateCell__message-in-progress">
      <div className="dateCell__message-in-progress__label">
        <ToolTip reference={() => <>{currentPeriodStatus}</>}>{currentPeriodName}</ToolTip>
      </div>
      {clock && (
        <>
          <div className="dateCell__message-in-progress__divider">•</div>
          <div className="dateCell__message-in-progress__clock">{clock}</div>
        </>
      )}
      <div className="dateCell__message-in-progress__divider">•</div>
      <div className="dateCell__message-in-progress__score">{score}</div>
    </div>
  );
};
