import './loader.scss';

import React from 'react';
import classnames from 'classnames';

export const Loader: React.FunctionComponent<{
  center?: boolean;
  size?: 'main' | 'small';
}> = ({ center = true, size }) => {
  return (
    <div className={classnames('loader', { center, [size || 'main']: Boolean(size) })}>
      <span></span>
    </div>
  );
};
