import gql from 'graphql-tag';
import React from 'react';
import { Loader } from '../../../components/loader/Loader';
import { Table } from '../../../components/Table/Table';
import { useErrorHandledQuery } from '../../../lib/apolloHooks';
import { formatter } from '../Subscriptions/SelectionOverview';

const invoicesQuery = gql`
  {
    invoices {
      status
      amountDue
      currency
      plan
      pdfLink
      period {
        start
        end
      }
    }
  }
`;

export const InvoicesTable = () => {
  const { data: invoices, loading } = useErrorHandledQuery(invoicesQuery, { fetchPolicy: 'no-cache' });
  if (loading) return <Loader />;

  if (!invoices.invoices || !invoices.invoices.length) {
    return <span>There are currently have no invoices for this organization</span>;
  }

  return (
    <Table
      // onSelect={(data) => setSelected(data)}
      data={invoices.invoices}
      small
      columns={[
        {
          Header: 'Period',
          Cell: ({ row }) =>
            `${new Date(row.original.period.start).toLocaleString('default', {
              day: '2-digit',
              month: 'short',
              year: 'numeric'
            })}`
        },
        { Header: 'Plan', accessor: 'plan' },
        { Header: 'Amount', accessor: 'amountDue', Cell: ({ cell }) => `$${formatter.format(cell.value / 100)}` },
        {
          Header: 'Invoice',
          accessor: 'pdfLink',
          Cell: ({ cell }) => (
            <div>
              <a href={cell.value} target="_blank" className="has-text-red">
                Download as PDF
              </a>
            </div>
          )
        }
      ]}
    />
  );
};
