import { summaryFragment } from '../../lib/gql/fragments/summary';
import gql from 'graphql-tag';

export const overlayQuery = gql`
  query overlayWithFullMatch($id: Float!) {
    overlay(id: $id) {
      id
      matchId
      encodingDelay
      autoMode
      resolution
      showWatermarks
      background
      backgroundColor
      enableAds
      name
      testMode
      manualGraphicState
      key
      match {
        id
        name
        awayTeamId
        homeTeamId
        venueId
        competitionId
        description
        date
        config
        finishedStatus
        liveStatus
        hasFacts
        sportName
        venue {
          id
          name
        }
        lineup {
          id
          position
          number
          starting
          captain
          photo {
            id
            url
          }
          player {
            id
            firstName
            lastName
          }
          team {
            id
            abbreviation
          }
        }
        organizationId
        facts {
          matchId
          id
          name
          teamId
          playerId
          minute
          date
          period
          data
        }
        competition {
          id
          themeId
          logo {
            id
            url
          }
          theme {
            id
            name
            sportName
            sportId
          }
          adAllocation {
            id
            adSets {
              id
              name
              adFiles {
                id
                adSetId
                file {
                  id
                  url
                  entityId
                  entityType
                }
                size
              }
            }
          }
        }
        summary {
          ...Summary
        }
        homeTeam {
          id
          name
          gfxName
          gfxFullName
          abbreviation
          logo {
            id
            url
          }
          adAllocation {
            id
            adSets {
              id
              name
              adFiles {
                id
                adSetId
                file {
                  id
                  url
                  entityId
                  entityType
                }
                size
              }
            }
          }
        }
        awayTeam {
          id
          name
          gfxName
          gfxFullName
          abbreviation
          logo {
            id
            url
          }
          adAllocation {
            id
            adSets {
              id
              name
              adFiles {
                id
                adSetId
                file {
                  id
                  url
                  entityId
                  entityType
                }
                size
              }
            }
          }
        }
      }
    }
  }
  ${summaryFragment}
`;

export const summarySubscription = gql`
  subscription matchFactFeed($id: Float!) {
    matchFeed(id: $id) {
      facts {
        matchId
        id
        name
        teamId
        playerId
        minute
        date
        period
        data
      }
    }
  }
`;
