import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClient, ApolloError } from 'apollo-client';
import { from, split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import { CONFIG } from '@ligr/shared';
import { history } from './history';
import { getOrgId } from './util/getOrgId';
import { getLocalToken } from './util/tokenLocalStorage';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import introspectionQueryResultData from './fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const authLink = setContext((_, ctx) => {
  const token = getLocalToken();
  const orgId = getOrgId();
  console.log(token, orgId);

  return {
    headers: {
      ...ctx.headers,
      authorization: token ? `Bearer ${token}` : '',
      'x-ligr-organization-id': orgId
    }
  };
});

const subscriptionMiddleware = {
  // @ts-ignore
  applyMiddleware(options, next) {
    const token = getLocalToken();
    const orgId = getOrgId();

    options = {
      ...options,
      headers: {
        authorization: token ? `Bearer ${token}` : '',
        'x-ligr-organization-id': orgId
      }
    };

    next();
  }
};

const wsLink = new WebSocketLink({
  uri: `${CONFIG.API_WS_URL}/graphql`,
  options: {
    reconnect: true,
    timeout: 30000,
    lazy: true,
    connectionCallback: error => {
      if (error) {
        console.log(error);
      }
    },
    connectionParams: async () => {
      // Allows url to change key in tab.
      const token = getLocalToken();
      const orgId = getOrgId();

      return {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
          // @ts-ignore
          'x-ligr-organization-id': parseInt(orgId)
        }
      };
    }
  }
});

export const getGQLError = (err: ApolloError | ApolloError[]) => {
  const gqlE = err instanceof Array ? err[0] : err;
  return gqlE.graphQLErrors[0];
};

export const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject: (object: any) => {
    switch (object.__typename) {
      case 'MatchSummary':
        return `${object.__typename}.${object.matchId}`;
      case 'UserOrganization':
        return `${object.__typename}.${object.organizationId}.${object.user.id}`;

      default:
        if (object.id) {
          return `${object.__typename}.${object.id}`;
        }
    }
  }
});

// @ts-ignore
wsLink.subscriptionClient.use([subscriptionMiddleware]);

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  from([
    onError(({ networkError }) => {
      if (networkError) history.push('/network-error');
    }),
    authLink,
    createUploadLink({
      uri: `${CONFIG.API_URL}/graphql`,
      credentials: 'include'
    })
  ])
);

export const client = new ApolloClient({
  link,
  cache
});
