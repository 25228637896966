import { Match } from '../../../api-types';
import React from 'react';
import { CompletedMatchCard } from '../../../components/CompletedMatchCard';
import { Loader } from '../../../components/loader/Loader';
import './style.scss';

interface MatchesListProps {
  matches: Match[];
  loading: boolean;
  count: number;
}

export const MatchesList: React.FunctionComponent<MatchesListProps> = ({ matches, loading, count }) => {
  return (
    <div className="matchesList">
      {loading ? (
        <Loader />
      ) : count > 0 ? (
        matches.map(match => <CompletedMatchCard key={match.id} match={match} />)
      ) : (
        'No results'
      )}
    </div>
  );
};
