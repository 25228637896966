import { useSearchParams } from '@ligr/shared';
import queryString from 'query-string';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { TeamsWithCompQuery, TeamWithCompFragment } from '../../../../api-types';
import { Button } from '../../../../components/Button';
import { CardTeam } from '../../../../components/CardTeam';
import { Grid } from '../../../../components/Grid/Grid';
import { getMissingEntitiesComponentContent } from '../../../../components/MissingEntitiesComponent/getMissingEntitiesComponentContent';
import { MissingEntitiesComponent } from '../../../../components/MissingEntitiesComponent/MissingEntitiesComponent';
import { PageContentContent } from '../../../../components/page/PageContentContent';
import { PageSubHeader } from '../../../../components/PageSubHeader/PageSubHeader';
import { SearchField } from '../../../../components/SearchField/SearchField';
import { useErrorHandledLazyQuery } from '../../../../lib/apolloHooks';
import { teamsWithCompQuery } from '../../../../lib/gql/queries/teams';
import { routes } from '../../../../router/Routes';
import { EntityEnum } from '../../../../types/assets';
import { Competition } from './Competition.page';

interface CompetitionTeamsPageProps {
  competition: Competition;
}

export const CompetitionTeamsPage: React.FunctionComponent<CompetitionTeamsPageProps> = ({ competition }) => {
  const history = useHistory<{ referrer?: string } | undefined>();

  const [fetch, { data, loading, called }] = useErrorHandledLazyQuery<TeamsWithCompQuery>(teamsWithCompQuery, {
    variables: { competitionId: competition.id }
  });
  const teams = (data && data.teams) || [];

  const searchParams = useSearchParams(['query']);

  // Note can pass searchParams into the fetch as GQL variables
  // if we are server side searching
  useEffect(() => {
    fetch({ variables: { competitionId: competition.id } });
  }, []);

  const teamsSearch = useMemo(() => {
    return teams
      ? teams.filter((t: TeamWithCompFragment) => {
          let query = true;
          if (searchParams.query) {
            query = t.name.toLowerCase().includes(searchParams.query.toLowerCase());
          }
          return query;
        })
      : [];
  }, [searchParams, teams]);

  const missingEntitiesDialogProps =
    called && !loading && getMissingEntitiesComponentContent(EntityEnum.teams, teams, teamsSearch);

  return (
    <PageContentContent loading={!called || loading} className="competition__teams">
      <PageSubHeader className="inner-page">
        <Button
          onClick={() =>
            history.push({
              pathname: routes.assetTeamCreate(),
              search: queryString.stringify({ competitionId: competition.id })
            })
          }
          icon="plus"
        >
          Create team
        </Button>
        <SearchField placeholder="Search teams" urlSearch />
      </PageSubHeader>
      {missingEntitiesDialogProps ? (
        <MissingEntitiesComponent
          {...missingEntitiesDialogProps}
          url={{
            pathname: routes.assetTeamCreate(),
            search: queryString.stringify({ competitionId: competition.id })
          }}
        />
      ) : (
        <Grid>
          {teamsSearch.map((t, i) => (
            <CardTeam key={t.id} team={t} competitions={t.competitions} />
          ))}
        </Grid>
      )}
    </PageContentContent>
  );
};
