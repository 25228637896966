import { RoleName } from '@ligr/api-v2';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Loader } from '../../components/loader/Loader';
import { Modal, ModalProps } from '../../components/modal/Modal';
import { getGQLError } from '../../lib/apolloClient';
import { useErrorHandledMutation, useErrorHandledQuery } from '../../lib/apolloHooks';
import { routes } from '../../router/Routes';
import { UserForm, UserFormValues } from './form/User.form';
import { PermissionQuery, UpdatePermissionMutation } from '../../api-types';

const updatePermissionMutation = gql`
  mutation updatePermission($userId: Float!, $role: String!) {
    updatePermission(userId: $userId, role: $role) {
      id
      user {
        id
        lastName
        firstName
        email
      }
      organizationId
      role
    }
  }
`;

const permissionQuery = gql`
  query permission($userId: Float!) {
    permission(userId: $userId) {
      id
      user {
        id
        lastName
        firstName
        email
      }
      organizationId
      role
    }
  }
`;

export const EditUserModal: React.FunctionComponent<Partial<ModalProps>> = props => {
  const [error, setError] = useState();

  const history = useHistory<{ referrer?: string } | undefined>();
  const { userId } = useParams<{ userId: string }>();
  const [form, setForm] = useState<any>(null);
  const [update, { loading }] = useErrorHandledMutation<UpdatePermissionMutation>(updatePermissionMutation);
  const { data, loading: initialLoading } = useErrorHandledQuery<PermissionQuery>(permissionQuery, {
    variables: { userId: parseInt(userId) }
  });

  const submit = async (values: UserFormValues) => {
    try {
      const response = await update({
        variables: { userId: parseInt(userId), role: values.role }
      });
      if (response.data && response.data.updatePermission) {
        history.push(routes.permissions());
      } else {
        if (props.onClose) props.onClose();
      }
    } catch (e) {
      // @ts-ignore
      setError(getGQLError(e).message);
    }
  };

  if (initialLoading || (data && !data.permission)) {
    return (
      <Modal title="Edit user permission" {...props}>
        <Loader />
      </Modal>
    );
  }

  return (
    <Modal
      title="Edit user permission"
      {...props}
      footerButtons={[
        { text: 'Cancel', onClick: props.onClose, type: 'secondary' },
        { text: 'update', onClick: async () => form.submitForm(), isLoading: loading }
      ]}
    >
      <UserForm
        initialValues={{
          firstName: data!.permission.user.firstName,
          lastName: data!.permission.user.lastName,
          email: data!.permission.user.email,
          role: data!.permission.role as RoleName
        }}
        onSubmit={i => submit(i)}
        setForm={setForm}
        editing={true}
      />
    </Modal>
  );
};
