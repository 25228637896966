import { MutationFunction } from '@apollo/react-common';
import { Match, Overlay } from '../../../api-types';
// @ts-ignore
import { hri } from 'human-readable-ids';
import { matchesQuery } from '../../../lib/gql/queries/matches';

export const deleteOverlay = async (
  id: number,
  matchId: number,
  variables: object,
  deleteOverlayM: MutationFunction
) => {
  await deleteOverlayM({
    variables: { id },
    update: (cache, { data }) => {
      try {
        const matchQueryCache = cache.readQuery<{
          matches: {
            matches: Match[];
            total: number;
          };
        }>({ query: matchesQuery, variables });
        if (matchQueryCache && data) {
          const idx = matchQueryCache.matches.matches.findIndex(({ id }: Match) => id === matchId);
          const match = matchQueryCache.matches.matches[idx];
          const overlays = match.overlays;
          const overlayIdx = match.overlays.findIndex(({ id: overlayId }: Overlay) => id === overlayId);
          const updated = {
            ...match,
            overlays: [...overlays.slice(0, overlayIdx), ...overlays.slice(overlayIdx + 1, overlays.length)]
          };
          cache.writeQuery({
            query: matchesQuery,
            data: {
              matches: {
                __typename: 'Matches',
                total: matchQueryCache.matches.total,
                matches: matchQueryCache.matches
                  ? [
                      ...matchQueryCache.matches.matches.slice(0, idx),
                      { ...updated },
                      ...matchQueryCache.matches.matches.slice(idx + 1, matchQueryCache.matches.matches.length)
                    ]
                  : []
              }
            }
          });
        }
      } catch (e) {
        console.log(e);
        // We should always catch here,
        // as the cache may be empty or the query may fail
      }
    }
  });
};

const getUnusedName = (overlays: Overlay[]) => {
  const genName = (val: string | number) => `Overlay ${val}`;
  const currentNumber = overlays.length + 1;
  let currentName = genName(currentNumber);
  while (overlays.find(({ name }) => currentName === name)) {
    currentName = genName(currentNumber + 1);
  }
  return currentName;
};

export const createOverlay = async (match: Match, variables: object, createOverlayM: MutationFunction) => {
  const usedMatch = match;
  await createOverlayM({
    variables: {
      createOverlayInput: {
        name: hri
          .random()
          .split('-')
          .slice(0, 2)
          .join('-'),
        matchId: match.id
      }
    },
    update: (cache, { data }) => {
      try {
        const matchQueryCache = cache.readQuery<{
          matches: {
            matches: Match[];
            total: number;
          };
        }>({
          query: matchesQuery,
          variables
        });
        if (matchQueryCache && data) {
          const idx = matchQueryCache.matches.matches.findIndex(({ id }: Match) => id === usedMatch.id);
          const match = matchQueryCache.matches.matches[idx];
          const overlays = match.overlays;
          cache.writeQuery({
            query: matchesQuery,
            data: {
              ...matchQueryCache,
              matches: {
                ...matchQueryCache.matches,
                matches: matchQueryCache.matches
                  ? [
                      ...matchQueryCache.matches.matches.slice(0, idx),
                      { ...match, overlays: [...overlays, data.createOverlay] },
                      ...matchQueryCache.matches.matches.slice(idx + 1, matchQueryCache.matches.matches.length)
                    ]
                  : []
              }
            }
          });
        }
      } catch (e) {
        console.log(e);
        // We should always catch here,
        // as the cache may be empty or the query may fail
      }
    }
  });
};
