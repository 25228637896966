import { Logo } from '@ligr/shared';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppPreviewAndSlogan } from '../../components/AppPreview';
import { routes } from '../../router/Routes';
import { LoginForm } from './forgot-password.form';
import './forgot-password.page.scss';

export const ForgotPasswordPage = () => (
  <div className="forgot-password">
    <div className="left-panel">
      <Link to={routes.login()}>
        <Logo type="color" />
      </Link>
      <LoginForm />
      <div>
        Don't have a LIGR account?{' '}
        <Link to={routes.register()} className="signup">
          Sign up
        </Link>
      </div>
    </div>
    <AppPreviewAndSlogan />
  </div>
);
