import { EFinishedStatus, Match } from '../../api-types';
import { CONFIG, Icon, Image } from '@ligr/shared';
import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import { Notification } from '../../lib/notificationContainer';
import { useArchiveMatch } from '../../lib/useArchiveMatch';
import { routes } from '../../router/Routes';
import { ConfirmWindow } from '../ConfirmWindow';
import { ConfirmWindowMatchContent } from '../ConfirmWindow/Content/MatchContent';
import { ContextMenu } from '../ContextMenu';
import './style.scss';

interface CompletedMatchCardProps {
  match: Match;
}

export const CompletedMatchCard: React.FunctionComponent<CompletedMatchCardProps> = ({ match }) => {
  const {
    id,
    finishedStatus,
    date,
    coverage,
    homeTeam,
    awayTeam,
    summary: {
      matchStats: { score }
    }
  } = match;
  const { name: homeTeamName } = homeTeam;
  const homeTeamLogo = homeTeam.logo?.url;
  const { name: awayTeamName } = awayTeam;
  const awayTeamLogo = awayTeam.logo?.url;
  const { home: homeTeamScore, away: awayTeamScore } = score;

  const { orgId } = useParams();
  const history = useHistory<{ referrer?: string } | undefined>();
  const { archiveMatch } = useArchiveMatch();
  const { addNotification } = Notification.useContainer();

  const archiveMatchFn = async (matchId: number) => {
    archiveMatch(matchId);
    addNotification('Match archived successfully.', 'success');
  };

  const ctxMenuitems = [
    {
      icon: 'settings',
      text: 'Match Settings',
      action: () => {
        history.push(history.location?.state?.referrer || routes.editMatchFromLive({ matchId: id }));
      }
    },
    {
      icon: 'liveScore',
      text: 'Open LiveScore',
      action: () => window.open(`${CONFIG.LIVE_SCORE_URL}/o/${orgId}/match/${id}`, '_blank')
    },
    {
      component: (
        <ConfirmWindow
          action={() => archiveMatchFn(match.id)}
          title={`Archive match`}
          content={<ConfirmWindowMatchContent match={match} />}
          className="contextMenu__item"
        >
          <Icon icon="close" className="contextMenu__icon" />
          <span className="contextMenu__text">Archive match</span>
        </ConfirmWindow>
      )
    }
  ];

  const expired =
    finishedStatus === EFinishedStatus.pregame &&
    new Date().getTime() > moment(date).toDate().getTime() + 1000 * 60 * 60 * 4;

  const notstarted =
    finishedStatus === EFinishedStatus.pregame &&
    new Date().getTime() > moment(date).toDate().getTime() - 1000 * 60 * 60 * 4 &&
    new Date().getTime() < moment(date).toDate().getTime();

  return (
    <ContextMenu
      id={`match-${id}`}
      // @ts-ignore
      items={ctxMenuitems}
      place="left"
    >
      {' '}
      <div className="completedMatchCard">
        <div
          className={classnames('completedMatchCard__status', {
            'completedMatchCard__status--cancelled': finishedStatus === EFinishedStatus.cancelled,
            'completedMatchCard__status--expired': expired
          })}
        >
          <div>{notstarted ? 'Not Started' : expired ? 'Expired' : finishedStatus}</div>

          <div className={'completedMatchCard__status__date'}>{moment(date).format('MMM D Y, h:mma')}</div>
        </div>
        <div
          className={classnames('completedMatchCard__team', {
            'completedMatchCard__team--winner': homeTeamScore > awayTeamScore
          })}
        >
          <div className="completedMatchCard__teamLogo">
            <Image url={homeTeamLogo} placeholder="team" />
          </div>
          <div className="completedMatchCard__teamName">{homeTeamName}</div>
          <div className="completedMatchCard__teamScore">{homeTeamScore}</div>
        </div>
        <div
          className={classnames('completedMatchCard__team', {
            'completedMatchCard__team--winner': homeTeamScore < awayTeamScore
          })}
        >
          <div className="completedMatchCard__teamLogo">
            <Image url={awayTeamLogo} placeholder="team" />
          </div>
          <div className="completedMatchCard__teamName">{awayTeamName}</div>
          <div className="completedMatchCard__teamScore">{awayTeamScore}</div>
        </div>
      </div>
    </ContextMenu>
  );
};
