import { Icon } from '@ligr/shared';
import React from 'react';

interface UploadImageFieldProps {
  disabled?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
}

export const UploadImageField: React.FunctionComponent<UploadImageFieldProps> = ({ disabled, handleChange }) => {
  return (
    <>
      <div className="center">
        <Icon icon="upload" iconSize="medium" />
        <span>Upload image</span>
      </div>
      <input
        className="image-field__input"
        type="file"
        value=""
        disabled={disabled}
        onChange={disabled ? undefined : handleChange}
      />
    </>
  );
};
