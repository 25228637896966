import { useFormikContext } from 'formik';
import React from 'react';
import { UpdateClubInput, VenuesQuery } from '../../../../api-types';
import { Button } from '../../../../components/Button';
import { ButtonBottom as BottomButtonRow } from '../../../../components/ButtonBottom/ButtonBottom';
import { FormField } from '../../../../components/FormField';
import { Loader } from '../../../../components/loader/Loader';
import { Title } from '../../../../components/Title/Title';
import { useErrorHandledMutation, useErrorHandledQuery } from '../../../../lib/apolloHooks';
import { deleteFileMutation } from '../../../../lib/gql/mutations/deleteFile';
import { clubQuery } from '../../../../lib/gql/queries/clubs';
import { venuesQuery } from '../../../../lib/gql/queries/venues';

export interface ClubFormProps {
  club: {
    id: number;
    name: string;
    gfxName: string;
    gfxFullName?: string;
    abbreviation: string;
    primaryBackgroundColor?: string | null;
    primaryTextColor?: string | null;
    secondaryBackgroundColor?: string | null;
    secondaryTextColor?: string | null;
    logo?: {
      url: string;
    } | null;
    defaultVenue?: {
      id: number;
    } | null;
  };
  loading?: boolean;
}

type Deletable = {
  id: number;
};

export const ClubForm: React.FunctionComponent<ClubFormProps> = ({ club, loading }) => {
  const form = useFormikContext<UpdateClubInput>();
  const { data, loading: initialLoading } = useErrorHandledQuery<VenuesQuery>(venuesQuery);
  const [deleteFile] = useErrorHandledMutation<boolean>(deleteFileMutation);

  const handleDelete = async (name: string) => {
    const id = (club[name as keyof typeof club] as Deletable)?.id;

    if (id) {
      await deleteFile({
        variables: { id },
        update: (cache: any, { data }: any) => {
          const clubCache = cache.readQuery({ query: clubQuery, variables: { id: club.id } });
          if (clubCache && data.deleteFile) {
            cache.writeQuery({
              query: clubQuery,
              variables: { id: club.id },
              data: {
                club: {
                  ...clubCache.club,
                  [name]: null
                }
              }
            });
          }
        }
      });
      form?.resetForm();
    }
  };

  if (initialLoading) return <Loader />;

  return (
    <>
      <div>
        <Title is3>Club name & venue</Title>
        <FormField name="name" type="text" label="Club Name" />
        <FormField
          name="defaultVenueId"
          type="select"
          placeholder={'Select Venue'}
          options={data?.venues.map(v => ({ label: v.name, value: v.id })) || []}
          label="Default Venue"
        />
        <Title is3>GFX Names</Title>
        <FormField name="gfxFullName" type="text" label="Full Name" />
        <FormField name="gfxName" type="text" label="Medium Name" />
        <FormField name="abbreviation" type="text" label="Short Name" />
      </div>
      <div>
        <Title is3>Club logo</Title>
        <FormField
          name="logo"
          type="image"
          colSpan={2}
          src={club?.logo?.url}
          editable
          onDelete={() => handleDelete('logo')}
        />
        <p className="pro-tip">Pro Tip: Upload a logo with a transparent background, and no padding around the logo.</p>
        <Title is3>CLub colors</Title>
        <div className="club-page__colors">
          <FormField name="primaryBackgroundColor" type="color" label="Primary Color" colSpan={1} />
          <FormField name="primaryTextColor" type="color" label="Primary Text Color" colSpan={1} />
          <FormField name="secondaryBackgroundColor" type="color" label="Secondary Color" colSpan={1} />
          <FormField name="secondaryTextColor" type="color" label="Secondary Text Color" colSpan={1} />
        </div>
        <p className="pro-tip">
          Pro Tip: If the <span className="bold">home</span> and <span className="bold">away</span> teams for a match
          have <span className="bold">Primary Colors</span> that are too similar the{' '}
          <span className="bold">Secondary Color </span>
          set of the away team will be used in the graphics for that match.
          <span>
            If the <span className="bold">Secondary Color</span> set is not provided for the{' '}
            <span className="bold">away team</span> the <span className="bold">Primary Background</span> and the{' '}
            <span className="bold">Primary Text</span> colors of the <span className="bold">away team</span> will be
            used.
          </span>
        </p>
      </div>
      {form?.dirty && (
        <BottomButtonRow withResetFormButton resetOnClick={() => form.resetForm()}>
          <Button
            onClick={event => {
              event.preventDefault();
              form.submitForm();
            }}
            isLoading={loading}
            type="primary"
          >
            Save
          </Button>
        </BottomButtonRow>
      )}
    </>
  );
};
