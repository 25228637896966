import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Page } from '../../components/page/Page';
import { PageMenu } from '../../components/PageMenu';
import { withTitle } from '../../components/PageTitle/PageTitle';
import { MenuAssets } from '../../router/menus';
import { routes } from '../../router/Routes';
import { CompetitionsRouter } from './Competition/Competitions.router';
import { PlayersRouter } from './Players/Players.router';
import { TeamsRouter } from './Team/TeamsRouter';
import { VenuesRouter } from './Venue/VenuesRouter';

export const AssetsRouter: React.FunctionComponent = () => {
  return (
    <Page type="assets">
      <PageMenu {...MenuAssets} />
      <Switch>
        <Route
          path={routes.assetsCompetitions(false)}
          component={withTitle({ component: CompetitionsRouter, pageTitle: 'Competitions' })}
        />
        <Route
          path={[routes.assetTeams(false), routes.assetClubs(false)]}
          component={withTitle({ component: TeamsRouter, pageTitle: 'Clubs & Teams' })}
        />
        <Route
          path={routes.assetsPlayers(false)}
          component={withTitle({ component: PlayersRouter, pageTitle: 'Players' })}
        />
        <Route
          path={routes.assetVenues(false)}
          component={withTitle({ component: VenuesRouter, pageTitle: 'Venues' })}
        />
        <Route path={'*'} render={() => <Redirect to={routes.assetsCompetitions()} />} />
      </Switch>
    </Page>
  );
};
