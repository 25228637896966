import { RugbyLeagueMatchConfig } from '@ligr/api-v2';
import { FormikProps, useFormikContext, FormikContextType } from 'formik';
import React from 'react';
import { FormField } from '../../../components/FormField';
import { Title } from '../../../components/Title/Title';
import { MatchFormValues } from '../NewMatchPage/Form/types';

export const RugbyUnionMatchFormConfigFields: React.FunctionComponent<FormikProps<MatchFormValues> & {
  disabled: boolean;
  configKey: string;
  form: FormikContextType<any>;
}> = props => {
  const { disabled } = props;
  const values = props.form.values;

  const config = values.config as RugbyLeagueMatchConfig;

  return (
    <>
      <section className="rugbyUnionConfigFields__matchSettings">
        <Title is3 className="">
          Match settings
        </Title>
        <FormField
          type="text"
          name="config.regularPeriodLength"
          label="Half length"
          colSpan={1}
          suffix="mins"
          disabled={disabled}
          value={values.config.regularPeriodLength}
        />
        <FormField
          type="text"
          name="config.regularHalftimeLength"
          label="Half time length"
          colSpan={1}
          suffix="mins"
          disabled={disabled}
          value={values.config.regularHalftimeLength}
        />
        <FormField
          type="switch"
          name="config.extratime"
          label="Extra time"
          colSpan={1}
          disabled={disabled}
          value={values.config.extratime}
        />
        {config?.extratime && (
          <>
            <FormField
              type="text"
              name="config.preOvertimeBreakLength"
              label="Pre Extra Time break length"
              colSpan={1}
              suffix="mins"
              disabled={disabled}
              value={values.config.preOvertimeBreakLength}
            />
            <FormField
              type="text"
              name="config.extratimePeriodLength"
              label="Extra Time half length"
              colSpan={1}
              suffix="mins"
              disabled={disabled}
              value={values.config.extratimePeriodLength}
            />
            <FormField
              type="text"
              name="config.extratimeHalftimeLength"
              label="Extra Time break length"
              colSpan={1}
              suffix="mins"
              disabled={disabled}
              value={values.config.extratimeHalftimeLength}
            />
          </>
        )}
      </section>
      <section className="rugbyUnionConfigFields__matchSettings">
        <Title is3 className="">
          Data Coverage
        </Title>
        <FormField
          type="radio-tab"
          name="config.dataCoverage.stats"
          options={[
            { value: 'minimum', label: 'Minimum' },
            { value: 'standard', label: 'Standard' }
          ]}
          colSpan={2}
          disabled={disabled}
          value={values.config.dataCoverage.stats}
        />
        <FormField
          type="switch"
          name="config.dataCoverage.players"
          label="Players"
          colSpan={1}
          disabled={disabled}
          value={values.config.dataCoverage.players}
        />
        <FormField
          type="switch"
          name="config.dataCoverage.possession"
          label="Possession"
          colSpan={1}
          disabled={disabled}
          value={values.config.dataCoverage.possession}
        />
      </section>
    </>
  );
};
