import { Icon } from '@ligr/shared';
import React from 'react';
import { useHistory } from 'react-router';
import { ConfirmWindow } from '../../../../components/ConfirmWindow';
import { ConfirmWindowClubContent } from '../../../../components/ConfirmWindow/Content/ClubContent';
import { ContextMenu } from '../../../../components/ContextMenu';
import { Notification } from '../../../../lib/notificationContainer';
import { useArchiveClub } from '../../../../lib/useArchiveClub';
import { routes } from '../../../../router/Routes';

type Club = {
  name: string;
  id: number;
  archived: boolean;
  logo?: {
    url: string;
  } | null;
  teams?:
    | {
        countMatches: number;
        countPlayers: number;
        archived: boolean;
        competitions?:
          | {
              name: string;
              id: number;
              logo?: {
                url: string;
              } | null;
            }[]
          | null;
      }[]
    | null;
};

interface ClubMenuProps {
  club: Club;
}

export const ClubMenu: React.FunctionComponent<ClubMenuProps> = ({ club }) => {
  const { archiveClub } = useArchiveClub();
  const { addNotification } = Notification.useContainer();
  const history = useHistory<{ referrer?: string } | undefined>();

  const archiveClubFn = async (clubId: number) => {
    await archiveClub(clubId);
    addNotification('Club archived successfully.', 'success');
    history.push(routes.assetTeams());
  };

  return (
    <ContextMenu
      place="bottom"
      clickable={true}
      className="pageHeader__menu clubMenu"
      renderMenu={() => {
        return (
          <ul>
            <li>
              <ConfirmWindow
                action={() => archiveClubFn(club.id)}
                title={`Archive club`}
                content={<ConfirmWindowClubContent club={club} />}
              >
                <Icon icon="close" />
                <span>Archive club</span>
              </ConfirmWindow>
            </li>
          </ul>
        );
      }}
    >
      <Icon icon="dropdown" color="grey-600" />
    </ContextMenu>
  );
};
