import React from 'react';
import { Match, SummaryFragment } from '../../../../../api-types';
import { footballStatKeyToNameMap } from '@ligr/api-v2';
import { Select } from '../../../../Select';
import { Checkbox } from '../../../../Checkbox/Checkbox';
import { TriggerProps } from '@ligr/themes/lib/football/themes/default/Scoreboard/types';

import './style.scss';

interface ScoreboardCustomProps {
  value?: TriggerProps;
  onChange: (update: TriggerProps) => any;
  match: Match;
  disabled?: boolean;
  summary?: SummaryFragment;
}

// FULLY CONTROLLED ONLY
export const Scoreboard: React.FunctionComponent<ScoreboardCustomProps> = ({
  disabled,
  onChange,
  value = {},
  match,
  summary
}) => {
  const teamType = value.teamId === match.awayTeamId ? 'away' : 'home';

  return (
    <div className="scoreboard">
      <div className="scoreboard__preset">
        <Checkbox
          disabled={!value.teamId || !value.primaryStatName}
          checked={value.preset === 'teamStats'}
          onChange={() => {
            // @ts-ignore
            return onChange({
              ...value,
              preset: value.preset !== 'teamStats' ? 'teamStats' : 'default'
            });
          }}
          label="Show Team Stat/s"
        />{' '}
        <div> Include </div>
      </div>
      <div className="scoreboard__team">
        <Select
          disabled={disabled}
          value={value.teamId}
          onChange={event => {
            // @ts-ignore
            return onChange({ ...value, teamId: parseInt(event.target.value) });
          }}
          label="Team"
          options={[
            {
              value: null,
              label: 'Select Team'
            },
            {
              value: match.homeTeam.id,
              label: match.homeTeam.gfxName || match.homeTeam.name
            },
            {
              value: match.awayTeam.id,
              label: match.awayTeam.gfxName || match.awayTeam.name
            }
          ]}
        />
      </div>
      <div className="scoreboard__stat">
        <Select
          disabled={disabled}
          value={value.primaryStatName}
          onChange={event => {
            // @ts-ignore
            return onChange({ ...value, primaryStatName: event.target.value });
          }}
          label="First Stat"
          // @ts-ignore
          options={([
            {
              value: null,
              label: 'Select Stat'
            }
          ] as {
            value: null | string;
            label: string;
          }[]).concat(
            Object.entries(footballStatKeyToNameMap).map(([val, label]) => ({
              value: val,
              // @ts-ignore
              label: label + ` ${value.teamId ? summary?.matchStats[teamType][val] : ''}`
            }))
          )}
        />
        {/*<Select
        disabled={disabled}
        value={value.secondaryTeamStat}
        onChange={event => {
          // @ts-ignore
          return onChange({ ...value, secondaryTeamStat: event.target.value });
        }}
        label="Second Stat"
        // @ts-ignore
        options={[{
          value: '',
          label: 'None'
          // @ts-ignore
        }].concat(Object.entries(statKeyToNameMap).map(([value, label]) => ({
          value,
          label
        })))}
      />*/}
      </div>
    </div>
  );
};
