import { updateQueryUrl } from '@ligr/shared';
import capitalize from 'capitalize';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { FilterOptions } from '.';
import { Age } from '../../api-types';
import { Select } from '../Select';
import { SelectOption } from '../Select/types';

interface AgeFilterProps {
  setActiveFilter: (name: FilterOptions, value: string) => void;
}

export const AgeFilter: React.FunctionComponent<AgeFilterProps> = ({ setActiveFilter }) => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const queryStrObject = queryString.parse(history.location.search);

  const options: SelectOption[] = useMemo(() => {
    const ageOptions = Object.values(Age).map(age => ({
      value: age,
      label: capitalize(age)
    })) as SelectOption[];

    return [{ value: '', label: 'All' } as SelectOption].concat(ageOptions);
  }, []);

  const handleChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const val = (e.target as HTMLSelectElement).value;
    setActiveFilter('age', val);
    updateQueryUrl(history, { age: val, competitions: '' });
  };

  return (
    <div className="filters__select__wrapper filters__select__wrapper--age">
      <label className="filters__select__wrapper__label" htmlFor="age">
        Age Group
      </label>
      <Select
        name="age"
        className="filters__select__wrapper__select"
        options={options}
        onChange={(e: React.FormEvent<HTMLSelectElement>) => handleChange(e)}
        value={queryStrObject?.age || ''}
      />
    </div>
  );
};
