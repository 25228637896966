import gql from 'graphql-tag';
import { summaryFragment } from '../fragments/summary';

export const matchSummaryQuery = gql`
  query matchSummary($matchId: Float!) {
    matchSummary(matchId: $matchId) {
      ...Summary
    }
  }
  ${summaryFragment}
`;
