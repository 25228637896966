import React from 'react';
import { rugbyUnionFactNameMap } from '@ligr/api-v2';
import { Match, Team, Fact } from '../../../../../api-types';
import { Select } from '../../../../Select';
import { DataProps } from '@ligr/themes/lib/sports/themes/rugbyunion1/Templates/Event/types';
import './style.scss';
import { SubstitutionFact } from '@ligr/api-v2/dist/types/facts/Facts';

interface EventCustomProps {
  value?: DataProps;
  onChange: (update: DataProps) => any;
  match: Match;
  disabled?: boolean;
  facts: Fact[];
}

// FULLY CONTROLLED ONLY
export const Event: React.FunctionComponent<EventCustomProps> = ({
  disabled,
  onChange,
  value = {},
  facts = [],
  match
}) => {
  const homeTeam = match.homeTeam;
  const awayTeam = match.awayTeam;

  const getFactTeam = (teamId: number) => {
    if (!teamId) {
      return '';
    }
    return teamId === homeTeam.id ? homeTeam.gfxName : awayTeam.gfxName;
  };

  return (
    <div className="event">
      <Select
        disabled={disabled}
        value={value.factId}
        onChange={event => {
          // @ts-ignore
          onChange({ factId: parseInt(event.target.value) });
        }}
        label="Event"
        options={([
          {
            value: null,
            label: 'Select Event'
          }
        ] as { value: null | number; label: string }[]).concat(
          facts
            ?.filter(fact => (fact.name as string) !== SubstitutionFact.SUBSTITUTION)
            .sort((a, b) => b.minute! - a.minute!)
            .map(fact => ({
              // @ts-ignore
              label: `${rugbyUnionFactNameMap[fact.name]} ${getFactTeam(fact.teamId)} ${fact.minute}\``,
              value: fact.id
            }))
        )}
      />
    </div>
  );
};
