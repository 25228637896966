import './page.scss';

import classnames from 'classnames';
import React from 'react';

import { PageProps } from './Page.types';

export const Page: React.FunctionComponent<PageProps> = ({ type, children }) => (
  <main className={classnames('page', type)}>{children}</main>
);
