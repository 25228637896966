import React, { useEffect, useMemo, useState } from 'react';
import './SelectionOverview.scss';
import { Selection } from './Subscription.type';

interface SelectionOverviewProps {
  selection: Selection;
}

export const formatter = new Intl.NumberFormat('en-AU');

export const SelectionOverview: React.FunctionComponent<SelectionOverviewProps> = ({ selection }) => {
  const [extraMatches, toggleExtraMatches] = useState(false);

  useEffect(() => {
    if (
      selection.plan &&
      !selection.plan.free &&
      !selection.plan.custom &&
      selection.quantity &&
      selection.quantity >= (selection.plan.limit || 100)
    ) {
      toggleExtraMatches(true);
    } else {
      toggleExtraMatches(false);
    }
  }, [selection]);

  if (!selection.plan) return null;

  const price = useMemo(() => {
    if (selection.plan) {
      if (selection.plan.free) {
        return <span className="free">FREE</span>;
      }
      if (selection.plan.custom) {
        return <span className="custom">N/A</span>;
      }
      if (selection.quantity) {
        if (selection.plan.brackets) {
          const bracket = selection.plan.brackets.find(({ upTo }) => selection.quantity! <= upTo);
          if (bracket) {
            return <span>{formatter.format(bracket.price)}</span>;
          }
        }
      }
    }
  }, [selection]);

  return (
    <div className="selection-overview">
      <div className="plan-title">
        {selection.plan.name} {selection.plan.title}
      </div>
      <div className="plan-description">
        {selection.plan.description}
        {extraMatches && (
          <>
            <br />
            <b>
              Need more matches? Please{' '}
              <a href="//www.ligrsystems.com/contact-us" target="_blank">
                Contact us
              </a>
            </b>
          </>
        )}
      </div>
      {selection.quantity !== undefined && <div className="plan-price">{price}</div>}
    </div>
  );
};
