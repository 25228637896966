import React from 'react';
import { NavLink } from 'react-router-dom';
import { logDelay } from '../../lib/animation';

export const SideNav: React.FunctionComponent<{ tabs: { [text: string]: string } }> = ({ tabs }) => (
  <div className="sideTabs">
    {Object.entries(tabs).map(([text, route], i) => (
      <NavLink key={i} to={route} {...logDelay(i)}>
        {' '}
        {text}{' '}
      </NavLink>
    ))}
  </div>
);
