import { Icon, IconType } from '@ligr/shared';
import classname from 'classnames';
import React from 'react';
import { InputField } from '../InputField';
import { Select } from '../Select';
import { TimeFieldProps } from './TimeField.types';
import './timefield.scss';

export const TimeField: React.FunctionComponent<TimeFieldProps> = ({
  onChange,
  value,
  name,
  error,
  icon,
  iconPosition,
  iconColor,
  disabled,
  className
}) => {
  const err = error ? error.toString() : null;
  let icn: IconType | undefined = err ? 'error' : icon;
  let icnPosition = iconPosition;
  let icnColor = err ? 'red' : iconColor;

  if (disabled) {
    icn = 'lock';
    icnColor = 'grey-600';
  }
  if (err) icnPosition = 'right';

  return (
    <div
      className={classname('timeField', className, {
        'icon-right': icnPosition === 'right',
        'timeField--disabled': disabled,
        error
      })}
    >
      {icn && <Icon icon={icn} color={icnColor} className={icn} />}
      <div className="time-wrapper">
        <InputField
          name={`${name}.hours`}
          placeholder="hh"
          className="hours"
          value={value.hours}
          maxLength={2}
          onChange={onChange}
          readOnly={disabled}
          type="text"
        />
        <span>:</span>
        <InputField
          name={`${name}.minutes`}
          placeholder="mm"
          className="minutes"
          value={value.minutes}
          maxLength={2}
          readOnly={disabled}
          onChange={onChange}
          type="text"
        />
        <Select
          name={`${name}.ampm`}
          value={value.ampm}
          options={[
            { value: 'am', label: 'AM' },
            { value: 'pm', label: 'PM' }
          ]}
          onChange={onChange}
          readOnly={disabled}
        />
      </div>
    </div>
  );
};
