import { useFormikContext } from 'formik';
import React from 'react';
import { CreateCompetitionValues } from '.';
import { Button } from '../../../../components/Button';
import { ButtonBottom as BottomButtonRow } from '../../../../components/ButtonBottom/ButtonBottom';
import { FormField } from '../../../../components/FormField';
import { SelectEntity } from '../../../../components/SelectEntity';

interface NewCompetitionPageFormProps {
  loading: boolean;
}

const waterMarkText = (
  <div>
    <p>500 kB or less, 300 x 300 pixels. Drop any .jpg, .png, .gif, or browse your files.</p>
    <p>Pro Tip: Upload a logo with a transparent background, and no padding around the logo.</p>
  </div>
);

export const NewCompetitionPageFormContent: React.FunctionComponent<NewCompetitionPageFormProps> = ({ loading }) => {
  const form = useFormikContext<CreateCompetitionValues>();

  const imageFields = [
    { name: 'logo', label: 'Competition logo' },
    { name: 'watermark', label: 'Primary watermark' },
    { name: 'secondaryWatermark', label: 'Secondary watermark' }
  ];

  return (
    <>
      <FormField
        name="sportId"
        placeholder="Select a sport"
        component={SelectEntity}
        entity="sports"
        label="Sport"
        colSpan={1}
        value={form.values.sportId}
      />
      <FormField name="name" type="text" label="Competition name" colSpan={1} />
      <FormField name="gfxName" type="text" label="Gfx name" colSpan={1} />
      {imageFields.map(i => (
        <FormField
          key={i.name}
          name={i.name}
          type="image"
          label={i.label}
          colSpan={1}
          tooltip={waterMarkText}
          editable
        />
      ))}
      {form?.dirty && (
        <BottomButtonRow withResetFormButton resetOnClick={() => form.resetForm()}>
          <Button
            onClick={event => {
              event.preventDefault();
              form.submitForm();
            }}
            isLoading={loading}
            type="primary"
          >
            Save
          </Button>
        </BottomButtonRow>
      )}
    </>
  );
};
