import { Icon, IconType } from '@ligr/shared';
import classname from 'classnames';
import { FormikProps } from 'formik';
import React, { HTMLProps, useMemo } from 'react';
import { Title } from '../Title/Title';
import './style.scss';

export interface ColorFieldProps extends HTMLProps<HTMLInputElement> {
  icon?: IconType;
  iconPosition?: 'left' | 'right';
  iconColor?: string;
  error?: string | Error;
  formik?: FormikProps<any>;
  round?: boolean;
}

const textColor = (hex: string) => {
  if (hex === '') return 'white';

  const c = hex.substring(1); // strip #
  const rgb = parseInt(c, 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  return luma < 150 ? 'white' : 'black';
};

export const ColorField: React.FunctionComponent<ColorFieldProps> = ({
  icon,
  iconColor,
  iconPosition,
  className,
  error,
  disabled,
  formik,
  round,
  ...props
}) => {
  const value = (props.value || '').toString();
  const color = useMemo(() => textColor(value), [value]);

  const reset = () => {
    if (formik) formik.setFieldValue(props.name!, '');
  };

  return (
    <div
      className={classname('colorField', className, {
        'colorField--disabled': disabled,
        'colorField--error': error,
        'colorField--noValue': !value,
        'colorField--round': round
      })}
      style={{
        color,
        backgroundColor: value
      }}
    >
      {!round && value && <Icon icon="remove" className="colorField__icon" onClick={reset} />}
      <input className="colorField__input" type="color" {...props} disabled={disabled} />
      {!round && (
        <Title is4 className="colorField__title">
          {value}
        </Title>
      )}
    </div>
  );
};
