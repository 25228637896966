import { ImagePlaceholderType } from '@ligr/shared';
import gql from 'graphql-tag';
import React, { useMemo } from 'react';
import { ClubsAndTeamsMenuQuery, FileEntityType } from '../../../../api-types';
import { ButtonDrop } from '../../../../components/ButtonDrop';
import { ClubItem } from '../../../../components/CubItem';
import { SideMenuItem } from '../../../../components/SideMenuItem';
import { SideMenuList, SideMenuListItem } from '../../../../components/SideMenuList';
import { useErrorHandledQuery } from '../../../../lib/apolloHooks';
import { routes } from '../../../../router/Routes';

export const clubsAndTeamsMenuQuery = gql`
  query clubsAndTeamsMenu {
    clubsAndTeams {
      ... on Club {
        id
        name
        logo {
          id
          url
        }
        teams {
          id
          age
          grade {
            id
            name
          }
          gender
        }
      }
      ... on Team {
        id
        name
        logo {
          id
          url
        }
      }
    }
  }
`;

interface EntityType {
  id: number;
  logo?: {
    url: string;
    id: number;
  } | null;
  __typename: string;
  name: string;
}

interface Club extends EntityType {
  teams: {
    id: number;
    age: number;
    gender: string;
    grade: {
      name: string;
    };
  }[];
}

export const TeamsSideMenu: React.FunctionComponent = () => {
  const { data } = useErrorHandledQuery<ClubsAndTeamsMenuQuery>(clubsAndTeamsMenuQuery);
  const entities = data?.clubsAndTeams || [];

  const items = useMemo(() => {
    return entities.map(e => ({
      imageUrl: e.logo?.url,
      text: e.name,
      to: e.__typename === 'Club' ? routes.assetClub({ clubId: e.id }) : routes.assetTeam({ teamId: e.id }),
      imagePlaceholder: 'team' as ImagePlaceholderType,
      type: e.__typename,
      subItems:
        e.__typename === 'Club'
          ? e.teams?.map(t => ({
              id: t.id,
              text: `${t.grade.name} - ${t.age} - ${t.gender}`,
              to: routes.assetTeam({ teamId: t.id })
            }))
          : undefined
    }));
  }, [entities]);

  const linkComponent = (
    <ButtonDrop
      icon="plus"
      // onClick={() => history.push({ pathname: routes.assetTeamCreate(), search: location.search })}
      options={[
        { text: 'Create a club', url: routes.assetClubCreate() },
        { text: 'Create a team', url: routes.assetTeamCreate() }
      ]}
      isSquare
    />
  );
  return (
    <SideMenuList
      items={items}
      entityType={FileEntityType.club}
      renderItem={(i: SideMenuListItem) => {
        if (i.type === 'Club') return <ClubItem item={i} />;
        return <SideMenuItem item={i} />;
      }}
      linkComponent={linkComponent}
    />
  );
};
