import { Icon, IconType } from '@ligr/shared';
import classnames from 'classnames';
import React, { HTMLProps } from 'react';
import { Notification } from '../../lib/notificationContainer';
import { useArchiveAdSet } from '../../lib/useArchiveAdSet';
import { routes } from '../../router/Routes';
import { AdSetItem } from '../AdSetItem';
import { Button } from '../Button';
import { Card } from '../Card/Card';
import { ConfirmWindow } from '../ConfirmWindow';
import { ConfirmWindowAdSetContent } from '../ConfirmWindow/Content/AdSetContent';
import './style.scss';

type AdSet = {
  countAdAllocations: number;
  archived: boolean;
  name: string;
  id: number;
  adFiles:
    | {
        size: string;
        id: number;
        file?: {
          url: string;
        } | null;
      }[]
    | null;
};

interface CardAdSetProps extends Partial<HTMLProps<HTMLDivElement>> {
  adSet: AdSet;
  to?: string;
  fn?: () => void;
  icon?: IconType;
}

export const CardAdSet: React.FunctionComponent<CardAdSetProps> = ({ adSet, to, fn, icon, ...rest }) => {
  const { restoreAdSet } = useArchiveAdSet();
  const { addNotification } = Notification.useContainer();

  const smallAdSet = adSet.adFiles?.find(as => as.size === 'small');

  return (
    <Card
      to={adSet.archived ? '' : to || routes.adSet({ adSetId: adSet.id })}
      className={classnames('cardAdSet', {
        'card--archived': adSet.archived
      })}
      title={adSet.name}
      {...rest}
    >
      {adSet.archived && (
        <div className="card--archived__overlay">
          <Button
            onClick={() => {
              restoreAdSet(adSet.id);
              addNotification('Ad set restored successfully.', 'success');
            }}
          >
            Restore
          </Button>
        </div>
      )}

      {!adSet.archived && fn && icon && (
        <ConfirmWindow action={fn} title={`Archive ad set`} content={<ConfirmWindowAdSetContent adSet={adSet} />}>
          <Icon icon={icon} />
        </ConfirmWindow>
      )}
      <AdSetItem
        url={smallAdSet?.file?.url}
        name={adSet.name}
        count={adSet.adFiles?.length || 0}
        to={routes.adSetImageAds({ adSetId: adSet.id })}
      />
    </Card>
  );
};
