import gql from 'graphql-tag';

export const sportsQuery = gql`
  query sports {
    sports {
      id
      name
    }
  }
`;
