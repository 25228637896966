import gql from 'graphql-tag';

export const updateMatchQuery = gql`
  mutation updateMatch($updateMatchInput: UpdateMatchInput!) {
    updateMatch(updateMatchInput: $updateMatchInput) {
      id
      name
      awayTeam {
        id
        name
      }
      homeTeam {
        id
        name
      }
      competitionId
      date
      competition {
        id
        name
      }
      baseEntity {
        id
        providerId
        providerName
      }
      overlays {
        id
        matchId
        encodingDelay
        autoMode
        resolution
        showWatermarks
        background
        enableAds
        key
      }
      config
      sportName
      summary {
        matchId
        currentPeriod
      }
    }
  }
`;
