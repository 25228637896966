import { Icon } from '@ligr/shared';
import React from 'react';
import './checkbox.scss';
import { CheckboxProps } from './Checkbox.types';
import classNames from 'classnames';

export const Checkbox: React.FunctionComponent<CheckboxProps> = ({ ...props }) => {
  if (props.checked === undefined) props.checked = Boolean(props.value);

  return (
    <label className={classNames('checkbox', props.className)}>
      <input type="checkbox" {...props} />
      <span>{<Icon icon="check" color={'white'} />}</span>
    </label>
  );
};
