import isPlainObject from 'lodash.isplainobject';
import { FormikErrors } from 'formik';

export const findErrors = (err: FormikErrors<any>) => {
  const errors: string[] = [];
  const ee = Object.values(err);

  for (const e of ee) {
    if (Array.isArray(e)) {
      (e as string[]).map(error => Object.values(error).map((emessage: string) => errors.push(emessage)));
    }
    if (isPlainObject(e)) {
      Object.values(e as FormikErrors<any>).map((emsg: string) => errors.push(emsg));
    }
    if (typeof e === 'string') {
      errors.push(e);
    }
  }
  return errors;
};
