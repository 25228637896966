import { useSearchParams } from '@ligr/shared';
import { ApolloQueryResult } from 'apollo-client';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { PlayersQuery } from '../../../../api-types';
import { Button } from '../../../../components/Button';
import { CardPlayer } from '../../../../components/CardPlayer';
import { Filters } from '../../../../components/Filters';
import { Grid } from '../../../../components/Grid/Grid';
import { getMissingEntitiesComponentContent } from '../../../../components/MissingEntitiesComponent/getMissingEntitiesComponentContent';
import { MissingEntitiesComponent } from '../../../../components/MissingEntitiesComponent/MissingEntitiesComponent';
import { PageContent } from '../../../../components/page/PageContent';
import { PageContentContent } from '../../../../components/page/PageContentContent';
import { PageHeader } from '../../../../components/PageHeader';
import { PageSubHeader } from '../../../../components/PageSubHeader/PageSubHeader';
import { useErrorHandledLazyQuery } from '../../../../lib/apolloHooks';
import { playersQuery } from '../../../../lib/gql/queries/players';
import { Notification } from '../../../../lib/notificationContainer';
import { useArchivePlayer } from '../../../../lib/useArchivePlayer';
import { routes } from '../../../../router/Routes';
import { EntityEnum } from '../../../../types/assets';
import './players.page.scss';

type Player = ApolloQueryResult<PlayersQuery>['data']['players'][0];

export const PlayersPage: React.FunctionComponent = () => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const searchParams = useSearchParams(['sports', 'competitions', 'teams', 'archive', 'query']);
  const [fetch, { data, loading, error, called }] = useErrorHandledLazyQuery<{ players: Player[] }>(playersQuery, {
    variables: { options: { archive: !!searchParams.archive } }
  });
  const players = (data && data.players) || [];
  const { archivePlayer } = useArchivePlayer();
  const { addNotification } = Notification.useContainer();

  // Note can pass searchParams into the fetch as GQL variables
  // if we are server side searching
  useEffect(() => {
    fetch({});
  }, []);

  const playersSearch = useMemo(() => {
    return players
      ? players.filter((p: Player) => {
          let passesFilter = true;
          if (searchParams.query) {
            const firstName = p.firstName.toLowerCase().includes(searchParams.query.toLowerCase());
            const lastName = p.lastName.toLowerCase().includes(searchParams.query.toLowerCase());
            const number = p.playsFor?.map(pf => pf.number?.toString())?.includes(searchParams.query) || false;
            passesFilter = firstName || lastName || number;
          }

          if (searchParams.teams) {
            return passesFilter && p.playsFor!.find(({ team: { id } }) => id === parseInt(searchParams.teams!));
          }

          if (searchParams.competitions) {
            return (
              passesFilter &&
              p.playsFor!.find(({ team: { competitions } }) => {
                return competitions.find(({ id }) => id === parseInt(searchParams.competitions!));
              })
            );
          }

          if (searchParams.sports) {
            return p.playsFor!.find(({ team: { competitions } }) => {
              return competitions.find(({ sport: { id } }) => id === parseInt(searchParams.sports!));
            });
          }

          return passesFilter;
        })
      : [];
  }, [searchParams, players]);

  const missingEntitiesDialogProps =
    called && !loading && getMissingEntitiesComponentContent(EntityEnum.players, players, playersSearch);

  const archivePlayerFn = async (playerId: number) => {
    archivePlayer(playerId);
    addNotification('Player archived successfully.', 'success');
  };

  return (
    <>
      <PageContent loading={!called || loading}>
        <PageHeader title="Players" />
        <PageContentContent>
          {players.length > 0 && (
            <PageSubHeader>
              <Filters searchable="players" filters={['sports', 'competitions', 'teams', 'archive']} />
              <Button
                icon="plus"
                iconPosition="left"
                shrink={false}
                onClick={() => history.push({ pathname: routes.assetPlayerCreate(), search: location.search })}
              >
                Create a player
              </Button>
            </PageSubHeader>
          )}
          {missingEntitiesDialogProps ? (
            <MissingEntitiesComponent {...missingEntitiesDialogProps} />
          ) : (
            <Grid>
              {playersSearch.map((p: Player, i: number) => (
                <CardPlayer
                  key={i}
                  player={p}
                  to={{
                    pathname: routes.assetPlayerEdit({ playerId: p.id }),
                    search: location.search
                  }}
                  icon="close"
                  fn={() => archivePlayerFn(p.id)}
                />
              ))}
            </Grid>
          )}
        </PageContentContent>
      </PageContent>
    </>
  );
};
