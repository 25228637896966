import React from 'react';
import { Match } from '../../../../../api-types';
import { Select } from '../../../../Select';
import { TriggerProps } from '@ligr/themes/lib/football/themes/default/TeamStats/types';
import './style.scss';

interface TeamStatsCustomProps {
  value?: TriggerProps;
  onChange: (update: TriggerProps) => any;
  match: Match;
  disabled?: boolean;
}

// FULLY CONTROLLED ONLY
export const TeamStats: React.FunctionComponent<TeamStatsCustomProps> = ({ disabled, onChange, value = {}, match }) => {
  return (
    <div className="teamstats">
      <div className="teamstats__period">
        <Select
          disabled={disabled}
          value={value.period}
          onChange={event => {
            // @ts-ignore
            onChange({ ...value, periodCode: event.target.value });
          }}
          label="Period"
          options={[
            {
              value: '',
              label: 'Total'
            },
            ...match.summary.periods
              .filter(({ status, started }) => status === 'live' && started)
              .map(period => ({
                label: period.name,
                value: period.code
              }))
          ]}
        />
      </div>
    </div>
  );
};
