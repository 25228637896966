import './style.scss';

import React from 'react';
import { SportsWithSpaceEnum, FactName } from '@ligr/api-v2';
import { mapFactNameBySport } from '@ligr/shared';
import moment from 'moment';
import { EFinishedStatus } from '@ligr/themes/lib/api-types';
import { useParams } from 'react-router';

import { Match, MatchesQuery } from '../../../../api-types';
import { Title } from '../../../../components/Title/Title';
import { useErrorHandledQuery } from '../../../../lib/apolloHooks';
import { matchesQuery } from '../../../../lib/gql/queries/matches';
import { Loader } from '../../../../components/loader/Loader';
import { Scores, TMatch } from './Scores';
import { TPlayerFact } from '../types';

interface PlayAndScoreTabProps {
  playerFacts: TPlayerFact[] | undefined;
  competitionId: number;
  sportName: SportsWithSpaceEnum;
}

export const PlayAndScoreTab: React.FunctionComponent<PlayAndScoreTabProps> = ({
  playerFacts,
  competitionId,
  sportName
}) => {
  const { overlayId } = useParams();
  const { data, loading } = useErrorHandledQuery<MatchesQuery>(matchesQuery, {
    variables: { finishedStatus: EFinishedStatus.inProgress, competitionId }
  });
  const matches = data?.matches?.matches.filter((m: Match) => !m.overlays.find(o => o.id === parseInt(overlayId!)));

  if (loading) return <Loader center />;

  return (
    <div className="playsAndScore">
      <div className="plays">
        <Title is4 className="plays__title">
          Play by Play
        </Title>
        <div className="playerFactsList">
          {playerFacts ? (
            playerFacts.length > 0 ? (
              playerFacts.map(fact => {
                const playsFor = fact.player?.playsFor?.find(pf => pf.team.id === fact.teamId);
                const factName = mapFactNameBySport(sportName, (fact.name as unknown) as FactName);
                return (
                  <div key={fact.id} className="playerFactsListItem">
                    <div className="playerFactsListItem__imageSection">
                      <img src={playsFor?.photo?.url} className="playerFactsListItem__image" />
                    </div>
                    <div className="playerFactsListItem__dataSection">
                      <div className="playerFactsListItem__timeScore">{`${moment(fact.date).format('mm:ss')}`}</div>
                      <div className="playerFactsListItem__teamFactPlayer">{`[${playsFor?.team.abbreviation?.toUpperCase()}] ${factName} - ${
                        fact.player?.firstName
                      } ${fact.player?.lastName}`}</div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>There are not facts to show</div>
            )
          ) : null}
        </div>
      </div>
      {matches
        ? matches.length > 0 && <Scores matches={matches as TMatch[]} competitionName={matches[0].competition.name} />
        : null}
    </div>
  );
};
