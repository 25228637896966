import { competitionAdAllocationFragment } from '@ligr/shared';
import gql from 'graphql-tag';

export const competitionWithTeamsAndAdAllocationFragment = gql`
  fragment CompetitionWithTeamsAndAdAllocation on Competition {
    id
    name
    gfxName
    matchConfig
    overlayConfig
    sportId
    archived
    teams {
      id
      name
    }
    sport {
      id
      name
    }
    watermark {
      id
      url
    }
    secondaryWatermark {
      id
      url
    }
    logo {
      id
      url
    }
    adAllocation {
      ...CompetitionAdAllocation
    }
  }
  ${competitionAdAllocationFragment}
`;

export const competitionFragment = gql`
  fragment Competition on Competition {
    id
    name
    gfxName
    countMatches
    countTeams
    archived
    age
    gender
    grade {
      id
      name
    }
    watermark {
      id
      url
    }
    secondaryWatermark {
      id
      url
    }
    logo {
      id
      url
    }
    sportId
    sport {
      id
      name
    }
    matchConfig
    overlayConfig
    adAllocation {
      ...CompetitionAdAllocation
    }
    baseEntity {
      id
      name
    }
    theme {
      id
      name
      sportId
      sportName
    }
    themeId
  }
  ${competitionAdAllocationFragment}
`;
