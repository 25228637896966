import React, { useState } from 'react';
import { object, string } from 'yup';
import { Form } from '../../../../components/Form/Form';
import { PageContent } from '../../../../components/page/PageContent';
import { PageHeader } from '../../../../components/PageHeader';
import { PageMenu } from '../../../../components/PageMenu';
import { getGQLError } from '../../../../lib/apolloClient';
import { useErrorHandledMutation } from '../../../../lib/apolloHooks';
import { createVenueMutation } from '../../../../lib/gql/mutations/createVenue';
import { venuesQuery } from '../../../../lib/gql/queries/venues';
import { Notification } from '../../../../lib/notificationContainer';
import { VenueSideMenu } from '../VenueSideMenu/VenueSideMenu';
import { NewVenuePageFormContent } from './form';
import { useHistory } from 'react-router';
import { routes } from '../../../../router/Routes';
import './style.scss';
import { Venue, CreateVenueInput, VenuesQuery } from '../../../../api-types';
import { FormikContextType } from 'formik';

export interface CreateVenueValues {
  name: string;
}

const validationSchema = object().shape({
  name: string().min(1).required().label('Name'),
  gfxName: string().max(40).nullable().label('Gfx name')
});

export const NewVenuePage: React.FunctionComponent = () => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const { addNotification } = Notification.useContainer();
  const [form, setForm] = useState<FormikContextType<any>>();

  const [createVenue, { loading }] = useErrorHandledMutation<{
    createVenue: Venue;
    createVenueInput: CreateVenueInput;
  }>(createVenueMutation);

  const newVenue = {
    name: '',
    gfxName: '',
    lat: 0,
    lng: 0,
    city: '',
    country: '',
    state: '',
    address: '',
    postcode: ''
  };

  const create = async (createVenueInput: CreateVenueValues) => {
    try {
      const res = await createVenue({ variables: { createVenueInput } });

      history.push(routes.assetVenue({ venueId: res.data!.createVenue.id }));
      addNotification('Venue successfully created', 'success');
    } catch (e) {
      // @ts-ignore
      form.setFieldError(getGQLError(e).details.field, getGQLError(e).message);
    }
  };

  return (
    <>
      <PageMenu title="Venues" component={VenueSideMenu} />

      <PageContent className="newVenuePage">
        <PageHeader preTitle="create" title="new venue" />
        <Form
          initialValues={newVenue}
          validationSchema={validationSchema}
          className="page-form createVenue"
          onSubmit={create}
          withSubmit
          setForm={setForm}
        >
          <NewVenuePageFormContent loading={loading} />
        </Form>
      </PageContent>
    </>
  );
};
