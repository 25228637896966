import React from 'react';
import { Route, Switch } from 'react-router';
import { withTitle } from '../../../components/PageTitle/PageTitle';
import { routes } from '../../../router/Routes';
import { CompetitionPage } from './CompetitionPage/Competition.page';
import { CompetitionsPage } from './CompetitionsPage';
import { NewCompetitionPage } from './NewCompetitionPage';

export const CompetitionsRouter: React.FunctionComponent = () => (
  <Switch>
    <Route
      exact
      path={[routes.assetsCompetitions(false)]}
      component={withTitle({ component: CompetitionsPage, pageTitle: 'Competitions' })}
    />
    <Route
      exact
      path={routes.assetCompetitionCreate(false)}
      component={withTitle({ component: NewCompetitionPage, pageTitle: 'New Competition' })}
    />
    <Route
      path={routes.assetsCompetition(false)}
      component={withTitle({ component: CompetitionPage, pageTitle: 'Competition' })}
    />
  </Switch>
);
