import { FormikContextType } from 'formik';
import merge from 'lodash.merge';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Form } from '../../../../components/Form/Form';
import { PageContent } from '../../../../components/page/PageContent';
import { PageHeader } from '../../../../components/PageHeader';
import { getGQLError } from '../../../../lib/apolloClient';
import { useErrorHandledLazyQuery, useErrorHandledMutation, useErrorHandledQuery } from '../../../../lib/apolloHooks';
import { createPlayerMutation } from '../../../../lib/gql/mutations/createPlayer';
import { playersQuery } from '../../../../lib/gql/queries/players';
import { teamQuery } from '../../../../lib/gql/queries/team';
import { teamsWithCompQuery } from '../../../../lib/gql/queries/teams';
import { Notification } from '../../../../lib/notificationContainer';
import { logoValidation } from '../../../../lib/util/imageValidation';
import { routes } from '../../../../router/Routes';
import { playerFormSchema } from '../Player.validation';
import { NewPlayerPageFormContent, PlayerFormValues, PlayerTeamValues } from './form';
import {
  CreatePlayerInput,
  PlayerTeamsInput,
  TeamQuery,
  CreatePlayerMutation,
  PlayersQuery,
  TeamsWithCompQuery
} from '../../../../api-types';

export interface CreateCompetitionValues {
  sportId: number;
  name: string;
  logo: File;
  watermark: File;
  secondaryWatermark: File;
}

export const NewPlayerPage: React.FunctionComponent = () => {
  const [error, setError] = useState();
  const [form, setForm] = useState<FormikContextType<PlayerFormValues> | null>(null);
  const { addNotification } = Notification.useContainer();
  const history = useHistory<{ referrer?: string; teamId?: number } | undefined>();
  const location = useLocation<{ referrer?: string; teamId?: number } | undefined>();
  const [fetchTeam, { data }] = useErrorHandledLazyQuery<TeamQuery>(teamQuery);
  const inititialTeam = data ? data.team : undefined;

  useEffect(() => {
    if (location.state && location.state.teamId) {
      fetchTeam({
        variables: {
          id: location.state.teamId
        }
      });
    }
  }, [location.state && location.state.teamId]);

  const { data: teamsData } = useErrorHandledQuery<TeamsWithCompQuery>(teamsWithCompQuery);

  const [createPlayer, { loading }] = useErrorHandledMutation<CreatePlayerMutation>(createPlayerMutation);

  const initialValues = {
    firstName: '',
    lastName: '',
    gfxFirstName: '',
    gfxLastName: '',
    teams: inititialTeam
      ? [
          {
            teamId: inititialTeam.id,
            abbreviation: inititialTeam.abbreviation,
            name: inititialTeam.name,
            position: '',
            positionType: '',
            number: '',
            photo: undefined,
            photoId: undefined
          }
        ]
      : []
  };

  const create = async (values: PlayerFormValues) => {
    let errors: any = {};
    for (let i = 0; i < values.teams!.length; i++) {
      const photo = values.teams![i].photo;
      if (photo) {
        errors = merge(errors, await logoValidation([{ [`teams.${i}.photo`]: values.teams![i].photo }]));
      }
    }

    if (errors && Object.keys(errors).length) {
      return form?.setErrors(errors);
    }

    try {
      const inp = {
        ...values,
        teams: values!.teams!.map(({ abbreviation, name, teamId, ...rest }: Partial<PlayerTeamValues>) => ({
          ...rest,
          teamId: parseInt(`${teamId}`)
        })) as PlayerTeamsInput[]
      };

      const player = await createPlayer({
        variables: { createPlayerInput: inp as CreatePlayerInput },
        update: (cache, { data }) => {
          const playersCache = cache.readQuery<PlayersQuery>({ query: playersQuery });
          if (playersCache && data) {
            cache.writeQuery({
              query: playersQuery,
              data: {
                players: [...playersCache.players, data.createPlayer]
              }
            });
          }
        }
      });
      const teamId = history.location.state?.teamId;
      const route = !!teamId
        ? routes.assetTeamPlayers({ teamId })
        : routes.assetPlayerEdit({ playerId: player.data!.createPlayer.id });

      history.push(route);
      addNotification('Player successfully created', 'success');
    } catch (e) {
      // @ts-ignore
      form.setFieldError(getGQLError(e).details.field, getGQLError(e).message);
    }
  };

  return (
    <PageContent className="newPlayerPage">
      <PageHeader preTitle="create" title="new player" />
      <Form
        initialValues={initialValues}
        validationSchema={playerFormSchema}
        className="page-form player-form"
        onSubmit={create}
        withSubmit
        setForm={setForm}
        error={error}
      >
        <NewPlayerPageFormContent loading={loading} teams={teamsData?.teams || []} />
      </Form>
    </PageContent>
  );
};
