import { Icon } from '@ligr/shared';
import gql from 'graphql-tag';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card/Card';
import { FormStatus } from '../../components/FormStatus/FormStatus';
import { Loader } from '../../components/loader/Loader';
import { useErrorHandledMutation } from '../../lib/apolloHooks';
import { routes } from '../../router/Routes';
import './verify-email.page.scss';
import { VerifyEmailPageProps } from './VerifyEmail.page.types';

const confirmEmailMutation = gql`
  mutation confirmEmail($token: String!) {
    confirmEmail(token: $token)
  }
`;

export const VerifyEmailPage: React.FunctionComponent<VerifyEmailPageProps> = () => {
  const { token } = useParams();
  const history = useHistory<{ referrer?: string } | undefined>();

  const [confirm, { error, loading, data }] = useErrorHandledMutation(confirmEmailMutation, {
    variables: { token }
  });

  useEffect(() => {
    confirm();
  }, [token]);
  useEffect(() => {
    if (data && data.confirmEmail) {
      setTimeout(() => history.replace(routes.login()), 1000);
    }
  }, [data]);

  const uiError = error ? error.graphQLErrors[0].message : null;

  return (
    <div className="page verify-email">
      <Card>
        {loading ? (
          <Loader />
        ) : (
          <div>
            {error ? (
              <>
                <Icon icon="error" color="red" iconSize="large" />
                <FormStatus error={true}>{uiError}</FormStatus>
                <Button href={routes.login()}>Return to login</Button>
              </>
            ) : (
              <>
                <Icon icon="check" color="green" iconSize="large" />
                <FormStatus error={false}>Success! Redirecting you to login…</FormStatus>
              </>
            )}
          </div>
        )}
      </Card>
    </div>
  );
};
