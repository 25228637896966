import { Icon, IconType, Image, SportIcon } from '@ligr/shared';
import classnames from 'classnames';
import isEqual from 'lodash.isequal';
import uniqWith from 'lodash.uniqwith';
import React, { HTMLProps, useMemo } from 'react';
import { Notification } from '../../lib/notificationContainer';
import { useArchivePlayer } from '../../lib/useArchivePlayer';
import { routes } from '../../router/Routes';
import { Button } from '../Button';
import { Card } from '../Card/Card';
import { ConfirmWindow } from '../ConfirmWindow';
import { ConfirmWindowPlayerContent } from '../ConfirmWindow/Content/PlayerContent';
import { Title } from '../Title/Title';
import { ToolTip } from '../ToolTipIcon';
import './style.scss';

type TeamPlayer = {
  defaultStarter: boolean;
  position?: string | null;
  photo?: {
    url: string;
  } | null;
  number?: string | null;
};

type Player = {
  id: number;
  firstName: string;
  lastName: string;
  playsFor?: TeamPlayer[] | null;
  archived: boolean;
  countTeams: number;
};

type Team = {
  id: number;
  name: string;
  sport: {
    name: string;
  };
  logo?: {
    url: string;
    id: number;
  } | null;
  competitions: {
    logo?: {
      url: string;
    } | null;
  }[];
};

interface CardPlayerProps extends Partial<HTMLProps<HTMLDivElement>> {
  player: Player;
  to?: any;
  showSport?: boolean;
  showCompetitions?: boolean;
  showTeams?: boolean;
  fn?: () => void;
  icon?: IconType;
}

export const CardPlayer: React.FunctionComponent<CardPlayerProps> = ({
  player,
  to,
  showSport = true,
  showCompetitions = true,
  showTeams = true,
  icon,
  fn,
  ...rest
}) => {
  const { restorePlayer } = useArchivePlayer();
  const { addNotification } = Notification.useContainer();

  const teams: Team[] | undefined = player.playsFor?.map((pf: any) => pf.team);
  // Get all the competitions is every team and returns them not repeating
  const competitions = useMemo(() => {
    if (teams && teams.length > 0) {
      return uniqWith(
        teams.map((t: Team) => t.competitions).reduce((pre, cur) => pre.concat(cur)),
        isEqual
      );
    }
  }, [teams]);

  const sport = useMemo(() => {
    if (teams && teams.length > 0) {
      return teams[0].sport.name;
    }
  }, [teams]);

  return (
    <Card
      to={player.archived ? '' : to || routes.assetsCompetition({ competitionId: player.id })}
      className={classnames('cardPlayer', {
        'card--archived': player.archived
      })}
      {...rest}
    >
      {player.archived && (
        <div className="card--archived__overlay">
          <Button
            onClick={() => {
              restorePlayer(player.id);
              addNotification('Player restored successfully.', 'success');
            }}
          >
            Restore
          </Button>
        </div>
      )}

      {!player.archived && fn && icon && (
        <ConfirmWindow action={fn} title={`Archive player`} content={<ConfirmWindowPlayerContent player={player} />}>
          <Icon icon={icon} />
        </ConfirmWindow>
      )}
      <Title is4 hasTextGrey600 className="cardPlayer__subTitle">
        {player?.playsFor![0]?.number}
      </Title>

      <Title is3 className="cardPlayer__title" title={`${player.firstName} ${player.lastName}`}>
        {player.firstName} {player.lastName}
      </Title>

      <Image className="cardPlayer__image" url={player?.playsFor?.[0]?.photo?.url} placeholder="player" />

      <div className="cardPlayer__extraDetails">
        {showSport && sport && <SportIcon className="cardPlayer__extraDetails__sportIcon" sport={sport} />}
        {showTeams && teams && (
          <div className="cardPlayer__extraDetails__teams">
            {teams.length > 0 && (
              <Image className="cardPlayer__extraDetails__teams__image" url={teams[0]?.logo?.url} placeholder="team" />
            )}
            {teams.length === 2 && (
              <Image className="cardPlayer__extraDetails__teams__image" url={teams[1]?.logo?.url} placeholder="team" />
            )}
            {teams.length > 2 && (
              <ToolTip
                reference={() => <div className="cardPlayer__extraDetails__teams__number">+{teams.length - 1}</div>}
              >
                <ul className="cardPlayer__extraDetails__teamList">
                  {teams.slice(1).map(t => (
                    <li key={`${player.id}.${t.id}`}>
                      <Image
                        className="cardPlayer__extraDetails__teamList__image"
                        url={t.logo?.url}
                        placeholder="team"
                      />
                      {t.name}
                    </li>
                  ))}
                </ul>
              </ToolTip>
            )}
          </div>
        )}
        {showCompetitions && competitions && (
          <div className="cardPlayer__extraDetails__competitions">
            {competitions.length > 0 && (
              <Image
                className="cardPlayer__extraDetails__competitions__image"
                url={competitions[0]?.logo?.url}
                placeholder="competition"
              />
            )}
            {competitions.length === 2 && (
              <Image
                className="cardPlayer__extraDetails__competitions__image"
                url={competitions[1]?.logo?.url}
                placeholder="competition"
              />
            )}
            {competitions.length > 2 && (
              <ToolTip
                reference={() => (
                  <div className="cardPlayer__extraDetails__competitions__number">+{competitions.length - 1}</div>
                )}
              >
                <ul className="cardPlayer__extraDetails__competitionList">
                  {competitions.slice(1).map(c => (
                    <li key={`${player.id}.${c.id}`}>
                      <Image
                        className="cardPlayer__extraDetails__competitionList__image"
                        url={c.logo?.url}
                        placeholder="competition"
                      />
                      {c.name}
                    </li>
                  ))}
                </ul>
              </ToolTip>
            )}
          </div>
        )}
      </div>
    </Card>
  );
};
