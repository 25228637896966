import { competitionAdAllocationFragment } from '@ligr/shared';
import gql from 'graphql-tag';

export const updateCompetition = gql`
  mutation updateCompetition($updateCompetitionInput: UpdateCompetitionInput!) {
    updateCompetition(updateCompetitionInput: $updateCompetitionInput) {
      id
      name
      gfxName
      themeId
      age
      gender
      grade {
        id
        name
      }
      theme {
        id
        name
        sportId
        sportName
      }
      matchConfig
      overlayConfig
      watermark {
        id
        url
      }
      baseEntity {
        id
        name
      }
      secondaryWatermark {
        id
        url
      }
      logo {
        id
        url
      }
      sportId
      sport {
        id
        name
      }
      adAllocation {
        ...CompetitionAdAllocation
      }
    }
  }
  ${competitionAdAllocationFragment}
`;
