import classnames from 'classnames';
import { Form as FormikForm, FormikContextType, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useRef, ReactElement } from 'react';
import { ConfirmModal } from '../../modals/ConfirmModal/Confirm.modal';
import { FormStatus } from '../FormStatus/FormStatus';
import { FormProps } from './Form.types';
import usePrevious from 'react-use/lib/usePrevious';
import { ErrorsGroup } from '../ErrorsGroup';

// Ligr Form context and hooks
export interface LigrFormContext {
  onChange: (values: any, formik: FormikContextType<any>) => any;
}
const LigrFormContext = React.createContext<LigrFormContext>({} as any);
export const useLigrFormContext = () => React.useContext<LigrFormContext>(LigrFormContext);

export function Form<Values extends {}>({
  onChange,
  children,
  withSubmit,
  className,
  onSubmit = () => {},
  initialValues = {} as Values,
  style,
  setForm,
  error,
  success,
  useConfirm = false,
  errorGroupGridColum,
  ...formikProps
}: FormProps<Values>): ReactElement<FormProps<Values>> {
  const formEl = useRef<HTMLFormElement>(null);

  // Establishes the context for FormField to call onChange
  const ctx: LigrFormContext = {
    onChange: (e, formik) => {
      if (onChange) onChange(e, formik);
    }
  };

  const form = useFormik({
    onSubmit,
    initialValues,
    enableReinitialize: true,
    ...formikProps
  });

  const prevValues = usePrevious(form.values);

  useEffect(() => {
    if (setForm) setForm(form);
  }, [form.values, form.errors, form.touched]);

  useEffect(() => {
    if (prevValues && form.dirty) {
      ctx.onChange(form.values, form);
    }
  }, [form.values]);

  useEffect(() => {
    if (form.submitCount > 0 && Object.keys(form?.errors).length > 0) {
      formEl?.current?.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [form.submitCount]);

  const content = (
    <>
      {Object.keys(form.errors).length > 0 && form.submitCount > 0 && (
        <ErrorsGroup form={form} errorGroupGridColum={errorGroupGridColum} />
      )}
      {children}
    </>
  );

  return (
    <LigrFormContext.Provider value={ctx}>
      <FormikProvider value={form}>
        {/* This error handlig probably should be moved to Error group up above */}
        {(error || success) && <FormStatus error={Boolean(error)}>{error || success}</FormStatus>}

        <FormikForm
          id="form"
          // @ts-ignore
          children={content}
          className={classnames(className, { 'with-submit': form.dirty && withSubmit })}
          style={style}
          ref={formEl}
        />
      </FormikProvider>
      {useConfirm && <ConfirmModal shouldShow={form.dirty && !form.isSubmitting} />}
    </LigrFormContext.Provider>
  );
}
