import { allPlans } from '@ligr/api-v2';
import { Organization } from '../../api-types';
import { updateQueryUrl } from '@ligr/shared';
import gql from 'graphql-tag';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from '../../components/Button';
import { Loader } from '../../components/loader/Loader';
import { SearchField } from '../../components/SearchField/SearchField';
import { Table } from '../../components/Table/Table';
import { useErrorHandledLazyQuery, useErrorHandledMutation } from '../../lib/apolloHooks';
import { AdminWrapper } from './Admin.wrapper';

const organizationSearchQuery = gql`
  query searchOrganizationsWithPayment($query: String!) {
    searchOrganizations(query: $query) {
      id
      name
      manualPayment
      stripeCustomerId
      stripePlanId
      stripeCardLast4
      planQuantity
      blocked
    }
  }
`;

interface ToggleManualPaymentForOrganizationMutation {
  toggleManualPaymentForOrganization: Organization;
}

const toggleManualPaymentForOrganizationMutation = gql`
  mutation toggleManualPaymentForOrganization($organizationId: Float!) {
    toggleManualPaymentForOrganization(organizationId: $organizationId) {
      id
      name
      manualPayment
      stripeCustomerId
      stripePlanId
      stripeCardLast4
      planQuantity
      blocked
    }
  }
`;

interface ToggleBlockOrganizationMutation {
  toggleBlockOrganization: Organization;
}

const toggleBlockOrganizationMutation = gql`
  mutation toggleBlockOrganization($organizationId: Float!) {
    toggleBlockOrganization(organizationId: $organizationId) {
      id
      name
      manualPayment
      stripeCustomerId
      stripePlanId
      stripeCardLast4
      planQuantity
      blocked
    }
  }
`;

export const AdminOrganizations: React.FunctionComponent = () => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const searchParsed = queryString.parse(history.location.search);
  const [query, setQuery] = useState(searchParsed.query || '');
  const [fetch, { data, loading }] = useErrorHandledLazyQuery<{
    searchOrganizations: Organization[];
  }>(organizationSearchQuery);
  const [toggleManualPaymentForOrganization] = useErrorHandledMutation<ToggleManualPaymentForOrganizationMutation>(
    toggleManualPaymentForOrganizationMutation
  );
  const [toggleBlockOrganization] = useErrorHandledMutation<ToggleBlockOrganizationMutation>(
    toggleBlockOrganizationMutation
  );
  const organizations = data?.searchOrganizations || [];

  useEffect(() => {
    submit();
  }, []);

  const submit = () => {
    fetch({ variables: { query } });
  };

  useEffect(() => {
    if (query === '') submit();
  }, [query]);

  const organizationsData = useMemo(() => {
    return organizations.map(organization => {
      const plan = organization.stripePlanId ? allPlans.find(p => p.stripeId === organization.stripePlanId) : undefined;
      const subscription = organization.stripeCustomerId
        ? organization.stripePlanId
          ? `Stripe ${plan?.name} ${organization.planQuantity}`
          : 'Stripe witout plan'
        : organization.manualPayment
        ? `Manual Payment`
        : 'Not paying';
      const subscriptionKlass = organization.manualPayment
        ? 'manual'
        : organization.stripePlanId
        ? 'stripe-pay'
        : organization.stripeCustomerId
        ? 'stripe'
        : 'trial';
      return {
        ...organization,
        subscriptionKlass,
        subscription
      };
    });
  }, [organizations]);

  const toggleManualPayment = async (id: number) => {
    await toggleManualPaymentForOrganization({ variables: { organizationId: id } });
  };

  const toggleBlock = async (id: number) => {
    await toggleBlockOrganization({ variables: { organizationId: id } });
  };

  if (loading) return <Loader />;

  return (
    <AdminWrapper>
      <div className="admin-search">
        <SearchField
          placeholder="Search organization by name"
          onChange={(value: any) => {
            updateQueryUrl(history, { query: value });
            setQuery(value);
          }}
          value={query}
        />
        <Button onClick={submit}>Search</Button>
      </div>
      <div className="search-organizations">
        <Table
          className="adminOrgs__table"
          data={organizationsData}
          small
          columns={[
            {
              Header: 'Name',
              accessor: 'blocked',
              Cell: ({ cell, row }) => (
                <div>
                  {row.original.name}
                  {cell.value && <span className="blocked">Blocked</span>}
                </div>
              )
            },
            {
              Header: 'Plan',
              accessor: 'subscription',
              Cell: ({ row }) => (
                <div className={`subscription ${row.original.subscriptionKlass}`}>{row.original.subscription}</div>
              )
            },
            {
              Header: 'Actions',
              Cell: ({ row }) => (
                <div className="actions">
                  <Button onClick={() => toggleManualPayment(row.original.id)}>
                    Turn Manual Payment {row.original.manualPayment ? 'OFF' : 'ON'}
                  </Button>
                  {row.original.blocked ? (
                    <Button onClick={() => toggleBlock(row.original.id)}>Unblock</Button>
                  ) : (
                    <Button onClick={() => toggleBlock(row.original.id)}>Block</Button>
                  )}
                </div>
              )
            }
          ]}
        />
      </div>
    </AdminWrapper>
  );
};
