import React, { useRef, useState } from 'react';
import { Modal, ModalProps } from '../../components/modal/Modal';
import { useErrorHandledMutation } from '../../lib/apolloHooks';
import { createAdSetMutation } from '../../lib/gql/mutations/createAdSet';
import { Notification } from '../../lib/notificationContainer';
import { adSetsQuery } from '../../pages/Ads/AdSets';
import { AdSetForm } from './form/AdSetForm';
import { AdSet, CreateAdSetInput, AdSetsQuery } from '../../api-types';

export const CreateAdSetModal: React.FunctionComponent<Partial<ModalProps>> = props => {
  const [createAdSet] = useErrorHandledMutation<{ createAdSet: AdSet }, { createAdSetInput: CreateAdSetInput }>(
    createAdSetMutation
  );

  const modal = useRef<Modal>(null);
  const [form, setForm] = useState<any>(null);

  const { addNotification } = Notification.useContainer();

  const create = async (values: CreateAdSetInput) => {
    const res = await createAdSet({
      variables: { createAdSetInput: values },
      update: (cache, { data }) => {
        const adSetCache = cache.readQuery<AdSetsQuery>({ query: adSetsQuery });
        if (adSetCache && data) {
          adSetCache.adSets.push(data.createAdSet);
          cache.writeQuery({
            query: adSetsQuery,
            data: {
              adSets: adSetCache.adSets || []
            }
          });
        }
      }
    });
    addNotification('Ad set succesfully added', 'success');

    // if (props.onClose) props.onClose();
    if (res.data) {
      if (props.onSuccess) {
        props.onSuccess(res.data.createAdSet.id);
      } else {
        if (props.onClose) props.onClose();
      }
    } else if (props.onClose) props.onClose();
  };

  const initialValues = {
    name: ''
  };

  return (
    <Modal
      ref={modal}
      title="Create Ad Set"
      {...props}
      footerButtons={[
        { text: 'Cancel', onClick: props.onClose, type: 'secondary' },
        { text: 'Create Ad Set', onClick: () => form.submitForm() }
      ]}
    >
      <AdSetForm onSubmit={create} setForm={setForm} initialValues={initialValues} useConfirm={true} />
    </Modal>
  );
};
