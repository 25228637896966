import { playsForFragment } from '@ligr/shared';
import gql from 'graphql-tag';
import { teamPlayerFragment } from '../fragments/player';

export const createPlayerMutation = gql`
  mutation createPlayer($createPlayerInput: CreatePlayerInput!) {
    createPlayer(createPlayerInput: $createPlayerInput) {
      ...TeamPlayer
    }
  }
  ${teamPlayerFragment}
  ${playsForFragment}
`;
