import { generatePath } from 'react-router';
import { AdAllocationEntityType } from '../api-types';
import { getOrgId } from '../lib/util/getOrgId';

export const URL_PREFIX = '/o/:orgId';

interface Params {
  [key: string]: number | string;
}
interface DefaultParams {
  orgId?: number;
}

const linkParams = <T extends Params = {}>(link: string, usePrefix = true) => (
  params?: (T & DefaultParams) | false
) => {
  const l = `${usePrefix ? URL_PREFIX : ''}${link}`;
  if (params === false) return l;

  return generatePath(l, {
    orgId: getOrgId(),
    ...params
  });
};

export const routes = {
  // Unauthenticated routes
  register: linkParams('/register', false),
  forgotPassword: linkParams('/forgot-password', false),
  updatePassword: linkParams<{ token: string }>('/reset-password/:token', false),
  invitedUserPasswordSet: linkParams<{ token: string }>('/set-password/:token', false),
  login: linkParams('/login', false),
  verify: linkParams<{ token: string }>('/verify/:token', false),

  // Errors
  planUpgrade: linkParams(`/plan-upgrade`),
  notFound: linkParams(`/not-found`, false),
  unauthorized: linkParams(`/unauthorized`, false),
  networkError: linkParams(`/network-error`, false),

  // Authenticated routes
  orgSelection: linkParams('/o/', false),
  organizationCreate: linkParams('/new-organization', false),
  home: linkParams('/dashboard'),

  assets: linkParams('/assets'),
  assetsCompetitions: linkParams('/assets/competitions'),
  assetCompetitionCreate: linkParams('/assets/competitions/new'),
  assetsCompetition: linkParams<{ competitionId: number }>('/assets/competitions/:competitionId'),
  assetsCompetitionTeams: linkParams<{ competitionId: number }>('/assets/competitions/:competitionId/teams'),
  assetsCompetitionSettings: linkParams<{ competitionId: number }>('/assets/competitions/:competitionId/settings'),

  assetsPlayers: linkParams('/assets/players'),
  assetsPlayer: linkParams<{ playerId: number }>('/assets/players/:playerId'),
  assetsPlayerTeams: linkParams<{ playerId: number }>('/assets/players/:playerId/teams'),
  assetsPlayerSettings: linkParams<{ playerId: number }>('/assets/players/:playerId/settings'),

  assetTeams: linkParams('/assets/teams'),
  assetTeam: linkParams<{ teamId: number }>('/assets/teams/:teamId'),
  assetTeamCreate: linkParams('/assets/teams/new'),
  assetTeamSettings: linkParams<{ teamId: number }>('/assets/teams/:teamId/general'),
  assetTeamPlayers: linkParams<{ teamId: number }>('/assets/teams/:teamId/players'),
  assetTeamCustomize: linkParams<{ teamId: number }>('/assets/teams/:teamId/customize'),
  assetTeamPlayersEditLineup: linkParams<{ teamId: number }>('/assets/teams/:teamId/players/edit-lineup'),

  assetClubCreate: linkParams('/assets/clubs/new'),
  assetClubs: linkParams('/assets/clubs'),
  assetClub: linkParams<{ clubId: number }>('/assets/clubs/:clubId'),
  assetClubEditTeams: linkParams<{ clubId: number }>('/assets/clubs/:clubId/teams/edit'),

  assetPlayers: linkParams('/assets/players'),
  assetPlayer: linkParams<{ playerId: number }>('/assets/players/:playerId'),
  assetPlayerCreate: linkParams('/assets/players/new'),
  assetPlayerEdit: linkParams<{ playerId: number }>('/assets/players/:playerId/edit'),
  assetTeamPlayersEdit: linkParams<{
    teamId: number;
    playerId: number;
  }>('/assets/teams/:teamId/players/:playerId/edit'),
  assetTeamPlayersCreate: linkParams<{ teamId: number }>('/assets/teams/:teamId/players/new'),

  assetVenues: linkParams('/assets/venues'),
  assetVenue: linkParams<{ venueId: number }>('/assets/venues/:venueId'),
  assetVenueCreate: linkParams('/assets/venues/new'),

  matchesLive: linkParams('/matches'),
  matchesNotStarted: linkParams('/matches/notstarted'),
  match: linkParams<{ matchId: number }>('/matches/:matchId'),
  createMatchFromLive: linkParams('/matches/new'),
  editMatchFromLive: linkParams<{ matchId: number }>('/matches/:matchId/edit'),
  matchesCompleted: linkParams('/matches/completed'),

  createOverlay: linkParams<{ matchId: number }>('/matches/:matchId/overlays/new'),
  editOverlay: linkParams<{ matchId: number; overlayId: number; graphicName: string }>(
    '/matches/:matchId/overlays/:overlayId/:graphicName'
  ),
  controlOverlay: linkParams<{ overlayId: number }>('/overlays/:overlayId/control'),

  // Profile
  profile: linkParams('/settings'),
  profileOrganization: linkParams('/settings/organization'),
  profilePassword: linkParams('/settings/password'),
  security: linkParams('/settings/security'),
  subscriptions: linkParams('/settings/subscription'),
  permissions: linkParams('/settings/permissions'),
  billing: linkParams('/settings/billing'),
  createUser: linkParams('/settings/permissions/new'),
  editPermission: linkParams<{ userId: number }>('/settings/permissions/:userId/edit'),

  themes: linkParams('/themes'),
  theme: linkParams<{ themeId: number }>('/themes/:themeId/preview'),
  themeGraphicPreview: linkParams<{ themeId: number; graphicName: string }>(
    '/themes/:themeId/preview/graphic/:graphicName'
  ),

  adSets: linkParams('/ads'),
  adSetsAll: linkParams('/ads/sets'),
  createAdSet: linkParams<{ adSetId: number }>('/ads/sets/new'),
  adSet: linkParams<{ adSetId: number }>('/ads/sets/:adSetId'),
  adSetImageAds: linkParams<{ adSetId: number }>('/ads/sets/:adSetId/images'),
  adSetVideoAds: linkParams<{ adSetId: number }>('/ads/sets/:adSetId/videos'),
  adSetsAllocation: linkParams('/ads/allocation'),
  adSetsAllocationDetails: linkParams<{ entityId: number; entityType: AdAllocationEntityType }>(
    '/ads/allocation/:entityType/:entityId'
  ),

  // Admin routes
  admin: linkParams('/admin', false),
  adminOrganizations: linkParams('/admin/organizations', false),
  adminCompetitions: linkParams('/admin/competitions', false),
  adminAdapters: linkParams('/admin/external-data', false),
  adminConnectAdapter: linkParams('/admin/external-data/new', false)
};
