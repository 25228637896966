import { RoleName } from '@ligr/api-v2';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Modal, ModalProps } from '../../components/modal/Modal';
import { useErrorHandledMutation } from '../../lib/apolloHooks';
import { permissionsQuery } from '../../pages/Profile/ProfilePermission.form';
import { routes } from '../../router/Routes';
import { UserForm, UserFormValues } from './form/User.form';
import { InviteMutation, PermissionFragment } from '../../api-types';

const inviteMutation = gql`
  mutation invite($user: InviteUserInput!) {
    invite(user: $user) {
      user {
        id
        lastName
        firstName
        email
      }
      role
      organizationId
    }
  }
`;

export const CreateUserModal: React.FunctionComponent<Partial<ModalProps>> = props => {
  const [error, setError] = useState();

  const history = useHistory<{ referrer?: string } | undefined>();
  const [form, setForm] = useState<any>(null);
  const [invite, { loading }] = useErrorHandledMutation<InviteMutation>(inviteMutation);

  const submit = async (values: UserFormValues) => {
    try {
      const response = await invite({
        variables: { user: values },
        update: (cache, { data }) => {
          try {
            const permissionsCache = cache.readQuery<{ permissions: PermissionFragment[] }>({
              query: permissionsQuery
            });
            if (permissionsCache && data) {
              permissionsCache.permissions.push(data.invite);
              cache.writeQuery({
                query: permissionsQuery,
                data: {
                  permissions: permissionsCache.permissions || []
                }
              });
            }
          } catch (e) {
            // TODO: This is broken
          }
        }
      });

      if (response.data && response.data.invite) {
        history.push(routes.permissions());
      } else if (props.onClose) props.onClose();
    } catch (e) {
      // TODO: This is broken. There is a strange bug occurring
      // setError(getGQLError(e).message);
    }
  };

  return (
    <Modal
      title="Invite user"
      {...props}
      footerButtons={[
        { text: 'Cancel', onClick: props.onClose, type: 'secondary' },
        { text: 'invite', onClick: async () => form.submitForm(), isLoading: loading }
      ]}
    >
      <UserForm
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          role: RoleName.user
        }}
        onSubmit={i => submit(i)}
        setForm={setForm}
        error={error}
        useConfirm={true}
      />
    </Modal>
  );
};
