import gql from 'graphql-tag';

export const leaveTeamMutation = gql`
  mutation leaveTeam($teamId: Float!, $id: Float!) {
    leaveTeam(teamId: $teamId, id: $id) {
      id
      firstName
      lastName
    }
  }
`;
