import { AdSet } from '../api-types';
import { useSearchParams } from '@ligr/shared';
import gql from 'graphql-tag';
import { adSetsQuery } from '../pages/Ads/AdSets';
import { useErrorHandledMutation } from './apolloHooks';
import { deleteAdSetMutation } from '../lib/gql/mutations/deleteAdset';

export const useArchiveAdSet = () => {
  const searchParams = useSearchParams(['archive']);

  const restoreAdSetMutation = gql`
    mutation restoreAdSet($id: Float!) {
      restoreAdSet(id: $id)
    }
  `;

  const [archiveAdSetMutation] = useErrorHandledMutation<boolean>(deleteAdSetMutation);
  const [unarchiveAdSetMutation] = useErrorHandledMutation<boolean>(restoreAdSetMutation);

  const archiveAdSet = async (adSetId: number) => {
    await archiveAdSetMutation({
      variables: { id: adSetId },
      update: (cache, { data }) => {
        const adSetsCache = cache.readQuery<{ adSets: AdSet[] }>({
          query: adSetsQuery,
          variables: { options: { archive: !!searchParams.archive } }
        });
        if (adSetsCache && data) {
          let ads;

          if (!!searchParams.archive) {
            ads = adSetsCache.adSets.map((as: AdSet) => {
              if (as.id === adSetId) {
                return { ...as, archived: true };
              }
              return as;
            });
          } else {
            ads = adSetsCache.adSets.filter(({ id }: AdSet) => id !== adSetId);
          }
          cache.writeQuery({
            query: adSetsQuery,
            variables: { options: { archive: !!searchParams.archive } },
            data: {
              adSets: ads
            }
          });
        }
      }
    });
  };

  const restoreAdSet = async (adSetId: number) => {
    await unarchiveAdSetMutation({
      variables: { id: adSetId },
      update: (cache, { data }) => {
        const adsCache = cache.readQuery<{ adSets: AdSet[] }>({
          query: adSetsQuery,
          variables: { options: { archive: !!searchParams.archive } }
        });
        if (adsCache && data) {
          const adSets = adsCache.adSets.map((as: AdSet) => {
            return {
              ...as,
              archived: as.id === adSetId ? false : as.archived
            };
          });
          cache.writeQuery({
            query: adSetsQuery,
            data: {
              adSets
            }
          });
        }
      }
    });
  };

  return {
    archiveAdSet,
    restoreAdSet
  };
};
