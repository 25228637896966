import classnames from 'classnames';
import { FormikContextType, FormikHandlers, getIn, useField, useFormikContext } from 'formik';
import get from 'lodash.get';
import React, { FunctionComponent, ReactElement, useMemo, useState } from 'react';
import { AddressField } from '../AddressField';
import { Checkbox } from '../Checkbox/Checkbox';
import { CheckboxProps } from '../Checkbox/Checkbox.types';
import { ColorField, ColorFieldProps } from '../ColorField';
import { DateField } from '../DateField';
import { useLigrFormContext } from '../Form/Form';
import { ImageField, ImageFieldProps } from '../ImageField';
import { InputField, InputFieldProps } from '../InputField';
import { InlineResultsInput, InlineResultsInputProps } from '../InputField/InlineResultsInput';
import { MultiSelect } from '../MultiSelect/MultiSelect';
import { MultiSelectProps } from '../MultiSelect/MultiSelect.types';
import { Radio } from '../RadioField/Radio';
import { RadioTab } from '../RadioTabField/RadioTab';
import { RadioTabProps } from '../RadioTabField/RadioTab.types';
import { Select } from '../Select';
import { SelectProps } from '../Select/types';
import { Switch } from '../Switch';
import { SwitchProps } from '../Switch/types';
import { TimeField } from '../TimeField/TimeField';
import { ToolTipIcon } from '../ToolTipIcon';
import './style.scss';

export type FormFieldType =
  | ({ type: 'text' | 'password' | 'number' } & InputFieldProps)
  | ({ type: 'color' } & ColorFieldProps)
  | ({ type: 'checkbox' } & CheckboxProps)
  | ({ type: 'switch' } & SwitchProps)
  | ({ type: 'select' } & Omit<SelectProps, 'name'>)
  | ({ type: 'multiselect' } & Omit<MultiSelectProps, 'name'>)
  | ({ type: 'image' } & ImageFieldProps)
  | ({ type: 'radio-tab' } & RadioTabProps)
  | ({ type: 'inline-results' } & InlineResultsInputProps)
  | ({ type: 'radio' } & RadioTabProps);

export type FormFieldBaseProps = {
  name: string;
  label?: string | ReactElement | Element;
  label2?: string | ReactElement | Element;
  labelComponent?: React.ComponentType<any>;
  className?: string;
  colSpan?: 1 | 2;
  changeOnBlur?: boolean;
  isAd?: boolean;
  originalValue?: string;
  tooltip?: JSX.Element | string;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  groupError?: boolean;
};

export type FormFieldProps = FormFieldBaseProps &
  (
    | FormFieldType
    | {
        component?: React.ComponentType | FunctionComponent<any>;
        [prop: string]: any;
      }
  );

export const displayError = (form: FormikContextType<any>, name: string) => {
  if (name === 'time' && getIn(form.errors, name)) {
    if (getIn(form.touched.time, 'hours') && getIn(form.touched.time, 'minutes')) {
      return Object.values(getIn(form.errors, name))[0] as string;
    }
  } else {
    return get(form.errors, name) && (form.submitCount > 0 || get(form.touched, name))
      ? (get(form.errors, name) as string)
      : null;
  }
};

export const FormField: React.FunctionComponent<FormFieldProps> = ({
  name,
  label,
  labelComponent,
  className,
  colSpan = 2,
  changeOnBlur,
  onChange: overridenOnChange,
  originalValue,
  tooltip,
  ...compProps
}) => {
  const [onBlurValue, setOnBlurValue] = useState();
  const [isFocused, setFocus] = useState(false);
  const [fieldFormik] = useField(name);
  const form = useFormikContext<any>();
  const ligrForm = useLigrFormContext();
  const value = getIn(form.values, name);

  const onChange: FormikHandlers['handleChange'] = (e: any) => {
    // Needed in ads as we need to have only one value in the values object
    if (compProps.isAd) form.resetForm();

    if (changeOnBlur) {
      setOnBlurValue(e.target.value);
      compProps.value = onBlurValue;
    } else {
      form.setFieldTouched(name);
      fieldFormik.onChange(e);
    }
  };

  const error = displayError(form, name);

  const extraProps: any = {};

  if (changeOnBlur) {
    extraProps.onBlur = () => {
      form.setFieldValue(name, onBlurValue);
      ligrForm.onChange(form.values, form);
    };
  }

  let Comp;
  if ('type' in compProps) {
    const type = compProps.type;

    switch (type) {
      case 'text':
      case 'number':
      case 'password':
        Comp = InputField;
        extraProps.type = type;
        extraProps.blurOnEnter = false;
        break;
      case 'address':
        Comp = AddressField;
        break;
      case 'color':
        Comp = ColorField;
        extraProps.formik = form;
        break;

      case 'time':
        Comp = TimeField;
        extraProps.onChange = (e: React.ChangeEvent<HTMLInputElement | any>) => {
          form.setFieldTouched(e.target.name);
          form.setFieldValue(
            e.target.name,
            e.target.value === ''
              ? undefined
              : // : e.target.name.includes('ampm')
                // ? e.target.value
                e.target.value
            // : !isNaN(parseInt(e.target.value))
            // ? parseInt(e.target.value)
            // : ''
          );
        };
        break;

      case 'date':
        Comp = DateField;
        extraProps.onChange = (e: any) => {
          form.setFieldValue(e.target.name, e.target.value);
        };
        break;

      case 'checkbox':
        Comp = Checkbox;
        extraProps.defaultChecked = Boolean(fieldFormik!.value);
        break;

      case 'switch':
        Comp = Switch;
        extraProps.onChange = (boolean: number) => {
          form.setFieldTouched(name);
          form.setFieldValue(name, boolean);
        };
        if (compProps.label2) {
          extraProps.label = label;
          // tslint:disable-next-line: no-parameter-reassignment
          label = undefined;
        }
        break;

      case 'select':
        Comp = Select;
        break;

      case 'multiselect':
        Comp = MultiSelect;
        extraProps.onChange = (opts: { value: number | string }[]) => {
          form.setFieldTouched(name);
          form.setFieldValue(name, opts);
        };
        break;

      case 'image':
        Comp = ImageField;
        extraProps.form = form;
        break;

      case 'radio-tab':
        Comp = RadioTab;
        extraProps.form = form;
        break;

      case 'radio':
        Comp = Radio;
        extraProps.form = form;
        break;

      case 'inline-results':
        Comp = InlineResultsInput;
        break;

      default:
        throw new Error(`Could not find field type for '${type}'`);
    }
  } else {
    Comp = compProps.component;
  }

  const LC = labelComponent;

  const originalValueTooltip = useMemo(() => {
    if (originalValue && originalValue !== value) {
      return (
        <ToolTipIcon icon="warning" className="formField__tooltipIcon">
          {label} does not match external data provider value ({originalValue})
        </ToolTipIcon>
      );
    }
    if (tooltip) {
      return (
        <ToolTipIcon position={compProps.tooltipPosition} className="formField__tooltipIcon">
          {tooltip}
        </ToolTipIcon>
      );
    }
    return null;
  }, [originalValue, value, tooltip]);

  const isDirty = getIn(form.initialValues, name) !== getIn(form.values, name);

  const SHOW_ERROR =
    !compProps.groupError &&
    ((typeof error === 'string' && getIn(form.touched, name) && isDirty) || form.submitCount) &&
    !isFocused;

  // Pass error to input field
  if (SHOW_ERROR) {
    extraProps.error = error;
  }

  return (
    <div
      className={classnames('formField', className, {
        [`formField--colSpan-${colSpan}`]: colSpan !== 2,
        'formField--error': SHOW_ERROR
      })}
    >
      {label ? (
        LC ? (
          <LC htmlFor={name}>
            {label}
            {originalValueTooltip}
          </LC>
        ) : (
          <label htmlFor={name}>
            {label}
            {originalValueTooltip}
          </label>
        )
      ) : null}
      {Comp ? (
        <Comp
          id={name}
          {...fieldFormik}
          onBlur={(e: any) => {
            fieldFormik.onBlur(e);
            setFocus(false);
          }}
          onFocus={() => setFocus(true)}
          {...(compProps as any)}
          {...extraProps}
          // TODO Refactor fix this
          onChange={overridenOnChange || extraProps.onChange || onChange}
        />
      ) : null}
      {SHOW_ERROR ? <span className="formField__error">{error}</span> : null}
    </div>
  );
};
