import React from 'react';
import { Route, Switch } from 'react-router';
import { withTitle } from '../../../components/PageTitle/PageTitle';
import { routes } from '../../../router/Routes';
import { ClubPage } from '../Club/ClubPage';
import { TeamPage } from './TeamPage';
import { TeamsPage } from './TeamsPage';

export const TeamsRouter: React.FunctionComponent = () => (
  <Switch>
    <Route
      exact
      path={routes.assetTeams(false)}
      component={withTitle({ component: TeamsPage, pageTitle: 'All Clubs and Teams' })}
    />
    <Route path={routes.assetTeam(false)} component={withTitle({ component: TeamPage, pageTitle: 'Team' })} />
    <Route path={routes.assetClub(false)} component={withTitle({ component: ClubPage, pageTitle: 'Club' })} />
  </Switch>
);
