import gql from 'graphql-tag';

export const createVenueMutation = gql`
  mutation createVenue($createVenueInput: CreateVenueInput!) {
    createVenue(createVenueInput: $createVenueInput) {
      id
      name
      lat
      lng
      city
      state
      country
      postcode
      address
    }
  }
`;
