import { Icon, IconType } from '@ligr/shared';
import classnames from 'classnames';
import React, { HTMLProps, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import { v4 } from 'uuid';
import './style.scss';

interface ToolTipIconType extends HTMLProps<HTMLInputElement> {
  position?: 'bottom' | 'top' | 'right' | 'left' | undefined;
  icon?: IconType;
  iconSize?: 'large' | 'small' | 'medium';
  color?: string;
}

interface ToolTipType extends ToolTipIconType {
  reference: React.FunctionComponent<{}>;
}

export const ToolTipIcon: React.FunctionComponent<ToolTipIconType> = ({
  children,
  position,
  icon = 'question',
  iconSize = 'small',
  color = 'grey-600',
  className
}) => {
  const id = useMemo(() => v4(), []);

  return (
    <>
      <div
        className={classnames('tooltipIcon', className)}
        data-scroll-hide
        data-iscapture="true"
        data-for={id}
        data-tip="tooltip"
      >
        <Icon style={{ pointerEvents: 'none' }} icon={icon} color={color} iconSize={iconSize} />
      </div>
      <ReactTooltip id={id} place={position} effect="solid" className="tooltip">
        {children}
      </ReactTooltip>
    </>
  );
};

export const ToolTip: React.FunctionComponent<ToolTipType> = ({ children, position, className, reference }) => {
  const id = useMemo(() => v4(), []);

  return (
    <>
      <div
        className={classnames('tooltipIcon', className)}
        data-scroll-hide
        data-iscapture="true"
        data-for={id}
        data-tip="tooltip"
      >
        {reference({})}
      </div>
      <ReactTooltip id={id} place={position} effect="solid" className="tooltip">
        {children}
      </ReactTooltip>
    </>
  );
};
