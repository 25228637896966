import gql from 'graphql-tag';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { createContainer } from 'unstated-next';
import { useErrorHandledLazyQuery } from './apolloHooks';

export const currentOrgQuery = gql`
  query currentOrganization {
    currentOrganization {
      id
      name
      default
      manualPayment
      stripeCustomerId
      stripePlanId
      stripeCardLast4
      planQuantity
      blocked
      logo {
        id
        url
      }
    }
  }
`;

const useOrganization = () => {
  const { orgId } = useParams();
  const [getCurrentOrg, { data, error, loading, called, refetch }] = useErrorHandledLazyQuery(currentOrgQuery, {});

  // Load the current org when org id changes
  useEffect(() => {
    orgId && getCurrentOrg();
  }, [orgId]);

  return {
    org: data?.currentOrganization?.blocked ? undefined : data?.currentOrganization,
    loading,
    called,
    refetch,
    error
  };
};

export const Organization = createContainer(useOrganization);
