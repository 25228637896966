import React from 'react';
import { NavTabs } from '../../components/NavTabs/NavTabs';
import { PageContent } from '../../components/page/PageContent';
import { Title } from '../../components/Title/Title';
import { routes } from '../../router/Routes';
import './Admin.scss';

export const AdminWrapper: React.FunctionComponent = ({ children }) => {
  return (
    <PageContent>
      <div className="page-header">
        <Title is1>Admin</Title>

        <NavTabs
          tabs={[
            { text: 'Users', to: routes.admin() },
            { text: 'Organizations', to: routes.adminOrganizations() },
            { text: 'External Data', to: routes.adminAdapters() },
            { text: 'Competitions', to: routes.adminCompetitions() }
          ]}
        />
      </div>

      <div className="admin-content">{children}</div>
    </PageContent>
  );
};
