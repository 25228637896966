import { Icon, IconType, Logo } from '@ligr/shared';
import classnames from 'classnames';
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Auth } from '../../lib/authContainer';
import { Me } from '../../lib/meContainer';
import { routes } from '../../router/Routes';
import { Loader } from '../loader/Loader';
import { UserMenu } from '../UserMenu/UserMenu';
import './main-menu.scss';

type MainMenuProps = {
  disabled?: boolean;
};

export const MainMenu: React.FunctionComponent<MainMenuProps> = ({ disabled }) => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const { me, loading, refetch } = Me.useContainer();
  const { status, unimpersonate, impersonating } = Auth.useContainer();

  const menuRoutes: {
    [route: string]: [IconType, string];
  } = {
    [routes.home()]: ['dashboard', 'Dashboard'],
    [routes.assets()]: ['assets', 'Assets'],
    [routes.adSets()]: ['ads', 'Ads'],
    [routes.matchesLive()]: ['match', 'Matches'],
    [routes.themes()]: ['themes', 'Themes']
  };

  const doUnimpersonate = async () => {
    await unimpersonate();
    history.push(routes.admin());
  };

  if (loading) return <Loader />;

  return (
    <nav className="main-menu">
      <NavLink className={`home${disabled ? ' disabled' : ''}`} to={routes.home()}>
        <Logo type="light" logoSize="small" />
      </NavLink>
      <div className="links">
        {Object.entries(menuRoutes).map(([r, [icon, label]], i) => (
          <div key={i}>
            <NavLink
              className={classnames({ disabled, [icon]: true })}
              to={r}
              style={{ animationDelay: `${(i + 2) / 10}s` }}
              data-tip={label.toUpperCase()}
              data-for={`menu-${label}`}
            >
              <Icon icon={icon} iconSize="medium" />
            </NavLink>
            <ReactTooltip id={`menu-${label}`} place="right" effect="solid" className="tooltip" />
          </div>
        ))}
      </div>

      {impersonating && (
        <a
          className="unimpersonate"
          onClick={doUnimpersonate}
          data-tip={`Stop impersonating ${me?.firstName} ${me?.lastName}`}
          data-for="user-impersonate"
        >
          <Icon icon="remove" iconSize="medium" />
          <ReactTooltip id="user-impersonate" place="right" effect="solid" className="user-tooltip" />
        </a>
      )}

      <UserMenu disabled={disabled} />
    </nav>
  );
};
