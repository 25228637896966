import { Competition, Team, Venue, VenuesQuery, CompetitionsQuery } from '../../../../api-types';
import { useFormikContext } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { Title } from '../../../../components/Title/Title';
import { FormField } from '../../../../components/FormField';
import { GroupErrorRow } from '../../../../components/GroupErrorRow';
import { MatchFormConfigFields } from '../../MatchFormConfigFields';
import { Button } from '../../../../components/Button';
import { ButtonBottom as BottomButtonRow } from '../../../../components/ButtonBottom/ButtonBottom';
import { ApolloQueryResult } from 'apollo-client';

export interface CreateMatchFormContentProps {
  competitions: ApolloQueryResult<CompetitionsQuery>['data']['competitions'];
  venues: ApolloQueryResult<VenuesQuery>['data']['venues'];
  setCompSelected: (compId: string) => void;
  loading?: boolean;
}

const setDefaultOption = (opt: any, entity: string) => {
  opt.unshift({ value: '', label: `Select ${entity}` });
  return opt;
};

export const CreateMatchFormContent: React.FunctionComponent<CreateMatchFormContentProps> = ({
  competitions,
  venues,
  setCompSelected,
  loading
}) => {
  const form = useFormikContext<any>();
  const values = form.values;
  const disabled = values.competitionId === '';

  const currentCompetition = useMemo(() => {
    return competitions.find((c: Competition) => c.id.toString() === values.competitionId);
  }, [values.competitionId]);

  const homeTeamOptions = useMemo(() => {
    const opt =
      competitions.length && values.competitionId !== ''
        ? competitions
            .filter((c: Competition) => c.id.toString() === values.competitionId)[0]
            .teams.filter((t: Team) => t.id.toString() !== values.awayTeamId) // Removes away team option selected option
            .map((t: Team) => ({ value: `${t.id}`, label: `${t.name}` }))
        : [];
    return setDefaultOption(opt, 'home team');
  }, [values.competitionId, values.awayTeamId]);

  const awayTeamOptions = useMemo(() => {
    const opt =
      competitions.length && values.competitionId !== ''
        ? competitions
            .filter((c: Competition) => c.id.toString() === values.competitionId)[0]
            .teams.filter((t: Team) => t.id.toString() !== values.homeTeamId) // Removes home team option selected option
            .map((t: Team) => ({ value: `${t.id}`, label: `${t.name}` }))
        : [];
    return setDefaultOption(opt, 'away team');
  }, [values.competitionId, values.homeTeamId]);

  const venueOptions = useMemo(() => {
    const opt = venues.length ? venues.map((v: Venue) => ({ value: `${v.id}`, label: `${v.name}` })) : [];
    return setDefaultOption(opt, 'venue');
  }, [competitions, venues]);

  const competitionOptions = useMemo(() => {
    const opt = competitions.length ? competitions.map(c => ({ value: `${c.id}`, label: `${c.name}` })) : [];
    return setDefaultOption(opt, 'competition');
  }, [competitions]);

  useEffect(() => {
    currentCompetition?.matchConfig && form.setFieldValue('config', currentCompetition?.matchConfig);
    form.setFieldValue('sport', currentCompetition?.sport.name);
  }, [currentCompetition]);

  // Set name when are empty
  useEffect(() => {
    let name;
    if (!form.touched.name) {
      name = [values.homeTeamId, values.awayTeamId].map((id: string) => {
        if (id === '') return '';
        return currentCompetition && currentCompetition.teams.filter((t: Team) => t.id === parseInt(id))[0].name;
      });
      if (name.some(n => n !== '')) {
        name = name.join(' VS ');
        form.setFieldValue('name', name);
      } else {
        form.setFieldValue('name', '');
      }
    }
  }, [values.awayTeamId, values.homeTeamId]);

  // sets the sport behind the scenes needed on submitting
  useEffect(() => {
    competitions?.length &&
      values.competitionId !== '' &&
      !values.sport &&
      form.setFieldValue(
        'sport',
        competitions.filter((c: Competition) => c.id.toString() === values.competitionId)[0].sport.name
      );
    values.competitionId === '' && form.setFieldValue('sport', '');
  }, [values.competitionId]);

  useEffect(() => {
    setCompSelected(values.competitionId);
    if (values.competitionId === '') form.resetForm();
  }, [values.competitionId]);

  return (
    <>
      <section className="createMatchFormContent__gameInfo">
        <Title is3>Game Info</Title>
        <FormField
          name="competitionId"
          type="select"
          label="Competition"
          options={competitionOptions}
          colSpan={2}
          value={values.competitionId}
        />
        <input type="hidden" name="sport" />

        <FormField
          name="homeTeamId"
          type="select"
          label="Home team"
          options={homeTeamOptions}
          colSpan={1}
          value={values.homeTeamId}
          disabled={disabled}
        />
        <FormField
          name="awayTeamId"
          type="select"
          label="Away team"
          options={awayTeamOptions}
          colSpan={1}
          value={values.awayTeamId}
          disabled={disabled}
        />
        <FormField
          name="name"
          type="text"
          label="Match name"
          colSpan={2}
          value={values.name}
          disabled={disabled}
          placeholder="Name for this match"
        />
      </section>

      <section className="createMatchFormContent__whenWhere">
        <Title is3>When & Where</Title>
        <GroupErrorRow form={form} errorsName={['date', 'time']}>
          <FormField
            name="date"
            label="Date"
            type="date"
            colSpan={1}
            icon="calendar"
            iconPosition="right"
            iconColor="grey-200"
            groupError
            value={values.date}
            disabled={disabled}
          />
          <FormField
            name="time"
            label="Time"
            type="time"
            colSpan={1}
            icon="clock"
            iconPosition="right"
            iconColor="grey-200"
            groupError
            value={values.time}
            disabled={disabled}
          />
        </GroupErrorRow>

        <FormField
          name="venueId"
          type="select"
          label="Venue"
          options={venueOptions}
          colSpan={1}
          value={values.venueId}
          disabled={disabled}
          className="createMatchFormContent__venueField"
        />
      </section>

      {form.values.config && (
        <section className="createMatchFormContent__matchConfig">
          {MatchFormConfigFields[values.sport.toLowerCase()]({ form, disabled })}
        </section>
      )}

      {form?.dirty && (
        <BottomButtonRow withResetFormButton resetOnClick={() => form.resetForm()}>
          <Button
            onClick={event => {
              event.preventDefault();
              form.submitForm();
            }}
            isLoading={loading}
            type="primary"
          >
            Save
          </Button>
        </BottomButtonRow>
      )}
    </>
  );
};
