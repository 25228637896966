import { AdFileSize } from '@ligr/api-v2';
import { Icon } from '@ligr/shared';
import { IGraphic } from '@ligr/themes/lib/Theme.type';
import { Graphic } from '@ligr/themes/lib/utils/components/Graphic';
import { MatchData } from '@ligr/themes';
import camelCase from 'camelcase';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useErrorHandledQuery } from '../../lib/apolloHooks';
import { competitionsQuery } from '../../lib/gql/queries/competitions';
import { useThemeLoad } from '../../lib/useThemeLoad';
import { adSetsQuery } from '../../pages/Ads/AdSets';
import { routes } from '../../router/Routes';
import { Button } from '../Button';
import { graphicUis } from '../graphicsUi/sports';
import { NavTabs } from '../NavTabs/NavTabs';
import { NavTab } from '../NavTabs/NavTabs.types';
import { Select } from '../Select';
import { ThemeDropdown } from '../ThemeSelector';
import { Title } from '../Title/Title';
import './style.scss';
import { AdFile, AdSet, Competition, FactFragment, FullMatchFragment, ThemeFragment } from '../../api-types';

const useReset = () => {
  const [canShow, setCanShow] = useState(true);

  const reset = () => {
    setCanShow(false);
  };

  useEffect(() => {
    !canShow && setCanShow(true);
  }, [canShow]);

  return { canShow, reset };
};

type AdState = {
  adSize?: AdFileSize;
  adSetId?: number;
};

const GraphicPreview: React.FunctionComponent<{
  graphic: IGraphic;
  sportName: string;
  adState: AdState;
  theme: ThemeFragment;
  adFile?: AdFile | null;
}> = ({ graphic, adState, theme, adFile }) => {
  const { width, height } = { width: 480, height: 270 };

  const { reset, canShow } = useReset();
  useEffect(() => {
    reset();
  }, [graphic]);

  const { data, loading } = useErrorHandledQuery(competitionsQuery, {});

  const Component = graphic.component;

  const url = adFile?.file?.url;

  const filteredComps =
    data?.competitions?.filter(
      ({ themeId, sportId }: Competition) => sportId === theme.sportId && themeId !== theme.id
    ) || [];

  return (
    <div className="graphicPreview__wrapper">
      <div className="graphicPreview__preview" style={{ width, height }}>
        <div
          style={{
            position: 'relative',
            background: 'green',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: -5000
          }}
        >
          {canShow && (
            <Graphic
              template={Component}
              name={graphic.name}
              adTransformObject={graphic.adTransformObject}
              animationList={graphic.animations}
              fieldDataSpec={graphic.demoDataSpec || graphic.dataSpec}
              delayMap={graphic.delayMap}
              // @ts-ignore
              graphicProps={{
                ...adState,
                // @ts-ignore
                adUrl: url
              }}
            />
          )}
        </div>
      </div>
      <div className="graphicPreview__btnGroup">
        <Button onClick={reset} type={'secondary'}>
          Re Animate
        </Button>
        <ThemeDropdown theme={theme} comps={filteredComps} />
      </div>
    </div>
  );
};

type ThemePreviewSettingsProps = {
  adSets: { id: number; name: string }[];
  onChange: (newState: AdState) => void;
  value: AdState;
  currentGraphicName: string;
  sport: string;
  facts: FactFragment[];
  match: Omit<FullMatchFragment, '__typename'>;
  adFileSizes?: string[];
  triggerToGraphicData?: (data: Omit<MatchData, 'desiredState'>, triggerProps: any) => any;
};

export const ThemePreviewSettings: React.FunctionComponent<ThemePreviewSettingsProps> = ({
  triggerToGraphicData,
  adFileSizes,
  adSets,
  onChange,
  value,
  sport,
  currentGraphicName,
  facts,
  match
}) => {
  // @ts-ignore
  const CustomGraphicUi = graphicUis[camelCase(sport)][currentGraphicName];

  // @ts-ignore
  const allData = useMemo<MatchData | undefined>(() => {
    return match
      ? {
          homeTeam: match?.homeTeam,
          awayTeam: match?.awayTeam,
          summary: match?.summary,
          stats: match?.summary?.matchStats,
          match,
          venue: match?.venue,
          facts
        }
      : undefined;
  }, [match, facts]);

  const update = useCallback(
    localState => {
      return {
        ...localState,
        ...(triggerToGraphicData ? triggerToGraphicData(allData, localState) : undefined)
      };
      // triggerToGraphicData lazy loads after allData
    },
    [allData, triggerToGraphicData]
  );
  useEffect(() => {
    onChange(update({ ...value }));
    // triggerToGraphicData lazy loads after allData
  }, [currentGraphicName, triggerToGraphicData]);

  return (
    <div className="themePreview__previewProps">
      {adFileSizes?.length && (
        <>
          <p className="themePreview__previewProps__heading">AD SETTINGS</p>
          <div className="themePreview__previewProps__row">
            <label htmlFor={'adSetId'}>Ad Set</label>
            <Select
              // @ts-ignore
              value={value.adSetId || ''}
              onChange={event =>
                onChange(
                  update({
                    ...value,
                    // @ts-ignore
                    adSetId: parseInt(event.target.value)
                  })
                )
              }
              placeholder="Ad Set"
              options={
                adSets?.map((adSet: AdSet) => {
                  return { value: adSet.id, label: adSet.name };
                }) || []
              }
              name="adSetId"
            />
          </div>
          <div className="themePreview__previewProps__row">
            <label htmlFor={'adSize'}>Ad Size</label>
            <Select
              // @ts-ignore
              onChange={event => onChange(update({ ...value, adSize: event.target.value }))}
              placeholder="Ad Size"
              // @ts-ignore
              value={value.adSize || ''}
              options={adFileSizes?.map(name => ({ value: name, label: name }))}
              name="adSize"
            />
          </div>
        </>
      )}
      {CustomGraphicUi && <p className="themePreview__previewProps__heading">GRAPHIC SETTINGS</p>}
      {CustomGraphicUi && (
        <CustomGraphicUi
          facts={facts}
          value={value}
          summary={match.summary}
          match={match}
          onChange={(changes: any) => {
            onChange(
              update({
                ...value,
                // @ts-ignore
                ...changes
              })
            );
          }}
        />
      )}
    </div>
  );
};

export const ThemePreview: React.FunctionComponent<{ theme: ThemeFragment; onClose?: (args: any) => any }> = ({
  theme,
  onClose
}) => {
  const [adState, setAdState] = useState<AdState>({});

  const { data } = useErrorHandledQuery(adSetsQuery);
  const themeObj = useThemeLoad(theme);

  const history = useHistory<{ referrer?: string } | undefined>();
  const { graphicName } = useParams();

  const graphic = useMemo(() => themeObj?.graphics.find(({ name }) => name === graphicName), [themeObj, graphicName]);

  useEffect(() => {
    if (!graphicName && themeObj) {
      history.push({
        pathname: routes.themeGraphicPreview({ themeId: theme.id, graphicName: themeObj.graphics[0].name })
      });
    }
  }, [graphicName, themeObj]);

  return (
    <div className="themePreview">
      <div className={'themePreview__header'}>
        <div className="themePreview__header__heading">
          <Title is4 hasTextGrey600 className="themePreview__header__heading__preTitle">
            {'THEME PREVIEW'}
          </Title>
          <Title is2 className="themePreview__header__heading__title">
            {theme.name}
          </Title>
        </div>

        <div className="close" onClick={onClose}>
          <Icon icon="close" color="grey-600" />
        </div>
      </div>
      {themeObj && (
        <div className="themePreview__tabs">
          <NavTabs
            tabs={themeObj?.graphics.map(({ name }) => {
              return {
                to: routes.themeGraphicPreview({ graphicName: name, themeId: theme.id }),
                text: name
              } as NavTab;
            })}
          />
          <div className="themePreview__divider" />
        </div>
      )}
      {graphic && (
        <div className="themePreview__graphicContainer">
          <GraphicPreview
            theme={theme}
            adFile={(data?.adSets.find((as: AdSet) => as.id === adState?.adSetId) as AdSet).adFiles?.find(
              af => af.size === adState.adSize
            )}
            adState={adState}
            sportName={theme.sportName}
            graphic={graphic}
          />
          {/*<ThemePreviewSettings
          value={adState}
          currentGraphicName={graphicName}
          sport={theme.sportName}
          facts={[]}
          onChange={newState => setAdState(newState)}
          adSets={data?.adSets}
        />*/}
        </div>
      )}
    </div>
  );
};
