import { EFinishedStatus } from '@ligr/api-v2';
import { EFinishedStatusNames } from '@ligr/api-v2/dist/types/Match';
import { updateQueryUrl } from '@ligr/shared';
import queryString from 'query-string';
import React from 'react';
import { useHistory } from 'react-router';
import { FilterOptions } from '.';
import { Select } from '../Select';
import { SelectOption } from '../Select/types';

interface StatusFilterProps {
  setActiveFilter: (name: FilterOptions, value: string) => void;
}

const buildOptions = (tab: string) => {
  if (tab === 'completed') {
    return [
      { label: 'All', value: '' },
      { label: 'Finished', value: EFinishedStatus.finished },
      { label: 'Cancelled', value: EFinishedStatus.cancelled }
    ];
  }
  if (tab === 'notstarted') {
    return [{ label: 'All', value: '' }];
  }
  return [
    { label: 'All', value: '' },
    { label: 'Upcoming', value: EFinishedStatus.pregame },
    { label: 'Live', value: EFinishedStatus.inProgress },
    { label: 'Running late', value: EFinishedStatus.runningLate }
  ];
};

export const StatusFilter: React.FunctionComponent<StatusFilterProps> = ({ setActiveFilter }) => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const tab = history.location.pathname.split('/')[4];
  const queryStrObject = queryString.parse(history.location.search);

  const options: SelectOption[] = buildOptions(tab);

  const handleChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const val = (e.target as HTMLSelectElement).value;
    setActiveFilter(
      'status',
      val === ''
        ? ''
        : // prettier-ignore
          /*
         //  @ts-ignore
         */ EFinishedStatusNames[val]
    );
    updateQueryUrl(history, { status: val });
  };

  return (
    <div className="filters__select__wrapper filters__select__wrapper--status">
      <label className="filters__select__wrapper__label" htmlFor="status">
        Status
      </label>
      <Select
        name="status"
        className="filters__select__wrapper__select"
        options={options}
        onChange={(e: React.FormEvent<HTMLSelectElement>) => handleChange(e)}
        value={queryStrObject?.status || ''}
      />
    </div>
  );
};
