import gql from 'graphql-tag';

export const venueQuery = gql`
  query venue($id: Float!) {
    venue(id: $id) {
      id
      name
      gfxName
      lat
      lng
      city
      state
      country
      postcode
      address
      countMatches
      countDefaultVenue
      archived
      baseEntity {
        id
        name
      }
    }
  }
`;
