import clone from 'clone';
import { Location } from 'history';
import React from 'react';
import { matchPath, Redirect, Route, RouteProps, Switch, useHistory, useLocation } from 'react-router-dom';
import { ComingSoon } from '../components/ComingSoon/ComingSoon';
import { Loader } from '../components/loader/Loader';
import { MainMenu } from '../components/MainMenu/MainMenu';
import { withTitle } from '../components/PageTitle/PageTitle';
import { Auth } from '../lib/authContainer';
import { Me } from '../lib/meContainer';
import { Organization } from '../lib/orgContainer';
import { CreateOrganizationModal } from '../modals/CreateOrganization/CreateOrganization.modal';
import { AdminRouter } from '../pages/Admin/Admin.router';
import { AdSetsRouter } from '../pages/Ads/AdsRouter';
import { AssetsRouter } from '../pages/Assets/Assets.router';
import { EditClubTeamsPage } from '../pages/Assets/Club/EditClubTeamsPage';
import { NewClubPage } from '../pages/Assets/Club/NewClubPage';
import { NewTeamPage } from '../pages/Assets/Team/NewTeamPage';
import { NetworkError } from '../pages/Errors/NetworkError';
import { NotFound } from '../pages/Errors/NotFound';
import { PlanUpgrade } from '../pages/Errors/PlanUpgrade';
import { Unauthorized } from '../pages/Errors/Unauthorized';
import { ForgotPasswordPage } from '../pages/ForgotPassword/ForgotPassword.page';
import { HomePage } from '../pages/Home';
import { InvitedUserPasswordSet } from '../pages/InvitedUserPasswordSet';
import { LoginPage } from '../pages/Login/Login.page';
import { MatchesRouter } from '../pages/Matches/Matches.router';
import { OverlaysRouter } from '../pages/OverlayControl/router';
import { ProfileRouter } from '../pages/Profile/Profile.router';
import { RegisterPage } from '../pages/Register/Register.page';
import { SelectOrganizationPage } from '../pages/SelectOrganization';
// import { ThemesRouter } from '../pages/Themes/Themes.router';
import { UpdatePasswordPage } from '../pages/UpdatePassword/UpdatePassword.page';
import { VerifyEmailPage } from '../pages/VerifyEmail/VerifyEmail.page';
import { routes, URL_PREFIX } from '../router/Routes';

const getLoginRedirect = (
  location: Location<{ referrer?: string | Location<any> } | undefined | null>,
  defaultOrgId?: number
): string | Location<any> => {
  if (location.state?.referrer) {
    return location.state?.referrer;
  }
  if (defaultOrgId) {
    return routes.home({ orgId: defaultOrgId });
  }

  return routes.orgSelection();
};

const AuthRoute: React.FunctionComponent<RouteProps> = props => {
  const { status, hasBeenLoggedIn, impersonating } = Auth.useContainer();
  const history = useHistory<{ referrer?: string | Location<any> } | undefined>();
  const meContainer = Me.useContainer();
  console.log(meContainer, status);

  if (status === 'loggedOut') {
    return (
      <Redirect
        to={{
          pathname: routes.login(),
          state: history.location.state || {
            referrer: hasBeenLoggedIn ? undefined : clone(history.location)
          }
        }}
      />
    );
  }
  if (['loggingIn', 'verifying'].includes(status as string) || !meContainer.called || meContainer.loading)
    return <Loader />;

  if (impersonating) {
    return (
      <div className="impersonating">
        <Route {...props} />
      </div>
    );
  }

  const me = meContainer.me!;

  // @ts-ignore
  window.analytics.identify(me.id, {
    firstName: me.firstName,
    lastName: me.lastName,
    email: me.email
  });
  // @ts-ignore
  window.analytics.page();

  return <Route {...props} />;
};

const UnAuthRoute: React.FunctionComponent<RouteProps> = props => {
  const { status, defaultOrganizationId } = Auth.useContainer();
  const history = useHistory();
  const location = useLocation();

  if (['verifying'].includes(status as string)) return <Loader />;
  if (status === 'loggedIn') {
    // @ts-ignore
    history.push(getLoginRedirect(location, defaultOrganizationId));
  }
  return <Route {...props} />;
};

export const Router: React.FunctionComponent = () => {
  const location = useLocation<{ referrer?: string } | undefined>();

  return (
    <Switch>
      <UnAuthRoute
        exact
        path={routes.register(false)}
        component={withTitle({
          component: RegisterPage,
          pageTitle: 'Register'
        })}
      />
      <UnAuthRoute
        exact
        path={routes.login(false)}
        component={withTitle({ component: LoginPage, pageTitle: 'Login' })}
      />
      <UnAuthRoute
        exact
        path={routes.forgotPassword(false)}
        component={withTitle({
          component: ForgotPasswordPage,
          pageTitle: 'Forgot password'
        })}
      />
      <UnAuthRoute
        exact
        path={routes.updatePassword(false)}
        component={withTitle({
          component: UpdatePasswordPage,
          pageTitle: 'Update password'
        })}
      />
      <UnAuthRoute
        exact
        path={routes.invitedUserPasswordSet(false)}
        component={withTitle({
          component: InvitedUserPasswordSet,
          pageTitle: 'Set Initia l Password'
        })}
      />
      <UnAuthRoute
        exact
        path={routes.verify(false)}
        component={withTitle({
          component: VerifyEmailPage,
          pageTitle: 'Verify email'
        })}
      />
      <AuthRoute
        exact
        path={routes.networkError(false)}
        component={withTitle({
          component: NetworkError,
          pageTitle: 'Network Error - 503'
        })}
      />
      <AuthRoute
        exact
        path={routes.unauthorized(false)}
        component={withTitle({
          component: Unauthorized,
          pageTitle: 'Unauthorized - 401'
        })}
      />
      <AuthRoute
        exact
        path={routes.notFound(false)}
        component={withTitle({
          component: NotFound,
          pageTitle: 'Not Found - 404'
        })}
      />
      <AuthRoute
        path={routes.controlOverlay(false)}
        component={withTitle({
          component: OverlaysRouter,
          pageTitle: 'Overlay Control'
        })}
      />

      {/* CREATE ROUTES */}
      <AuthRoute
        exact
        path={routes.assetTeamCreate(false)}
        component={withTitle({ component: NewTeamPage, pageTitle: 'New Team' })}
      />
      <AuthRoute
        exact
        path={routes.assetClubCreate(false)}
        component={withTitle({ component: NewClubPage, pageTitle: 'New club' })}
      />
      <AuthRoute
        exact
        path={routes.assetClubEditTeams(false)}
        component={withTitle({ component: EditClubTeamsPage, pageTitle: 'Add teams to club' })}
      />

      <AuthRoute path="*">
        <div className="app-grid">
          {!matchPath(location.pathname, {
            path: routes.controlOverlay(false)
          }) && (
            <MainMenu
              disabled={
                !!matchPath(location.pathname, {
                  path: routes.orgSelection(false),
                  exact: true
                }) || !!matchPath(location.pathname, { path: routes.admin(false) })
              }
            />
          )}
          <Switch>
            <Route
              exact
              path={routes.orgSelection(false)}
              component={withTitle({
                component: SelectOrganizationPage,
                pageTitle: 'Organization selection'
              })}
            />
            <Route
              exact
              path={routes.organizationCreate(false)}
              component={withTitle({
                component: CreateOrganizationModal,
                pageTitle: 'Create organization'
              })}
            />
            <Route
              path={routes.admin(false)}
              component={withTitle({
                component: AdminRouter,
                pageTitle: 'Admin'
              })}
            />
            <Route path={URL_PREFIX}>
              <Organization.Provider>
                <Switch>
                  <Route
                    exact
                    path={routes.planUpgrade(false)}
                    component={withTitle({
                      component: PlanUpgrade,
                      pageTitle: 'Plan upgrade - 402'
                    })}
                  />
                  <Route
                    path={routes.adSets(false)}
                    component={withTitle({
                      component: AdSetsRouter,
                      pageTitle: 'Ads'
                    })}
                  />
                  <Route
                    path={routes.assets(false)}
                    component={withTitle({
                      component: AssetsRouter,
                      pageTitle: 'Assets'
                    })}
                  />
                  <Route
                    path={routes.matchesLive(false)}
                    component={withTitle({
                      component: MatchesRouter,
                      pageTitle: 'Matches'
                    })}
                  />
                  <Route
                    path={routes.themes(false)}
                    component={withTitle({
                      component: ComingSoon,
                      pageTitle: 'Themes'
                    })}
                  />
                  <Route
                    path={routes.profile(false)}
                    component={withTitle({
                      component: ProfileRouter,
                      pageTitle: 'Profile'
                    })}
                  />
                  <Route
                    exact
                    path={[routes.home(false)]}
                    component={withTitle({
                      component: HomePage,
                      pageTitle: 'Home'
                    })}
                  />
                  <Route
                    path={'*'}
                    render={({
                      match: {
                        params: { orgId }
                      }
                    }) => <Redirect to={routes.home({ orgId })} />}
                  />
                </Switch>
              </Organization.Provider>
            </Route>
            <Route path={'*'} render={() => <Redirect to={routes.orgSelection(false)} />} />
          </Switch>
        </div>
      </AuthRoute>
    </Switch>
  );
};
