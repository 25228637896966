import gql from 'graphql-tag';
import React, { useMemo } from 'react';
import { SideMenuList, SideMenuListItem } from '../../../../components/SideMenuList';
import { useErrorHandledQuery } from '../../../../lib/apolloHooks';
import { routes } from '../../../../router/Routes';
import { FileEntityType, CompForMenuQuery } from '../../../../api-types';
import { ImagePlaceholderType } from '@ligr/shared';

const competitionsQuery = gql`
  query CompForMenu {
    competitions {
      id
      name
      logo {
        id
        url
      }
    }
  }
`;

export const CompetitionSideMenu: React.FunctionComponent = () => {
  const { data } = useErrorHandledQuery<CompForMenuQuery>(competitionsQuery);
  const competitions = data ? data.competitions : [];

  const items = useMemo(() => {
    return competitions.map(c => ({
      imageUrl: c.logo?.url,
      text: c.name,
      to: routes.assetsCompetition({ competitionId: c.id }),
      imagePlaceholder: 'competition' as ImagePlaceholderType
    }));
  }, [competitions]);

  return (
    <SideMenuList entityType={FileEntityType.competitionLogo} items={items} link={routes.assetCompetitionCreate()} />
  );
};
