import { useReducer } from 'react';
import { createContainer } from 'unstated-next';
import { string } from 'yup';

type NotificationType = 'alert' | 'error' | 'info' | 'success';

type Notification = {
  message: string;
  type: NotificationType;
};

enum NotificationActionType {
  Add = 'add',
  Remove = 'remove'
}

interface NotificationState {
  notifications: Notification[];
}

interface NotificationAction {
  type: NotificationActionType;
  payload: {
    message?: string;
    notificationType?: string;
  };
}

const initialState: NotificationState = {
  notifications: []
};

const reducer: React.Reducer<NotificationState, NotificationAction> = (state, action) => {
  switch (action.type) {
    case NotificationActionType.Add:
      return {
        notifications: [
          {
            message: action.payload.message,
            type: action.payload.notificationType
          } as Notification,
          ...state.notifications
        ]
      };
    case NotificationActionType.Remove:
      const notifications = state.notifications;
      notifications.pop();
      return {
        notifications
      };
    default:
      return state;
  }
};

type Error = {
  message: string;
  code?: string;
};

const parseErrors = (exception: any): Error[] => {
  const errors: Error[] = [];
  if (exception.graphQLErrors) {
    exception.graphQLErrors.forEach((error: any) => {
      errors.push({ message: error.message, code: error.code });
    });
  } else if (exception.networkError) {
    exception.networkError.forEach((error: any) => {
      console.info(error);
    });
  } else if (typeof exception === 'string') {
    errors.push({
      message: exception
    });
  } else {
    errors.push(exception);
  }
  return errors;
};

const useNotification = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const addNotification = (exception: any, type: NotificationType = 'error') => {
    const errors = parseErrors(exception);
    errors.forEach(err => {
      dispatch({ type: NotificationActionType.Add, payload: { message: err.message, notificationType: type } });
      setTimeout(() => dispatch({ type: NotificationActionType.Remove, payload: {} }), 5000);
    });
  };

  return {
    notifications: state.notifications,
    addNotification
  };
};

export const Notification = createContainer(useNotification);
