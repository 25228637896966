import gql from 'graphql-tag';
import { adFileFragment, fileFragment } from '@ligr/shared';

export const createAdFileMutation = gql`
  mutation createAdFile($createAdFileInput: CreateAdFileInput!) {
    createAdFile(createAdFileInput: $createAdFileInput) {
      ...AdFile
    }
  }
  ${adFileFragment}
  ${fileFragment}
`;
