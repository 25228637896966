import classnames from 'classnames';
import { getIn, useFormikContext } from 'formik';
import React, { useEffect, useState, useMemo, memo } from 'react';
import { useHistory } from 'react-router';
import { v4 } from 'uuid';
import { FormProps } from '../../../../components/Form/Form.types';
import { useErrorHandledLazyQuery } from '../../../../lib/apolloHooks';
import { FormField } from '../../../../components/FormField';
import { routes } from '../../../../router/Routes';
import { ExistingPlayerMenuItem } from '../../../../components/InputField/InlineResultsInput/ExistingPlayerMenuItem';
import { Tabs } from '../../../../components/Tabs/Tabs';
import { Button } from '../../../../components/Button';
import { Title } from '../../../../components/Title/Title';
import { ButtonBottom as BottomButtonRow } from '../../../../components/ButtonBottom/ButtonBottom';
import { searchPlayersQuery } from '../gql';
import { positionTypesBySport } from '@ligr/shared';
import { ApolloQueryResult } from 'apollo-client';
import { SearchPlayersQuery, TeamsWithCompQuery } from '../../../../api-types';
import './style.scss';

const photoText = (
  <div>
    <p>Upload a photo with a transparent background, and no padding around the photo.</p>
  </div>
);

export interface PlayerTeamValues {
  number: string;
  position?: string | undefined;
  photo?: File | string | null;
  photoId?: number;
  teamId: number;
  abbreviation?: string | null;
  defaultStarter?: boolean;
  delete?: boolean;
  name: string;
  preexisting?: boolean;
}
export interface PlayerFormValues {
  id?: number;
  firstName: string;
  lastName: string;
  gfxFirstName: string;
  gfxLastName: string;
  teams?: PlayerTeamValues[];
}

interface NewPlayerPageFormContentProps extends FormProps<PlayerFormValues> {
  teams: ApolloQueryResult<TeamsWithCompQuery>['data']['teams'];
}

type QueryPlayersType = ApolloQueryResult<SearchPlayersQuery>['data']['searchPlayers'];
type QueryPlayerType = QueryPlayersType[0];

export const NewPlayerPageFormContent: React.FunctionComponent<NewPlayerPageFormContentProps> = ({ teams }) => {
  const form = useFormikContext<PlayerFormValues>();
  const values = form.values;
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [firstNamePlayers, setFirstNamePlayers] = useState<QueryPlayersType>();
  const [lastNamePlayers, setLastNamePlayers] = useState<QueryPlayersType>();

  const [fetch, { data, loading }] = useErrorHandledLazyQuery<SearchPlayersQuery>(searchPlayersQuery);

  const history = useHistory<{ referrer?: string; playerId?: number } | undefined>();

  const currentTeam = (values.teams && values.teams[currentTab]) || undefined;
  const currentTeamDeleted = getIn(values, `teams.${currentTab}.delete`);

  const firstNameLength = form.values.firstName.length;
  const lastNameLength = form.values.lastName.length;
  const showGfxNameFields = firstNameLength > 26 || lastNameLength > 26;

  useEffect(() => {
    if (!showGfxNameFields) {
      form.setFieldValue('gfxFirstName', '');
      form.setFieldValue('gfxLastName', '');
    }
  }, [showGfxNameFields]);

  useEffect(() => {
    if (form.values.firstName.length > 1) {
      fetch({ variables: { searchStr: form.values.firstName, searchFromStart: true, searchOnFields: ['firstName'] } });
      setFirstNamePlayers(data?.searchPlayers);
    } else {
      setFirstNamePlayers(undefined);
    }
  }, [form.values.firstName]);

  useEffect(() => {
    if (form.values.lastName.length > 1) {
      fetch({ variables: { searchStr: form.values.lastName, searchFromStart: true, searchOnFields: ['lastName'] } });
      setLastNamePlayers(data?.searchPlayers);
    } else {
      setLastNamePlayers(undefined);
    }
  }, [form.values.lastName]);

  const isdelete = (teamId: number) => {
    const team = values.teams!.find(({ teamId: curTeamId }) => teamId === curTeamId);
    return team!.delete;
  };

  const getRemoveMessage = () => {
    if (currentTeam && currentTeam.preexisting) {
      return `${!currentTeam.delete ? 'Remove Player From Team' : 'Undo Remove Team'}`;
    }

    return 'Remove Player From Team';
  };

  const teamOptions = useMemo(() => {
    if (teams) {
      return teams.map(({ name, id }) => ({
        label: name,
        value: id
      }));
    }
    return [];
  }, [teams]);

  const sport = useMemo(() => {
    const team = teams?.find(t => t.id === currentTeam?.teamId);
    return team ? team.sport.name : undefined;
  }, [currentTeam, teams]);

  const positionOptions = useMemo(() => {
    // @ts-ignore
    return positionTypesBySport[sport]?.map(p => {
      return { value: p, label: p };
    });
  }, [sport]);

  const showWarningTooltipFirst =
    (firstNameLength > 26 || firstNameLength + lastNameLength > 28) && form.values.firstName.length > 0;
  const showWarningTooltipLast =
    (lastNameLength > 26 || firstNameLength + lastNameLength > 28) && form.values.lastName.length > 0;
  const exceedsCharactersMsg = (
    <>
      <div>
        This name exceeds the character limit. Form the live stream graphics, please edit the name below if you wish to
        change it
      </div>
      <div>
        <b>Limits:</b>
      </div>
      <div>
        <b>First name:</b> 26 characters
      </div>
      <div>
        <b>Last name:</b> 26 characters
      </div>
      <div>
        <b>Total:</b> 28 characters
      </div>
    </>
  );

  return (
    <div className="player-form-content">
      <div className="player">
        <div className="name-fields">
          <FormField
            name="firstName"
            type="inline-results"
            className="firstNameField"
            label="First Name"
            menuTitle="Already Exists?"
            loading={loading}
            startAtLength={2}
            colSpan={1}
            items={
              firstNamePlayers?.map(player => ({
                value: player,
                label: `${player.firstName} ${player.lastName}`
              })) || []
            }
            onSelection={({ value }) => history.push(routes.assetPlayerEdit({ playerId: value }))}
            renderItem={(p: any) => <ExistingPlayerMenuItem player={p} />}
            icon={showWarningTooltipFirst ? 'warning' : undefined}
            iconColor={showWarningTooltipFirst ? 'blue' : undefined}
            iconPosition={showWarningTooltipFirst ? 'right' : undefined}
            iconTooltip={showWarningTooltipFirst ? exceedsCharactersMsg : undefined}
            tooltipPosition={showWarningTooltipLast ? 'top' : undefined}
          />

          <FormField
            name="lastName"
            type="inline-results"
            label="Last Name"
            menuTitle="Already Exists?"
            className="lastNameField"
            loading={loading}
            startAtLength={2}
            colSpan={1}
            items={
              lastNamePlayers?.map(player => ({
                value: player,
                label: `${player.firstName} ${player.lastName}`
              })) || []
            }
            onSelection={({ value }) => history.push(routes.assetsPlayer({ playerId: value }))}
            renderItem={(p: any) => <ExistingPlayerMenuItem player={p} />}
            icon={showWarningTooltipLast ? 'warning' : undefined}
            iconColor={showWarningTooltipLast ? 'blue' : undefined}
            iconPosition={showWarningTooltipLast ? 'right' : undefined}
            iconTooltip={showWarningTooltipLast ? exceedsCharactersMsg : undefined}
            tooltipPosition={showWarningTooltipLast ? 'top' : undefined}
          />

          {showGfxNameFields && (
            <>
              <FormField
                className="gfxFirstNameField"
                name="gfxFirstName"
                type="text"
                label="Gfx First Name"
                colSpan={1}
                placeholder="Please add a shorter first name for graphics (max 26 characters)"
                maxLength={28 - form.values.gfxLastName.length}
              />
              <FormField
                className="gfxLastNameField"
                name="gfxLastName"
                type="text"
                label="Gfx Last Name"
                colSpan={1}
                placeholder="Please add a shorter last name for graphics (max 26 characters)"
                maxLength={28 - form.values.gfxFirstName.length}
              />
              <div style={{ marginTop: '-30px', marginBottom: '30px' }}>{`${
                28 - (form.values.gfxLastName?.length + form.values.gfxFirstName.length)
              } characters left`}</div>
            </>
          )}
        </div>
      </div>
      <div className="player-team">
        <div className={classnames('tab-row', { 'no-teams': !values.teams || !values.teams.length })}>
          {values.teams && values.teams.length ? (
            <Tabs
              active={currentTab}
              onChange={({ index }) => setCurrentTab(index)}
              tabs={
                values && values.teams
                  ? values.teams.map(({ teamId, name }, index) => ({
                      value: teamId,
                      text: name,
                      className: classnames({
                        delete: isdelete(teamId),
                        error: currentTab !== index && getIn(form.errors, `teams.${index}`)
                      })
                    }))
                  : []
              }
            />
          ) : (
            ''
          )}

          <Button
            iconColor="black"
            icon="plus"
            type="secondary"
            onClick={e => {
              e.preventDefault();
              form.setFieldValue('teams', [
                ...values.teams!,
                {
                  name: 'New Team',
                  number: '',
                  position: '',
                  photo: undefined,
                  defaultStarter: false,
                  teamId: `new_team_${v4()}`
                }
              ]);
              setCurrentTab(values.teams!.length);
            }}
          >
            ADD TEAM
          </Button>
        </div>

        {values.teams && values.teams.length ? (
          <div className="player-team-content">
            <div>
              {/*
           // @ts-ignore */}
              {currentTeam && !currentTeam.preexisting && (
                <FormField
                  disabled={currentTeamDeleted}
                  name={`teams.${currentTab}.teamId`}
                  type="select"
                  value={currentTeam.teamId}
                  placeholder={'Select Team'}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const team = teamOptions.find(({ value }) => value === parseInt(e.target.value))!;
                    form.setFieldValue('teams', [
                      ...values.teams!.slice(0, currentTab),
                      {
                        ...currentTeam,
                        name: !team ? 'New Team' : team.label,
                        teamId: team?.value || ''
                      },
                      ...values.teams!.slice(currentTab + 1, values.teams!.length)
                    ]);
                  }}
                  options={teamOptions.filter(
                    ({ value }) => value === currentTeam.teamId || !values.teams!.find(({ teamId }) => teamId === value)
                  )}
                  label="Team"
                />
              )}
              <FormField
                disabled={!sport}
                name={`teams.${currentTab}.positionType`}
                type="select"
                label="Position type"
                options={positionOptions}
                placeholder="Select a position"
              />
              <FormField type="text" name={`teams.${currentTab}.position`} label="Position" disabled={!sport} />
              <FormField disabled={currentTeamDeleted} name={`teams.${currentTab}.number`} type="text" label="Number" />
              <FormField
                isDisabled={currentTeamDeleted}
                name={`teams.${currentTab}.defaultStarter`}
                label="Starter"
                type="switch"
              />
              <Button
                onClick={event => {
                  event.preventDefault();
                  if (currentTeam && currentTeam.preexisting) {
                    form.setFieldValue(`teams`, [
                      ...values.teams!.slice(0, currentTab),
                      {
                        ...currentTeam,
                        delete: !currentTeam.delete
                      },
                      ...values.teams!.slice(currentTab + 1, values.teams!.length)
                    ]);
                    return;
                  }

                  setCurrentTab(0);

                  form.setFieldValue(`teams`, [
                    ...values.teams!.slice(0, currentTab),
                    ...values.teams!.slice(currentTab + 1, values.teams!.length)
                  ]);
                }}
                type={'borderless'}
                className="remove-player-btn"
              >
                {getRemoveMessage()}
              </Button>
            </div>
            <div>
              <Title is3>Photo</Title>
              <FormField
                name={`teams.${currentTab}.photo`}
                disabled={currentTeamDeleted}
                type="image"
                colSpan={1}
                value={form.values.teams?.[currentTab]?.photo as File | undefined}
                tooltip={photoText}
                editable
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {form?.dirty && (
        <BottomButtonRow withResetFormButton resetOnClick={() => form.resetForm()}>
          <Button
            onClick={event => {
              event.preventDefault();
              form.submitForm();
            }}
            isLoading={loading}
            type="primary"
          >
            Save
          </Button>
        </BottomButtonRow>
      )}
    </div>
  );
};
