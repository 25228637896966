import { playsForFragment } from '@ligr/shared';
import gql from 'graphql-tag';

export const playersQuery = gql`
  query players($options: IQueryOptions) {
    players(options: $options) {
      id
      firstName
      lastName
      countTeams
      archived
      playsFor {
        ...PlaysFor
        team {
          id
          name
          competitions {
            id
            name
            sport {
              id
              name
            }
          }
        }
      }
    }
  }
  ${playsForFragment}
`;
