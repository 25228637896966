import gql from 'graphql-tag';
import { factFragment, playsForFragment } from '@ligr/shared';

export const factsQuery = gql`
  query facts($matchId: Float!) {
    facts(matchId: $matchId) {
      ...Fact
      player {
        id
        firstName
        lastName
        playsFor {
          ...PlaysFor
        }
      }
    }
  }
  ${factFragment}
  ${playsForFragment}
`;
