import { AdSet, Competition, EntityAdAllocation, Player, Team, Venue } from '../api-types';

export enum EntityEnum {
  competitions = 'competitions',
  teams = 'teams',
  players = 'players',
  venues = 'venues',
  adSets = 'ad sets',
  allocations = 'allocations'
}

export type EntityList = {
  id: number;
}[];
