export const customStyles = {
  container: (provided: object, state: any) => ({
    ...provided
  }),
  control: (provided: object, { isFocused, isDisabled }: any) => ({
    ...provided,
    color: `${isDisabled ? '#939393' : '#000'}`,
    fontSize: '1.4rem',
    verticalAlign: 'middle',
    background: `${isDisabled ? '#f5f5f5' : '#ffffff'}`,
    border: `1px solid ${isDisabled ? '#e6e6e6' : isFocused ? '#5589F1' : '#E6E6E6'}`,
    boxShadow: 'none',
    borderRadius: '0.3rem',
    ':hover': {
      border: `1px solid ${isFocused ? '#5589F1' : '#CCCCCC'}`
    }
  }),
  multiValueRemove: (provided: object, state: any) => ({
    ...provided,
    ':hover': {
      color: '#ffffff',
      background: '#FF504E'
    },
    ':active': {
      color: '#ffffff',
      background: '#A33A38'
    }
  }),
  menu: (provided: object, state: any) => ({
    ...provided,
    padding: 0,
    borderRadius: '0.3rem'
  }),
  menuList: (provided: object, state: any) => ({
    ...provided,
    padding: 0,
    borderRadius: '0.3rem'
  }),
  option: (provided: object, state: any) => ({
    ...provided,
    backgroundColor: 'none',
    ':hover': {
      background: '#E6E6E6'
    }
  }),
  multiValueLabel: (provided: object, state: any) => ({
    ...provided,
    padding: '3px 6px 3px 12px',
    paddingLeft: '12px',
    maxWidth: '200px'
  }),
  input: (provided: object, state: any) => ({
    ...provided,
    width: '0px',
    margin: 0,
    padding: 0
  }),
  placeholder: (provided: object) => ({
    ...provided,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  })
};

/**
clearIndicator
container
control
dropdownIndicator
group
groupHeading
indicatorsContainer
indicatorSeparator
input
loadingIndicator
loadingMessage
menu
menuList
menuPortal
multiValue
multiValueLabel
multiValueRemove
noOptionsMessage
option
placeholder
singleValue
valueContainer
 */
