// @ts-ignore
import Slider, { Handle } from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import React, { useEffect, useState } from 'react';
import { Selection } from '../Subscription.type';
import './MatchSlider.scss';

interface MatchSliderProps {
  selection: Selection;
  updateSelection: (selection: Selection) => void;
}

const MatchSliderHandle: React.FunctionComponent = (props: any) => {
  const { value, index, dragging, ...restProps } = props;

  return (
    <div className="slider-handle">
      <Handle value={value} {...restProps} />
      <div className="slider-tooltip" style={{ left: `${value}%` }}>
        {Math.round(restProps.offset)}
      </div>
    </div>
  );
};

export const MatchSlider: React.FunctionComponent<MatchSliderProps> = ({ selection, updateSelection }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (selection.plan && selection.plan.limit && selection.quantity && selection.plan.limit < selection.quantity) {
      setValue(selection.plan.limit);
    } else {
      if (selection.quantity) {
        setValue(selection.quantity);
      } else {
        if (selection.plan && selection.plan.quantity) setValue(selection.plan.quantity);
      }
    }
  }, [selection]);

  const change = (value: number) => {
    if (selection.plan && selection.plan.limit && value >= selection.plan.limit) {
      updateSelection({ ...selection, quantity: selection.plan.limit });
    } else {
      updateSelection({ ...selection, quantity: value });
    }
  };

  return (
    <div className="match-slider">
      <label className="label">How many matches do you stream per month?</label>
      <div className="slider">
        <Slider min={0} max={100} value={value} step={1} onChange={change} handle={MatchSliderHandle} />
      </div>
    </div>
  );
};
