import { summaryFragment } from '../../lib/gql/fragments/summary';
import gql from 'graphql-tag';

export const matchFragment = gql`
  fragment FullMatch on Match {
    id
    name
    awayTeamId
    homeTeamId
    venueId
    competitionId
    description
    date
    finishedStatus
    liveStatus
    config
    sportName
    lineup {
      id
      position
      number
      starting
      captain
      photo {
        id
        url
      }
      player {
        id
        firstName
        lastName
      }
      team {
        id
        abbreviation
      }
    }
    venue {
      id
      name
    }
    competition {
      id
      themeId
      logo {
        id
        url
      }
      theme {
        id
        name
        sportName
        sportId
      }
      adAllocation {
        id
        adSets {
          id
          name
          adFiles {
            id
            adSetId
            file {
              id
              url
              entityId
              entityType
            }
            size
          }
        }
      }
    }
    summary {
      ...Summary
    }
    overlays {
      id
      matchId
      encodingDelay
      autoMode
      resolution
      showWatermarks
      background
      backgroundColor
      enableAds
      manualGraphicState
      name
      key
    }
    homeTeam {
      id
      name
      gfxName
      abbreviation
      gfxFullName
      logo {
        id
        url
      }
      adAllocation {
        id
        adSets {
          id
          name
          adFiles {
            id
            adSetId
            file {
              id
              url
              entityId
              entityType
            }
            size
          }
        }
      }
    }
    awayTeam {
      id
      name
      gfxName
      abbreviation
      gfxFullName
      logo {
        id
        url
      }
      adAllocation {
        id
        adSets {
          id
          name
          adFiles {
            id
            adSetId
            file {
              id
              url
              entityId
              entityType
            }
            size
          }
        }
      }
    }
  }
`;

export const fullMatchQuery = gql`
  query fullMatch($id: Float!) {
    match(id: $id) {
      ...FullMatch
    }
  }
  ${matchFragment}
  ${summaryFragment}
`;

export const matchSubscription = gql`
  subscription matchSub($matchSubscriptionArgs: MatchSubscriptionArgs!) {
    matchSubscription(matchSubscriptionArgs: $matchSubscriptionArgs) {
      match {
        id
        name
        organizationId
        awayTeamId
        homeTeamId
        venueId
        competitionId
        description
        finishedStatus
        sportName
        date
        config
        liveStatus
        awayTeam {
          id
          abbreviation
          name
          gfxName
          logo {
            id
            url
            entityType
            entityId
          }
          adAllocation {
            id
            adSets {
              id
              name
              adFiles {
                id
                adSetId
                file {
                  id
                  url
                  entityId
                  entityType
                }
                size
              }
            }
          }
        }
        homeTeam {
          id
          abbreviation
          gfxName
          name
          logo {
            id
            url
            entityType
            entityId
          }
          adAllocation {
            id
            adSets {
              id
              name
              adFiles {
                id
                adSetId
                file {
                  id
                  url
                  entityId
                  entityType
                }
                size
              }
            }
          }
        }
        id
        lineup {
          id
          position
          number
          starting
          captain
          photo {
            id
            url
          }
          player {
            id
            firstName
            lastName
          }
          team {
            id
            abbreviation
          }
        }
        venue {
          id
          name
        }
        competition {
          id
          themeId
          logo {
            id
            url
          }
          theme {
            id
            name
            sportName
            sportId
          }
          adAllocation {
            id
            adSets {
              id
              name
              adFiles {
                id
                adSetId
                file {
                  id
                  url
                  entityId
                  entityType
                }
                size
              }
            }
          }
        }
        overlays {
          id
          matchId
          encodingDelay
          autoMode
          resolution
          showWatermarks
          background
          backgroundColor
          enableAds
          manualGraphicState
          name
          key
        }
      }
    }
  }
`;
