import { Match } from '../../../../api-types';
import { Icon } from '@ligr/shared';
import React from 'react';
import { v4 } from 'uuid';
import { ContextMenu } from '../../../../components/ContextMenu';
import './style.scss';

interface NoCoverageWarningProps {
  liveScoreRoute: string;
  match: Match;
}

export const NoCoverageWarning: React.FunctionComponent<NoCoverageWarningProps> = ({ liveScoreRoute, match }) => {
  const id = v4();
  return (
    <ContextMenu
      className="noCoverageWarning"
      id={id}
      // @ts-ignore
      renderMenu={() => {
        return (
          <div className="noCoverageWarning__tooltip">
            <div>
              This match (providerId:{' '}
              <span className="coverageTooltip__providerId">{match.baseEntity?.providerId}</span>) is sourced from
              external data provider,{' '}
              <span className="coverageTooltip__providerName">{match.baseEntity?.providerName}</span>, but will not be
              externally lived scored.
            </div>
            <div>
              To live score with LIGR{' '}
              <span onClick={() => window.open(liveScoreRoute, '_blank')} className="coverageTooltip__liveScoreLink">
                Open in Live Score app
              </span>
              {'>'}
            </div>
          </div>
        );
      }}
    >
      <Icon icon="error" color="red" />
    </ContextMenu>
  );
};
