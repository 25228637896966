import { SportsEnum } from '@ligr/api-v2';
import React, { useState } from 'react';
import { MatchFormInitialValues, MatchFormConfig } from '@ligr/shared';
import { Notification } from '../../../lib/notificationContainer';
import { useErrorHandledQuery, useErrorHandledMutation } from '../../../lib/apolloHooks';
import { venuesQuery } from '../../../lib/gql/queries/venues';
import { PageContent } from '../../../components/page/PageContent';
import { PageHeader } from '../../../components/PageHeader';
import { Loader } from '../../../components/loader/Loader';
import { matchValidationSchema } from '../validation';
import { getGQLError } from '../../../lib/apolloClient';
import { Form } from '../../../components/Form/Form';
import { useHistory, useParams } from 'react-router';
import { routes } from '../../../router/Routes';
import { updateMatchQuery } from '../../../lib/gql/mutations/updateMatch';
import { UpdateMatchFormContent } from './Form';
import { MatchFormInput } from './Form/types';
import './style.scss';
import gql from 'graphql-tag';
import { UpdateMatchMutation, MatchQuery, VenuesQuery } from '../../../api-types';
import { MatchFormValues } from '../NewMatchPage/Form/types';

const matchQuery = gql`
  query match($id: Float!) {
    match(id: $id) {
      name
      hasFacts
      date
      id
      sportName
      competition {
        id
        name
        teams {
          name
          id
        }
        sport {
          name
          id
        }
      }
      venue {
        id
        name
      }
      homeTeam {
        id
        name
      }
      awayTeam {
        id
        name
      }
      config
      baseEntity {
        id
        providerId
        providerName
      }
    }
  }
`;

export const UpdateMatchPage: React.FunctionComponent = () => {
  const [error, setError] = useState();

  const [updateMatch] = useErrorHandledMutation<UpdateMatchMutation>(updateMatchQuery);

  const history = useHistory<{ referrer?: string } | undefined>();
  const { matchId } = useParams<{ matchId: string }>();
  const { data, loading: loadingMatch } = useErrorHandledQuery<MatchQuery>(matchQuery, {
    variables: { id: parseInt(matchId) }
  });

  const { data: venueData } = useErrorHandledQuery<VenuesQuery>(venuesQuery);
  const venues = venueData && venueData.venues ? venueData.venues : [];

  const { addNotification } = Notification.useContainer();

  const onSubmit = async (values: MatchFormValues & { id: number }) => {
    try {
      const newMatch = MatchFormConfig[values.sport].formatValues(values);
      await updateMatch({
        variables: { updateMatchInput: newMatch }
      });
      history.push(routes.matchesLive());
      addNotification('Match successfully updated', 'success');
    } catch (e) {
      // @ts-ignore
      setError(getGQLError(e).message);
    }
  };

  if (loadingMatch) {
    return <Loader />;
  }

  const match = data?.match!;

  if (match.hasFacts) {
    addNotification('You cannot edit a match that has started.', 'error');
    history.push(routes.matchesLive());
    return null;
  }

  const initialValues = {
    ...(MatchFormInitialValues(match) as MatchFormValues & { id: number }),
    config: match.config
  };

  return (
    <PageContent className="updateMatchPage">
      <PageHeader preTitle="create" title="update match" close={routes.matchesLive()} />
      <div className="updateMatchPage__formSection">
        <Form
          initialValues={initialValues}
          validationSchema={() => matchValidationSchema(match?.sportName as SportsEnum)}
          className="updateMatch"
          onSubmit={onSubmit}
          errorGroupGridColum="span 3"
          error={error}
        >
          {match.baseEntity && (
            <div className="updateMatch--external-notice">
              This game is configured using external data. If you decide to override the settings, you will have to use
              LIGR Livescore data to generate data for this match. Contact the LIGR success team if you decide to not
              use external data for this game.
            </div>
          )}
          {match && venues && <UpdateMatchFormContent venues={venues} match={match} loading={loadingMatch} />}
        </Form>
      </div>
    </PageContent>
  );
};
