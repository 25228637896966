import { competitionAdAllocationFragment } from '@ligr/shared';
import gql from 'graphql-tag';

export const adAllocationQuery = gql`
  query adAllocationWithEntity($adAllocationArgs: AdAllocationArgs!) {
    adAllocation(adAllocationArgs: $adAllocationArgs) {
      entity {
        ... on Team {
          name
          id
          logo {
            id
            url
          }
          sport {
            id
            name
          }
        }
        ... on Competition {
          name
          id
          logo {
            id
            url
          }
          sport {
            id
            name
          }
        }
      }
      ...CompetitionAdAllocation
      id
      entityId
      entityType
    }
  }
  ${competitionAdAllocationFragment}
`;
