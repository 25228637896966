import React from 'react';
import { Button } from '../../components/Button';
import { Title } from '../../components/Title/Title';
import { Organization } from '../../lib/orgContainer';
import { routes } from '../../router/Routes';
import './errors.scss';

export const PlanUpgrade = () => {
  const { org } = Organization.useContainer();
  const link = org?.id ? routes.subscriptions() : routes.orgSelection();

  return (
    <div className="page error plan-upgrade">
      <div className="center">
        <Title is1>Out of reach…</Title>
        <span>
          You have reached the limitation of your plan. You will need to change plan to continue what you were doing.
        </span>
        <br />
        <Button href={link}>Select new plan</Button>
      </div>
    </div>
  );
};
