import React from 'react';
import { FormikContextType } from 'formik';
import classnames from 'classnames';
import './style.scss';
import { displayError } from '../FormField';

/**
 * This component is to group form errors in a row, instead of having them
 * under each field where sometimes are too big
 *
 * 1- Add 'groupError' in the fields within the row that the errors are grouped
 * 2- Wrap that row with GroupErrorRow
 * @param errorsName arrays of errors field name to check
 * @param gridTemplateColumns grid-templates-columns style
 */

interface GroupErrorRowProps {
  className?: string;
  errorsName: string[];
  form: FormikContextType<any>;
  gridTemplateColumns?: string;
  errorGridColumn?: string;
  gridColumn?: string;
}

export const GroupErrorRow: React.FunctionComponent<GroupErrorRowProps> = ({
  className,
  children,
  errorsName,
  gridTemplateColumns = '1fr 1fr',
  errorGridColumn = 'span 2',
  gridColumn = 'span 2',
  form
}) => {
  const styles = {
    gridTemplateColumns,
    gridColumn
  };
  const hasErrorsClass = errorsName.filter(eName => displayError(form, eName));
  return (
    <div
      style={styles}
      className={classnames('groupErrorRow', className, { 'groupErrorRow--hasErrors': hasErrorsClass.length > 0 })}
    >
      {children}
      <div style={{ gridColumn: errorGridColumn }} className="groupError">
        {errorsName.map(n => {
          const error = displayError(form, n);
          return (
            error && (
              <span key={n} className="groupError__text">
                {error}
              </span>
            )
          );
        })}
      </div>
    </div>
  );
};
