import { ProviderName } from '@ligr/api-v2/dist/types/providers';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { object, string } from 'yup';
import { Form } from '../../components/Form/Form';
import { FormField } from '../../components/FormField';
import { Modal, ModalProps } from '../../components/modal/Modal';
import { NotificationMessage } from '../../components/NotificationMessage';
import { useErrorHandledLazyQuery, useErrorHandledMutation } from '../../lib/apolloHooks';
import { Notification } from '../../lib/notificationContainer';
import { routes } from '../../router/Routes';
import './style.scss';
import {
  Organization,
  OrganizationAdapter,
  CreateOrganizationMutation,
  SearchOrganizationsQuery,
  CreateOrganizationAdapterMutation
} from '../../api-types';

const schemaValidation = object().shape({
  organizationId: string().required().label('Organization'),
  providerName: string().oneOf(Object.keys(ProviderName)).required().label('Provider'),
  providerCompetitionId: string().required().label('External competition ID')
});

const organizationQuery = gql`
  query searchOrganizations($query: String!) {
    searchOrganizations(query: $query) {
      id
      name
    }
  }
`;

const connectMutation = gql`
  mutation createOrganizationAdapter($createOrganizationAdapterInput: CreateOrganizationAdapterInput!) {
    createOrganizationAdapter(createOrganizationAdapterInput: $createOrganizationAdapterInput) {
      id
      organization {
        id
        name
      }
      providerName
      providerCompetitionId
    }
  }
`;

interface OrganizationAdapterValues {
  organizationId: string;
  providerName: string;
  providerCompetitionId: string;
}

export const ConnectOrganizationAdapter: React.FunctionComponent<Partial<ModalProps>> = props => {
  const [error, setError] = useState();

  const history = useHistory<{ referrer?: string } | undefined>();
  const [form, setForm] = useState<any>(null);
  const [fetch, { data, loading: loadingOrg, error: orgError }] = useErrorHandledLazyQuery<{
    searchOrganizations: Organization[];
  }>(organizationQuery);
  const organizations = data?.searchOrganizations || [];
  const [connect, { loading }] = useErrorHandledMutation<CreateOrganizationAdapterMutation>(connectMutation);

  const { addNotification } = Notification.useContainer();

  useEffect(() => {
    fetch({ variables: { query: '' } });
  }, []);

  const submit = async (values: OrganizationAdapterValues) => {
    try {
      const response = await connect({
        variables: {
          createOrganizationAdapterInput: {
            organizationId: parseInt(values.organizationId),
            providerName: values.providerName,
            providerCompetitionId: parseInt(values.providerCompetitionId)
          }
        }
      });

      if (response.data && response.data.createOrganizationAdapter) {
        addNotification('Adapter connected successfully', 'success');
        history.push(routes.adminAdapters());
      } else if (props.onClose) props.onClose();
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <Modal
      title="Connect to external data"
      {...props}
      className="connectOrganizationAdapter"
      footerButtons={[
        { text: 'Cancel', onClick: props.onClose, type: 'secondary' },
        { text: 'Connect', onClick: async () => form.submitForm(), isLoading: false }
      ]}
    >
      <NotificationMessage type="info">
        When connecting a third party provider to an organization, we will be creating all the entities (competition,
        teams, players, venues and matches) if they exists in our system.
      </NotificationMessage>
      <Form
        {...props}
        initialValues={{
          organizationId: '',
          providerName: '',
          providerCompetitionId: ''
        }}
        validationSchema={schemaValidation}
        className="connect-external-data-form"
        useConfirm={true}
        onSubmit={i => submit(i)}
        setForm={setForm}
        error={error}
      >
        <FormField
          name="organizationId"
          className="connect-external-data-form__organizationSelect"
          type="select"
          placeholder={'Select an organization'}
          options={organizations.map(org => ({ value: org.id, label: org.name }))}
          label="Organization"
        />
        <FormField
          name="providerName"
          className="connect-external-data-form__providerSelect"
          type="select"
          placeholder={'Select a provider'}
          options={Object.values(ProviderName).map(val => ({ value: val, label: val }))}
          label="Provider"
        />
        <FormField
          className="connect-external-data-form__compId"
          name="providerCompetitionId"
          type="text"
          label="External competition ID"
        />
      </Form>
    </Modal>
  );
};
