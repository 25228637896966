import { UpdateOverlayInput } from '../../../../api-types';
import { useFormikContext } from 'formik';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from '../../../../components/Button';
import { ButtonBottom as BottomButtonRow } from '../../../../components/ButtonBottom/ButtonBottom';
import { FormField } from '../../../../components/FormField';
import { routes } from '../../../../router/Routes';

export const OverlaySettingsForm: React.FunctionComponent<{
  loading: boolean;
  overlayId: number;
}> = ({ loading, overlayId }) => {
  const form = useFormikContext<UpdateOverlayInput>();

  // Used by the `onChange` for the Form. Form does not use `onSubmit`
  // const [values, setValues] = useState<any>({});
  // Find the overlay based on the match and the overlayId (changed by the select)

  // Update the current overlay with the form values (onChange for the Form)
  // useDebouncedEffect(() => {
  //   if (!Object.keys(values).length) return;

  //   const { __typename, matchId, key, ...updateOverlayInput } = values;
  //   // Cast fields as number for submission (type error otherwise)
  //   updateOverlayInput.resolution = parseInt(updateOverlayInput.resolution);
  //   updateOverlayInput.encodingDelay = updateOverlayInput?.encodingDelay || 0;
  //   updateOverlayM({ variables: { updateOverlayInput } });
  // }, [values]);

  return (
    <>
      <section className="overlaySettings__auto">
        <FormField name="autoMode" type="switch" label="Manual mode" label2="Auto mode" />
        {form.values.autoMode ? (
          <small>This match is on Auto Mode. Turning on Manual Mode requires you to control the graphics.</small>
        ) : (
          <small>
            Manual Mode is ON. Graphics will not be shown until you activate them.{' '}
            <NavLink
              to={{
                pathname: routes.controlOverlay({ overlayId })
              }}
            >
              <span className="overlaySettings__auto__link">Take control now</span>
            </NavLink>
          </small>
        )}
      </section>

      <section className="overlaySettings__settings">
        <FormField name="name" type="text" label="Name" />

        <FormField
          name="resolution"
          type="select"
          label="Resolution"
          options={[
            { label: 540, value: 540 },
            { label: 720, value: 720 },
            { label: 1080, value: 1080 }
          ]}
        />

        <div className="liveBackground">
          <FormField
            name="background"
            type="radio-tab"
            label="Live Background"
            options={[
              { label: 'None', value: 'none' },
              { label: 'Demo', value: 'demo' },
              { label: 'Color', value: 'color' }
            ]}
            colSpan={1}
          />
          {form.values.background === 'color' && (
            <FormField type="color" className="liveBackground__color" name="backgroundColor" round colSpan={1} />
          )}
        </div>
        <div className="gridColumns__2">
          <FormField name="showWatermarks" type="switch" label="Watermarks" colSpan={1} />
          <FormField name="enableAds" type="switch" label="Ads" colSpan={1} />
        </div>
        <FormField name="encodingDelay" type="number" label="Encoding Delay" suffix="sec" />
      </section>
      {form && form.dirty && (
        <BottomButtonRow withResetFormButton resetOnClick={() => form.resetForm()}>
          <Button
            onClick={event => {
              event.preventDefault();
              form.submitForm();
            }}
            type="primary"
            isDisabled={!form.isValid}
            isLoading={loading}
          >
            {' '}
            Save{' '}
          </Button>
        </BottomButtonRow>
      )}
    </>
  );
};
