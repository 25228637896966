import { Venue } from '../api-types';
import { useSearchParams } from '@ligr/shared';
import gql from 'graphql-tag';
import { useErrorHandledMutation } from './apolloHooks';
import { venuesQuery } from './gql/queries/venues';

export const useArchiveVenue = () => {
  const searchParams = useSearchParams(['archive']);

  const deleteVenueMutation = gql`
    mutation deleteVenue($id: Float!) {
      deleteVenue(id: $id)
    }
  `;

  const restoreVenueMutation = gql`
    mutation restoreVenue($id: Float!) {
      restoreVenue(id: $id)
    }
  `;

  const [archiveVenueMutation] = useErrorHandledMutation<boolean>(deleteVenueMutation);
  const [unarchiveVenueMutation] = useErrorHandledMutation<boolean>(restoreVenueMutation);

  const archiveVenue = async (venueId: number) => {
    await archiveVenueMutation({
      variables: { id: venueId },
      update: (cache, { data }) => {
        const venueCache = cache.readQuery<{ venues: Venue[] }>({
          query: venuesQuery,
          variables: { options: { archive: !!searchParams.archive } }
        });
        if (venueCache && data) {
          let venues;

          if (!!searchParams.archive) {
            venues = venueCache.venues.map((v: Venue) => {
              if (v.id === venueId) {
                return { ...v, archived: true };
              }
              return v;
            });
          } else {
            venues = venueCache.venues.filter(({ id }: Venue) => id !== venueId);
          }
          cache.writeQuery({
            query: venuesQuery,
            variables: { options: { archive: !!searchParams.archive } },
            data: {
              venues
            }
          });
        }
      }
    });
  };

  const restoreVenue = async (venueId: number) => {
    await unarchiveVenueMutation({
      variables: { id: venueId },
      update: (cache, { data }) => {
        const venueCache = cache.readQuery<{ venues: Venue[] }>({
          query: venuesQuery,
          variables: { options: { archive: !!searchParams.archive } }
        });
        if (venueCache && data) {
          const venues = venueCache.venues.map((v: Venue) => {
            return {
              ...v,
              archived: v.id === venueId ? false : v.archived
            };
          });
          cache.writeQuery({
            query: venuesQuery,
            variables: { options: { archive: !!searchParams.archive } },
            data: {
              venues
            }
          });
        }
      }
    });
  };

  return {
    archiveVenue,
    restoreVenue
  };
};
