import React, { useMemo, useState, memo } from 'react';
import { useHistory, useParams } from 'react-router';
import { Form } from '../../../../components/Form/Form';
import { Loader } from '../../../../components/loader/Loader';
import { PageContent } from '../../../../components/page/PageContent';
import { PageHeader } from '../../../../components/PageHeader';
import { getGQLError } from '../../../../lib/apolloClient';
import { useErrorHandledMutation, useErrorHandledQuery } from '../../../../lib/apolloHooks';
import { updatePlayerMutation } from '../../../../lib/gql/mutations/updatePlayer';
import { playerQuery } from '../../../../lib/gql/queries/player';
import { teamsWithCompQuery } from '../../../../lib/gql/queries/teams';
import { Notification } from '../../../../lib/notificationContainer';
import { routes } from '../../../../router/Routes';
import { playerFormSchema } from '../Player.validation';
import { PlayerFormValues, PlayerTeamValues, UpdatePlayerPageFormContent } from './form';
import { PlayerMenu } from './PlayerMenu';
import { FormikContextType } from 'formik';
import { PlayerQuery, TeamsWithCompQuery, UpdatePlayerMutation, PlayerTeamsInput } from '../../../../api-types';

export interface CreateCompetitionValues {
  sportId: number;
  name: string;
  logo: File;
  watermark: File;
  secondaryWatermark: File;
}

export const UpdatePlayerPage: React.FunctionComponent = () => {
  const [error, setError] = useState();
  const [form, setForm] = useState<FormikContextType<any>>();
  const { addNotification } = Notification.useContainer();
  const history = useHistory<{ referrer?: string; teamId?: number } | undefined>();

  const teamReferrerId = history.location.state?.teamId;

  const { playerId } = useParams<{ playerId: string }>();

  const { data: playerData, loading: playerLoading } = useErrorHandledQuery<PlayerQuery>(playerQuery, {
    variables: { id: parseInt(playerId) }
  });

  const { data: teamsData } = useErrorHandledQuery<TeamsWithCompQuery>(teamsWithCompQuery);

  const player = useMemo(() => {
    if (!playerData) return;
    const player = playerData.player;
    return {
      id: player.id,
      firstName: player.firstName,
      lastName: player.lastName,
      gfxFirstName: player.gfxFirstName || '',
      gfxLastName: player.gfxLastName || '',
      countTeams: player.countTeams,
      archived: player.archived,
      teams: player.playsFor
        ? player.playsFor.map(({ position, positionType, number, team, photo, defaultStarter }) => ({
            position: position || '',
            positionType: positionType || null,
            number: number || '',
            teamId: team.id,
            defaultStarter: !!defaultStarter,
            abbreviation: team.abbreviation || '',
            name: team.name,
            preexisting: true,
            photo: photo?.url || '',
            photoId: photo?.id || undefined
          }))
        : []
    };
  }, [playerData]);

  const playerInitialValues = useMemo(() => {
    if (!player) return;
    delete player.countTeams;
    delete player.archived;
    return player;
  }, [player]);

  const [updatePlayer, { loading }] = useErrorHandledMutation<UpdatePlayerMutation>(updatePlayerMutation);

  const update = async (values: PlayerFormValues) => {
    try {
      const inp = {
        ...values,
        id: parseInt(playerId),
        teams: values!
          .teams!.filter(({ teamId }) => !teamId.toString().match(/^new_team_/))
          .map(({ abbreviation, name, teamId, preexisting, ...rest }: Partial<PlayerTeamValues>) => ({
            ...rest,
            teamId: parseInt(`${teamId}`)
          })) as PlayerTeamsInput[]
      };

      const player = await updatePlayer({ variables: { updatePlayerInput: inp } });

      const teamId = history.location.state?.teamId;
      const route = !!teamId
        ? routes.assetTeamPlayers({ teamId })
        : routes.assetPlayerEdit({ playerId: player.data!.updatePlayer.id });

      history.push(route);
      addNotification('Player successfully updated', 'success');
    } catch (e) {
      // @ts-ignore
      form.setFieldError(getGQLError(e).details.field, getGQLError(e).message);
    }
  };

  if (playerLoading) return <Loader />;

  return (
    <PageContent className="newPlayerPage">
      <PageHeader
        title={`${player?.firstName} ${player?.lastName}`}
        close={teamReferrerId ? routes.assetTeamPlayers({ teamId: teamReferrerId }) : routes.assetsPlayers()}
        editMenu={() => <PlayerMenu player={player!} />}
      />
      <Form
        initialValues={playerInitialValues}
        validationSchema={playerFormSchema}
        className="page-form player-form"
        onSubmit={update}
        withSubmit
        error={error}
        setForm={setForm}
      >
        <UpdatePlayerPageFormContent teams={teamsData?.teams || []} />
      </Form>
    </PageContent>
  );
};
