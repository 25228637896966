import React from 'react';
//@ts-ignore
import preview from '../../images/login/macbook-ui.png';
import './style.scss';

export const AppPreviewAndSlogan = () => {
  return (
    <div className="rightPanelPreview">
      <div className="center">
        <img className="rightPanelPreview__image" alt="LIGR UI" src={preview} />
        <div className="rightPanelPreview__title">TV-QUALITY. EFFORTLESS TO USE.</div>
        <div className="rightPanelPreview__title">UP AND RUNNING IN MINUTES.</div>
      </div>
    </div>
  );
};
