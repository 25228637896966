import { Icon, updateQueryUrl } from '@ligr/shared';
import React from 'react';
import { useHistory } from 'react-router';
import { FilterOptions } from '.';

interface ActiveFilterProps {
  property: FilterOptions;
  value: string;
  setActiveFilter: (name: FilterOptions, value: string) => void;
}

export const ActiveFilter: React.FunctionComponent<ActiveFilterProps> = ({ property, value, setActiveFilter }) => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const removeFilter = () => {
    updateQueryUrl(history, { [property]: '' });
    setActiveFilter(property, '');
  };

  return (
    <div className="filters__wrapper__active-filter">
      {value}
      <Icon icon="close" onClick={removeFilter} />
    </div>
  );
};
