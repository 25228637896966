import capitalize from 'capitalize';
import pluralize from 'pluralize';
import React from 'react';

export const ConfirmWindowCompetitionContent: React.FunctionComponent<{
  competition: {
    countMatches: number;
    countTeams: number;
    name: string;
  };
}> = ({ competition }) => {
  const withNotification = competition.countMatches > 0 || competition.countTeams > 0;
  const notifications = [];

  if (competition.countMatches > 0) {
    notifications.push(
      <li>
        Archive {competition.countMatches} {pluralize('matches', competition.countMatches)}
      </li>
    );
  }

  if (competition.countTeams > 0) {
    notifications.push(
      <li>
        Remove {competition.countTeams} {pluralize('team associations', competition.countTeams)}
      </li>
    );
  }

  return (
    <>
      Are you sure you want to archive {capitalize(competition.name)}?
      {withNotification && (
        <div className={`notification notification--error confirm-window__notification`}>
          This action will also:
          <ul>{notifications.map(item => item)}</ul>
        </div>
      )}
    </>
  );
};
