import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SideMenuList } from '../../../components/SideMenuList';
import { Tabs } from '../../../components/Tabs/Tabs';
import { useErrorHandledQuery } from '../../../lib/apolloHooks';
import { adAllocationsQuery } from '../../../lib/gql/queries/adAllocations';
import { routes } from '../../../router/Routes';
import { ImagePlaceholderType } from '@ligr/shared';
import './AdAllocation.menu.scss';
import { AdAllocationEntityType, EntityAdAllocation, AdAllocationsQuery } from '../../../api-types';

const options = [
  {
    value: AdAllocationEntityType.competition,
    text: 'Competitions'
  },
  {
    value: AdAllocationEntityType.team,
    text: 'Teams'
  }
];

export const AdAllocactionSideMenu: React.FunctionComponent = () => {
  const { data, loading: loading } = useErrorHandledQuery<AdAllocationsQuery>(adAllocationsQuery);

  const { entityType } = useParams();
  const [filter, setFilter] = useState(entityType || AdAllocationEntityType.competition);
  const allocations = data ? data.adAllocations : [];

  const items = useMemo(
    () =>
      allocations
        .filter(({ entityType }) => !filter || entityType === filter)
        .map(({ entityId, entity, entityType }: EntityAdAllocation) => ({
          imageUrl: entity.logo?.url,
          text: entity.name,
          to: routes.adSetsAllocationDetails({ entityId, entityType }),
          imagePlaceholder: (entityType === AdAllocationEntityType.competition
            ? 'competition'
            : 'team') as ImagePlaceholderType
        })),
    [allocations, filter]
  );

  return (
    <div className="aa-menu">
      <Tabs
        className="aa-tabs"
        active={options.findIndex(({ value }) => value === filter)}
        onChange={({ value }) => setFilter(value as AdAllocationEntityType)}
        tabs={options}
      />
      <SideMenuList items={items} />
    </div>
  );
};
