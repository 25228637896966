import { SportsEnum } from '@ligr/api-v2';
import React, { useState } from 'react';
import { MatchFormInitialValues, MatchFormConfig } from '@ligr/shared';
import { Notification } from '../../../lib/notificationContainer';
import { useErrorHandledQuery, useErrorHandledMutation } from '../../../lib/apolloHooks';
import { competitionsQuery } from '../../../lib/gql/queries/competitions';
import { venuesQuery } from '../../../lib/gql/queries/venues';
import { createMatchQuery } from '../../../lib/gql/mutations/createMatch';
import { PageContent } from '../../../components/page/PageContent';
import { PageHeader } from '../../../components/PageHeader';
import { Loader } from '../../../components/loader/Loader';
import { matchValidationSchema } from '../validation';
import { CreateMatchFormContent } from './Form';
import { getGQLError } from '../../../lib/apolloClient';
import { Form } from '../../../components/Form/Form';
import { useHistory } from 'react-router';
import { routes } from '../../../router/Routes';
import { MatchFormInput, MatchFormValues } from './Form/types';
import './style.scss';
import { CreateMatchMutation, VenuesQuery, CompetitionsQuery } from '../../../api-types';

export const NewMatchPage: React.FunctionComponent = () => {
  const [error, setError] = useState('');
  const [compSelected, setCompSelected] = useState('');

  const history = useHistory<{ referrer?: string } | undefined>();

  const [createMatch, { loading }] = useErrorHandledMutation<CreateMatchMutation>(createMatchQuery);

  const { data: competitionsData, loading: competitionsLoading } = useErrorHandledQuery<CompetitionsQuery>(
    competitionsQuery
  );
  const { data: venuesData, loading: venueLoading } = useErrorHandledQuery<VenuesQuery>(venuesQuery);

  const competitions = competitionsData?.competitions;
  const venues = venuesData?.venues;
  const competition = competitions?.find(c => c.id === parseInt(compSelected));

  const { addNotification } = Notification.useContainer();

  // const create = async (createMatchInput: CreateMatchInput) => {
  //   await createMatch({
  //     variables: { createMatchInput }
  //   });
  // };

  const onSubmit = async (values: MatchFormValues) => {
    try {
      const newMatch = MatchFormConfig[values.sport].formatValues(values);
      await createMatch({ variables: { createMatchInput: newMatch } });
      history.push(routes.matchesLive());
      addNotification('Match successfully created', 'success');
    } catch (e) {
      setError(getGQLError(e).message);
    }
  };

  const initialLoad = competitionsLoading || venueLoading;

  return (
    <PageContent loading={initialLoad} className="newMatchPage">
      <PageHeader preTitle="create" title="new match" close={routes.matchesLive()} />
      {initialLoad ? (
        <Loader />
      ) : (
        <Form
          initialValues={MatchFormInitialValues()}
          validationSchema={() => matchValidationSchema(competition?.sport.name as SportsEnum)}
          className="createMatch"
          onSubmit={onSubmit}
          withSubmit
          error={error}
          errorGroupGridColum="span 3"
        >
          <CreateMatchFormContent
            competitions={competitions!}
            venues={venues!}
            setCompSelected={(compId: string) => {
              setCompSelected(compId);
            }}
            loading={loading}
          />
        </Form>
      )}
    </PageContent>
  );
};
