import { IconType } from '@ligr/shared';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { sizeHelperPropsToClasses, stateHelperPropsToClasses } from '../modifiers';
import { Title } from '../Title/Title';
import './style.scss';
import { SwitchProps } from './types';

export const Switch: React.FunctionComponent<SwitchProps> = ({
  onChange,
  value,
  initialValue,
  label,
  label2,
  disabled,
  dark,
  ...props
}) => {
  const [controlled] = useState(value !== undefined || !initialValue);
  const [v, setValue] = useState(value || initialValue);

  if (!controlled && value) {
    console.warn(
      'Invalid use. Cannot have an undefined value switch to a non undefined value. Please provide a default.'
    );
  }
  useEffect(() => {
    if (controlled) {
      setValue(value);
    }
  }, [value]);
  const state = v ? 'on' : v === null ? 'indeterminate' : 'off';

  let Label1 = null;
  let Label2 = null;
  let switchDual = false;

  if (label && label2) {
    Label1 = (
      <Title is4 hasTextGrey600={(!dark && v!) || (dark && !v)} hasTextGrey800={dark && v!}>
        {' '}
        {label}
      </Title>
    );
    Label2 = (
      <Title is4 hasTextGrey600={(dark && v) || (!dark && !v)} hasTextGrey800={dark && !v}>
        {label2}
      </Title>
    );
    switchDual = true;
  } else if (label) {
    Label2 = <span>{label}</span>;
  }

  const icn: IconType | undefined = disabled ? 'lock' : undefined;

  return (
    <>
      <label
        className={classnames({
          switch: true,
          switchDual,
          'switch--disabled': disabled,
          [state]: true,
          ...stateHelperPropsToClasses(props),
          ...sizeHelperPropsToClasses(props)
        })}
        onClick={e => {
          setValue(!v);
          if (onChange) onChange(!v);
        }}
      >
        {Label1}
        <div>
          <span className="toggle">{/* {icn && <Icon className="switch__icon" icon={icn} />} */}</span>
        </div>
        {Label2}
      </label>
    </>
  );
};
