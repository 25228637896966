import { Icon, IconType } from '@ligr/shared';
import classname from 'classnames';
import React, { HTMLProps } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import DayPickerMoment from 'react-day-picker/moment';
import './style.scss';

export interface DateFieldProps extends Partial<HTMLProps<HTMLInputElement>> {
  onChange: any;
  name: string;
  error?: string | Error;
  icon?: IconType;
  iconPosition?: 'left' | 'right';
  iconColor?: string;
}

export const DateField: React.FunctionComponent<DateFieldProps> = ({
  onChange,
  name,
  error,
  icon,
  iconPosition,
  iconColor,
  disabled,
  className,
  value
}) => {
  const err = error ? error.toString() : null;
  let icn: IconType | undefined = err ? 'error' : icon;
  let icnPosition = iconPosition;
  let icnColor = err ? 'red' : iconColor;
  //@ts-ignore
  const { formatDate, parseDate } = DayPickerMoment;

  if (disabled) {
    icn = 'lock';
    icnColor = 'grey-600';
  }
  if (err) icnPosition = 'right';

  return (
    <div
      className={classname('dateField', className, {
        'dateField--iconRight': icnPosition === 'right',
        'dateField--disabled': disabled,
        'dateField--error': error
      })}
    >
      {icn && <Icon icon={icn} color={icnColor} />}
      <DayPickerInput
        value={value ? new Date(value as string) : undefined}
        onDayChange={(date: Date) => onChange({ target: { name, value: date } })}
        placeholder="DD MMM YYYY"
        format="DD MMM YYYY"
        formatDate={formatDate}
        parseDate={parseDate}
        inputProps={{
          readOnly: disabled,
          disabled
        }}
      />
    </div>
  );
};
