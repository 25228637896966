import React from 'react';
import { Match } from '../../../../../api-types';
import { Select } from '../../../../Select';
import { DataProps } from '@ligr/themes/lib/sports/themes/rugbyunion1/Templates/TeamList/types';
import './style.scss';

interface TeamListCustomProps {
  value?: DataProps & { teamId: number };
  onChange: (update: DataProps) => any;
  match: Match;
  disabled?: boolean;
}

// FULLY CONTROLLED ONLY
export const TeamList: React.FunctionComponent<TeamListCustomProps> = ({ disabled, onChange, value = {}, match }) => {
  return (
    <div className="teamlist">
      <div className="scoreboard__team">
        <Select
          disabled={disabled}
          value={value.teamId}
          onChange={event => {
            // @ts-ignore
            return onChange({ ...value, teamId: parseInt(event.target.value) });
          }}
          label="Team"
          options={[
            {
              value: null,
              label: 'Select Team'
            },
            {
              value: match.homeTeam.id,
              label: match.homeTeam.gfxName || match.homeTeam.name
            },
            {
              value: match.awayTeam.id,
              label: match.awayTeam.gfxName || match.awayTeam.name
            }
          ]}
        />
      </div>
    </div>
  );
};
