import { Logo } from '@ligr/shared';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppPreviewAndSlogan } from '../../components/AppPreview';
import { routes } from '../../router/Routes';
import { LoginForm } from './login.form';
import './login.page.scss';

export const LoginPage = () => {
  return (
    <div className="login">
      <div className="left-panel">
        <Logo type="color" />
        <LoginForm />
        <div>
          Don't have a LIGR account? <Link to={routes.register()}>Sign up</Link>
        </div>
      </div>
      <AppPreviewAndSlogan />
    </div>
  );
};
