import { UpdateVenueInput } from '../../../../../api-types';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button } from '../../../../../components/Button';
import { ButtonBottom as BottomButtonRow } from '../../../../../components/ButtonBottom/ButtonBottom';
import { FormProps } from '../../../../../components/Form/Form.types';
import { FormField } from '../../../../../components/FormField';
import { Map } from '../../../../../components/Map';
import { Title } from '../../../../../components/Title/Title';

interface Coordinates {
  lat: number | null;
  lng: number | null;
}

export const VenueForm: React.FunctionComponent<FormProps<any> & {
  loading?: boolean;
}> = ({ venue, loading }) => {
  const form = useFormikContext<UpdateVenueInput>();
  const [coords, setCoords] = useState<Partial<Coordinates> | undefined>(undefined);
  const [validCoords, setValidCoords] = useState(false);

  useEffect(() => {
    if (coords?.lat && coords?.lng) setValidCoords(true);
    else setValidCoords(false);
  }, [coords]);

  useEffect(() => {
    setCoords({
      lat: form.values.lat,
      lng: form.values.lng
    });
  }, [form.values]);

  return (
    <>
      <div className="venuePageForm">
        <Title is3>General details</Title>
        <FormField name="name" type="text" label="Venue name" colSpan={2} originalValue={venue?.baseEntity?.name} />
        <FormField name="gfxName" type="text" label="Gfx name" colSpan={2} />
        <FormField placeholder="Search for your venue address" name="address" type="address" form={form} />

        {validCoords && <Map lat={coords!.lat!} lng={coords!.lng!} />}

        {form && form.dirty && (
          <BottomButtonRow withResetFormButton resetOnClick={() => form.resetForm()}>
            <Button
              onClick={event => {
                event.preventDefault();
                form.submitForm();
              }}
              type="primary"
              isDisabled={!form.isValid}
              isLoading={loading}
            >
              {' '}
              Save{' '}
            </Button>
          </BottomButtonRow>
        )}
      </div>
    </>
  );
};
