import { calcClock, MatchConfig } from '@ligr/api-v2';
import { Image, getCurrentPeriod } from '@ligr/shared';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { Title } from '../Title/Title';
import { SummaryFragment } from '../../api-types';
import { InProgressTime } from './InProgressTime';
import { PregameTime } from './PregameTime';
import './style.scss';

type MatchTitleTeam = {
  name: string;
  logo?: {
    url: string;
  } | null;
};

type MatchTitleProps = {
  match: {
    homeTeam: MatchTitleTeam;
    awayTeam: MatchTitleTeam;
    config: MatchConfig;
    sportName: string;
    date: Date;
  };
  className?: string;
  matchSummary: SummaryFragment;
};

export const MatchTitle: React.FunctionComponent<MatchTitleProps> = ({ match, className, matchSummary }) => {
  const { expectedSecondsTillStart, countdown } = calcClock(
    match.date,
    match.sportName,
    matchSummary.clock,
    matchSummary.periods,
    matchSummary.currentPeriod,
    match.config
  );

  const { matchFinished, started, pregame } = getCurrentPeriod(matchSummary);

  const home = match.homeTeam;
  const away = match.awayTeam;
  const d = new Date(match.date);

  const expired =
    pregame &&
    moment(match.date)
      .toDate()
      .getTime() <
      new Date().getTime() + 60 * 60 * 30;
  const isLate = expectedSecondsTillStart && expectedSecondsTillStart < 0;

  const matchTitleDateClass = 'matchTitleTime__date';
  const matchTitleTimeClass = 'matchTitleTime__time';
  let timeContent: any = '';

  if (pregame) {
    timeContent = (
      <>
        {expired ? (
          <>
            <Title is5 className={matchTitleDateClass} hasTextGrey600>
              {moment(match.date).format('MMM D')}
            </Title>
            <Title is3 className={matchTitleTimeClass}>
              {moment(match.date).format('hh:mm A')}
            </Title>
          </>
        ) : (
          <>
            <Title is5 className={`${matchTitleDateClass} ${matchTitleDateClass}--pregame`} hasTextGrey600>
              {moment(match.date).format('MMM D, hh:mm A')}
            </Title>
            <PregameTime
              className={matchTitleTimeClass}
              countdown={{ countdown: countdown! }}
              expectedSecondsTillStart={expectedSecondsTillStart!}
            />
          </>
        )}
        {isLate && (
          <Title className="matchTitleTime__extraNote" is5 hasTextRed>
            {expired ? 'Running late' : 'Not started'}
          </Title>
        )}
      </>
    );
  } else if (matchFinished) {
    timeContent = (
      <>
        <Title className={matchTitleDateClass} is5 hasTextGrey600>
          {moment(match.date).format('hh:mm A')}
        </Title>
        <Title className={matchTitleTimeClass} is3>
          Finished
        </Title>
      </>
    );
  } else if (!!started && !matchFinished) {
    timeContent = <InProgressTime match={match} matchSummary={matchSummary} />;
  } else {
    <>
      <Title is5 className={matchTitleDateClass} hasTextGrey600>
        {d.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
      </Title>
      <Title is3 className={matchTitleTimeClass}>
        {d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}
      </Title>
    </>;
  }

  return (
    <div className={classNames('matchTitle', className)}>
      <Title is3>{home.name}</Title>
      <Image url={home.logo?.url} placeholder="team" />
      <span className="matchTitle__points"> {matchSummary.matchStats.score.home} </span>

      <time className="matchTitleTime">{timeContent}</time>

      <span className="matchTitle__points"> {matchSummary.matchStats.score.away} </span>
      <Image url={away.logo?.url} placeholder="team" />
      <Title is3>{away.name}</Title>
    </div>
  );
};
