import classnames from 'classnames';
import React from 'react';
import { colorTextHelperPropsToClasses, textTransformHelperPropsToClasses } from '../modifiers';
import './Title.scss';
import { TitleProps } from './Title.type';

export const Title: React.FunctionComponent<TitleProps> = props => {
  const { is1, is2, is3, is4, is5, is6, className, onMouseOver, onMouseLeave } = props;

  const classNames = classnames(
    'title',
    {
      'is-1': is1,
      'is-2': is2,
      'is-3': is3,
      'is-4': is4,
      'is-5': is5,
      'is-6': is6
    },
    textTransformHelperPropsToClasses(props),
    colorTextHelperPropsToClasses(props),
    className
  );

  if (is1)
    return (
      <h1 className={classNames} {...onMouseOver} {...onMouseLeave}>
        {props.children}
      </h1>
    );
  if (is2)
    return (
      <h2 className={classNames} {...onMouseOver} {...onMouseLeave}>
        {props.children}
      </h2>
    );
  if (is3)
    return (
      <h3 className={classNames} {...onMouseOver} {...onMouseLeave}>
        {props.children}
      </h3>
    );
  if (is4)
    return (
      <h4 className={classNames} {...onMouseOver} {...onMouseLeave}>
        {props.children}
      </h4>
    );
  if (is5)
    return (
      <h5 className={classNames} {...onMouseOver} {...onMouseLeave}>
        {props.children}
      </h5>
    );
  if (is6)
    return (
      <h6 className={classNames} {...onMouseOver} {...onMouseLeave}>
        {props.children}
      </h6>
    );
  return (
    <p className={classNames} {...onMouseOver} {...onMouseLeave}>
      {props.children}
    </p>
  );
};
