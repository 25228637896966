import { OverlayWithFullMatchQuery, FactFragment, SummaryFragment } from '../../api-types';
import { GraphicProps } from '@ligr/api-v2';
import { IGraphic } from '@ligr/themes/lib/Theme.type';
import { MatchData } from '@ligr/themes';
import camelCase from 'camelcase';
import classNames from 'classnames';
import gql from 'graphql-tag';
import React, { useEffect, useMemo, useState } from 'react';
import { useErrorHandledMutation } from '../../lib/apolloHooks';
import { Button } from '../Button';
import { graphicUis } from '../graphicsUi/sports';
import { Select } from '../Select';
import './style.scss';
import { ApolloQueryResult } from 'apollo-client';
import { ControlGridAdSet } from '../../pages/OverlayControl/ControlGrid';

export const createGraphicCommandMutation = gql`
  mutation manualGraphicsUpdate($manualGraphicsUpdate: ManualGraphicsUpdate!) {
    createManualGraphicsCommand(manualGraphicsUpdate: $manualGraphicsUpdate) {
      id
      manualGraphicState
    }
  }
`;

export type GraphicControlProps = {
  graphic: IGraphic;
  localState?: GraphicProps;
  remoteState?: GraphicProps;
  adSets: ControlGridAdSet[];
  overlayId: number;
  onPreviewClick?: () => void;
  isPreviewing: boolean;
  overlay: ApolloQueryResult<OverlayWithFullMatchQuery>['data']['overlay'];
  forwardRef?: React.RefObject<HTMLDivElement>;
  onChange: (graphicProps: Partial<GraphicProps>) => void;
  onSizeChange?: () => void;
  facts: FactFragment[];
  summary: SummaryFragment;
  triggerToGraphicData?: (matchData: MatchData, triggerData: any) => any;
  match: ApolloQueryResult<OverlayWithFullMatchQuery>['data']['overlay']['match'];
};

export const GraphicControl: React.FunctionComponent<GraphicControlProps> = ({
  isPreviewing,
  onSizeChange,
  facts,
  triggerToGraphicData,
  graphic: { name, imageAdSizes },
  overlay,
  remoteState,
  localState,
  adSets,
  overlayId,
  onPreviewClick,
  forwardRef,
  onChange,
  summary,
  match
}) => {
  const [open, setOpen] = useState(false);
  const [createCommandMutation] = useErrorHandledMutation(createGraphicCommandMutation);

  const allData = useMemo<Partial<MatchData>>(
    () => ({
      homeTeam: match?.homeTeam,
      awayTeam: match?.awayTeam,
      summary,
      // @ts-ignore
      stats: summary?.matchStats,
      // @ts-ignore
      match,
      venue: match?.venue,
      facts
    }),
    [match, facts]
  );

  const updateFromLocalChange = (update?: { [key: string]: any }) => {
    return {
      ...update,
      ...(triggerToGraphicData ? triggerToGraphicData(allData, update) : undefined)
    };
  };

  const updateLocalState = (newData: any) => {
    onChange(updateFromLocalChange(newData));
  };

  useEffect(() => {
    onSizeChange && onSizeChange();
  }, [open]);

  const [loading, setLoading] = useState(false);

  const isShowing = !!remoteState;

  const createGraphicCommand = async (command: any) => {
    if (command !== 'update') {
      setLoading(true);
    }
    await createCommandMutation({
      variables: {
        manualGraphicsUpdate: {
          overlayId,
          name: camelCase(name),
          command,
          graphicProps:
            localState || (command === 'show' ? updateFromLocalChange({ ...(localState || {}) }) : undefined)
        }
      }
    });
    if (command !== 'update') {
      setLoading(false);
    }
  };

  useEffect(() => {
    onChange(updateFromLocalChange(localState));
  }, [isPreviewing]);

  useEffect(() => {
    isShowing && createGraphicCommand('update');
  }, [localState]);

  // @ts-ignore
  const CustomGraphicUi = graphicUis[camelCase(overlay.match.sportName)][name];

  return (
    <div
      className="graphicControl"
      data-state={isShowing ? 'showing' : isPreviewing ? 'previewing' : ''}
      ref={forwardRef}
    >
      <div className={classNames('graphicControl__main', { open })}>
        <div onClick={() => setOpen(!open)} className="graphicControl__startGroup">
          <div className={classNames(`graphicControl__iconGroup`, { open })}>
            <Button iconColor="grey-800" icon="dropdown" isSquare type="plain" />
          </div>
          <span className="graphicControl__graphicLabel">{name}</span>
        </div>
        <div className="graphicControl__endGroup">
          {/** TODO: add in eye icon */}
          <Button isSquare type="plain" onClick={onPreviewClick} iconColor={'grey-800'} icon="preview" />
          <Button
            isLoading={loading}
            className="button__graphicControl__show-hide"
            type={!isShowing ? 'secondary' : 'primary'}
            onClick={() => createGraphicCommand(isShowing ? 'hide' : 'show')}
          >
            {isShowing ? 'HIDE' : 'SHOW'}
          </Button>
        </div>
      </div>
      {open && (
        <div className="graphicControl__expandedGroup">
          <div
            className={classNames('graphicControl__adGroup', {
              'graphicControl__adGroup--withCustom': CustomGraphicUi
            })}
          >
            {imageAdSizes?.length && (
              <>
                <Select
                  // @ts-ignore
                  value={localState?.adSize}
                  // @ts-ignore
                  options={[
                    {
                      value: 'none',
                      label: 'Ad Size (none)'
                    } /*{
              value: 'auto',
              label: 'Ad Size (auto)'
            }*/,
                    ,
                    ...(imageAdSizes?.map(size => {
                      return {
                        value: size,
                        label: `Ad Size (${size})`
                      };
                    }) || undefined)
                  ]}
                  onChange={event => {
                    updateLocalState({
                      ...remoteState,
                      ...localState,
                      // @ts-ignore
                      adSize: event.target.value === 'none' ? undefined : event.target.value
                    });
                  }}
                />
                <Select
                  // @ts-ignore
                  value={localState?.adSetId}
                  options={[
                    {
                      value: 'none',
                      label: 'Ad Set (none)'
                    },
                    /*{
                value: 'auto',
                label: 'Ad Set (auto)'
                },*/ ...adSets.map(
                      adSet => {
                        return {
                          value: adSet.id,
                          label: `Ad Set (${adSet.name})`
                        };
                      }
                    )
                  ]}
                  onChange={event => {
                    updateLocalState({
                      ...remoteState,
                      ...localState,
                      // prettier-ignore
                      adSetId:
                        // @ts-ignore
                        event.target.value === 'auto'
                          ? 'auto'
                          :
                          // @ts-ignore
                          event.target.value === 'none'
                          ? undefined
                          :
                          // @ts-ignore
                            parseInt((event as React.FormEvent<HTMLSelectElement>).target.value)
                    });
                  }}
                />
              </>
            )}
          </div>
          {CustomGraphicUi && (
            <div className="graphicControl__customGraphicUi">
              <CustomGraphicUi
                facts={facts}
                match={match}
                summary={summary}
                overlay={overlay}
                value={localState || {}}
                onChange={(changes: Partial<GraphicProps>) => {
                  updateLocalState({
                    ...remoteState,
                    ...localState,
                    // @ts-ignore
                    ...changes
                  });
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
