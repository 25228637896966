import { adFileSizes } from '@ligr/api-v2';
import { Image } from '@ligr/shared';
import classNames from 'classnames';
import React from 'react';
import { CardProps } from '../Card/Card.types';
import './style.scss';

export interface AdSetItemProps extends CardProps {
  url?: string;
  name: string;
  count?: number;
}

export const AdSetItem: React.FunctionComponent<AdSetItemProps> = ({ url, name, count = 0, onClick }) => {
  const numberOfSizes = Object.keys(adFileSizes).length;

  let indicatorType = 'low';
  const frac = count / numberOfSizes;
  if (0.3 < frac && frac < 0.6) {
    indicatorType = 'average';
  } else if (frac < 1) {
    indicatorType = 'most';
  } else if (frac === 1) {
    indicatorType = 'all';
  }

  const content = (
    <div className="as-item" onClick={onClick}>
      <div className="wrap">
        <div className="name">{name}</div>
        <div className={`number ${count === numberOfSizes ? 'completed' : ''}`}>
          {`${count}/${numberOfSizes}`}
          <div className={classNames('indicator', indicatorType)} />
        </div>
      </div>
      <Image url={url} placeholder="adset" />
    </div>
  );

  return content;
};
