import { ErrorCode } from '@ligr/api-v2';
import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import { competitionQuery } from '../lib/gql/queries/competition';
import { competitionsQuery } from '../lib/gql/queries/competitions';
import { useErrorHandledLazyQuery } from './apolloHooks';
import { Competition as IComp, CompetitionQuery, CompetitionsQuery } from '../api-types';

export const Competitions = createContainer(() => {
  const [getCompetitions, { data, error, loading, called }] = useErrorHandledLazyQuery(competitionsQuery, {});

  return {
    competitions: (data ? data.competitions : []) as IComp[],
    loading,
    getCompetitions,
    called,
    error
  };
});

export const Competition = createContainer(() => {
  const [getCompetition, { data, error, loading }] = useErrorHandledLazyQuery(competitionQuery, {});
  const [notFound, setNotFound] = useState<Boolean | null>(null);

  useEffect(() => {
    if (error) {
      const err = error.graphQLErrors[0];

      console.log(error);
      // @ts-ignore
      if (err && err.code === ErrorCode.ResourceNotFound) {
        setNotFound(true);
      }
    }

    if (data && !data.competition) {
      setNotFound(true);
    }
  }, [data, loading, error]);

  return {
    competition: data ? data.competition : data,
    getCompetition,
    loading,
    error,
    notFound
  };
});
