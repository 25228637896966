import moment from 'moment';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { MatchCard } from '../../components/MatchCard';
import { MissingEntitiesComponent } from '../../components/MissingEntitiesComponent/MissingEntitiesComponent';
import { Page } from '../../components/page/Page';
import { PageContent } from '../../components/page/PageContent';
import { PageContentContent } from '../../components/page/PageContentContent';
import { PageHeader } from '../../components/PageHeader';
import { Title } from '../../components/Title/Title';
import { useErrorHandledQuery } from '../../lib/apolloHooks';
import { matchesQuery } from '../../lib/gql/queries/matches';
import { Me } from '../../lib/meContainer';
import { routes } from '../../router/Routes';
import './style.scss';
import { MatchesQuery } from '../../api-types';

export const HomePage: React.FunctionComponent = () => {
  const { me } = Me.useContainer();

  const { data, loading } = useErrorHandledQuery<MatchesQuery>(matchesQuery, {
    variables: {
      finishedStatus: ['inProgress', 'pregame'],
      options: {
        orderBy: 'date',
        order: 'ASC',
        startAt: moment()
          .second(0)
          .minute(0)
          .hour(0)
          .toString(),
        endAt: moment()
          .second(59)
          .minute(59)
          .hour(23)
          .toString()
      }
    }
  });

  const matches = data?.matches.matches ? data.matches.matches : [];

  const matchesSorted = useMemo(() => {
    const inProgress = matches.filter(m => m.finishedStatus === 'inProgress');
    const pregame = matches.filter(m => m.finishedStatus === 'pregame');
    return inProgress.concat(pregame);
  }, [matches]);

  return (
    <Page type="home">
      <PageContent>
        <PageHeader title={`${me?.firstName} ${me?.lastName}`} preTitle="Welcome back," url={me?.photo?.url} />
        <PageContentContent>
          <section className="todaysMatches">
            <div className="todaysMatches__header">
              <Title className="todaysMatches__title" is3>
                Today's matches
              </Title>
              {matchesSorted.length > 0 && (
                <Link className="todaysMatches__seeAllMatches" to={routes.matchesLive()}>
                  See all matches >
                </Link>
              )}
            </div>
            {loading ? null : matchesSorted.length > 0 ? (
              matchesSorted
                .slice(0, 3)
                .map(m => (
                  <MatchCard
                    key={m.id}
                    competition={m.competition}
                    homeTeam={m.homeTeam}
                    awayTeam={m.awayTeam}
                    match={m}
                  />
                ))
            ) : (
              <MissingEntitiesComponent
                text="There are no matches today"
                buttonText="All matches"
                url={routes.matchesLive()}
              />
            )}
          </section>
        </PageContentContent>
      </PageContent>
    </Page>
  );
};
