import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import { CONFIG } from '@ligr/shared';

export const bugsnagClient = Bugsnag.start({
  apiKey: CONFIG.BUGSNAG_KEY,
  appType: 'dashboard',
  plugins: [new BugsnagPluginReact(React)],
  releaseStage: CONFIG.ENV
});

export const BugSnagErrorBoundary = Bugsnag.getPlugin('react');
