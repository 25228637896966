import { Venue } from '../../../../api-types';
import { Icon } from '@ligr/shared';
import React from 'react';
import { useHistory } from 'react-router';
import { ConfirmWindow } from '../../../../components/ConfirmWindow';
import { ConfirmWindowVenueContent } from '../../../../components/ConfirmWindow/Content/VenueContent';
import { ContextMenu } from '../../../../components/ContextMenu';
import { Notification } from '../../../../lib/notificationContainer';
import { useArchiveVenue } from '../../../../lib/useArchiveVenue';
import { routes } from '../../../../router/Routes';

interface VenueMenuProps {
  venue: Pick<Venue, 'countMatches' | 'countDefaultVenue' | 'name' | 'id'>;
}

export const VenueMenu: React.FunctionComponent<VenueMenuProps> = ({ venue }) => {
  const { archiveVenue } = useArchiveVenue();
  const { addNotification } = Notification.useContainer();
  const history = useHistory<{ referrer?: string } | undefined>();

  const archiveVenueFn = async (venueId: number) => {
    await archiveVenue(venueId);
    addNotification('Venue archived successfully.', 'success');
    history.push(routes.assetVenues());
  };

  return (
    <ContextMenu
      place="bottom"
      clickable={true}
      className="pageHeader__menu venueMenu"
      renderMenu={() => {
        return (
          <ul>
            <li>
              <ConfirmWindow
                action={() => archiveVenueFn(venue.id)}
                title={`Archive venue`}
                content={<ConfirmWindowVenueContent venue={venue} />}
              >
                <Icon icon="close" />
                <span>Archive venue</span>
              </ConfirmWindow>
            </li>
          </ul>
        );
      }}
    >
      <Icon icon="dropdown" color="grey-600" />
    </ContextMenu>
  );
};
