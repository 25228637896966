import { AdFileSize } from '@ligr/api-v2';
import { ImagePlaceholderType } from '@ligr/shared';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FileEntityType } from '../../api-types';
import { logDelay } from '../../lib/animation';
import { Button } from '../Button';
import { Loader } from '../loader/Loader';
import { SearchField } from '../SearchField/SearchField';
import { SideMenuItem } from '../SideMenuItem';
import './style.scss';

export interface SideMenuListSubItem {
  id: number;
  text: string;
  to: string;
  type?: string;
}

export interface SideMenuListItem {
  text: string;
  to: string;
  count?: number;
  imageUrl?: string;
  imagePlaceholder?: ImagePlaceholderType;
  type?: string;
  subItems?: SideMenuListSubItem[];
}
export interface SideMenuListProps {
  entityType?: FileEntityType;
  items: SideMenuListItem[];
  size?: AdFileSize;
  link?: string;
  linkComponent?: JSX.Element;
  renderItem?: any;
  loading?: boolean;
}

export const SideMenuList: React.FunctionComponent<SideMenuListProps> = ({
  items,
  entityType,
  size,
  link,
  linkComponent,
  loading,
  ...props
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [filteredItems, setFilteredItems] = useState<SideMenuListItem[]>([]);

  useEffect(() => {
    setFilteredItems(items);
  }, []);

  useEffect(() => {
    if (searchValue === '') {
      setFilteredItems(items);
    } else {
      setFilteredItems(items.filter(i => i.text.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())));
    }
  }, [searchValue, items]);

  return (
    <>
      <div className="sideMenuActions">
        {link && <Button className="is-primary" isSquare icon="plus" href={link} />}
        {linkComponent}
        <SearchField
          className="sideMenuActions__searchField"
          placeholder="Search"
          onChange={(value: any) => setSearchValue(value)}
          value={searchValue}
        />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <nav className="sideMenuList">
          {filteredItems.map((i, j) => {
            return (
              <div key={i.to}>
                <NavLink to={i.to} {...logDelay(j)}>
                  {props.renderItem ? props.renderItem(i) : <SideMenuItem item={i} />}
                </NavLink>
                {i.subItems?.length &&
                  i.subItems.map(item => (
                    <NavLink to={item.to} key={item.to} {...logDelay(j)}>
                      <div key={`${entityType}-${item.id}`} className="sideMenuSubItem__text">
                        {item.text}
                      </div>
                    </NavLink>
                  ))}
              </div>
            );
          })}
        </nav>
      )}
    </>
  );
};
