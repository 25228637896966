import { FormikHelpers, useFormikContext } from 'formik';
import gql from 'graphql-tag';
import React, { useMemo, useState } from 'react';
import { object, string } from 'yup';
import {
  Age,
  ClubsQuery,
  Gender,
  GradesQuery,
  TeamCompetitionsInput,
  UpdateTeamInput,
  UpdateTeamSettingsMutation
} from '../../../../api-types';
import { Button } from '../../../../components/Button';
import { ButtonBottom as BottomButtonRow } from '../../../../components/ButtonBottom/ButtonBottom';
import { Form } from '../../../../components/Form/Form';
import { FormField } from '../../../../components/FormField';
import { Loader } from '../../../../components/loader/Loader';
import { Title } from '../../../../components/Title/Title';
import { getGQLError } from '../../../../lib/apolloClient';
import { useErrorHandledMutation, useErrorHandledQuery } from '../../../../lib/apolloHooks';
import { clubsQuery } from '../../../../lib/gql/queries/clubs';
import { gradesQuery } from '../../../../lib/gql/queries/grades';
import { Notification } from '../../../../lib/notificationContainer';

const validationSettingsSchema = object().shape({
  age: string().required().oneOf(Object.values(Age)).label('Age'),
  gender: string().required().oneOf(Object.values(Gender)).label('Gender'),
  gradeId: string().required().label('Division')
});

export interface SettingsTabProps {
  team: {
    id: number;
    age: Age;
    gender: Gender;
    grade: {
      id: number;
    };
    club?: {
      id: number;
    } | null;
    sport: {
      id: number;
      name: string;
    };
    competitions: { id: number; name: string }[];
  };
  loading?: boolean;
  competitions?: { id: number; name: string; sportId: number }[];
}

type SettingsFormProps = SettingsTabProps;

type CompetitionOption = { label: string; value: string };

interface UpdateTeamSettingsValues {
  competitions: CompetitionOption[];
  age: Age;
  gender: Gender;
  gradeId: string | number;
  clubId?: string | number;
}

const updateTeamMutationSettings = gql`
  mutation updateTeamSettings($updateTeamInput: UpdateTeamInput!) {
    updateTeam(updateTeamInput: $updateTeamInput) {
      id
      club {
        id
      }
      age
      gender
      grade {
        id
      }
      sport {
        id
        name
      }
      competitions {
        id
        name
      }
    }
  }
`;

const SettingsForm: React.FunctionComponent<SettingsFormProps> = ({ team, competitions, loading }) => {
  const form = useFormikContext<UpdateTeamSettingsValues>();
  const { data: dataGrades, loading: loadingGrades } = useErrorHandledQuery<GradesQuery>(gradesQuery);
  const grades = (dataGrades && dataGrades.grades) || [];
  const { data: dataClubs, loading: loadingClubs } = useErrorHandledQuery<ClubsQuery>(clubsQuery);
  const clubs = (dataClubs && dataClubs.clubs) || [];

  if (loadingGrades || loadingClubs) return <Loader />;

  return (
    <>
      <div>
        <FormField
          value={team.club?.id}
          name="clubId"
          placeholder="None"
          options={clubs.map(club => ({ label: club.name, value: club.id }))}
          type="select"
          label="Club"
        />
        <FormField
          disabled
          value={team.sport.id}
          name="sportId"
          placeholder="Select Sport"
          options={[{ label: team.sport.name, value: team.sport.id }]}
          type="select"
          label="Sport"
        />
        <FormField
          name="gradeId"
          type="select"
          label="Division"
          options={grades.map(grade => ({ label: grade.name, value: grade.id }))}
        />
      </div>
      <div>
        <FormField
          name="competitions"
          noOptionsMessage={() => 'No remaining competitions'}
          options={
            competitions
              ?.filter(({ sportId }) => parseInt(`${sportId}`) === team.sport.id)
              .map(({ id, name }) => ({
                label: name,
                value: id
              })) || []
          }
          type="multiselect"
          label="Competitions"
        />
        <FormField
          name="age"
          type="select"
          label="Age Group"
          options={Object.values(Age).map(age => ({ label: age, value: age }))}
        />
        <FormField
          name="gender"
          type="select"
          label="Gender"
          options={Object.values(Gender).map(gender => ({ label: gender, value: gender }))}
        />
      </div>
      {form?.dirty && (
        <BottomButtonRow withResetFormButton resetOnClick={() => form.resetForm()}>
          <Button
            onClick={event => {
              event.preventDefault();
              form.submitForm();
            }}
            isLoading={loading}
            type="primary"
          >
            Save
          </Button>
        </BottomButtonRow>
      )}
    </>
  );
};

export const SettingsTab: React.FunctionComponent<SettingsTabProps> = ({ team, competitions = [], loading }) => {
  const [error, setError] = useState('');
  const [updateTeam] = useErrorHandledMutation<UpdateTeamSettingsMutation>(updateTeamMutationSettings);
  const { addNotification } = Notification.useContainer();

  const initialValues = useMemo(
    () => ({
      age: team.age,
      gender: team.gender,
      gradeId: team.grade.id,
      clubId: team.club?.id,
      competitions: team.competitions.map(
        ({ id, name }) =>
          ({
            label: name,
            value: id.toString()
          } as CompetitionOption)
      )
    }),
    [team]
  );

  const update = async (
    updateTeamInput: UpdateTeamSettingsValues,
    formikHelpers: FormikHelpers<UpdateTeamSettingsValues>
  ) => {
    try {
      const deletedCompetitions: TeamCompetitionsInput[] = initialValues!.competitions
        .filter(
          ({ value }: CompetitionOption) =>
            !updateTeamInput.competitions ||
            !updateTeamInput.competitions.find(({ value: competitionId }: any) => competitionId === value)
        )
        .map(({ value }: CompetitionOption) => ({
          competitionId: parseInt(value),
          delete: true
        }));
      const inp = {
        id: team.id,
        age: updateTeamInput.age,
        gender: updateTeamInput.gender,
        clubId: updateTeamInput.clubId
          ? /*
        // prettier-ignore
        // @ts-ignore */
            parseInt(updateTeamInput.clubId)
          : null,
        gradeId: updateTeamInput.gradeId /*
          // prettier-ignore
          // @ts-ignore */
          ? parseInt(updateTeamInput.gradeId)
          : null,
        competitions: updateTeamInput.competitions
          ? updateTeamInput.competitions
              .map(({ value }) => ({
                competitionId: parseInt(value)
              }))
              .concat(deletedCompetitions)
          : ([] as TeamCompetitionsInput[]).concat(deletedCompetitions)
      } as UpdateTeamInput;
      await updateTeam({
        variables: { updateTeamInput: inp }
      });
      addNotification('Saved successfully', 'success');
      formikHelpers.resetForm();
    } catch (e) {
      setError(getGQLError(e).message);
    }
  };

  return (
    <Form
      onSubmit={update}
      error={error}
      className={`page-form team-page-settings`}
      validationSchema={validationSettingsSchema}
      withSubmit
      initialValues={initialValues}
      useConfirm={true}
    >
      <Title is3>Team settings</Title>
      <SettingsForm team={team} competitions={competitions} loading={loading} />
    </Form>
  );
};
