import { Image } from '@ligr/shared';
import React from 'react';
import { SideMenuListItem } from '../SideMenuList';

export interface SideMenuItemProps {
  item: SideMenuListItem;
}

export const SideMenuItem: React.FunctionComponent<SideMenuItemProps> = ({
  item: { text, imageUrl, imagePlaceholder }
}) => {
  return (
    <>
      <Image url={imageUrl} placeholder={imagePlaceholder} className="sideMenuItem__image" />
      <div className="sideMenuItem__text">{text}</div>
    </>
  );
};
