import { EFinishedStatus } from '@ligr/api-v2';
import { IQueryOptions, Match, MatchSummary, Overlay } from '../../../api-types';
import { CONFIG, Icon, Image } from '@ligr/shared';
import classnames from 'classnames';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import React, { memo, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Button } from '../../../components/Button';
import { ConfirmWindow } from '../../../components/ConfirmWindow';
import { ConfirmWindowMatchContent } from '../../../components/ConfirmWindow/Content/MatchContent';
import { ContextMenu } from '../../../components/ContextMenu';
import { InputField, InputFieldProps } from '../../../components/InputField';
import { Select } from '../../../components/Select';
import { Switch } from '../../../components/Switch';
import { isSubRow, Table } from '../../../components/Table/Table';
import { Title } from '../../../components/Title/Title';
import { ToolTip } from '../../../components/ToolTipIcon';
import { Notification } from '../../../lib/notificationContainer';
import { useArchiveMatch } from '../../../lib/useArchiveMatch';
import { alphabetSort } from '../../../lib/util/alphabetSort';
import { routes } from '../../../router/Routes';
import { HasCoverageIndicator } from './HasCoverageIndicator';
import { MatchDateCell } from './MatchDateCell';
import { NoCoverageWarning } from './NoCoverageWarning';
import './style.scss';
import { createOverlay, deleteOverlay } from './util';

interface MatchOverlayRow {
  match: Match;
  overlay: Overlay | null;
  subRows: any[];
  expanded: boolean;
}

interface MatchTableProps {
  matchLoading: boolean;
  rows: MatchOverlayRow[];
  actions: {
    updateOverlayM: any;
    deleteOverlayM: any;
    createOverlayM: any;
  };
  hasPagination: boolean;
  variables: {
    options: IQueryOptions;
    query: string | string[] | null | undefined;
    finishedStatus: EFinishedStatus | EFinishedStatus[];
  };
}

interface HoverInputFieldProps extends InputFieldProps {
  initialValue: any;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
  className?: string;
}

const DateCell: React.FunctionComponent<{
  match: Match;
  summary: MatchSummary;
  className?: string;
}> = ({ match, summary, className }) => {
  return (
    <div className={classnames('dateCell', className)}>
      <MatchDateCell match={match} summary={summary} />
    </div>
  );
};

const HoverInputField: React.FunctionComponent<HoverInputFieldProps> = ({ initialValue, onBlur, className }) => {
  const [mouseOver, setMouseOver] = useState(false);
  const [focused, setFocused] = useState(false);

  const [val, setVal] = useState<string | undefined>();
  const initVal = useMemo(() => initialValue, [val]);

  return (
    <div
      className={className}
      onClick={() => {
        if (!focused && !mouseOver) {
          setMouseOver(true);
        }
      }}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      {!mouseOver && !focused ? (
        <span>{val || initVal}</span>
      ) : (
        <InputField
          type="text"
          defaultValue={val || initVal}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setVal(e.target.value)}
          onBlur={onBlur}
        />
      )}
    </div>
  );
};

export const MatchesTable: React.FunctionComponent<MatchTableProps> = ({
  matchLoading,
  rows,
  actions,
  variables,
  hasPagination
}) => {
  const { orgId } = useParams();

  const history = useHistory<{ referrer?: string } | undefined>();
  const { archiveMatch, restoreMatch } = useArchiveMatch();
  const { addNotification } = Notification.useContainer();

  const updateOverlay = (overlay: Overlay, property: keyof Overlay, number?: boolean) => (value: any) => {
    const v = number ? parseFloat(value) : value;

    actions.updateOverlayM({
      variables: {
        updateOverlayInput: {
          id: overlay!.id,
          [property]: v
        }
      }
    });
  };

  const archiveMatchFn = async (matchId: number) => {
    archiveMatch(matchId);
    addNotification('Match archived successfully.', 'success');
  };

  const ContextCell = useMemo(
    // @ts-ignore
    () => ({ cell: { row } }) => {
      const {
        original: { overlay, match }
      } = row;

      if (match.archived) {
        if (isSubRow(row)) return null;

        return (
          <div className="matchesTable--archived__overlay">
            <Button
              onClick={() => {
                restoreMatch(match.id);
                addNotification('Match restored successfully.', 'success');
              }}
            >
              Restore
            </Button>
          </div>
        );
      }

      const items = [];
      if (!isSubRow(row) && !match.hasFacts) {
        items.push({
          icon: 'settings',
          text: 'Match Settings',
          action: () => {
            history.push(routes.editMatchFromLive({ matchId: match.id }));
          }
        });
      }

      items.push(
        {
          icon: 'settings',
          text: 'Overlay Settings',
          action: () => {
            history.push(
              routes.editOverlay({ matchId: match.id, overlayId: overlay.id, graphicName: 'select-graphic' })
            );
          }
        },
        {
          icon: 'controlCenter',
          text: 'Overlay Control',
          action: () => {
            history.push(routes.controlOverlay({ overlayId: overlay.id }));
          }
        }
      );
      if (overlay) {
        items.push({
          icon: 'link',
          text: 'Copy Overlay link',
          action: () => {
            copy(`${CONFIG.OVERLAY_URL}/${overlay.key}`);
            addNotification('URL copied', 'info');
          }
        });
      }
      items.push({
        icon: 'liveScore',
        text: 'Open LiveScore',
        action: () => window.open(`${CONFIG.LIVE_SCORE_URL}/o/${orgId}/match/${match.id}`, '_blank')
      });

      if (!isSubRow(row)) {
        items.push(
          {
            icon: 'plus',
            text: `${overlay ? 'New' : 'Create'} overlay`,
            action: () => createOverlay(match, variables, actions.createOverlayM)
          },
          {
            component: (
              <ConfirmWindow
                action={() => archiveMatchFn(match.id)}
                title={`Archive match`}
                content={<ConfirmWindowMatchContent match={match} />}
                className="contextMenu__item"
              >
                <Icon
                  icon="close"
                  className="contextMenu__icon"
                  style={{ width: '10px', height: '10px', padding: '0 3px', boxSizing: 'content-box' }}
                />
                <span className="contextMenu__text">Archive match</span>
              </ConfirmWindow>
            )
          }
        );
      }

      items.push({
        icon: 'remove',
        iconStyle: { width: '10px', height: '10px', padding: '0 3px', boxSizing: 'content-box' },
        text: 'Delete Overlay',
        action: () => deleteOverlay(overlay.id, match.id, variables, actions.deleteOverlayM)
      });

      if (match.finishedStatus !== EFinishedStatus.cancelled) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: match.baseEntity ? '-2px' : 0
            }}
          >
            <ContextMenu
              id={overlay ? overlay.id : `match-${match.id}`}
              // @ts-ignore
              items={items}
              place="left"
            >
              <Button type="plain" isSquare icon="ellipsis" iconColor="grey-600" />
            </ContextMenu>
            {match.baseEntity?.id &&
              (match.coverage ? (
                <HasCoverageIndicator match={match} />
              ) : (
                <NoCoverageWarning
                  match={match}
                  liveScoreRoute={`${CONFIG.LIVE_SCORE_URL}/o/${orgId}/match/${match.id}`}
                />
              ))}
          </div>
        );
      }
      return null;
    },
    [variables]
  );

  const DelayCell = useMemo(
    // @ts-ignore
    () => ({ cell: { value }, row }) => {
      const { finishedStatus, archived } = row.original.match;
      if (value && finishedStatus !== EFinishedStatus.cancelled && !archived) {
        return (
          <InputField
            style={{ maxWidth: '200px' }}
            defaultValue={value.encodingDelay}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateOverlay(value, 'encodingDelay', true)(e.target.value)
            }
            suffix="sec"
          />
        );
      }
      return null;
    },
    []
  );

  const ResolutionCell = useMemo(
    // @ts-ignore
    () => ({ cell: { value }, row }) => {
      const { finishedStatus, archived } = row.original.match;
      if (value && finishedStatus !== EFinishedStatus.cancelled && !archived) {
        return (
          <Select
            value={value.resolution.toString()}
            type="select"
            options={[540, 720, 1080].map(value => ({ label: value, value }))}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              updateOverlay(value, 'resolution', true)(e.target.value)
            }
          />
        );
      }
      return null;
    },
    []
  );

  const AutoModeCell = useMemo(
    // @ts-ignore
    () => ({ cell: { value }, row }) => {
      const { finishedStatus, archived } = row.original.match;
      if (value && finishedStatus !== EFinishedStatus.cancelled && !archived) {
        return <Switch initialValue={value.autoMode} onChange={updateOverlay(value, 'autoMode')} />;
      }
      return null;
    },
    []
  );

  const WatermarksCell = useMemo(
    // @ts-ignore

    () => ({ cell: { value }, row }) => {
      const { finishedStatus, archived } = row.original.match;
      if (value && finishedStatus !== EFinishedStatus.cancelled && !archived) {
        return <Switch initialValue={value.showWatermarks} onChange={updateOverlay(value, 'showWatermarks')} />;
      }
      return null;
    },
    []
  );

  const AdToggleCell = useMemo(
    // @ts-ignore
    () => ({ cell: { value }, row }) => {
      const { finishedStatus, archived } = row.original.match;
      if (value && finishedStatus !== EFinishedStatus.cancelled && !archived) {
        return <Switch initialValue={value.enableAds} onChange={updateOverlay(value, 'enableAds')} />;
      }
      return null;
    },
    []
  );

  const OverlayNameCell = useMemo(
    // @ts-ignore
    () => ({ cell: { value }, row }) => {
      const { finishedStatus, archived } = row.original.match;
      if (value && finishedStatus !== EFinishedStatus.cancelled) {
        if (archived) {
          return value.name;
        }
        return (
          <HoverInputField
            className="matchesTable__overlayInput"
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => updateOverlay(value, 'name')(e.target.value)}
            initialValue={value.name}
          />
        );
      }
      return null;
    },
    []
  );

  return (
    <div className={classnames('tableWrapper', { 'tableWrapper--withPagination': hasPagination })}>
      <Table
        className="matchesTable"
        loading={matchLoading}
        // @ts-ignore
        data={rows}
        columns={
          !rows
            ? []
            : [
                {
                  Header: 'When',
                  accessor: 'match.date',
                  // @ts-ignore
                  className: 'matchesTable__when',
                  // @ts-ignore
                  sortType: ({ values: valuesA }: any, { values: valuesB }: any) => {
                    return moment(valuesA).unix() - moment(valuesB).unix();
                  },
                  Cell: memo(({ row }) => {
                    return !isSubRow(row) ? (
                      <DateCell
                        className="matchesTable__dateCell"
                        match={row.original.match}
                        summary={row.original.match.summary}
                      />
                    ) : (
                      <span className="additional">
                        <Icon icon="linkedAbove" />
                      </span>
                    );
                  })
                },
                {
                  Header: 'Comp',
                  accessor: 'match.competition',
                  className: 'matchesTable__competition',
                  sortType: ({ values: valuesA }: any, { values: valuesB }: any) => {
                    return alphabetSort(valuesA['match.competition'].name, valuesB['match.competition'].name);
                  },
                  Cell: memo(({ cell, row }) => {
                    return !isSubRow(row) ? (
                      <ToolTip
                        reference={() => (
                          <Image
                            className="matchesTable__compImg"
                            url={cell.value.logo?.url}
                            placeholder="competition"
                          />
                        )}
                      >
                        {cell.value.name}
                      </ToolTip>
                    ) : null;
                  })
                },
                {
                  Header: 'Home',
                  accessor: 'match.homeTeam',
                  className: 'matchesTable__homeTeam',
                  sortType: ({ values: valuesA }: any, { values: valuesB }: any) => {
                    return alphabetSort(valuesA['match.homeTeam'].name, valuesB['match.homeTeam'].name);
                  },
                  Cell: memo(({ cell, row }) => (
                    <>
                      {!isSubRow(row) && (
                        <>
                          <ToolTip
                            reference={() => (
                              <Image
                                className="matchesTable__homeTeamImg"
                                url={cell.value.logo?.url}
                                placeholder="team"
                              />
                            )}
                          >
                            {cell.value.name}
                          </ToolTip>
                          <Title className="matchesTable__homeTeamTitle">{cell.value.abbreviation}</Title>
                        </>
                      )}
                    </>
                  ))
                },
                {
                  Header: 'Away',
                  accessor: 'match.awayTeam',
                  className: 'matchesTable__awayTeam',
                  sortType: ({ values: valuesA }: any, { values: valuesB }: any) => {
                    return alphabetSort(valuesA['match.awayTeam'].name, valuesB['match.awayTeam'].name);
                  },
                  Cell: memo(({ cell: { value }, row }) => (
                    <>
                      {!isSubRow(row) && (
                        <>
                          <ToolTip
                            reference={() => (
                              <Image className="matchesTable__awayTeamImg" url={value.logo?.url} placeholder="team" />
                            )}
                          >
                            {value.name}
                          </ToolTip>
                          <Title className="matchesTable__awayTeamTitle">{value.abbreviation}</Title>
                        </>
                      )}
                    </>
                  ))
                },
                {
                  Header: 'Overlay Name',
                  id: 'overlay.name',
                  accessor: 'overlay',
                  className: 'matchesTable__overlayName',
                  disableSortBy: true,
                  Cell: OverlayNameCell
                },
                {
                  Header: 'Ads',
                  accessor: 'overlay',
                  disableSortBy: true,
                  className: 'matchesTable__ads',
                  Cell: AdToggleCell
                },
                {
                  Header: 'Watermarks',
                  accessor: 'overlay',
                  id: 'overlay.watermarks',
                  className: 'matchesTable__watermarks',
                  disableSortBy: true,
                  Cell: WatermarksCell
                },
                {
                  Header: 'Auto mode',
                  accessor: 'overlay',
                  id: 'overlay.autoMode',
                  disableSortBy: true,
                  className: 'matchesTable__autoMode',
                  Cell: AutoModeCell
                },
                {
                  Header: 'Resolution',
                  id: 'overlay.resolution',
                  accessor: 'overlay',
                  className: 'matchesTable__resolution',
                  disableSortBy: true,
                  Cell: ResolutionCell
                },
                {
                  Header: 'Delay',
                  accessor: 'overlay',
                  id: 'overlay.delay',
                  disableSortBy: true,
                  className: 'matchesTable__delay',
                  Cell: DelayCell
                },
                {
                  className: 'matchesTable__settings',
                  id: 'settings',
                  Cell: ContextCell
                }
              ]
        }
      />
    </div>
  );
};
