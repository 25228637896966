import React, { useEffect, useState } from 'react';
import { ReactStripeElements } from 'react-stripe-elements';
import { Loader } from '../../components/loader/Loader';
import { Me as MeContainer } from '../../lib/meContainer';
import { Organization as OrgContainer } from '../../lib/orgContainer';
import { ProfilePageWrapper } from './ProfilePageWrapper';
import { CurrentSubscriptionOverview } from './Subscriptions/CurrentSubscriptionOverview';
import { Actions } from './Subscriptions/forms/Actions';
import { CancelSubscriptionForm } from './Subscriptions/forms/CancelSubscriptionForm';
import { CardForm } from './Subscriptions/forms/CardForm';
import { CycleSelectionForm } from './Subscriptions/forms/CycleSelectionForm';
import { MatchSlider } from './Subscriptions/forms/MatchSlider';
import { PlanSelectionForm } from './Subscriptions/forms/PlanSelectionForm';
import { ManualPayment } from './Subscriptions/ManualPayment';
import { SelectionOverview } from './Subscriptions/SelectionOverview';
import { CurrentSubscription, Selection } from './Subscriptions/Subscription.type';
import { ApolloQueryResult } from 'apollo-client';
import { MeQuery } from '../../api-types';

interface ProfileSubscriptionProps {
  stripe: ReactStripeElements.StripeProps;
  me: ApolloQueryResult<MeQuery>['data']['me'];
}

export const ProfileSubscriptionForm: React.FunctionComponent<ProfileSubscriptionProps> = ({ stripe, me }) => {
  const orgContainer = OrgContainer.useContainer();
  const [currentSubscription, setCurrentSubscription] = useState<CurrentSubscription | undefined>(undefined);
  const [selection, setSelection] = useState<Selection>({ cycle: 'annual', cardChanged: false, validCard: false });
  const [displayForm, toggleForm] = useState<boolean>(true);
  const [displayCardForm, toggleCardForm] = useState<boolean>(false);

  // Set default currentSubscription from Org
  useEffect(() => {
    if (orgContainer.org?.stripeCustomerId) {
      setCurrentSubscription({
        stripeCustomerId: orgContainer.org.stripeCustomerId,
        stripePlanId: orgContainer.org.stripePlanId,
        stripeCardLast4: orgContainer.org.stripeCardLast4,
        planQuantity: orgContainer.org.planQuantity
      });
    }
  }, [me]);

  // Reset the selectedQuantity if selectedPlan has a limit that was reached
  useEffect(() => {
    if (selection && selection.plan) {
      if (selection.plan.limit && selection.quantity) {
        if (selection.quantity > selection.plan.limit) {
          setSelection({ ...selection, quantity: selection.plan.limit });
        }
      }
      if (selection.plan.custom) toggleCardForm(false);
    }
  }, [selection]);

  useEffect(() => {
    if (currentSubscription) {
      if (currentSubscription.stripeCardLast4) setSelection({ ...selection, validCard: true });
    }
  }, [currentSubscription]);

  useEffect(() => {
    if (currentSubscription && currentSubscription.stripePlanId) {
      toggleForm(false);
      toggleCardForm(false);
    } else {
      toggleForm(true);
    }
  }, [currentSubscription]);

  useEffect(() => {
    if (currentSubscription && currentSubscription.stripePlanId) {
      toggleForm(false);
      toggleCardForm(false);
    }
  }, []);

  if (!orgContainer.called || orgContainer.loading) return <Loader />;

  const org = orgContainer.org!;

  if (org?.manualPayment) return <ManualPayment me={me} />;

  return (
    <ProfilePageWrapper me={me}>
      <div className="subscription">
        {currentSubscription && (
          <CurrentSubscriptionOverview
            currentSubscription={currentSubscription}
            displayForm={displayForm}
            toggleForm={toggleForm}
            displayCardForm={displayCardForm}
            toggleCardForm={toggleCardForm}
          />
        )}
        {displayForm && (
          <>
            <CycleSelectionForm updateSelection={setSelection} selection={selection} />
            <PlanSelectionForm
              currentSubscription={currentSubscription}
              selection={selection}
              updateSelection={setSelection}
            />
            {selection.plan && !selection.plan.custom && !selection.plan.free && (
              <MatchSlider selection={selection} updateSelection={setSelection} />
            )}
            {(selection.plan || selection.quantity) && <SelectionOverview selection={selection} />}
          </>
        )}
        {(displayForm || displayCardForm) && (
          <>
            {((selection.plan && !selection.plan.free && !selection.plan.custom) || displayCardForm) && (
              <CardForm selection={selection} updateSelection={setSelection} />
            )}
            <Actions
              stripe={stripe}
              currentSubscription={currentSubscription}
              selection={selection}
              updateSelection={setSelection}
              toggleForm={toggleForm}
              toggleCardForm={toggleCardForm}
            />
          </>
        )}
        <CancelSubscriptionForm currentSubscription={currentSubscription} />
      </div>
    </ProfilePageWrapper>
  );
};
