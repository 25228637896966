import { Match } from '../../../../../api-types';
import { TriggerProps } from '@ligr/themes/lib/sports/themes/rugbyunion1/Templates/Player/types';
import React from 'react';
import { Select } from '../../../../Select';
import './style.scss';

interface PlayercardCustomProps {
  value?: Partial<TriggerProps>;
  onChange: (update: Partial<TriggerProps>) => any;
  match: Match;
  disabled?: boolean;
}

// FULLY CONTROLLED ONLY
export const Player: React.FunctionComponent<PlayercardCustomProps> = ({ disabled, onChange, value = {}, match }) => {
  return (
    <div className="playercard">
      <Select
        disabled={disabled}
        value={value.playerId}
        onChange={event => {
          // @ts-ignore
          onChange({ playerId: event.target.value ? parseInt(event.target.value) : null });
        }}
        label="Player"
        options={([
          {
            value: null,
            label: 'Select Player'
          }
        ] as { value: null | number; label: string }[]).concat(
          match?.lineup
            ?.sort((a, b) => {
              if (a.team.id !== b.team.id) return -1;
              return parseInt(a.number || '0') - parseInt(b.number || '0');
            })
            .map(({ player, number, position, team }) => ({
              label: `${number} - ${team.abbreviation} - ${player.firstName} ${player.lastName} (${position})`,
              value: player.id
            })) || []
        )}
      />
    </div>
  );
};
