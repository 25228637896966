import gql from 'graphql-tag';

export const updateAdFileMutation = gql`
  mutation updateAdFile($updateAdFileInput: UpdateAdFileInput!) {
    updateAdFile(updateAdFileInput: $updateAdFileInput) {
      id
      adSetId
      file {
        id
        url
        entityId
        entityType
      }
      size
      adSet {
        id
        name
        organizationId
        teamId
      }
    }
  }
`;
