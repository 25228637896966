import capitalize from 'capitalize';
import pluralize from 'pluralize';
import React from 'react';

type Team = {
  countMatches: number;
  countPlayers: number;
  archived: boolean;
  name: string;
  id: number;
  logo?: {
    url: string;
  } | null;
};

export const ConfirmWindowTeamContent: React.FunctionComponent<{
  team: Team;
}> = ({ team }) => {
  const withNotification = team.countMatches > 0 || team.countPlayers > 0;
  const notifications = [];

  if (team.countMatches > 0) {
    notifications.push(
      <li>
        Archive {team.countMatches} {pluralize('matches', team.countMatches)}
      </li>
    );
  }

  if (team.countPlayers > 0) {
    notifications.push(
      <li>
        Remove {team.countPlayers} {pluralize('player associations', team.countPlayers)}
      </li>
    );
  }

  return (
    <>
      Are you sure you want to archive {capitalize(team.name)}?
      {withNotification && (
        <div className={`notification notification--error confirm-window__notification`}>
          This action will also:
          <ul>{notifications.map(item => item)}</ul>
        </div>
      )}
    </>
  );
};
