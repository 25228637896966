import { AdAllocationEntityType } from '../../api-types';
import React, { HTMLProps } from 'react';
import { routes } from '../../router/Routes';
import { CardCompetition } from '../CardCompetition';
import { CardTeam } from '../CardTeam';

type Competition = {
  archived: boolean;
  name: string;
  id: number;
  countMatches: number;
  countTeams: number;
  logo?: {
    url: string;
  } | null;
};

type Team = {
  id: number;
  countMatches: number;
  countPlayers: number;
  archived: boolean;
  name: string;
  logo?: {
    url: string;
  } | null;
};

interface CardAllocationProps extends Partial<HTMLProps<HTMLDivElement>> {
  entity: Competition | Team;
  type: string;
}

export const CardAllocation: React.FunctionComponent<CardAllocationProps> = ({ entity, type, ...rest }) => {
  const to = routes.adSetsAllocationDetails({ entityId: entity.id, entityType: type as AdAllocationEntityType });

  if (type === 'competition') return <CardCompetition competition={entity as Competition} to={to} {...rest} />;
  if (type === 'team') return <CardTeam team={entity as Team} to={to} {...rest} />;
  return null;
};
