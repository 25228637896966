import { MatchConfig } from '@ligr/api-v2';
import React from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import {
  AdAllocationEntityType,
  Age,
  CompetitionQuery,
  DefaultOverlayConfig,
  Gender,
  Grade
} from '../../../../api-types';
import { NavTab } from '../../../../components/NavTabs/NavTabs.types';
import { PageContent } from '../../../../components/page/PageContent';
import { PageHeader } from '../../../../components/PageHeader';
import { AdAllocationLink } from '../../../../components/PageHeader/PageHeaderAllocationButton';
import { PageMenu } from '../../../../components/PageMenu';
import { useErrorHandledQuery } from '../../../../lib/apolloHooks';
import { competitionQuery } from '../../../../lib/gql/queries/competition';
import { routes } from '../../../../router/Routes';
import { CompetitionSideMenu } from '../CompetitionSideMenu/CompetitionSideMenu';
import { CompetitionMenu } from './CompetitionMenu';
import { CompetitionSettingsPage } from './CompetitionSettings.page';
import { CompetitionTeamsPage } from './CompetitionTeams.page';
import './style.scss';

interface CompetitionPageProps {
  competitionId: string;
  matchConfig: MatchConfig;
  overlayConfig: DefaultOverlayConfig;
}

export type Competition = {
  name: string;
  gfxName?: string | null;
  id: number;
  logo?: { url: string; id: number } | null;
  matchConfig: MatchConfig;
  overlayConfig: DefaultOverlayConfig;
  countTeams: number;
  countMatches: number;
  age: Age;
  gender: Gender;
  grade: Grade;
};

const CompetitionPageContent = ({ competition }: { competition: Competition }) => {
  const tabs: NavTab[] = [
    {
      text: 'Settings',
      to: routes.assetsCompetitionSettings({ competitionId: competition.id })
    },
    {
      text: 'Teams',
      to: routes.assetsCompetitionTeams({ competitionId: competition.id })
    }
  ];

  return (
    <>
      <PageHeader
        title={competition.name}
        preTitle={''}
        tabs={tabs}
        close={routes.assetsCompetitions()}
        tabRowEndComponent={() => (
          <AdAllocationLink entity={competition} entityType={AdAllocationEntityType.competition} />
        )}
        editMenu={() => <CompetitionMenu competition={competition} />}
      />
      <Switch>
        <Route path={tabs[1].to} render={props => <CompetitionTeamsPage competition={competition} {...props} />} />
        <Route path={tabs[0].to} render={() => <CompetitionSettingsPage competition={competition} />} />
        <Redirect to={routes.assetsCompetitionSettings({ competitionId: competition.id })} />
      </Switch>
    </>
  );
};

export const CompetitionPage: React.FunctionComponent = () => {
  const { competitionId: id } = useParams();
  const competitionId = parseInt(id!);

  const { data, loading } = useErrorHandledQuery<CompetitionQuery>(competitionQuery, {
    variables: { id: competitionId }
  });

  const competition = data ? data.competition : null;
  if (!competition && !loading) return <Redirect to={routes.notFound()} />;

  return (
    <>
      <PageMenu title="Competitions" component={() => <CompetitionSideMenu />} />

      <PageContent loading={loading} className="competition">
        {competition && <CompetitionPageContent competition={competition} />}
      </PageContent>
    </>
  );
};
