import { useSearchParams } from '@ligr/shared';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Competition, CompetitionsQuery } from '../../../../api-types';
import { Button } from '../../../../components/Button';
import { CardCompetition } from '../../../../components/CardCompetition';
import { Filters } from '../../../../components/Filters';
import { Grid } from '../../../../components/Grid/Grid';
import { getMissingEntitiesComponentContent } from '../../../../components/MissingEntitiesComponent/getMissingEntitiesComponentContent';
import { MissingEntitiesComponent } from '../../../../components/MissingEntitiesComponent/MissingEntitiesComponent';
import { PageContent } from '../../../../components/page/PageContent';
import { PageContentContent } from '../../../../components/page/PageContentContent';
import { PageHeader } from '../../../../components/PageHeader';
import { PageSubHeader } from '../../../../components/PageSubHeader/PageSubHeader';
import { logDelay } from '../../../../lib/animation';
import { useErrorHandledLazyQuery } from '../../../../lib/apolloHooks';
import { competitionsQuery } from '../../../../lib/gql/queries/competitions';
import { Notification } from '../../../../lib/notificationContainer';
import { useArchiveCompetition } from '../../../../lib/useArchiveCompetition';
import { routes } from '../../../../router/Routes';
import { EntityEnum } from '../../../../types/assets';
import './style.scss';

export const CompetitionsPage: React.FunctionComponent = () => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const { archiveCompetition } = useArchiveCompetition();
  const searchParams = useSearchParams(['sports', 'archive', 'query']);

  const [fetch, { data, loading, called }] = useErrorHandledLazyQuery<CompetitionsQuery>(competitionsQuery, {
    variables: { options: { archive: !!searchParams.archive } }
  });
  const competitions = (data && data.competitions) || [];
  const { addNotification } = Notification.useContainer();

  // Note can pass searchParams into the fetch as GQL variables
  // if we are server side searching
  useEffect(() => {
    fetch();
  }, []);

  const archiveCompetitionFn = async (competitionId: number) => {
    archiveCompetition(competitionId);
    addNotification('Competition archived successfully.', 'success');
  };

  // filter the results
  const competitionsSearch = useMemo(
    () =>
      competitions.filter((c: Competition) => {
        let query = true;
        if (searchParams.query) query = c.name.toLowerCase().includes(searchParams.query.toLowerCase());
        if (searchParams.sports) query = query && c.sport.id.toString() === searchParams.sports;
        return query;
      }),
    [searchParams.query, searchParams.sports, competitions]
  );

  const missingEntitiesDialogProps =
    called && !loading && getMissingEntitiesComponentContent(EntityEnum.competitions, competitions, competitionsSearch);

  return (
    <PageContent loading={!called || loading}>
      <PageHeader title="Competitions" />
      <PageContentContent className="competition">
        {competitions.length > 0 && (
          <PageSubHeader>
            <Filters searchable="competitions" filters={['sports', 'archive']} />
            <Button icon="plus" iconPosition="left" onClick={() => history.push(routes.assetCompetitionCreate())}>
              Create a competition
            </Button>
          </PageSubHeader>
        )}

        {missingEntitiesDialogProps ? (
          <MissingEntitiesComponent {...missingEntitiesDialogProps} />
        ) : (
          <Grid>
            {competitionsSearch.map((c, i) => (
              <CardCompetition
                key={i}
                competition={c}
                {...logDelay(i)}
                icon="close"
                fn={() => archiveCompetitionFn(c.id)}
              />
            ))}
          </Grid>
        )}
      </PageContentContent>
    </PageContent>
  );
};
