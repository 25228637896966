import { Team } from '../api-types';
import { useSearchParams } from '@ligr/shared';
import gql from 'graphql-tag';
import { useErrorHandledMutation } from './apolloHooks';
import { teamsWithCompQuery } from './gql/queries/teams';

export const useArchiveTeam = () => {
  const searchParams = useSearchParams(['archive']);

  const deleteTeamMutation = gql`
    mutation deleteTeam($id: Float!) {
      deleteTeam(id: $id)
    }
  `;

  const restoreTeamMutation = gql`
    mutation restoreTeam($id: Float!) {
      restoreTeam(id: $id)
    }
  `;

  const [archiveTeamMutation] = useErrorHandledMutation<boolean>(deleteTeamMutation);
  const [unarchiveTeamMutation] = useErrorHandledMutation<boolean>(restoreTeamMutation);

  const archiveTeam = async (teamId: number) => {
    await archiveTeamMutation({
      variables: { id: teamId },
      update: (cache, { data }) => {
        const teamCache = cache.readQuery<{ teams: Team[] }>({
          query: teamsWithCompQuery,
          variables: { options: { archive: !!searchParams.archive } }
        });
        if (teamCache && data) {
          let teams;

          if (!!searchParams.archive) {
            teams = teamCache.teams.map((t: Team) => {
              if (t.id === teamId) {
                return { ...t, archived: true };
              }
              return t;
            });
          } else {
            teams = teamCache.teams.filter(({ id }: Team) => id !== teamId);
          }
          cache.writeQuery({
            query: teamsWithCompQuery,
            variables: { options: { archive: !!searchParams.archive } },
            data: {
              teams
            }
          });
        }
      }
    });
  };

  const restoreTeam = async (teamId: number) => {
    await unarchiveTeamMutation({
      variables: { id: teamId },
      update: (cache, { data }) => {
        const teamCache = cache.readQuery<{ teams: Team[] }>({
          query: teamsWithCompQuery,
          variables: { options: { archive: !!searchParams.archive } }
        });
        if (teamCache && data) {
          const teams = teamCache.teams.map((t: Team) => {
            return {
              ...t,
              archived: t.id === teamId ? false : t.archived
            };
          });
          cache.writeQuery({
            query: teamsWithCompQuery,
            variables: { options: { archive: !!searchParams.archive } },
            data: {
              teams
            }
          });
        }
      }
    });
  };

  return {
    archiveTeam,
    restoreTeam
  };
};
