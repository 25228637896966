import { annualPlans, IPlan, monthlyPlans } from '@ligr/api-v2';
import { Icon } from '@ligr/shared';
import React, { useEffect, useRef, useState } from 'react';
import { CurrentSubscription, Selection } from '../Subscription.type';

interface PlanSelectionFormProps {
  currentSubscription?: CurrentSubscription;
  selection: Selection;
  updateSelection: (selection: Selection) => void;
}

export const PlanSelectionForm: React.FunctionComponent<PlanSelectionFormProps> = ({
  currentSubscription,
  selection,
  updateSelection
}) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const [plans, setPlans] = useState<IPlan[]>([]);

  const resetPlans = () => {
    if (selection.cycle === 'annual') {
      setPlans(annualPlans);
    } else {
      setPlans(monthlyPlans);
    }
  };

  useEffect(() => {
    resetPlans();
  }, []);

  useEffect(() => {
    if (!selection.plan) selectRef.current!.selectedIndex = 0;
    if (selection.cycle) resetPlans();
  }, [selection]);

  const change = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const plan = plans[parseInt(event.target.value)];
    if (plan.free) {
      updateSelection({ ...selection, plan, quantity: plan.quantity, validCard: true, cardChanged: false });
    } else if (plan.custom) {
      updateSelection({ ...selection, plan, validCard: false, cardChanged: false });
    } else {
      if (!selection.quantity) {
        updateSelection({
          ...selection,
          plan,
          quantity: plan.quantity,
          validCard: currentSubscription && currentSubscription.stripeCardLast4 ? true : selection.validCard
        });
      } else {
        updateSelection({
          ...selection,
          plan,
          validCard: currentSubscription && currentSubscription.stripeCardLast4 ? true : selection.validCard
        });
      }
    }
  };

  return (
    <div className="plan-form">
      <label className="label" htmlFor="plan">
        Select a plan
      </label>
      <div className="select">
        <Icon icon="arrowDownSmall" className="arrow" color="grey-600" />
        <select ref={selectRef} className="plan-select" name="plan" defaultValue="" onChange={change}>
          <option value="" disabled>
            Select your plan
          </option>
          {plans.map((p, i) => (
            <option
              key={`plan-${i}`}
              value={i}
              disabled={currentSubscription && !p.custom && p.stripeId === currentSubscription.stripePlanId}
            >
              {p.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
