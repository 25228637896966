import * as Yup from 'yup';
import { MatchFormConfig } from '@ligr/shared';
import { SportsEnum } from '@ligr/api-v2';

export const matchValidationSchema = (sportName: SportsEnum) =>
  Yup.object().shape({
    time: Yup.object().shape({
      hours: Yup.number()
        .typeError('Hours has to be a number')
        .min(0)
        .max(12, 'Hours must be less than or equal to 12')
        .required('Hours field is required'),
      minutes: Yup.number()
        .min(0)
        .max(59, 'Minutes must be less than or equal to 59')
        .required('Minutes field is required'),
      ampm: Yup.string().oneOf(['am', 'pm'])
    }),
    name: Yup.string().required('Name field is required'),
    competitionId: Yup.string()
      .required()
      .test('competition', 'Competition field is required', value => value !== '')
      .label('Competition'),
    homeTeamId: Yup.string()
      .required()
      .test('home', 'Home Team field is required', value => value !== '')
      .label('Home team'),
    awayTeamId: Yup.string()
      .required()
      .test('away', 'Away Team field is required', value => value !== '')
      .label('Away team'),
    venueId: Yup.string()
      .required()
      .test('venue', 'Venue field is required', value => value !== '')
      .label('Venue'),
    date: Yup.string().required('Date field is required'),
    config: MatchFormConfig[sportName]?.validation
  });
