import React from 'react';
import { matchPath, NavLink, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Page } from '../../components/page/Page';
import { PageMenu } from '../../components/PageMenu';
import { withTitle } from '../../components/PageTitle/PageTitle';
import { routes } from '../../router/Routes';
import { AdSetPage } from './AdSetPage';
import { AdSetsPage } from './AdSets';
import { AdSetsAllocationPage } from './AdSetsAllocation/AdSetsAllocationPage';
import { AdSetsAllocationsPage } from './AdSetsAllocation/AdSetsAllocationsPage';

const AdSetMenu = () => (
  <div className="sideTabs">
    <NavLink
      isActive={(_, location) => {
        return !matchPath(location.pathname, {
          path: routes.adSetsAllocation()
        });
      }}
      to={routes.adSetsAll()}
    >
      Ad Sets
    </NavLink>
    <NavLink to={routes.adSetsAllocation()}>Allocation</NavLink>
  </div>
);

export const AdSetsRouter: React.FunctionComponent = () => {
  const history = useHistory<{ referrer?: string } | undefined>();

  return (
    <Page type="ads">
      <PageMenu component={AdSetMenu} title="Ad Sets" />
      <Switch>
        <Route
          exact
          path={[routes.adSetsAll(false), routes.createAdSet(false)]}
          component={withTitle({ component: AdSetsPage, pageTitle: 'Ad Sets' })}
        />
        <Route
          exact
          path={routes.adSetsAllocation(false)}
          component={withTitle({ component: AdSetsAllocationsPage, pageTitle: 'Ad Set Allocations' })}
        />
        <Route
          exact
          path={routes.adSetsAllocationDetails(false)}
          component={withTitle({ component: AdSetsAllocationPage, pageTitle: 'Ad Set Allocation' })}
        />
        <Route path={routes.adSet(false)} component={withTitle({ component: AdSetPage, pageTitle: 'Ad Set' })} />
        <Route path={'*'} render={() => <Redirect to={routes.adSetsAll()} />} />
      </Switch>
    </Page>
  );
};
