import { adAllocationFragment } from '@ligr/shared';
import gql from 'graphql-tag';

export const createTeamMutation = gql`
  mutation createTeam($createTeamInput: CreateTeamInput!) {
    createTeam(createTeamInput: $createTeamInput) {
      id
      name
      gfxName
      gfxFullName
      abbreviation
      sport {
        id
      }
      primaryBackgroundColor
      primaryTextColor
      secondaryBackgroundColor
      secondaryTextColor
      organizationId
      defaultVenueId
      competitions {
        id
        name
      }
      logo {
        id
        url
        entityId
        entityType
      }
      adAllocation {
        ...AdAllocation
      }
    }
  }
  ${adAllocationFragment}
`;
