import { Icon, Image } from '@ligr/shared';
import classnames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router';
import { useErrorHandledMutation } from '../../lib/apolloHooks';
import { leaveTeamMutation } from '../../lib/gql/mutations/leaveTeam';
import { playersForTeamQuery } from '../../lib/gql/queries/playersForTeam';
import { Notification } from '../../lib/notificationContainer';
import { routes } from '../../router/Routes';
import { Card } from '../Card/Card';
import { ContextMenu } from '../ContextMenu';
import './style.scss';

type TeamPlayer = {
  defaultStarter: boolean;
  position?: string | null;
  photo?: {
    url: string;
  } | null;
  number?: string | null;
  team: {
    id: number;
  };
};

type Player = {
  id: number;
  firstName: string;
  lastName: string;
  playsFor?: TeamPlayer[] | null;
};

type Team = {
  id: number;
};

export interface CardPlayerListProps {
  player: Player;
  playerTeam?: TeamPlayer;
  className?: string;
  team: Team;
}

export const CardPlayerList: React.FunctionComponent<CardPlayerListProps> = ({
  player,
  playerTeam,
  className,
  team
}) => {
  const history = useHistory<{ referrer?: string; teamId?: number } | undefined>();

  const [leaveTeam] = useErrorHandledMutation<boolean>(leaveTeamMutation);
  const { addNotification } = Notification.useContainer();

  return (
    <Card className={classnames('cardPlayerList', className)}>
      <div>
        <Image className="cardPlayerList__image" url={playerTeam?.photo?.url} placeholder="player" />
        <div className="cardPlayerList__name">{`${player.firstName} ${player.lastName}`}</div>
      </div>
      <div>
        <div className="cardPlayerList__position">{playerTeam?.position}</div>
        <div className="cardPlayerList__number">{playerTeam?.number}</div>
        <ContextMenu
          className="cardPlayerList__menu"
          id={player.id}
          items={[
            {
              icon: 'pencil',
              text: 'Edit player',
              action: () =>
                history.push({
                  pathname: routes.assetPlayerEdit({ playerId: player.id }),
                  search: location.search,
                  state: {
                    teamId: team.id
                  }
                })
            },
            {
              icon: 'remove',
              text: 'Remove player from this team',
              action: async () => {
                try {
                  await leaveTeam({
                    variables: { id: player.id, teamId: team.id },
                    update: (cache, { data }) => {
                      const playersForTeamCache = cache.readQuery<{ playersForTeam: Player[] }>({
                        query: playersForTeamQuery,
                        variables: { teamId: team.id }
                      });
                      if (playersForTeamCache && data) {
                        const newPlayers = playersForTeamCache.playersForTeam.filter(
                          ({ id }: Player) => id !== player.id
                        );
                        cache.writeQuery({
                          query: playersForTeamQuery,
                          variables: { teamId: team.id },
                          data: {
                            playersForTeam: newPlayers
                          }
                        });
                      }
                    }
                  });
                  addNotification('Player removed', 'success');
                } catch (e) {
                  addNotification('Something wetn wrong', 'error');
                }
              }
            }
          ]}
        >
          <Icon icon="ellipsis" color="grey-600" />
        </ContextMenu>
      </div>
    </Card>
  );
};
