import { Team, Venue } from '../../api-types';
import { Icon, IconType, Image } from '@ligr/shared';
import classnames from 'classnames';
import React, { HTMLProps } from 'react';
import { Notification } from '../../lib/notificationContainer';
import { useArchiveVenue } from '../../lib/useArchiveVenue';
import { routes } from '../../router/Routes';
import { Button } from '../Button';
import { Card } from '../Card/Card';
import { ConfirmWindow } from '../ConfirmWindow';
import { ConfirmWindowVenueContent } from '../ConfirmWindow/Content/VenueContent';
import { Title } from '../Title/Title';
import { ToolTip } from '../ToolTipIcon';
import './style.scss';

interface CardVenueProps extends Partial<HTMLProps<HTMLDivElement>> {
  venue: { name: string; id: number; archived: boolean; countMatches: number; countDefaultVenue: number };
  teams?: {
    id: number;
    name: string;
    logo?: {
      url: string;
    } | null;
  }[];
  fn?: () => void;
  icon?: IconType;
}

export const CardVenue: React.FunctionComponent<CardVenueProps> = ({ venue, teams = [], fn, icon, ...rest }) => {
  const { restoreVenue } = useArchiveVenue();
  const { addNotification } = Notification.useContainer();

  return (
    <Card
      to={venue.archived ? '' : routes.assetVenue({ venueId: venue.id })}
      className={classnames('cardVenue', {
        'card--archived': venue.archived
      })}
      title={venue.name}
      {...rest}
    >
      {venue.archived && (
        <div className="card--archived__overlay">
          <Button
            onClick={() => {
              restoreVenue(venue.id);
              addNotification('Venue restored successfully.', 'success');
            }}
          >
            Restore
          </Button>
        </div>
      )}

      {!venue.archived && fn && icon && (
        <ConfirmWindow action={fn} title={`Archive venue`} content={<ConfirmWindowVenueContent venue={venue} />}>
          <Icon icon={icon} />
        </ConfirmWindow>
      )}

      <Image className="cardVenue__image" url={undefined} placeholder="venue" />
      <Title is3 className="cardVenue__title">
        {venue.name}
      </Title>

      <div className="cardVenue__teams">
        {teams.length > 0 && (
          <ToolTip
            reference={() => <Image className="cardVenue__teams__team" url={teams[0].logo?.url} placeholder="team" />}
          >
            {teams[0].name}
          </ToolTip>
        )}
        {teams.length === 2 && (
          <ToolTip
            reference={() => <Image className="cardVenue__teams__team" url={teams[1].logo?.url} placeholder="team" />}
          >
            {teams[1].name}
          </ToolTip>
        )}
        {teams.length > 2 && (
          <ToolTip reference={() => <div className="cardVenue__teams__number">+{teams.length - 1}</div>}>
            <ul className="cardVenue__teams__teamList">
              {teams.slice(1).map(t => (
                <li key={`${venue.id}.${t.id}`}>
                  <Image className="cardVenue__teams__teamList__image" url={t.logo?.url} placeholder="team" />
                  {t.name}
                </li>
              ))}
            </ul>
          </ToolTip>
        )}
      </div>
    </Card>
  );
};
