import { updateQueryUrl } from '@ligr/shared';
import queryString from 'query-string';
import React from 'react';
import { useHistory } from 'react-router';
import { Page } from '../../../components/page/Page';
import { PageContent } from '../../../components/page/PageContent';
import { Pagination } from '../../../components/Pagination/Pagination';
import { useMatchLoad } from '../../../lib/useMatchLoad';
import { usePagination } from '../../../lib/usePagination';
import { matchesSubscription } from '../matches.qql';
import { MatchesList } from '../MatchesList';
import { MatchHeader } from '../MatchHeader';

export const CompletedMatchesPage: React.FunctionComponent = props => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const tab = history.location.pathname.split('/')[4];
  const { page = '1', pageSize = '12' } = queryString.parse(history.location.search);
  const pageNumber = parseInt(page as string);
  const pageSizeNumber = parseInt(pageSize as string);

  const { matches, loading, count, setStartDate, setEndDate } = useMatchLoad({
    matchesSubscription,
    pageNumber,
    pageSizeNumber
  });

  const { pageCount, totalCount } = usePagination({ pageNumber, pageSize: pageSizeNumber, count });

  return (
    <Page type="matches">
      <PageContent>
        <MatchHeader tab={tab} setStartDate={setStartDate} setEndDate={setEndDate} />

        <MatchesList matches={matches} loading={loading} count={count} />

        {pageNumber && totalCount > pageSizeNumber ? (
          <div className="matches__pagination">
            <Pagination
              forcePage={pageNumber - 1}
              initialPage={pageNumber - 1}
              onPageChange={({ selected }: { selected: number }) => updateQueryUrl(history, { page: selected + 1 })}
              pageCount={pageCount}
            />
          </div>
        ) : null}
      </PageContent>
    </Page>
  );
};
