import './style.scss';

import React from 'react';
import { statKeyToNameMapBySport, Image } from '@ligr/shared';
import { SportName } from '@ligr/api-v2/dist/gql/entities/Sport';

import { MatchStats } from '../../../../api-types';

interface TeamStatsTabProps {
  matchStats: MatchStats;
  sportName: string;
  homeTeamLogoUrl?: string;
  awayTeamLogoUrl?: string;
}

export const TeamStatsTab: React.FunctionComponent<TeamStatsTabProps> = ({
  matchStats: {
    home: { __typename, ...homeStats },
    away
  },
  sportName,
  homeTeamLogoUrl,
  awayTeamLogoUrl
}) => {
  return (
    <div className="teamStatsTab">
      <div className="teamStatsTab__card">
        <div className="statRow statRow--header">
          <Image className="statRow__homeLogo" url={homeTeamLogoUrl} />
          <div className="statRow__vs">VS</div>
          <Image className="statRow__awayLogo" url={awayTeamLogoUrl} />
        </div>
        {Object.keys(homeStats).map(item => {
          return (
            <div key={item} className="statRow">
              {/*
 // @ts-ignore */}
              <div className="statRow__homeStat">{homeStats[item]}</div>
              <div className="statRow__stat">
                {/*
 // @ts-ignore */}
                {statKeyToNameMapBySport[sportName as SportName][item]}
              </div>
              {/*
 // @ts-ignore */}
              <div className="statRow__awayStat">{away[item]}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
