import { Icon } from '@ligr/shared';
import classnames from 'classnames';
import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import ReactTooltip from 'react-tooltip';
import { Button } from '../Button';
import { ButtonProps } from '../Button/type';
import { Title } from '../Title/Title';
import './modal.scss';

export interface ModalProps {
  title?: string;
  onClose?: () => void;
  onSuccess?: (newPlayerId: number) => void;
  footerButtons?: (ButtonProps & { text: string })[];
  className?: string;
}

const closeModal = (close: (() => void) | undefined) => (ev: KeyboardEvent) => {
  if (ev.keyCode === 27 && close) {
    close();
  }
};
export class Modal extends Component<ModalProps> {
  el = document.createElement('div');
  modalRoot?: HTMLElement;

  componentDidMount() {
    this.modalRoot = document.getElementById('modal-root')!;
    this.modalRoot.appendChild(this.el);
    this.el.classList.add('modal');
    this.el.addEventListener('click', e => {
      if (e.target === this.el && this.props.onClose) this.props.onClose();
    });
    document.addEventListener('keydown', closeModal(this.props.onClose));
    ReactTooltip.rebuild();
  }

  componentWillUnmount() {
    if (this.modalRoot) this.modalRoot.removeChild(this.el);
    document.removeEventListener('keydown', closeModal(undefined));
  }

  render() {
    const modal = <DefaultModal {...this.props} />;

    return createPortal(modal, this.el);
  }
}

const DefaultModal: React.FunctionComponent<ModalProps> = ({ onClose, children, footerButtons, title, className }) => {
  return (
    <aside className={classnames('modalContent rounded', className)}>
      {title && (
        <header className="modalContent__header">
          <Title is2>{title}</Title>
          <div className="close" onClick={onClose}>
            <Icon icon="close" color="grey-600" />
          </div>
        </header>
      )}

      <main className="modalContent__main"> {children} </main>

      {footerButtons && (
        <footer className="modalContent__footer">
          {footerButtons.map(({ text, ...props }, idx) => (
            <Button key={idx} {...props}>
              {text}
            </Button>
          ))}
        </footer>
      )}
    </aside>
  );
};
