import gql from 'graphql-tag';

export const clubsAndTeamsQuery = gql`
  query clubsAndTeams($options: IQueryOptions) {
    clubsAndTeams(options: $options) {
      ... on Club {
        id
        name
        archived
        logo {
          id
          url
        }
        teams {
          id
          age
          gender
          archived
          grade {
            id
            name
          }
          countMatches
          countPlayers
          archived
          sport {
            id
            name
          }
          competitions {
            id
            name
            logo {
              id
              url
            }
            sport {
              id
              name
            }
          }
        }
      }
      ... on Team {
        id
        name
        countMatches
        countPlayers
        archived
        logo {
          id
          url
        }
        sport {
          id
          name
        }
        age
        gender
        grade {
          id
          name
        }
        competitions {
          id
          name
          logo {
            id
            url
          }
          sport {
            id
            name
          }
        }
      }
    }
  }
`;
