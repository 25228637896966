import { RoleName, roles } from '@ligr/api-v2';
import capitalize from 'capitalize';
import React from 'react';
import { Form } from '../../../components/Form/Form';
import { FormProps } from '../../../components/Form/Form.types';
import { FormField } from '../../../components/FormField';
import './User.form.scss';
import { userFormSchema } from './User.form.validation';

export interface UserFormValues {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  role: RoleName;
}

interface UserFormContentProps extends FormProps<UserFormValues> {
  editing?: boolean;
}

const UserFormContent: React.FunctionComponent<UserFormContentProps> = ({ editing }) => {
  const options = Object.keys(roles)
    .filter(r => ![RoleName.owner, RoleName.overlay, RoleName.ligrAdmin].includes(r as RoleName))
    .map(r => ({ label: capitalize(r), value: r }));

  return (
    <div className="user-form-content">
      <div className="fields-group">
        <FormField name="firstName" type="text" label="First Name" disabled={editing} />
        <FormField name="lastName" type="text" label="Last Name" disabled={editing} />
      </div>
      <div className="fields-group">
        <FormField name="email" type="text" label="Email" disabled={editing} />
        <FormField name="role" type="select" options={options} label="Role" />
      </div>
    </div>
  );
};

export const UserForm: React.FunctionComponent<UserFormContentProps> = ({ onChange, editing = false, ...rest }) => (
  <Form {...rest} validationSchema={userFormSchema} className="create-user user-form" useConfirm={true}>
    <UserFormContent {...rest} onChange={onChange} editing={editing} />
  </Form>
);
