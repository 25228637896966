import React, { memo } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router';
import { AdAllocationEntityType, CompetitionsQuery, TeamQuery } from '../../../../api-types';
import { NavTab } from '../../../../components/NavTabs/NavTabs.types';
import { PageContent } from '../../../../components/page/PageContent';
import { PageHeader } from '../../../../components/PageHeader';
import { AdAllocationLink } from '../../../../components/PageHeader/PageHeaderAllocationButton';
import { PageMenu } from '../../../../components/PageMenu';
import { useErrorHandledQuery } from '../../../../lib/apolloHooks';
import { competitionsQuery } from '../../../../lib/gql/queries/competitions';
import { teamQuery } from '../../../../lib/gql/queries/team';
import { routes } from '../../../../router/Routes';
import { TeamsSideMenu } from '../TeamsSideMenu';
import { CustomizeTab } from './CustomizeTab';
import { PlayersTab } from './PlayersTab';
import { SettingsTab } from './SettingsTab';
import './style.scss';
import { TeamMenu } from './TeamMenu';

export const TeamPage: React.FunctionComponent = () => {
  const { teamId: id } = useParams<{ teamId: string }>();
  const teamId = parseInt(id!);

  const { data, loading } = useErrorHandledQuery<TeamQuery>(teamQuery, {
    variables: { id: teamId, clubOverride: false }
  });
  const team = data ? data.team : null;

  // Load comps
  const { data: compsData, loading: compsLoading } = useErrorHandledQuery<CompetitionsQuery>(competitionsQuery, {
    variables: {}
  });

  const competitions = compsData
    ? compsData.competitions.filter(({ sport: { id } }) => !team || id === team.sport.id)
    : null;

  if (!team && !loading) return <Redirect to={routes.notFound()} />;

  const tabs: NavTab[] = [
    {
      text: 'General',
      to: routes.assetTeamSettings({ teamId })
    },
    {
      text: 'Players',
      to: routes.assetTeamPlayers({ teamId })
    },
    {
      text: 'Customize',
      to: routes.assetTeamCustomize({ teamId })
    }
  ];

  return (
    <>
      <PageMenu title="Clubs & Teams" component={TeamsSideMenu} />

      <PageContent className="teamPage" loading={loading || compsLoading}>
        {team && (
          <>
            <PageHeader
              title={team.name}
              tabs={tabs}
              preTitle={''}
              close={routes.assetTeams()}
              tabRowEndComponent={() => <AdAllocationLink entity={team} entityType={AdAllocationEntityType.team} />}
              editMenu={() => team && <TeamMenu team={team} />}
            />

            <Switch>
              <Route path={tabs[0].to} render={() => <SettingsTab competitions={competitions || []} team={team} />} />
              <Route path={tabs[1].to} render={props => <PlayersTab team={team} {...props} />} />
              <Route path={tabs[2].to} render={() => <CustomizeTab team={team} competitions={competitions || []} />} />
              <Redirect to={tabs[0].to as string} />
            </Switch>
          </>
        )}
      </PageContent>
    </>
  );
};
