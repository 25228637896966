import { updateQueryUrl } from '@ligr/shared';
import classnames from 'classnames';
import deepEqual from 'deep-equal';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Page } from '../../../components/page/Page';
import { PageContent } from '../../../components/page/PageContent';
import { Pagination } from '../../../components/Pagination/Pagination';
import { useErrorHandledMutation } from '../../../lib/apolloHooks';
import { useMatchLoad } from '../../../lib/useMatchLoad';
import { usePagination } from '../../../lib/usePagination';
import {
  createOverlayMutation,
  deleteOverlayMutation,
  matchesSubscription,
  updateOverlayMutation
} from '../matches.qql';
import { MatchesTable } from '../MatchesTable';
import { MatchHeader } from '../MatchHeader';
import './style.scss';
import {
  UpdateOverlayMutation,
  DeleteOverlayMutation,
  CreateOverlayMutation,
  Match,
  Overlay
} from '../../../api-types';

const missingExpectedCoverage = (match: Match) => match.baseEntity?.id && !match.coverage;

interface MatchOverlayRow {
  match: Match;
  overlay: Overlay | null;
  subRows: any[];
  expanded: boolean;
  classNames?: string;
}

const createMatchRow = (m: Match) => {
  m.date = new Date(m.date);
  const classNames = classnames('matchesTable__row', {
    'matchesTable__row--missingCoverage': missingExpectedCoverage(m),
    'matchesTable__row--archived': m.archived
  });
  const obj: MatchOverlayRow = {
    match: m,
    overlay: m.overlays.length ? { ...m.overlays[0] } : null,
    subRows: [],
    expanded: true,
    classNames
  };
  if (m.overlays.length > 1) {
    // @ts-ignore
    obj.subRows = m.overlays.slice(1).map(overlay => ({ match: m, overlay, classNames }));
  }
  return obj;
};

export const NotStartedMatchesPage: React.FunctionComponent = () => {
  const history = useHistory<{ referrer?: string } | undefined>();
  const tab = history.location.pathname.split('/')[4];
  const { page = '1', pageSize = '25' } = queryString.parse(history.location.search);
  const pageNumber = parseInt(page as string);
  const pageSizeNumber = parseInt(pageSize as string);

  const {
    fetchMatches,
    matches,
    loading: matchesLoading,
    count: matchesCount,
    setStartDate,
    setEndDate,
    variables
  } = useMatchLoad({ matchesSubscription });

  const { pageCount, totalCount } = usePagination({ pageNumber, pageSize: pageSizeNumber, count: matchesCount });

  const countString =
    pageNumber && totalCount > pageSizeNumber
      ? `Showing ${1 + (pageNumber - 1) * pageSizeNumber}-${
          pageNumber * pageSizeNumber < totalCount ? pageNumber * pageSizeNumber : totalCount
        } of ${totalCount} Matches`
      : `Showing ${totalCount} Matches`;

  // MUTATIONS ---------------------------------
  const [updateOverlayM, { error: updateError }] = useErrorHandledMutation<UpdateOverlayMutation>(
    updateOverlayMutation
  );

  const [deleteOverlayM, { error: deleteError }] = useErrorHandledMutation<DeleteOverlayMutation>(
    deleteOverlayMutation
  );

  const [createOverlayM, { error: createError }] = useErrorHandledMutation<CreateOverlayMutation>(
    createOverlayMutation
  );
  // ----------------------------------------------------------

  const rows = useMemo<MatchOverlayRow[]>(() => {
    return matches.map(match => {
      return createMatchRow(match);
    });
  }, [matches]);

  const [usedRows, setUsedRows] = useState<MatchOverlayRow[]>([]);

  useEffect(() => {
    setUsedRows(
      rows.map((row, index) => {
        // Prevents row rerender by preserving references in the case of no changes between
        // update of matches
        if (deepEqual(row, usedRows[index])) {
          return usedRows[index];
        }
        return row;
      })
    );
  }, [rows]);

  // In the case of error reset the table
  useEffect(() => {
    if (updateError || createError || deleteError) {
      setUsedRows(rows);
    }
  }, [updateError, createError, deleteError]);

  return (
    <Page type="matches">
      <PageContent>
        <MatchHeader tab={tab} createButton={true} setStartDate={setStartDate} setEndDate={setEndDate} />
        <MatchesTable
          matchLoading={matchesLoading}
          rows={usedRows}
          variables={variables}
          actions={{ createOverlayM, updateOverlayM, deleteOverlayM }}
          hasPagination={pageCount > 1}
        />

        {pageNumber && totalCount && !matchesLoading ? (
          totalCount > pageSizeNumber ? (
            <div className="matches__pagination">
              <Pagination
                forcePage={pageNumber - 1}
                initialPage={pageNumber - 1}
                onPageChange={({ selected }: { selected: number }) => updateQueryUrl(history, { page: selected + 1 })}
                pageCount={pageCount}
              />
              <div>{countString}</div>
            </div>
          ) : (
            <div className="matches__pagination">
              <div>{countString}</div>
            </div>
          )
        ) : null}
      </PageContent>
    </Page>
  );
};
