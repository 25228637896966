import React from 'react';
import { LoaderAssetCard } from '../Loaders/LoaderAssetCard';
import './grid.scss';
import { GridProps } from './Grid.types';

export const Grid: React.FunctionComponent<GridProps> = ({ spacing, children, loading, minColWidth = 260 }) => (
  <div
    className="entity-grid"
    style={{
      gridGap: spacing,
      gridTemplateColumns: `repeat(auto-fill, minmax(${minColWidth}px, 1fr))`
    }}
  >
    {loading ? (
      <>
        <LoaderAssetCard />
        <LoaderAssetCard />
        <LoaderAssetCard />
        <LoaderAssetCard />
      </>
    ) : (
      children
    )}
  </div>
);
