import './loaders.scss';

import React from 'react';

import { LoaderText } from './LoaderText';
import { LoaderButton } from './LoaderButton';
import { Grid } from '../Grid/Grid';
import { PageContentContent } from '../page/PageContentContent';

export const LoaderPageContent: React.FunctionComponent = () => (
  <div className="loader-page-content">
    <div className="pageHeader">
      <div className="heading">
        <LoaderText size="small" />
        <LoaderText />
      </div>
    </div>
    <PageContentContent>
      <div className="pageSubHeader">
        <LoaderText />
        <LoaderButton />
      </div>
      <Grid loading={true} />
    </PageContentContent>
  </div>
);
