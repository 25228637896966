import classnames from 'classnames';
import { FormikHelpers, useFormikContext } from 'formik';
import gql from 'graphql-tag';
import React, { useMemo, useState } from 'react';
import { mixed, object, string } from 'yup';
import { UpdateTeamCustomizeMutation, UpdateTeamInput, VenuesQuery } from '../../../../api-types';
import { Button } from '../../../../components/Button';
import { ButtonBottom as BottomButtonRow } from '../../../../components/ButtonBottom/ButtonBottom';
import { Form } from '../../../../components/Form/Form';
import { FormField } from '../../../../components/FormField';
import { Loader } from '../../../../components/loader/Loader';
import { Title } from '../../../../components/Title/Title';
import { getGQLError } from '../../../../lib/apolloClient';
import { useErrorHandledMutation, useErrorHandledQuery } from '../../../../lib/apolloHooks';
import { deleteFileMutation } from '../../../../lib/gql/mutations/deleteFile';
import { teamQuery } from '../../../../lib/gql/queries/team';
import { venuesQuery } from '../../../../lib/gql/queries/venues';
import { Notification } from '../../../../lib/notificationContainer';
import { logoValidation } from '../../../../lib/util/imageValidation';

const validationCustomizeSchema = object().shape({
  name: string().required().min(2).label('Name'),
  gfxFullName: string().nullable().min(4).max(35).label('Full name'),
  gfxName: string().nullable().min(4).max(19).label('Medium name'),
  abbreviation: string().nullable().min(2).max(4).label('Short name'),
  primaryBackgroundColor: string().nullable().label('Primary color'),
  primaryTextColor: string().nullable().label('Primary text color'),
  secondaryBackgroundColor: string().nullable().label('Secondary color'),
  secondaryTextColor: string().nullable().label('Secondary text color'),
  logo: mixed().test('logo', 'Invalid logo', async function (value) {
    if (value) {
      const { logo } = await logoValidation([{ logo: value }]);
      if (logo) {
        return this.createError({
          path: this.path,
          message: logo
        });
      }
    }
    return true;
  })
});

export interface CustomizeTabProps {
  team: {
    id: number;
    name: string;
    gfxName?: string | null;
    gfxFullName?: string | null;
    abbreviation?: string | null;
    primaryBackgroundColor?: string | null;
    primaryTextColor?: string | null;
    secondaryBackgroundColor?: string | null;
    secondaryTextColor?: string | null;
    logo?: {
      url: string;
    } | null;
    club?: {
      id: number;
    } | null;
    defaultVenue?: {
      id: number;
    } | null;
    baseEntity?: {
      id: number;
      name: string;
      gfxFullName: string;
      gfxName: string;
      abbreviation: string;
    } | null;
  };
  loading?: boolean;
  competitions?: { id: number; name: string; sportId: number }[] | null;
}

type CustomizeFormProps = CustomizeTabProps;

const updateTeamMutationCustomize = gql`
  mutation updateTeamCustomize($updateTeamInput: UpdateTeamInput!, $clubOverride: Boolean) {
    updateTeam(updateTeamInput: $updateTeamInput, clubOverride: $clubOverride) {
      id
      name
      gfxName
      gfxFullName
      abbreviation
      primaryBackgroundColor
      primaryTextColor
      secondaryBackgroundColor
      secondaryTextColor
      defaultVenueId
      logo {
        id
        url
      }
    }
  }
`;

type Deletable = {
  id: number;
};

const CustomizeForm: React.FunctionComponent<CustomizeFormProps> = ({ team, competitions, loading }) => {
  const form = useFormikContext<UpdateTeamInput>();
  const { data, loading: initialLoading } = useErrorHandledQuery<VenuesQuery>(venuesQuery);
  const [deleteFile] = useErrorHandledMutation<boolean>(deleteFileMutation);

  const handleDelete = async (name: string) => {
    const id = (team[name as keyof typeof team] as Deletable)?.id;

    if (id) {
      await deleteFile({
        variables: { id },
        update: (cache: any, { data }: any) => {
          const teamCache = cache.readQuery({ query: teamQuery, variables: { id: team.id, clubOverride: false } });
          if (teamCache && data.deleteFile) {
            cache.writeQuery({
              query: teamQuery,
              variables: { id: team.id },
              data: {
                team: {
                  ...teamCache.team,
                  [name]: null
                }
              }
            });
          }
        }
      });
      form?.resetForm();
    }
  };

  if (initialLoading) return <Loader />;

  return (
    <>
      <div>
        <Title is3>Team name & venue</Title>
        <FormField name="name" type="text" label="Team Name" originalValue={team?.baseEntity?.name} />
        <FormField
          name="defaultVenueId"
          type="select"
          placeholder={'Select Venue'}
          options={data ? data.venues.map(v => ({ label: v.name, value: v.id })) : []}
          label="Default Venue"
        />
        <Title is3>GFX Names</Title>
        <FormField name="gfxFullName" type="text" label="Full Name" originalValue={team?.baseEntity?.gfxFullName} />
        <FormField name="gfxName" type="text" label="Medium Name" originalValue={team?.baseEntity?.gfxName} />
        <FormField name="abbreviation" type="text" label="Short Name" originalValue={team?.baseEntity?.abbreviation} />
      </div>
      <div>
        <Title is3>Team logo</Title>
        <FormField
          name="logo"
          type="image"
          colSpan={2}
          src={team?.logo?.url}
          // @ts-ignore
          editables
          onDelete={() => handleDelete('logo')}
        />
        <p className="pro-tip">Pro Tip: Upload a logo with a transparent background, and no padding around the logo.</p>
        <Title is3>Team colors</Title>
        <div className="team-page-customize__colors">
          <FormField name="primaryBackgroundColor" type="color" label="Primary Color" colSpan={1} />
          <FormField name="primaryTextColor" type="color" label="Primary Text Color" colSpan={1} />
          <FormField name="secondaryBackgroundColor" type="color" label="Secondary Color" colSpan={1} />
          <FormField name="secondaryTextColor" type="color" label="Secondary Text Color" colSpan={1} />
        </div>
        <p className="pro-tip">
          Pro Tip: If the <span className="bold">home</span> and <span className="bold">away</span> teams for a match
          have <span className="bold">Primary Colors</span> that are too similar the{' '}
          <span className="bold">Secondary Color </span>
          set of the away team will be used in the graphics for that match.
          <span>
            If the <span className="bold">Secondary Color</span> set is not provided for the{' '}
            <span className="bold">away team</span> the <span className="bold">Primary Background</span> and the{' '}
            <span className="bold">Primary Text</span> colors of the <span className="bold">away team</span> will be
            used.
          </span>
        </p>
      </div>
      {form?.dirty && (
        <BottomButtonRow withResetFormButton resetOnClick={() => form.resetForm()}>
          <Button
            onClick={event => {
              event.preventDefault();
              form.submitForm();
            }}
            isLoading={loading}
            type="primary"
          >
            Save
          </Button>
        </BottomButtonRow>
      )}
    </>
  );
};

export const CustomizeTab: React.FunctionComponent<CustomizeTabProps> = ({ team, competitions = [], loading }) => {
  const [error, setError] = useState('');
  const [updateTeam] = useErrorHandledMutation<UpdateTeamCustomizeMutation>(updateTeamMutationCustomize);
  const { addNotification } = Notification.useContainer();

  const initialValues = useMemo(
    () => ({
      id: team.id,
      name: team.name || '',
      gfxName: team.gfxName || null,
      gfxFullName: team.gfxFullName || null,
      abbreviation: team.abbreviation || null,
      primaryBackgroundColor: team.primaryBackgroundColor || null,
      primaryTextColor: team.primaryTextColor || null,
      secondaryBackgroundColor: team.secondaryBackgroundColor || null,
      secondaryTextColor: team.secondaryTextColor || null,
      logo: undefined,
      defaultVenueId: team.defaultVenue?.id
    }),
    [team]
  );

  const update = async (
    updateTeamInput: UpdateTeamInput,
    formikHelpers: FormikHelpers<UpdateTeamInput>
  ): Promise<void> => {
    try {
      const inp = {
        ...updateTeamInput,
        defaultVenueId: updateTeamInput.defaultVenueId ? parseInt(`${updateTeamInput.defaultVenueId}`) : null,
        secondaryBackgroundColor: updateTeamInput.secondaryBackgroundColor || null,
        secondaryTextColor: updateTeamInput.secondaryTextColor || null,
        id: team.id
      } as UpdateTeamInput;
      await updateTeam({
        variables: { updateTeamInput: inp, clubOverride: false },
        update: (cache: any, { data }: any) => {
          const teamCache = cache.readQuery({ query: teamQuery, variables: { id: team.id, clubOverride: false } });
          if (teamCache && data.deleteFile) {
            cache.writeQuery({
              query: teamQuery,
              variables: { id: team.id },
              data: {
                team: {
                  ...teamCache.team
                }
              }
            });
          }
        }
      });
      addNotification('Saved successfully', 'success');
      formikHelpers.resetForm();
    } catch (e) {
      setError(getGQLError(e).message);
    }
  };

  const resetToClubDefault = async () => {
    try {
      const inp = {
        defaultVenueId: null,
        gfxName: null,
        gfxFullName: null,
        abbreviation: null,
        primaryBackgroundColor: null,
        primaryTextColor: null,
        secondaryBackgroundColor: null,
        secondaryTextColor: null,
        id: team.id
      } as UpdateTeamInput;
      await updateTeam({
        variables: { updateTeamInput: inp, clubOverride: false },
        update: (cache: any, { data }: any) => {
          const teamCache = cache.readQuery({ query: teamQuery, variables: { id: team.id, clubOverride: false } });
          if (teamCache && data.deleteFile) {
            cache.writeQuery({
              query: teamQuery,
              variables: { id: team.id },
              data: {
                team: {
                  ...teamCache.team
                }
              }
            });
          }
        }
      });
      addNotification('Reset successfully', 'success');
    } catch (e) {
      setError(getGQLError(e).message);
    }
  };

  return (
    <Form
      onSubmit={update}
      error={error}
      className={classnames('page-form team-page-customize', { 'team-page-customize--with-club': team.club?.id })}
      validationSchema={validationCustomizeSchema}
      withSubmit
      initialValues={initialValues}
      useConfirm={true}
    >
      {team.club?.id && (
        <section className="club-info">
          <div>
            Editing any fields here will overwrite the default inherited club settings. If you wish to make a change
            across all teams within a club, edit the parent club
          </div>
          <Button
            icon="refresh"
            type="secondary"
            onClick={e => {
              e.preventDefault();
              resetToClubDefault();
            }}
          >
            Reset to club default
          </Button>
        </section>
      )}
      <CustomizeForm team={team} competitions={competitions} loading={loading} />
    </Form>
  );
};
