import gql from 'graphql-tag';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useErrorHandledMutation, useErrorHandledQuery } from '../../lib/apolloHooks';
import { routes } from '../../router/Routes';
import { Button } from '../Button';
import { AdAllocationEntityType, AdAllocationQuery, CreateEntityAdAllocationMutation } from '../../api-types';

const createEntityAdAllocation = gql`
  mutation createEntityAdAllocation($entityAdAllocationInput: EntityAdAllocationInput!) {
    createEntityAdAllocation(entityAdAllocationInput: $entityAdAllocationInput) {
      entityId
      entityType
      id
    }
  }
`;

const findAdAllocation = gql`
  query adAllocation($adAllocationArgs: AdAllocationArgs!) {
    adAllocation(adAllocationArgs: $adAllocationArgs) {
      entityId
      entityType
      id
    }
  }
`;

type Team = {
  id: number;
};

type Competition = {
  id: number;
};

type AdAllocationLinkProps = {
  entity: Competition | Team;
  entityType: AdAllocationEntityType;
};

export const AdAllocationLink: React.FunctionComponent<AdAllocationLinkProps> = ({ entityType, entity }) => {
  const { data: adSetAllocation, loading, error } = useErrorHandledQuery<AdAllocationQuery>(findAdAllocation, {
    variables: {
      adAllocationArgs: {
        entityType,
        entityId: entity.id
      }
    }
  });

  const adAllocation = adSetAllocation ? adSetAllocation.adAllocation[0] : undefined;

  const [createAllocation] = useErrorHandledMutation<CreateEntityAdAllocationMutation>(createEntityAdAllocation);
  const history = useHistory<{ referrer?: string } | undefined>();

  if (loading) {
    return null;
  }

  return adAllocation ? (
    <Button
      type="borderless"
      iconPosition="right"
      icon="arrowRight"
      onClick={async () => {
        history.push({
          pathname: routes.adSetsAllocationDetails({ entityId: entity.id, entityType })
        });
      }}
    >
      Sponsor allocation
    </Button>
  ) : (
    <Button
      type="borderless"
      onClick={async () => {
        try {
          await createAllocation({
            variables: {
              entityAdAllocationInput: {
                entityType,
                entityId: entity.id
              }
            }
          });
          history.push({
            pathname: routes.adSetsAllocationDetails({ entityId: entity.id, entityType })
          });
        } catch (error) {
          console.log(error);
        }
      }}
    >
      Add Sponsor Allocation
    </Button>
  );
};
