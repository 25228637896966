import React from 'react';
import { useClock, LiveClockState } from '@ligr/shared';

import { Title } from '../../../../components/Title/Title';
import { TMatch } from './Scores';

export const ScoresMatchesCard: React.FunctionComponent<{ match: TMatch }> = ({ match }) => {
  const liveClockState = useClock({ ...match, sport: { name: match.sportName } }, match.summary) as LiveClockState;

  return (
    <div className="scoresMatchesCard">
      <div className="scoresMatchesCard__status">
        <Title is4 hasTextRed className="scoresMatchesCard__liveStatus">
          {match.liveStatus}
        </Title>
        <div className="scoresMatchesCard__timeStatus">{liveClockState?.clock}</div>
      </div>
      {['homeTeam', 'awayTeam'].map(t => {
        return (
          <div key={t} className={`scoresMatchesCard__teamsScore`}>
            <div className={`scoresMatchesCard__team`}>
              <img src={match[t as 'homeTeam' | 'awayTeam'].logo?.url} />
              <div>{match[t as 'homeTeam' | 'awayTeam'].abbreviation?.toUpperCase()}</div>
            </div>
            <div className="scoresMatchesCard__score">{match.score[t === 'homeTeam' ? 'home' : 'away']}</div>
          </div>
        );
      })}
    </div>
  );
};
