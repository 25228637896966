import { Icon } from '@ligr/shared';
import { useFormikContext } from 'formik';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Age, Gender, GradesQuery, UpdateCompetitionInput } from '../../../../../api-types';
import { Button } from '../../../../../components/Button';
import { ButtonBottom as BottomButtonRow } from '../../../../../components/ButtonBottom/ButtonBottom';
import { FormProps } from '../../../../../components/Form/Form.types';
import { FormField } from '../../../../../components/FormField';
import { Loader } from '../../../../../components/loader/Loader';
import { Title } from '../../../../../components/Title/Title';
import { useErrorHandledMutation, useErrorHandledQuery } from '../../../../../lib/apolloHooks';
import { deleteFileMutation } from '../../../../../lib/gql/mutations/deleteFile';
import { competitionQuery } from '../../../../../lib/gql/queries/competition';
import { gradesQuery } from '../../../../../lib/gql/queries/grades';
import { MatchFormConfigFields } from '../../../../Matches/MatchFormConfigFields';
import { CompFormValues } from '../CompetitionSettings.page';
import './style.scss';

const waterMarkText = (
  <div>
    <p>500 kB or less, 300 x 300 pixels. Drop any .jpg, .png, .gif, or browse your files.</p>
    <p>Pro Tip: Upload a logo with a transparent background, and no padding around the logo.</p>
  </div>
);

export const CompetitionSettingsForm: React.FunctionComponent<FormProps<CompFormValues>> = ({
  competition,
  loading
}) => {
  const form = useFormikContext<UpdateCompetitionInput>();
  const [deleteFile] = useErrorHandledMutation<boolean>(deleteFileMutation);

  const { data, loading: loadingGrades } = useErrorHandledQuery<GradesQuery>(gradesQuery, {
    variables: {}
  });

  const grades = (data && data.grades) || [];

  if (loadingGrades) return <Loader />;

  const externalDataEnabled = !!competition?.baseEntity;

  const handleDelete = async (name: string) => {
    const id = competition[name]?.id;

    if (id) {
      await deleteFile({
        variables: { id },
        update: (cache: any, { data }: any) => {
          const competitionCache = cache.readQuery({ query: competitionQuery, variables: { id: competition.id } });
          if (competitionCache && data.deleteFile) {
            cache.writeQuery({
              query: competitionQuery,
              variables: { id: competition.id },
              data: {
                competition: {
                  ...competitionCache.competition,
                  [name]: null
                }
              }
            });
          }
        }
      });
      form!.resetForm();
    }
  };

  return (
    <>
      <section className="competitionSettings__general">
        <Title is3>General details</Title>
        <FormField
          name="name"
          type="text"
          label="Competition name"
          colSpan={2}
          originalValue={competition?.baseEntity?.name}
        />
        <FormField name="gfxName" type="text" label="Gfx name" colSpan={2} />
        <FormField
          name="age"
          type="select"
          label="Age group"
          options={Object.values(Age).map(age => ({ label: age, value: age }))}
        />
        <FormField
          name="gender"
          type="select"
          label="Gender"
          options={Object.values(Gender).map(gender => ({ label: gender, value: gender }))}
        />
        <FormField
          name="gradeId"
          type="select"
          label="Division"
          options={grades.map(grade => ({ label: grade.name, value: grade.id }))}
        />
        <FormField
          name="logo"
          label="Competition logo"
          type="image"
          colSpan={2}
          src={competition?.logo?.url}
          editable
          onDelete={() => handleDelete('logo')}
          tooltip={waterMarkText}
        />
        <FormField
          name="watermark"
          label="Primary watermark"
          type="image"
          colSpan={2}
          src={competition?.watermark?.url}
          editable
          onDelete={() => handleDelete('watermark')}
          tooltip={waterMarkText}
        />
        <FormField
          name="secondaryWatermark"
          label="Secondary watermark"
          type="image"
          colSpan={2}
          src={competition?.secondaryWatermark?.url}
          editable
          onDelete={() => handleDelete('secondaryWatermark')}
          tooltip={waterMarkText}
        />
      </section>

      <section className="competitionSettings__overlay">
        <Title is3>Default overlay settings</Title>
        <FormField
          name="overlayConfig.resolution"
          type="select"
          label="Resolution"
          options={[
            { label: 540, value: 540 },
            { label: 720, value: 720 },
            { label: 1080, value: 1080 }
          ]}
        />
        <div className="gridColumns__2">
          <FormField name="overlayConfig.showWatermarks" type="switch" label="Watermarks" colSpan={1} />
          <FormField name="overlayConfig.enableAds" type="switch" label="Show ads" colSpan={1} />
        </div>
      </section>

      <section
        className={`competitionSettings__match ${externalDataEnabled ? 'competitionSettings__match--disabled' : ''}`}
      >
        <Title is3>
          Default match settings
          {externalDataEnabled && (
            <Icon className="competitionSettings__match__icon" icon="lock" data-for="config-external-data" data-tip />
          )}
        </Title>
        {MatchFormConfigFields[competition.sport.name]({ disabled: externalDataEnabled, form })}
      </section>

      {form?.dirty && (
        <BottomButtonRow withResetFormButton resetOnClick={() => form.resetForm()}>
          <Button
            onClick={event => {
              event.preventDefault();
              form.submitForm();
            }}
            type="primary"
            isDisabled={!form.isValid}
            isLoading={loading}
          >
            {' '}
            Save{' '}
          </Button>
        </BottomButtonRow>
      )}
      {externalDataEnabled && (
        <ReactTooltip id="config-external-data" place="left" effect="solid">
          You can override default match settings at the match level when using LIGR Livescore data
        </ReactTooltip>
      )}
    </>
  );
};
