import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { object, string } from 'yup';
import { Form } from '../../../../components/Form/Form';
import { PageContent } from '../../../../components/page/PageContent';
import { PageHeader } from '../../../../components/PageHeader';
import { PageMenu } from '../../../../components/PageMenu';
import { getGQLError } from '../../../../lib/apolloClient';
import { useErrorHandledMutation, useErrorHandledQuery } from '../../../../lib/apolloHooks';
import { updateVenueMutation } from '../../../../lib/gql/mutations/updateVenue';
import { venueQuery } from '../../../../lib/gql/queries/venue';
import { Notification } from '../../../../lib/notificationContainer';
import { routes } from '../../../../router/Routes';
import { VenueSideMenu } from '../VenueSideMenu/VenueSideMenu';
import { VenueMenu } from './VenueMenu';
import { VenueForm } from './VenuePageForm';
import { UpdateVenueMutation, VenueQuery } from '../../../../api-types';
import './style.scss';

export interface VenuePageProps {
  venueId: string;
}

type VenueFormValue = {
  id?: number;
  name: string;
  gfxName: string;
  lat?: number | null;
  lng?: number | null;
  city?: string | null;
  country?: string | null;
  state?: string | null;
  address?: string | null;
  postcode?: string | null;
};

const validationSchema = object().shape({
  name: string().required().label('Name'),
  gfxName: string().max(40).nullable().label('Gfx name')
});

export const VenuePage: React.FunctionComponent = () => {
  const [form, setForm] = useState<any | undefined>();

  const { venueId } = useParams<{ venueId: string }>();
  const id = parseInt(venueId!);
  const { data, loading: initialLoading } = useErrorHandledQuery<VenueQuery>(venueQuery, { variables: { id } });
  const venue = data ? data.venue : null;
  const [update, { loading }] = useErrorHandledMutation<UpdateVenueMutation>(updateVenueMutation);
  const [error, setError] = useState('');

  const { addNotification } = Notification.useContainer();

  const submit = async (values: VenueFormValue) => {
    try {
      const venue = { ...values };
      await update({ variables: { updateVenueInput: venue } });
      addNotification('Venue successfully updated.', 'success');
      form?.resetForm();
    } catch (e) {
      // @ts-ignore
      form.setFieldError(getGQLError(e).details.field, getGQLError(e).message);
    }
  };

  return (
    <>
      <PageMenu title="Venues" component={VenueSideMenu} />

      <PageContent loading={initialLoading} className="venuePage">
        <PageHeader
          title={venue?.name || ''}
          preTitle={''}
          close={routes.assetVenues()}
          editMenu={() => venue && <VenueMenu venue={venue} />}
        />
        {!initialLoading ? (
          <Form
            error={error}
            onSubmit={submit}
            className={`page-form venuePage__settings`}
            validationSchema={validationSchema}
            withSubmit
            initialValues={{
              id: venue?.id,
              name: venue?.name || '',
              gfxName: venue?.gfxName || '',
              lat: venue?.lat,
              lng: venue?.lng,
              city: venue?.city,
              country: venue?.country,
              state: venue?.state,
              address: venue?.address || '',
              postcode: venue?.postcode
            }}
            useConfirm={true}
            setForm={setForm}
          >
            <VenueForm venue={venue} loading={loading} />
          </Form>
        ) : null}
      </PageContent>
    </>
  );
};
