import React, { useState, useEffect } from 'react';
import { Prompt, useHistory } from 'react-router-dom';

import { Modal, ModalProps } from '../../components/modal/Modal';
import { Location } from 'history';

export interface ConfirmModalProps {
  title?: ModalProps['title'];
  message?: string;
  shouldShow: boolean;
}

export const ConfirmModal: React.FunctionComponent<ConfirmModalProps> = ({
  title = 'You have unsaved changes',
  message = 'You have unsaved changes. Are you sure you want to leave this page?',
  shouldShow
}) => {
  const [show, setShow] = useState(false);
  const [nextPath, setNextPath] = useState<string | undefined>();
  const [leaving, setLeaving] = useState(false);

  const history = useHistory<{ referrer?: string } | undefined>();

  const leavePage = () => setLeaving(true);

  useEffect(() => {
    if (leaving) {
      nextPath && history.replace(nextPath);
      setLeaving(false);
      setNextPath(undefined);
      setShow(false);
    }
  }, [leaving]);

  const handleBlockedNavigation = (location: Location<any>): boolean | string => {
    if (leaving) return true;
    setShow(true);
    setNextPath(location.pathname);
    return false;
  };

  return (
    <>
      <Prompt when={shouldShow} message={handleBlockedNavigation} />
      {show && (
        <Modal
          title={title}
          onClose={() => setShow(false)}
          footerButtons={[
            { text: 'Cancel', onClick: () => setShow(false), type: 'secondary' },
            { text: 'Leave page', onClick: leavePage }
          ]}
        >
          {message}
        </Modal>
      )}
    </>
  );
};
