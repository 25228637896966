import gql from 'graphql-tag';

export const competitionBasesQuery = gql`
  query competitionBases {
    competitionBases {
      id
      name
      providerId
      providerName
    }
  }
`;
